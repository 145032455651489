import { Subscription } from 'rxjs';

import {
  AfterContentInit,
  Directive,
  ElementRef,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { DOWN_ARROW } from '@angular/cdk/keycodes';

import {
  ReferencePickerComponent,
  ZWI_REFERENCE_PICKER_SCROLL_STRATEGY,
} from '../reference-picker/reference-picker.component';
import { MatFormField } from '@angular/material/form-field';

import { ReferenceAdapter } from '@zwiloo/verse/domain/reference-adapter';
import { ReferenceRangeAdapter } from '@zwiloo/verse/domain/reference-range-adapter';
import { ReferenceRange } from '@zwiloo/verse/domain/reference';
import {
  ReferenceFormats,
  ZWI_REFERENCE_FORMATS,
} from '@zwiloo/verse/domain/reference-formats';
import { ReferenceFormatter } from '@zwiloo/verse/domain/reference-formatter';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';

export const ZWI_REFERENCE_PICKER_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ReferencePickerInputDirective),
  multi: true,
};

// export const ZWI_REFERENCE_PICKER_VALIDATORS: any = {
//   provide: NG_VALIDATORS,
//   useExisting: forwardRef(() => ReferencePickerInputDirective),
//   multi: true,
// };

export class ZwiReferencePickerInputEvent {
  value: ReferenceRange | null;

  constructor(
    public target: ReferencePickerInputDirective,
    public targetElement: HTMLElement
  ) {
    this.value = this.target.value;
  }
}

// noinspection TypeScriptUnresolvedVariable
/** Directive used to connect input to ZwiReferencePicker */
@Directive({
  selector: 'input[zwiReferencePicker]',
  providers: [
    ZWI_REFERENCE_PICKER_VALUE_ACCESSOR,
    // ZWI_REFERENCE_PICKER_VALIDATORS,
    {
      provide: MAT_INPUT_VALUE_ACCESSOR,
      useExisting: ReferencePickerInputDirective,
    },
  ],
  host: {
    '[disabled]': 'disabled',
    '(input)': '_onInput($event.target.value)',
    '(change)': '_onChange()',
    '(blur)': '_onBlur()',
    '(keydown)': '_onKeydown($event)',
  },
  exportAs: 'zwiReferencePickerInput',
})
export class ReferencePickerInputDirective
  implements ControlValueAccessor, AfterContentInit, OnDestroy
{
  @Input()
  set zwiReferencePicker(value: ReferencePickerComponent) {
    this.registerReferencePicker(value);
  }
  _referencePicker!: ReferencePickerComponent;

  private registerReferencePicker(value: ReferencePickerComponent) {
    if (value) {
      this._referencePicker = value;
      this._referencePicker._registerInput(this);
    }
  }

  @Input()
  get value(): ReferenceRange | null {
    return this._value;
  }
  set value(value: ReferenceRange | null) {
    if (value !== null) {
      this._value = value;
      this._formatValue(value);
      this._valueChange.emit(value);
    }
  }
  private _value: ReferenceRange | null = null;

  @Input()
  get disabled(): boolean {
    return !!this._disabled;
  }
  set disabled(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    const element = this._elementRef.nativeElement;

    if (this._disabled !== newValue) {
      this._disabled = newValue;
    }

    if (newValue && element.blur) {
      element.blur();
    }
  }
  private _disabled: boolean = false;

  // Emits when an 'change' event is fired on this '<input>'
  @Output()
  readonly referenceChange: EventEmitter<ZwiReferencePickerInputEvent> = new EventEmitter();

  // Emits when an 'input' event is fired on this '<input>'
  @Output()
  readonly referenceInput: EventEmitter<ZwiReferencePickerInputEvent> = new EventEmitter();

  // Emits when the value changes (either due to user input or programattic change).
  _valueChange = new EventEmitter<ReferenceRange | null>();

  _disabledChange = new EventEmitter<boolean>();

  _onTouched = () => {};

  private _cvaOnChange: (value: any) => void = () => {};

  private _referencePickerSubscription = Subscription.EMPTY;

  constructor(
    private _elementRef: ElementRef,
    @Inject(ZWI_REFERENCE_FORMATS) private _referenceFormats: ReferenceFormats,
    public _referenceRangeAdapter: ReferenceRangeAdapter,
    @Optional() public _referenceAdapter: ReferenceAdapter,
    @Optional() private _formField: MatFormField
  ) {}

  ngAfterContentInit() {
    if (this._referencePicker) {
      this._referencePickerSubscription =
        this._referencePicker._selectedChanged.subscribe(
          (selected: ReferenceRange) => {
            this.value = selected;
            this._cvaOnChange(selected);
            this._onTouched();
            this.referenceInput.emit(
              new ZwiReferencePickerInputEvent(
                this,
                this._elementRef.nativeElement
              )
            );
            this.referenceChange.emit(
              new ZwiReferencePickerInputEvent(
                this,
                this._elementRef.nativeElement
              )
            );
          }
        );
    }
  }

  ngOnDestroy() {
    this._referencePickerSubscription.unsubscribe();
  }

  // Implemented as part of ControlValueAccessor
  writeValue(value: any): void {
    this.value = value;
  }

  // Implemented as part of ControlValueAccessor
  registerOnChange(fn: (value: any) => void): void {
    this._cvaOnChange = fn;
  }

  // Implemented as part of ControlValueAccessor
  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  // Implemented as part of ControlValueAccessor
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _onKeydown(event: KeyboardEvent) {
    if (event.altKey && event.keyCode === DOWN_ARROW) {
      this._referencePicker.open();
      event.preventDefault();
    }
  }

  getConnectedOverlayOrigin(): ElementRef {
    return this._formField
      ? this._formField.getConnectedOverlayOrigin()
      : this._elementRef;
  }

  _onInput(value: string) {
    const reference = this._referenceRangeAdapter.parse(value);
    if (this._value !== null) {
      if (!this._referenceRangeAdapter.isSame(reference, this._value)) {
        this._value = reference;
        this._valueChange.emit(reference);
        this.referenceInput.emit(
          new ZwiReferencePickerInputEvent(this, this._elementRef.nativeElement)
        );
      }
    }
  }

  _onChange() {
    this.referenceChange.emit(
      new ZwiReferencePickerInputEvent(this, this._elementRef.nativeElement)
    );
  }

  _onBlur() {
    this._onTouched();
    // TODO: Format values to standard reference
    // if (this.value) {
    //   this._formatValue(this.value);
    // }
  }

  _formatValue(value: ReferenceRange) {
    const formatter = new ReferenceFormatter();
    this._elementRef.nativeElement.value = value
      ? formatter.formatRange(
          value,
          this._referenceFormats.display.referenceInput
        )
      : '';
  }
}
