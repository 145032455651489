<div *ngIf="cancelService.status() === 1; else content" class="zwi-main-content zwi-justify-center">
  <zwi-widget>
    <div>Loading</div>
    <!--    <zwi-progress-indicator></zwi-progress-indicator>-->
  </zwi-widget>
</div>
<ng-template #content>
  <ng-container [ngSwitch]="cancelService.result().status">
    <ng-container *ngSwitchCase="'success'">
      <zwi-simple-page>
        <zwi-call-to-action>
          <ng-container title>Your account has been closed.</ng-container>
        </zwi-call-to-action>
      </zwi-simple-page>
    </ng-container>
    <ng-container *ngSwitchCase="'expired'">
      <zwi-simple-page>
        <zwi-call-to-action>
          <ng-container title>Close account link has expired.</ng-container>
        </zwi-call-to-action>
      </zwi-simple-page>
    </ng-container>
  </ng-container>
</ng-template>
