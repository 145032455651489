import { Temporal } from '@js-temporal/polyfill';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';

import { Injectable, Injector, signal } from '@angular/core';

import { Sermon } from '@zwiloo/sermon/domain';
import { FeedDataService } from '@zwiloo/feed/data-interface/feed-data.service';
import { FavoritesService } from '@zwiloo/user/context/favorites.service';
import { UserDataService } from '@zwiloo/user/data-interface/user-data.service';
import { SermonService } from '@zwiloo/sermon/sermon.service';
import { LoadingState } from '@zwiloo/util/state';
import { toObservable } from '@angular/core/rxjs-interop';
import { sermonAirDate, sermonCreatedAt } from '@zwiloo/sermon/domain/sermon';

@Injectable()
export class FeedService {
  addedCursor = 0;
  cursor = 0;
  trendingCursor = 0;
  date = Temporal.Now.plainDateISO();

  addedSermons = signal<Sermon[]>([]);
  dailyAddedSermons = signal<
    { createdAt: string; sermons: Sermon[]; id: number }[]
  >([]);
  nextAddedSermons = signal<Sermon[]>([]);

  sermons = signal<Sermon[]>([]);
  dailySermons = signal<{ airDate: string; sermons: Sermon[]; id: number }[]>(
    []
  );
  nextSermons = signal<Sermon[]>([]);

  trendingSermons = signal<Sermon[]>([]);
  dailyTrendingSermons = signal<
    { airDate: string; sermons: Sermon[]; id: number }[]
  >([]);
  nextTrendingSermons = signal<Sermon[]>([]);

  // public status = new BehaviorSubject<CallState>(LoadingState.INIT);
  addedStatus = signal(LoadingState.INIT);
  status = signal(LoadingState.INIT);
  trendingStatus = signal(LoadingState.INIT);

  // Feed and trending are tracked because they are the initial tabs
  isFeedLoaded = signal(false);
  isTrendingLoaded = signal(false);

  constructor(
    private favorites: FavoritesService,
    private feedData: FeedDataService,
    private sermon: SermonService,
    private userData: UserDataService,
    public injector: Injector
  ) {}

  init() {
    this.addedSermons.set([]);
    this.dailyAddedSermons.set([]);
    this.nextAddedSermons.set([]);

    this.sermons.set([]);
    this.dailySermons.set([]);
    this.nextSermons.set([]);
    this.status.set(LoadingState.INIT);

    this.trendingSermons.set([]);
    this.dailyTrendingSermons.set([]);
    this.nextTrendingSermons.set([]);
    this.trendingStatus.set(LoadingState.INIT);

    this.addedCursor = 0;
    this.cursor = 0;
    this.trendingCursor = 0;
    this.date = Temporal.Now.plainDateISO();
  }

  initializePage() {
    this.init();

    this.getAdded();
    this.getTrending();
    this.getFeed();

    // Add sermons and get sermons for next on the initialization
    toObservable(this.nextAddedSermons, { injector: this.injector })
      .pipe(first((sermons) => sermons.length > 0))
      .subscribe((_) => {
        this.addAdded();
        this.getAdded();
      });
    toObservable(this.nextSermons, { injector: this.injector })
      .pipe(first((sermons) => sermons.length > 0))
      .subscribe((_) => {
        this.addDaily();
        this.getFeed();
      });
    toObservable(this.nextTrendingSermons, { injector: this.injector })
      .pipe(first((sermons) => sermons.length > 0))
      .subscribe((_) => {
        this.addTrending();
        this.getTrending();
      });
  }

  initializeGuestPage() {
    this.init();

    this.getGuestTrending();

    toObservable(this.nextTrendingSermons, { injector: this.injector })
      .pipe(first((sermons) => sermons.length > 0))
      .subscribe((_) => {
        this.addTrending();
        this.getGuestTrending();
      });
  }

  getAdded() {
    if (this.addedStatus() === LoadingState.LOADING) {
      return;
    }
    this.addedStatus.set(LoadingState.LOADING);
    const limit = 10;
    forkJoin({
      response: this.feedData.getAdded(this.date, this.addedCursor, limit),
      favorites: this.userData.favorites(),
    }).subscribe(({ response, favorites }) => {
      this.addedCursor = response.nextCursor;
      const s = this.sermon.markFavorites(response.sermons, favorites);
      this.nextAddedSermons.set(s);
      this.addedStatus.set(LoadingState.LOADED);
    });
  }

  getFeed() {
    if (this.status() === LoadingState.LOADING) {
      return;
    }
    this.status.set(LoadingState.LOADING);
    const limit = 10;
    forkJoin({
      feedResponse: this.feedData.getFeed(this.date, this.cursor, limit),
      favorites: this.userData.favorites(),
    }).subscribe(({ feedResponse, favorites }) => {
      this.cursor = feedResponse.nextCursor;
      const s = this.sermon.markFavorites(feedResponse.sermons, favorites);
      this.nextSermons.set(s);
      this.isFeedLoaded.set(true);
      this.status.set(LoadingState.LOADED);
    });
  }

  getTrending() {
    if (this.trendingStatus() === LoadingState.LOADING) {
      return;
    }
    this.trendingStatus.set(LoadingState.LOADING);
    const limit = 10;
    forkJoin({
      feedResponse: this.feedData.getTrending(
        this.date,
        this.trendingCursor,
        limit
      ),
      favorites: this.userData.favorites(),
    }).subscribe(({ feedResponse, favorites }) => {
      this.trendingCursor = feedResponse.nextCursor;
      const s = this.sermon.markFavorites(feedResponse.sermons, favorites);
      this.nextTrendingSermons.set(s);
      this.isTrendingLoaded.set(true);
      this.trendingStatus.set(LoadingState.LOADED);
    });
  }

  getGuestTrending() {
    const limit = 10;
    this.feedData
      .getTrending(this.date, this.trendingCursor, limit)
      .subscribe((feedResponse) => {
        this.trendingCursor = feedResponse.nextCursor;
        this.nextTrendingSermons.set(feedResponse.sermons);
        this.isTrendingLoaded.set(true);
        this.trendingStatus.set(LoadingState.LOADED);
      });
  }

  addAdded() {
    this.addedSermons.set([...this.addedSermons(), ...this.nextAddedSermons()]);
    const nextDaily = sermonCreatedAt(
      this.nextAddedSermons(),
      this.dailyAddedSermons()
    );
    this.dailyAddedSermons.set(structuredClone(nextDaily));
  }

  addDaily() {
    this.sermons.set([...this.sermons(), ...this.nextSermons()]);
    const nextDaily = sermonAirDate(this.nextSermons(), this.dailySermons());
    this.dailySermons.set(structuredClone(nextDaily));
    this.nextSermons.set([]);
  }

  addTrending() {
    this.trendingSermons.set([
      ...this.trendingSermons(),
      ...this.nextTrendingSermons(),
    ]);
    const nextDaily = sermonAirDate(
      this.nextTrendingSermons(),
      this.dailyTrendingSermons()
    );
    this.dailyTrendingSermons.set(structuredClone(nextDaily));
    this.nextTrendingSermons.set([]);
  }
}
