import { Capacitor } from '@capacitor/core';

import { Component, computed, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Account, AccountService } from '@zwiloo/user/account';
import { AuthService, LoginInfo } from '@zwiloo/user/auth';
import { mobileWidth } from '@zwiloo/util/tokens';

@Component({
  selector: 'app-signup',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {
  isMobile = computed(() => {
    return window.innerWidth <= mobileWidth;
  });
  isNativePlatform = Capacitor.isNativePlatform();
  constructor(
    private accountService: AccountService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  navigateToIndex() {
    this.router.navigateByUrl('/index');
  }

  submit(account: Account) {
    this.accountService.signUp(account).subscribe((_) => {
      this.authService
        .signin(new LoginInfo(account.email, account.password1))
        .subscribe((r) => {
          if (r.valid) {
            this.accountService.setMinistryInfo().subscribe((_) => {
              this.authService.hasSession.set(true);
              this.router.navigateByUrl('/home');
            });
          }
        });
    });
  }
}
