import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AdminInvitationComponent } from '../admin-invitation/admin-invitation.component';
import { CancelComponent } from '../cancel/cancel.component';
import { EmailSentComponent as PasswordEmailSentComponent } from '../password-reset/email-sent/email-sent.component';
import { MinistryEmbedComponent } from '../embed/ministry/ministry-embed.component';
import { SermonEmbedComponent } from '../embed/sermon/sermon-embed.component';
import { EnterNewPasswordComponent } from '../password-reset/enter-new-password/enter-new-password.component';
import { VerifyComponent } from '../verify-email/verify/verify.component';
import { EmailSentComponent } from '../verify-email/email-sent/email-sent.component';
import { HomeComponent } from '../home/home.component';
import { SignInComponent } from '@zwiloo/frontend/sign-in/sign-in.component';
import { SignUpCompletionComponent } from '@zwiloo/frontend/sign-up-completion/sign-up-completion.component';
import { AnonymousGuardService } from '@zwiloo/frontend/core/services/anonymous-guard.service';
import { PrivacyPolicyComponent } from '@zwiloo/frontend/legal/privacy-policy/privacy-policy.component';
import { OnboardingGuard } from '@zwiloo/frontend/core/services/onboarding-guard.service';

export const routes: Routes = [
  {
    path: 'index',
    canActivate: [OnboardingGuard],
    component: HomeComponent,
  },
  { path: 'admin-invitation', component: AdminInvitationComponent },
  { path: 'close', component: CancelComponent },
  // { path: 'embed', component: EmbedComponent },
  { path: 'embed/ministry/:id', component: MinistryEmbedComponent },
  { path: 'embed/sermon/:id', component: SermonEmbedComponent },
  { path: 'complete-sign-up', component: SignUpCompletionComponent },
  { path: 'sign-in', component: SignInComponent },
  { path: 'verify', component: VerifyComponent },
  { path: 'reset', component: EnterNewPasswordComponent },
  { path: 'password-reset-sent', component: PasswordEmailSentComponent },
  { path: 'email-verification-sent', component: EmailSentComponent },
  { path: 'legal/privacy-policy', component: PrivacyPolicyComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class IndexRoutingModule {}
