import { Subscription } from 'rxjs';

import { Component, OnInit } from '@angular/core';

import { ProfileImageService } from '@zwiloo/settings/context/profile-image.service';

@Component({
  selector: 'zwi-profile-image-form',
  templateUrl: './profile-image-form.component.html',
  styleUrls: ['./profile-image-form.component.scss'],
  providers: [ProfileImageService],
})
export class ProfileImageFormComponent implements OnInit {
  constructor(public profileImage: ProfileImageService) {}

  ngOnInit(): void {
    this.profileImage.loadProfileImage();
  }

  upload(file: File) {
    this.profileImage.upload(file);
  }
}
