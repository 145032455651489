import { BehaviorSubject, Subject } from 'rxjs';

import { Injectable } from '@angular/core';

import { Series, Speaker } from '@zwiloo/ministry/domain';
import { SermonService } from '@zwiloo/sermon/sermon.service';

import { SpeakerDataService } from '@zwiloo/ministry/data-interface/speaker-data.service';
import { SeriesDataService } from '@zwiloo/ministry/data-interface/series-data.service';
import { SermonDataService } from '@zwiloo/ministry/data-interface/sermon-data.service';
import { SermonsDataService } from '../data-interface/sermons-data.service';
import { Sermon } from '@zwiloo/sermon/domain';
import { SermonEdit } from '../domain';
import { CallState, ErrorState, LoadingState } from '@zwiloo/util/state';
import { AccountService } from '@zwiloo/user/account';
import { UploadService } from './upload.service';

@Injectable()
export class StudioSermonsService {
  speakers = new Subject<Speaker[]>();
  series = new Subject<Series[]>();
  sermons = new BehaviorSubject<Sermon[]>([]);
  public updateStatus = new BehaviorSubject<CallState>(LoadingState.INIT);

  constructor(
    private accountService: AccountService,
    private seriesData: SeriesDataService,
    private sermon: SermonService,
    private sermonData: SermonDataService,
    private sermonsData: SermonsDataService,
    private speakerData: SpeakerDataService,
    private uploadService: UploadService
  ) {}

  deleteSermon(sermonID: number) {
    this.sermons.next(this.sermons.getValue().filter((s) => s.id !== sermonID));
  }

  finalizeDeletion(sermonID: number) {
    this.sermonsData.delete(sermonID).subscribe(() => {
      this.initializePage();
    });
  }

  initializePage() {
    const m = this.accountService.getMinistry();

    // this.speakerData.getSpeakers(m.id).subscribe((s) => {
    //   this.speakers.next(s);
    // });
    // this.seriesData.getSeries(m.id).subscribe((s) => {
    //   this.series.next(s);
    // });
    this.sermonData.getSermons(m.id).subscribe((s) => {
      this.sermons.next(s);
    });
    this.uploadService.getMinistryInfo();
  }

  updateSermon(sermonEdit: SermonEdit) {
    this.updateStatus.next(LoadingState.LOADING);
    this.sermonsData.update(sermonEdit).subscribe({
      next: () => {
        this.updateStatus.next(LoadingState.LOADED);
      },
      error: () => {
        this.updateStatus.next(new ErrorState('Update failed.'));
      },
    });
  }
}
