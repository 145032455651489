import { BehaviorSubject, Subject } from 'rxjs';

import { Injectable } from '@angular/core';

import { MinistryService } from '@zwiloo/ministry/ministry.service';
import { CallState, LoadingState } from '@zwiloo/util/state';
import { Ministry, MinistryAccountDataService } from '../ministry-account';

@Injectable()
export class ConvertAccountService {
  public ministry = new Subject<Ministry>();
  public status = new BehaviorSubject<CallState>(LoadingState.INIT);

  constructor(
    public ministryService: MinistryService,
    private ministryAccountData: MinistryAccountDataService
  ) {}

  convertAccount(ministry: Ministry) {
    this.status.next(LoadingState.LOADING);
    this.ministryAccountData.convertAccount(ministry).subscribe((m) => {
      this.status.next(LoadingState.LOADED);
      this.ministry.next(m);
    });
  }
}
