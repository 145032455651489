import { Subscription } from 'rxjs';

import { Component, OnInit } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';

import { Ministry } from '@zwiloo/ministry/domain';
import { SubscribeChange } from '../../shared/subscribe-button/subscribe-button.component';

import { SubscriptionService } from '@zwiloo/subscription/context/subscription.service';
import { MinistryService } from '@zwiloo/ministry/ministry.service';
import { SearchService } from '@zwiloo/search/context/search.service';
import { CallState, LoadingState } from '@zwiloo/util/state';
import { MatChipListboxChange } from '@angular/material/chips';

@Component({
  selector: 'zwi-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss'],
  providers: [MinistryService],
})
export class SubscriptionsComponent implements OnInit {
  active = 'Ministries';
  criteria = '';
  localChurch: Ministry[] = [];
  localChurchSubscription: Subscription;
  subscriptions: Ministry[] = [];
  subscriptionsSubscription: Subscription;
  ministries: Ministry[] = [];
  ministriesSubscription: Subscription;
  mode = 'subscriptions';
  status: CallState = LoadingState.INIT;
  statusSubscription: Subscription;

  constructor(
    private ministryService: MinistryService,
    private searchService: SearchService,
    public subscription: SubscriptionService
  ) {
    this.localChurchSubscription = toObservable(
      subscription.localChurch
    ).subscribe((m) => {
      // Reset search which shows up if there is no local church
      if (m.length === 0) {
        this.searchService.reset();
      }
      this.localChurch = m;
    });

    this.subscriptionsSubscription = toObservable(
      subscription.subscriptions
    ).subscribe((m) => {
      this.subscriptions = m;
    });

    this.ministriesSubscription = searchService.ministries.subscribe((m) => {
      this.ministries = m;
    });
    this.statusSubscription = searchService.status.subscribe((s) => {
      this.status = s;
    });
  }

  ngOnInit() {}

  cancelSearch() {
    this.setMode('subscriptions');
    this.criteria = '';
    this.searchService.reset();
  }

  setMode(m: string) {
    this.mode = m;
  }

  onSearch(criteria: string) {
    this.searchService.searchMinistries(criteria);
  }

  search(criteria: string) {
    if (this.active === 'Ministries') {
      this.searchService.searchMinistries(criteria);
    } else {
      this.searchService.searchLocalChurch(criteria);
    }
  }

  searchLoaded() {
    return this.searchService.status.getValue() === LoadingState.LOADED;
  }

  setCriteria(c: string) {
    this.criteria = c;
  }

  changeSubscription(change: SubscribeChange) {
    // this.subscribeChange.emit(change);
    if (change.value === 'subscribe') {
      this.ministryService.subscribe(change.source.ministry);
    } else if (change.value === 'unsubscribe') {
      this.ministryService.unsubscribe(change.source.ministry);
    }
  }

  changeLocalChurch(change: SubscribeChange) {
    // this.subscribeChange.emit(change);
    if (change.value === 'localChurchSubscribe') {
      this.subscription.subscribeLocal(change.source.ministry);
    } else if (change.value === 'localChurchUnsubscribe') {
      this.subscription.unsubscribeLocal(change.source.ministry);
    }
  }

  updateActive(c: MatChipListboxChange) {
    if (c.value) {
      this.active = c.value;
      this.subscription.loadSubscriptions();

      this.searchService.reset();
      this.mode = 'subscriptions';
    }
  }
}
