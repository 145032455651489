import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';

import { MinistrySignUpComponent } from './ministry-sign-up.component';
import { MinistrySignUpRoutingModule } from './ministry-sign-up-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@zwiloo/frontend/shared/shared.module';
import { TypographyModule } from '@zwiloo/frontend/typography/typography.module';
import { SignupAccountComponent } from './signup-account/signup-account.component';
import { SignUpModule } from '../sign-up/sign-up.module';

@NgModule({
  declarations: [MinistrySignUpComponent, SignupAccountComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MinistrySignUpRoutingModule,
    ReactiveFormsModule,
    SharedModule,
    SignUpModule,
    TypographyModule,
  ],
})
export class MinistrySignUpModule {}
