export class Ministry {
  constructor(
    public id = 0,
    public name = '',
    public username = '',
    public profileImage = '',
    public subscribed = false,
    public unsubscribed = true,
    public localChurchSubscribed = false,
    public localChurchUnsubscribed = false
  ) {}
}

export function setSubscribed(ministry: Ministry) {
  ministry.localChurchSubscribed = false;
  ministry.localChurchUnsubscribed = false;
  ministry.subscribed = true;
  ministry.unsubscribed = false;
}

export function setUnsubscribed(ministry: Ministry) {
  ministry.localChurchSubscribed = false;
  ministry.localChurchUnsubscribed = false;
  ministry.subscribed = false;
  ministry.unsubscribed = true;
}

export function setLocalChurchSubscribed(ministry: Ministry) {
  ministry.localChurchSubscribed = true;
  ministry.localChurchUnsubscribed = false;
  ministry.subscribed = false;
  ministry.unsubscribed = false;
}

export function setLocalChurchUnsubscribed(ministry: Ministry) {
  ministry.localChurchSubscribed = false;
  ministry.localChurchUnsubscribed = true;
  ministry.subscribed = false;
  ministry.unsubscribed = false;
}
