<h1 mat-dialog-title *ngIf="sent === false; else emailMessage">Are you sure you want to close your account?</h1>
<ng-template #emailMessage>
  <h1 mat-dialog-title>Check your email to close</h1>
</ng-template>
<div mat-dialog-content>
  <ng-container *ngIf="sent === false; else sentMessage">
    <div class="zwi-block-section">
      <zwi-body>Closing your account will delete your data permanently.</zwi-body>
      <zwi-body>When you close your account, you'll lose:</zwi-body>
      <ul class="zwi-bullet-list">
        <li><zwi-body>Your saved sermons</zwi-body></li>
        <li><zwi-body>Your following ministries</zwi-body></li>
      </ul>
    </div>
    <mat-checkbox class="example-margin" [checked]="ack" (change)="updateAck($event.checked)">I understand</mat-checkbox>
  </ng-container>
  <ng-template #sentMessage>
    <div class="zwi-block-section">
      <zwi-body>A verification email was sent to {{ email }} to close your account.</zwi-body>
      <div>
        <zwi-title>Didn't receive the email?</zwi-title>
        <zwi-body>Check your spam or junk folder. If you still can't find it <a href="https://support.zwiloo.com/contact">contact us</a>.</zwi-body>
      </div>
    </div>

  </ng-template>
</div>
<div mat-dialog-actions>
  <button *ngIf="sent === false; else okay" [disabled]="ack === false" mat-button cdkFocusInitial (click)="sendCloseAccountEmail()">Continue</button>
  <ng-template #okay>
    <button mat-button mat-dialog-close cdkFocusInitial>Okay</button>
  </ng-template>
</div>
<!--<ng-template #sentMessage>-->
<!--  <h1 mat-dialog-title>Check your email to close</h1>-->
<!--  <div mat-dialog-content>-->
<!--    <div *ngIf="sent === false; else sentMessage">-->
<!--      <div class="zwi-block-section">-->
<!--        <zwi-body>Click the link in the email we sent to {{ email }}. Your account is not closed until you do so.</zwi-body>-->
<!--        <zwi-body>The link will expire after 24 hours.</zwi-body>-->
<!--        <zwi-body>If you don't see it, check your spam or junk folder. If you still can't find it <a href="https://support.zwiloo.com/contact">contact us</a></zwi-body>-->
<!--        <ul class="zwi-bullet-list">-->
<!--          <li><zwi-body>Your saved sermons</zwi-body></li>-->
<!--          <li><zwi-body>Your following ministries</zwi-body></li>-->
<!--        </ul>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div mat-dialog-actions>-->
<!--      <button mat-button mat-dialog-close cdkFocusInitial>Okay</button>-->
<!--  </div>-->
<!--</ng-template>-->
