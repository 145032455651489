<div class="zwi-wrapper zwi-column-center zwi-section zwi-call-to-action-wrapper">
  <div class="zwi-text-section zwi-column-center">
    <zwi-section-headline>
      <ng-content select="[title]"></ng-content>
    </zwi-section-headline>
    <zwi-body>
      <ng-content select="[content]"></ng-content>
    </zwi-body>
  </div>
  <ng-content select="[action]"></ng-content>
</div>
