import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormErrorService } from './services/form-error.service';

@NgModule({
  imports: [CommonModule],
  providers: [FormErrorService],
})
export class FormModule {
  // constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
  //   throwIfAlreadyLoaded(parentModule, 'CoreModule');
  // }
}
