import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { CallState, LoadingState } from '@zwiloo/util/state';
import { Sermon } from '@zwiloo/sermon/domain';
import { UserDataService } from '@zwiloo/user/data-interface/user-data.service';
import { SermonService } from '@zwiloo/sermon/sermon.service';

@Injectable({
  providedIn: 'root',
})
export class FavoritesService {
  public favorites = new Subject<Sermon[]>();
  public loadingStatus = new BehaviorSubject<CallState>(LoadingState.INIT);

  constructor(
    private sermon: SermonService,
    public userData: UserDataService
  ) {}

  hydrateFavorite(source: Observable<Sermon[]>): Observable<Sermon[]> {
    return forkJoin({
      source,
      favorites: this.userData.favorites(),
    }).pipe(
      map(({ source, favorites }) => {
        return this.sermon.markFavorites(source, favorites);
      })
    );
  }
}
