import { Injectable, isDevMode } from '@angular/core';
import { Router } from '@angular/router';

import { AccountService } from '@zwiloo/user/account';

@Injectable()
export class MinistryGuard {
  constructor(private router: Router, private accountService: AccountService) {}

  canActivate() {
    return this.accountService.getMinistry()?.id > 0;
  }

  canActivateChild(): boolean {
    return this.canActivate();
  }
}
