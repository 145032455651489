import sortBy from 'lodash/sortBy';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { StarChange, SermonComponent } from '../sermon/sermon.component';
import { Sermon } from '@zwiloo/sermon/domain';

@Component({
  selector: 'zwi-sermon-list',
  templateUrl: './sermon-list.component.html',
  styleUrls: ['./sermon-list.component.scss'],
})
export class SermonListComponent {
  @Input() bookmarkable = true;

  private _sortBy: string = '';
  private _sermons: Sermon[] = [];

  @Input()
  set sermons(sermons) {
    this._sermons = this._sortSermons(sermons);
  }

  get sermons() {
    return this._sermons;
  }

  @Input()
  set sortBy(sortBy: string | number | Date) {
    if (this.sermons) {
      this._sermons = this._sortSermons(this.sermons);
    }
  }

  @Output() starChange: EventEmitter<StarChange> = new EventEmitter();

  get sortBy() {
    return this._sortBy;
  }

  private _sortSermons(sermons: Sermon[]): Sermon[] {
    if (this.sortBy) {
      return sortBy(sermons, this.sortBy) as Sermon[];
    } else {
      return [...sermons];
    }
  }

  onStarChange(starChange: StarChange) {
    this.starChange.emit(starChange);
  }
}
