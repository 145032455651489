import { Subject } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { User } from './domain';
import { Ministry } from '@zwiloo/ministry/domain';
import { Favorite } from '@zwiloo/sermon/domain';
import { UserDataService } from '@zwiloo/user/data-interface/user-data.service';

@Injectable()
export class UserService {
  public favorites = new Subject<Favorite[]>();
  public localChurch = new Subject<Ministry[]>();
  public subscriptions = new Subject<Ministry[]>();

  constructor(private http: HttpClient, private userData: UserDataService) {}

  loadFavorites() {
    return this.userData.favorites().subscribe((f) => this.favorites.next(f));
  }

  // loadSubscriptions() {
  //   return this.http
  //     .get<Favorite[]>('/api/favorites')
  //     .subscribe((f) => this.favorites.next(f));
  loadLocalChurch() {
    return this.userData
      .loadLocalChurch()
      .subscribe((l) => this.localChurch.next(l));
  }

  loadSubscriptions() {
    return this.userData
      .loadSubscriptions()
      .subscribe((s) => this.subscriptions.next(s));
  }
}
//
//   loadLocalChurch() {
//     return this.http
//       .get<Favorite[]>('/api/favorites')
//       .subscribe((f) => this.favorites.next(f));
//   }
// }
