import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { Injectable, isDevMode } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpHeaders, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { environment } from '../../environments/environment';
import { AuthService } from '@zwiloo/user/auth';

@Injectable()
export class APIInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) {}

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    //handle your auth error or rethrow
    if (err.status === 401) {
      this.authService.signOut();
      this.router.navigateByUrl('/index');
      // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
      return of(err.message);
    }
    return throwError(err);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // let headers = new HttpHeaders();
    // const sessionID = this.authService.getSessionID();
    // if (sessionID !== null) {
    //   headers = headers.append('Authorization', `Bearer ${sessionID}`);
    // }
    const apiRequest = req.clone({
      withCredentials: true,
      url: environment.apiUrl + req.url,
    });

    return next
      .handle(apiRequest)
      .pipe(catchError((x) => this.handleAuthError(x)));
  }
}
