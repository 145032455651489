import { Injectable } from '@angular/core';
import { Speaker } from '@zwiloo/ministry/domain/speaker';

import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SpeakerDataService {
  constructor(private http: HttpClient) {}

  addSpeaker(name: string, ministryID: number) {
    // `/api/ministries/${payload.username}/speakers`
    return this.http.post<Speaker[]>(`/api/ministries/${ministryID}/speakers`, {
      name: name,
    });
  }

  getSpeakers(ministryID: number) {
    return this.http.get<Speaker[]>(`/api/ministries/${ministryID}/speakers`);
  }
}
