import { Component, EventEmitter, Inject, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

import { Capacitor } from '@capacitor/core';
import { SavePassword } from 'capacitor-ios-autofill-save-password';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { FormErrorService } from '../../form/services/form-error.service';

import { AuthService, LoginInfo } from '@zwiloo/user/auth';
import { AccountService } from '@zwiloo/user/account';
import { Router } from '@angular/router';
import { ResetPasswordService } from '@zwiloo/user';
import { EmailSentComponent } from '@zwiloo/frontend/password-reset/email-sent/email-sent.component';

const defaultServerErrorState = () => ({
  email: false,
  password: false,
  active: false,
});

@Component({
  selector: 'zwi-login-form',
  templateUrl: './login-form.component.html',
})
export class LoginFormComponent {
  @Output() signedIn = new EventEmitter();
  @Output() resetPasswordSent = new EventEmitter();
  form: UntypedFormGroup = this.fb.group({
    email: ['', Validators.required],
    password: ['', Validators.required],
  });
  serverErrors = defaultServerErrorState();
  resetSubmitted = false;

  constructor(
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    public formError: FormErrorService,
    private accountService: AccountService,
    private authService: AuthService,
    private resetPasswordService: ResetPasswordService,
    private submitErrorState: ErrorStateMatcher,
    private router: Router
  ) {}

  ngOnDestroy() {
    // Reset error submit
    // @ts-ignore
    this.submitErrorState.force = false;
  }

  private loginInfo(): LoginInfo {
    return {
      email: this.form.get('email')?.value,
      password: this.form.get('password')?.value,
    };
  }

  hasResetError() {
    return (
      this.resetSubmitted &&
      (this.form.controls.email.errors?.resetValid ||
        this.form.controls.email.errors?.resetRequired)
    );
  }

  resetPassword() {
    this.resetSubmitted = true;
    const email = this.form.get('email')?.value;
    if (email !== null && email !== '') {
      this.resetPasswordService.getUser(email).subscribe((u: any) => {
        if (u.id !== 0) {
          this.resetPasswordSent.emit();
          const dialogRef = this.dialog.open(EmailSentComponent, {
            data: { email, id: u.id },
          });

          dialogRef.afterClosed().subscribe((result) => {});
          // this.router.navigateByUrl(
          //   `/password-reset-sent?e=${u.email}&id=${u.id}`
          // );
        } else {
          this.form.controls.email.setErrors({ resetValid: true });
          this.form.controls.email.updateValueAndValidity();
        }
      });
    } else {
      this.form.controls.email.setErrors({ resetRequired: true });
    }
    // Clear password errors because the password field isn't relevant
    this.form.controls.password.setErrors(null);
    // @ts-ignore
    this.submitErrorState.force = true;
  }

  submit() {
    // To remove reset errors
    this.resetSubmitted = false;
    // @ts-ignore
    this.submitErrorState.force = false;
    const info: LoginInfo = this.loginInfo();
    this.serverErrors = defaultServerErrorState();
    this.authService.signin(info).subscribe((r) => {
      // If validEmail and validPassword and isActive but not verified then send to email verification sent page
      // otherwise display message related to the error. If no errors then start session and go to home page.
      if (r.valid) {
        this.accountService.setMinistryInfo().subscribe((_) => {
          this.authService.hasSession.set(true);
          if (Capacitor.getPlatform() === 'ios') {
            SavePassword.promptDialog({
              username: this.loginInfo().email,
              password: this.loginInfo().password,
            })
              .then(() => console.log('promptDialog success'))
              .catch((err) => console.error('promptDialog failure', err));
          }
          this.signedIn.emit();
          // this.router.navigateByUrl('/home');
        });
      } else {
        if (r.validEmail === false) {
          this.form.controls.email.setErrors({ valid: true });
        } else if (r.validPassword === false) {
          this.form.controls.password.setErrors({ valid: true });
        } else if (r.isActive === false) {
          this.form.controls.email.setErrors({ active: true });
        }
      }
    });
  }
}

// data: string;
// serverValidation: ServerValidation = new ServerValidation();
// submitted = false;

// changeRoute() {
//   this.router.navigate(['convert']);
// }

// submit() {
//   const login: Login = this.buildLogin();
//   this.updateLogin.emit(login);
// this.submitted = true;
//
// this.login.login(this.form.get('login').value, this.form.get('password').value)
//   .subscribe(
//     data => {
//       this.auth.setTokenCookie(data['token']);
//       this.ministryService.isMinistry()
//         .subscribe(
//           data => {
//             this.auth.setIsMinistry(data.is_ministry);
//             if (this.router.routerState.snapshot.root.queryParams['redirect'] == 'convert') {
//               this.router.navigate(['/ministry-account/convert']);
//             }
//             else {
//               this.router.navigate(['home']);
//             }
//           }
//         )
//     }
//   );

// var headers = new Headers({
// 	'Content-Type': 'application/x-www-form-urlencoded',
// 	'X-CSRFToken': getCookie('csrftoken')
// });
// let credentials = 'email=' + this.form.get('login').value + '&password=' + this.form.get('password').value;

// this.http.post(this.configuration.getResourceUrl('/app/accounts/api-token-auth'), credentials, {headers: headers})
// 	.map(response => response.json())
// 	.subscribe(
// 		data => {
// 			this.auth.setToken(data['token']);
// 			if (this.router.routerState.snapshot.root.queryParams['redirect'] == 'convert') {
// 				this.router.navigate(['/ministry-account/convert']);
// 			}
// 			else {
// 				this.router.navigate(['home']);
// 			}
// 		}
// 	);

// if (this.router.routerState.snapshot.queryParams['redirect'] == 'convert') {
// 	this.http.post('/app/accounts/api-token-auth', credentials, {headers: headers})
// 		.map(response => response.json())
// 		.subscribe(
// 			data => {
// 				if (data.is_ministry) {
// 					window.location.href = '/stream'
// 				}
// 				else {
// 					this.router.navigate(['/ministry-account/convert']);
// 				}
// 			}
// 		);
// this.http.post('/account/login/', credentials, {headers: headers})
// 	.map(response => response.json())
// 	.subscribe(
// 		data => {
// 			if (data.is_ministry) {
// 				window.location.href = '/stream'
// 			}
// 			else {
// 				this.router.navigate(['/ministry-account/convert']);
// 			}
// 		}
// 	);
// }

// else {
// 	this.http.post('/account/login/', credentials, {headers: headers})
// 		.map(response => response.json())
// 		.subscribe(
// 			response => {
// 				this.serverValidation = new ServerValidation();
// 				if (response['result'] === 'failed') {
// 					this.serverValidation.general = 'The email or password you entered is incorrect.';
// 				}
// 				else {
// 					this.router.navigate(['home']);
// 				}
// 			}
// 		);
// this.http.post('/account/login/', credentials, {headers: headers})
// 	.map(response => response.json())
// 	.subscribe(
// 		response => {
// 			this.serverValidation = new ServerValidation();
// 			if (response['result'] === 'failed') {
// 				this.serverValidation.general = 'The email or password you entered is incorrect.';
// 			}
// 			else {
// 				this.router.navigate(['home']);
// 			}
// 		}
// 	);
