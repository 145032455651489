<tr *ngFor="let row of rows; let rowIndex = index" role="row">
  <td *ngFor="let item of row; let colIndex = index"
      role="gridcell"
      class="zwi-reference-body-cell"
      [tabIndex]="_isActiveCell(rowIndex, colIndex) ? 0 : -1"
      [class.zwi-reference-body-disabled]="!item.enabled || null"
      [class.zwi-reference-body-active]="_isActiveCell(rowIndex, colIndex)"
      [class.zwi-reference-body-range]="_isCellInRange(item.value)"
      [class.zwi-reference-body-cell-selected-solo]="_isSoloCell(item.value)"
      [class.zwi-reference-body-cell-selected-start]="_isStartCell(item.value)"
      [class.zwi-reference-body-cell-selected-end]="_selectedCell(item.value) === 'end'"
      (click)="_cellClicked(item)"
      (mouseover)="_onHover(item.value)"
      [style.width.%]="100 / numCols"
      [style.paddingTop.%]="50 * cellAspectRatio / numCols"
      [style.paddingBottom.%]="50 * cellAspectRatio / numCols">
    <div class="zwi-reference-body-cell-content"
         [class.zwi-reference-body-selected]="_selectedCell(item.value)">
<!--         [class.zwi-reference-body-today]="todayValue === item.value">-->
      {{ item.displayValue }}
    </div>
  </td>
  <td *ngIf="rowIndex === 0 && _firstRowOffset"
      aria-hidden="true"
      class="zwi-reference-body-label"
      [attr.colspan]="_firstRowOffset"
      [style.paddingTop.%]="50 * cellAspectRatio / numCols"
      [style.paddingBottom.%]="50 * cellAspectRatio / numCols">
    {{ '' }}
  </td>
</tr>
