import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { MatSnackBar } from '@angular/material/snack-bar';

import { PasswordService } from '@zwiloo/settings/context/password.service';
import { PasswordMatchValidator } from '@zwiloo/frontend/validators/password-match-validator.service';
import { FormErrorService } from '@zwiloo/frontend/form/services/form-error.service';
import { CallState, LoadingState } from '@zwiloo/util/state';
import { Subscription } from 'rxjs';

@Component({
  selector: 'zwi-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
  providers: [PasswordService],
})
export class PasswordComponent implements OnDestroy {
  status: CallState = LoadingState.INIT;
  statusSubscription: Subscription;
  form = this.formBuilder.group(
    {
      password1: [
        '',
        Validators.compose([Validators.required, Validators.minLength(6)]),
      ],
      password2: ['', Validators.required],
    },
    { validator: this.passwordMatchValidator.validate }
  );

  constructor(
    private formBuilder: UntypedFormBuilder,
    public formError: FormErrorService,
    private _snackBar: MatSnackBar,
    private password: PasswordService,
    private passwordMatchValidator: PasswordMatchValidator
  ) {
    this.statusSubscription = this.password.status.subscribe((s) => {
      this.status = s;
      if (s === 2) {
        this._snackBar.open('Password updated.', undefined, {
          duration: 3000,
        });
      }
    });
  }

  ngOnDestroy() {
    this.statusSubscription.unsubscribe();
  }

  submit() {
    if (this.form.valid) {
      const p = this.form.get('password1')?.value;
      if (p !== undefined) {
        this.password.updatePassword(p);
      }
    }
  }
}
