import { mergeMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder } from '@angular/forms';

// import { ReferenceValidator } from '../../../verse/services/reference-validator.service';
import { Series, Speaker } from '@zwiloo/ministry/domain';
import { SaveStatus, SermonInfo, Upload } from '@zwiloo/studio/domain';
import { UploadService } from '@zwiloo/studio/context/upload.service';

@Component({
  selector: 'zwi-upload-form',
  templateUrl: './upload-form.component.html',
  styleUrls: ['./upload-form.component.scss'],
})
export class UploadFormComponent implements OnInit {
  @Input() series: Series[] = [];
  @Input() speakers: Speaker[] = [];
  @Input()
  get sermonInfo(): SermonInfo | undefined {
    return this._sermonInfo;
  }
  set sermonInfo(s: SermonInfo | undefined) {
    if (s !== undefined) {
      this.hydrateForm(s);
      this._sermonInfo = s;
    }
  }
  private _sermonInfo: SermonInfo | undefined;
  @Output() sermonInfoChange = new EventEmitter<SermonInfo>();
  // uploads: Upload[] = [];
  // upload?: Upload;

  // selectedSubscription: Subscription;
  // uploadSubsciption: Subscription;

  get references() {
    return this.form.get('references') as UntypedFormArray;
  }

  form = this.formBuilder.group({
    title: [''],
    description: [''],
    references: this.formBuilder.array([]),
    series: [0],
    speaker: [0],
  });

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public uploadService: UploadService,
    private formBuilder: UntypedFormBuilder
  ) // private referenceValidator: ReferenceValidator,

  {
    if (this.sermonInfo !== undefined) {
      this.hydrateForm(this.sermonInfo);
    }
    // this.selectedSubscription = uploadService.selected.subscribe((s) => {
    //   const current = this.uploads.find((u) => u.id === s);
    //   if (current !== undefined) {
    //     this.upload = current;
    //     this.hydrateForm(current);
    //   }
    // });
    // this.uploadSubsciption = uploadService.uploads.subscribe((u) => {
    //   this.uploads = u;
    // });
  }

  ngOnInit() {
    // this.initializeForm();

    this.form.valueChanges
      .pipe(
        mergeMap((_) => {
          return this.form.statusChanges;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((status) => {
        if (status === 'VALID') {
          this.emitChange();
        }
      });

    // this.form.valueChanges.subscribe((form) => {
    //   const uploadFile = cloneDeep(this.uploadFile);
    //   uploadFile.description = form['description'];
    //   uploadFile.series = form['series'];
    //   uploadFile.speaker = form['speaker'];
    //   this.uploadChanges.emit(uploadFile);
    // })
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   if (changes.upload.currentValue !== undefined) {
  //     this.hydrateForm(changes.upload.currentValue);
  //   }
  // }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  hydrateForm(sermonInfo: SermonInfo) {
    const title = sermonInfo.title || '';
    const description = sermonInfo.description || '';
    const references = sermonInfo.references || [];
    const seriesID = sermonInfo.seriesID || 0;
    const speakerID = sermonInfo.speakerID || 0;

    this.form.patchValue(
      {
        title,
        description,
        series: seriesID,
        speaker: speakerID,
      },
      { emitEvent: false }
    );
    this.references.clear({ emitEvent: false });
    references.forEach((r) => {
      this.references.push(this.formBuilder.control(r), { emitEvent: false });
    });
  }

  addReference() {
    this.references.push(this.formBuilder.control(''));
  }

  removeReference(i: number) {
    this.references.removeAt(i);
  }

  getValue(): SermonInfo {
    return {
      title: this.form.value.title,
      description: this.form.value.description,
      references: this.form.value.references,
      speakerID: this.form.value.speaker,
      seriesID: this.form.value.series,
    };
  }

  emitChange() {
    const sermonInfo = this.getValue();
    this.sermonInfoChange.emit(sermonInfo);
    // upload.saveStatus = SaveStatus.Editing;
    // this.uploadService.updateUpload(upload);
    // this.store.dispatch(UploadFormActions.update({ upload }));
  }

  // isSaved() {
  //   return this.upload?.saveStatus === SaveStatus.Saved;
  // }

  // showSave() {
  //   return this.upload?.saveStatus === SaveStatus.Unsaved || this.isSaved();
  // }
  //
  // showSaveChanges() {
  //   return this.upload?.saveStatus === SaveStatus.Editing;
  // }

  // getSaveStatusMessage() {
  //   switch (this.upload?.saveStatus) {
  //     case SaveStatus.Saved:
  //       return 'All changes are saved.';
  //     case SaveStatus.Editing:
  //       return 'Some changes are not yet saved.';
  //     default:
  //       return '';
  //   }
  // }

  // getUploadStatusMessage() {
  //   return this.messages.messages.fileUploadStatus[this.getUploadStatus()];
  // }

  // getFileUploadStatusMessage() {
  //   let result = 'Uploading';
  //   if (this.upload?.uploadedFile) {
  //     result = 'Processing';
  //     if (this.upload?.uploadedFile.path) {
  //       result = 'Complete';
  //     }
  //   }

  // return result;
  // switch (this.upload.saveStatus) {
  //   case SaveStatus.Saved:
  //     return this.messages.messages.saveStatus['SAVED'];
  //   case SaveStatus.Editing:
  //     return this.messages.messages.saveStatus['EDITING'];
  //   default:
  //     return '';
  // }
  // let status = 'UPLOADING';
  // if (this.upload.uploadedFile) {
  //   status = 'PROCESSING';
  //   if (this.upload.uploadedFile.path) {
  //     status = 'COMPLETE';
  //   }
  // }
  // return status;
  // }

  // getUploadValue(): Upload {

  // save() {
  //   const upload = this.getUploadValue();
  //   upload.saveStatus = SaveStatus.Saved;
  //   if (upload !== undefined) {
  //     this.uploadService.updateUpload(upload);
  //     this.uploadService.save(upload.id);
  //   }
  //
  //   // this.store.dispatch(UploadFormActions.save({ id: upload.id }));
  //   // upload.saveStatus = SaveStatus.Saved;
  // }

  submit() {
    const test = 'test';
  }

  // addMultiVerse() {
  //   // $scope.formData.setSaveStatus("editing");
  //   // this.model.addMultiVerse();
  //   this.scripture.push(this.getMultiVerseFormGroup());
  // }
  //
  // addSingleVerse() {
  //   // $scope.formData.setSaveStatus("editing");
  //   // this.model.addSingleVerse();
  //   this.scripture.push(this.getSingleVerseFormGroup());
  // }

  // getMultiVerseFormGroup(): FormGroup {
  //   return new FormGroup(
  //     {
  //       book: new FormControl('', Validators.required),
  //       startingChapter: new FormControl(''),
  //       startingVerses: new FormControl(''),
  //       endingChapter: new FormControl(''),
  //       endingVerses: new FormControl(''),
  //     },
  //     this.referenceValidator.verseComplete,
  //     this.referenceValidator.checkMulti
  //   );
  // }
  //
  // getSingleVerseFormGroup(): FormGroup {
  //   return new FormGroup(
  //     {
  //       book: new FormControl('', Validators.required),
  //       chapter: new FormControl(''),
  //       verses: new FormControl(''),
  //     },
  //     this.referenceValidator.verseComplete,
  //     this.referenceValidator.check
  //   );
  // }
}
