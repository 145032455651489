import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';

import { SharedModule } from '@zwiloo/frontend/shared/shared.module';
import { TypographyModule } from '@zwiloo/frontend/typography/typography.module';
import { StudioComponent } from './studio.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DefiniteSeriesMatchComponent } from './series/definite-series-match/definite-series-match.component';
import { DefiniteSpeakerMatchComponent } from './speakers/definite-speaker-match/definite-speaker-match.component';
import { SeriesComponent } from './series/series.component';
import { SermonsComponent } from './sermons/sermons.component';
import { SpeakersComponent } from './speakers/speakers.component';
import { UploadComponent } from './upload/upload.component';
import { AddSeriesComponent } from './series/add-series/add-series.component';
import { EditSeriesComponent } from './series/edit-series/edit-series.component';
import { CurrentSeriesComponent } from './series/current-series/current-series.component';
import { UploadInfoComponent } from './upload/upload-info/upload-info.component';
import { UploadFormComponent } from './upload/upload-form/upload-form.component';
import { UploadCompleteComponent } from './upload/upload-complete/upload-complete.component';
import { DeleteConfirmationComponent } from './sermons/delete-confirmation/delete-confirmation.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { LayoutModule } from '@angular/cdk/layout';

@NgModule({
  declarations: [
    StudioComponent,
    DashboardComponent,
    DefiniteSeriesMatchComponent,
    DefiniteSpeakerMatchComponent,
    SeriesComponent,
    SermonsComponent,
    SpeakersComponent,
    UploadComponent,
    AddSeriesComponent,
    EditSeriesComponent,
    CurrentSeriesComponent,
    UploadInfoComponent,
    UploadFormComponent,
    UploadCompleteComponent,
    DeleteConfirmationComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatChipsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatSelectModule,
    MatSnackBarModule,
    MatTabsModule,
    MatToolbarModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    TypographyModule,
    MatGridListModule,
    LayoutModule,
  ],
})
export class StudioModule {}
