import { Component, Input, SimpleChanges } from '@angular/core';

import { MatChipListboxChange, MatChipsModule } from '@angular/material/chips';

import { TimeDisplayComponent } from '@zwiloo/frontend/shared/player/time-display/time-display.component';
import { SermonListItemComponent } from './sermon-list-item/sermon-list-item.component';
import { Sermon } from '@zwiloo/sermon/domain';
import { SermonService } from '../../sermon.service';
import { SharedModule } from '@zwiloo/frontend/shared/shared.module';
import { EmbedLatestSermonsComponent } from '../embed-latest-sermons/embed-latest-sermons.component';
import { EmbedSeriesSermonsComponent } from '../embed-series-sermons/embed-series-sermons.component';
import { EmbedVerseSermonsComponent } from '../embed-verse-sermons/embed-verse-sermons.component';

@Component({
  selector: 'zwi-embed-sermon-list',
  standalone: true,
  imports: [
    EmbedLatestSermonsComponent,
    EmbedSeriesSermonsComponent,
    EmbedVerseSermonsComponent,
    MatChipsModule,
    SermonListItemComponent,
    TimeDisplayComponent,
    SharedModule,
  ],
  templateUrl: './sermon-list.component.html',
  styleUrl: './sermon-list.component.scss',
})
export class SermonListComponent {
  active = 'Home';
  @Input() sermons: Sermon[] = [];
  series: { [series: string]: Sermon[] } = {};

  constructor(public sermonService: SermonService) {}

  setSermonOrToggleStatus(s: Sermon) {
    if (s.id === this.sermonService.sermon().id) {
      if (this.sermonService.status() === 'PLAYING') {
        this.sermonService.status.set('PAUSED');
      } else {
        this.sermonService.status.set('PLAYING');
      }
    }
    this.sermonService.sermon.set(s);
  }

  updateActive(c: MatChipListboxChange) {
    // Do not update active because it will remove highlight if an already selected
    // chip is clicked again
    if (c.value) {
      this.active = c.value;
    }
  }
}
