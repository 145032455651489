import { Subject, Observable, of, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

import {
  BOOKS_TESTAMENTS,
  BookTestament,
} from '@zwiloo/verse/domain/books-testaments';

import { Sermon, sermonsByBook } from '@zwiloo/sermon/domain';

import { StarChange } from '../sermon/sermon.component';

@Component({
  selector: 'zwi-verse-sermons',
  templateUrl: './verse-sermons.component.html',
  styleUrls: ['./verse-sermons.component.scss'],
})
export class VerseSermonsComponent implements OnChanges {
  @Input() bookmarkable = true;
  @Input() sermons: Sermon[] = [];
  @Output() starChange: EventEmitter<StarChange> = new EventEmitter();

  selectedBook$ = new BehaviorSubject<BookTestament | null>(null);
  bookSermons: Observable<{ book: number; sermons: Sermon[] }[]>;

  constructor() {
    this.bookSermons = this.selectedBook$.pipe(
      map((selected) => {
        const allSermonsByBook = sermonsByBook(this.sermons);

        if (selected === null) {
          return allSermonsByBook;
        }

        const bookSermons = allSermonsByBook.filter(
          (a) => a.book === selected?.id
        );
        return bookSermons;
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (propName === 'sermons') {
        this.selectedBook$.next(null);
      }
    }
  }

  blankMessage(bookID: number): string {
    return `No sermons found for ${this.toBook(bookID)}.`;
  }

  favoriteChange(starChange: StarChange) {
    this.starChange.emit(starChange);
  }

  onBookSelected(book: BookTestament | null) {
    if (book !== null) {
      this.selectedBook$.next(book);
    }
  }

  removeSelected() {
    this.selectedBook$.next(null);
  }

  toBook(bookID: number): string {
    // const bookNum = Number(bookId);
    if (bookID !== Number.MAX_SAFE_INTEGER) {
      const bookIndex = bookID - 1;
      return BOOKS_TESTAMENTS[bookIndex].name;
    } else {
      return 'No References';
    }
  }
}
