import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';

import { Account } from '@zwiloo/user/account';
import { FormErrorService } from '@zwiloo/frontend/form/services/form-error.service';
import { PasswordMatchValidator } from '@zwiloo/frontend/validators/password-match-validator.service';
import { UsernameValidator } from '@zwiloo/frontend/validators/username-validator.service';
import { Sermon } from '@zwiloo/sermon/domain';
import { UserValidatorService } from '@zwiloo/user/validators/user-validator.service';

@Component({
  selector: 'zwi-signup-form',
  templateUrl: './sign-up-form.component.html',
})
export class SignUpFormComponent {
  @Output() updateAccount = new EventEmitter<Account>();
  @Input()
  set email(e: string | undefined) {
    if (e !== undefined) {
      this.form.patchValue({ email: e });
    }
  }

  form = this.fb.group({
    email: [
      '',
      Validators.compose([Validators.required, Validators.email]),
      this.userValidator.emailTaken,
    ],
    password: this.fb.group(
      {
        password1: [
          '',
          Validators.compose([Validators.required, Validators.minLength(6)]),
        ],
        password2: [''],
      },
      { validator: this.passwordMatchValidator.validate }
    ),
  });

  constructor(
    private fb: UntypedFormBuilder,
    public formError: FormErrorService,
    private passwordMatchValidator: PasswordMatchValidator,
    private userValidator: UserValidatorService
  ) {}
  // private _buildForm() {
  //   this.form = this.fb.group({
  //     // email: ['', Validators.compose([Validators.required, Validators.email]), this.usernameValidator.usernameTaken],
  //     email: ['', Validators.compose([Validators.required, Validators.email])],
  //     password: this.fb.group(
  //       {
  //         password1: [
  //           '',
  //           Validators.compose([Validators.required, Validators.minLength(6)]),
  //         ],
  //         password2: [''],
  //       },
  //       { validator: this.passwordMatchValidator.validate }
  //     ),
  //   });
  // }

  private buildAccount(): Account {
    return {
      id: 0,
      email: this.form.get('email')?.value,
      password1: this.form.get('password.password1')?.value,
      password2: this.form.get('password.password2')?.value,
    };
  }

  submit() {
    if (this.form.valid) {
      const account: Account = this.buildAccount();
      this.updateAccount.emit(account);
    } else {
      const invalidEl = document.querySelectorAll('input.ng-invalid');
      const firstInput: HTMLInputElement = <HTMLInputElement>invalidEl.item(0);
      firstInput.focus();
    }
  }
}
