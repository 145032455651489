import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';

import { SharedModule } from '@zwiloo/frontend/shared/shared.module';
import { TypographyModule } from '@zwiloo/frontend/typography/typography.module';
import { AdminInvitationComponent } from './admin-invitation.component';

@NgModule({
  declarations: [AdminInvitationComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatTabsModule,
    RouterModule,
    SharedModule,
    TypographyModule,
  ],
})
export class AdminInvitationModule {}
