import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSliderModule } from '@angular/material/slider';
import { MatToolbarModule } from '@angular/material/toolbar';

import { TypographyModule } from '@zwiloo/frontend/typography/typography.module';
import { ReferencePickerModule } from './reference-picker/reference-picker.module';
import { AutofillDirective } from './app-autofill/app-autofill.directive';
import { BookMenuComponent } from './book-menu/book-menu.component';
import { InfoHeaderComponent } from './info-header/info-header.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { MainContentComponent } from './main-content/main-content.component';
import { MainWrapperComponent } from './main-wrapper/main-wrapper.component';
import { SermonComponent } from './sermon/sermon.component';
import { SermonEditorComponent } from './sermon-editor/sermon-editor.component';
import { SeriesSermonsComponent } from './series-sermons/series-sermons.component';
import { SermonCategoryRowComponent } from './sermon-category-row/sermon-category-row.component';
import { SermonListComponent } from './sermon-list/sermon-list.component';
import { SubscribeButtonComponent } from './subscribe-button/subscribe-button.component';
import { AvatarComponent } from './avatar/avatar.component';
import { MinistryInfoHeaderComponent } from './ministry-info-header/ministry-info-header.component';
import { MinistryListComponent } from './ministry-list/ministry-list.component';
import { MinistrySermonsComponent } from './ministry-sermons/ministry-sermons.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { SermonInformationComponent } from './sermon-information/sermon-information.component';
import { SignUpFormComponent } from './signup-form/sign-up-form.component';
import { SignupMinistryComponent } from './signup-ministry/signup-ministry.component';
import { VerseSermonsComponent } from './verse-sermons/verse-sermons.component';
import { WidgetComponent } from './widget/widget.component';
import { DraggableSermonListComponent } from './draggable-sermon-list/draggable-sermon-list.component';
import { UploadManagerComponent } from './upload-manager/upload-manager.component';
import { PlayButtonComponent } from './play-button/play-button.component';
import { PlayerComponent } from './player/player.component';
import { AudioDirective } from './audio/audio.directive';
import { ProgressSliderComponent } from './player/progress-slider/progress-slider.component';
import { TimeDisplayComponent } from './player/time-display/time-display.component';
import { PlayerButtonComponent } from './player/player-button/player-button.component';
import { ImageUploaderComponent } from './image-uploader/image-uploader.component';
import { CallToActionComponent } from './call-to-action/call-to-action.component';
import { SimplePageComponent } from './simple-page/simple-page.component';
import { SimpleFormPageComponent } from './simple-form-page/simple-form-page.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { AgreementMessageComponent } from './agreement-message/agreement-message.component';
import { TermsOfUseDialogComponent } from './terms-of-use-dialog/terms-of-use-dialog.component';
import { BottomAppBarComponent } from './bottom-app-bar/bottom-app-bar.component';
import { TabButtonComponent } from './tab-button/tab-button.component';
import { NavTabsComponent } from './nav-tabs/nav-tabs.component';
import { EmailSentMessageComponent } from './email-sent-message/email-sent-message.component';
import { SeriesCardComponent } from './series-card/series-card.component';
import { SeriesListComponent } from './series-list/series-list.component';
import { UploadSeriesCardComponent } from './upload-series-card/upload-series-card.component';
import { SeriesInfoCardComponent } from './series-info-card/series-info-card.component';
import { ContentComponent } from './content/content.component';
import { TopSpacerComponent } from './top-spacer/top-spacer.component';
import { BackHeadlineComponent } from './back-headline/back-headline.component';
import { MobileBooksComponent } from './book-menu/mobile-books/mobile-books.component';
import { BooksComponent } from './book-menu/books/books.component';
import { BannerComponent } from './banner/banner.component';
import { SignInDialogComponent } from './sign-in-dialog/sign-in-dialog.component';
import { SignInCallToActionComponent } from './sign-in-call-to-action/sign-in-call-to-action.component';
import { SignInDialogContentComponent } from './sign-in-dialog/sign-in-dialog-content/sign-in-dialog-content.component';

@NgModule({
  imports: [
    CommonModule,
    DragDropModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatSliderModule,
    MatToolbarModule,
    ReactiveFormsModule,
    ReferencePickerModule,
    RouterModule,
    TimeDisplayComponent,
    TypographyModule,
  ],
  exports: [
    AutofillDirective,
    AvatarComponent,
    BannerComponent,
    BookMenuComponent,
    ContentComponent,
    MainContentComponent,
    MainWrapperComponent,
    MinistryInfoHeaderComponent,
    MinistryListComponent,
    MinistrySermonsComponent,
    NavMenuComponent,
    InfoHeaderComponent,
    LoginFormComponent,
    PlayerComponent,
    ReferencePickerModule,
    SearchBarComponent,
    SeriesSermonsComponent,
    SermonComponent,
    SermonCategoryRowComponent,
    SermonEditorComponent,
    SermonInformationComponent,
    SermonListComponent,
    SignInCallToActionComponent,
    SignInDialogComponent,
    SignUpFormComponent,
    SignupMinistryComponent,
    SubscribeButtonComponent,
    VerseSermonsComponent,
    WidgetComponent,
    DraggableSermonListComponent,
    UploadManagerComponent,
    ImageUploaderComponent,
    CallToActionComponent,
    SimplePageComponent,
    SimpleFormPageComponent,
    TimeDisplayComponent,
    AgreementMessageComponent,
    BottomAppBarComponent,
    EmailSentMessageComponent,
    SeriesCardComponent,
    SeriesInfoCardComponent,
    SeriesListComponent,
    UploadSeriesCardComponent,
    TopSpacerComponent,
    BackHeadlineComponent,
    SignInCallToActionComponent,
  ],
  declarations: [
    AudioDirective,
    AutofillDirective,
    AvatarComponent,
    BookMenuComponent,
    MinistryInfoHeaderComponent,
    MinistryListComponent,
    MinistrySermonsComponent,
    NavMenuComponent,
    InfoHeaderComponent,
    LoginFormComponent,
    MainContentComponent,
    MainWrapperComponent,
    SearchBarComponent,
    SermonCategoryRowComponent,
    SeriesSermonsComponent,
    SermonComponent,
    SermonEditorComponent,
    SermonInformationComponent,
    SermonListComponent,
    SignUpFormComponent,
    SignupMinistryComponent,
    SubscribeButtonComponent,
    VerseSermonsComponent,
    WidgetComponent,
    DraggableSermonListComponent,
    UploadManagerComponent,
    PlayerComponent,
    ProgressSliderComponent,
    PlayButtonComponent,
    PlayerButtonComponent,
    ImageUploaderComponent,
    CallToActionComponent,
    SimplePageComponent,
    SimpleFormPageComponent,
    TermsOfUseComponent,
    AgreementMessageComponent,
    TermsOfUseDialogComponent,
    BottomAppBarComponent,
    TabButtonComponent,
    NavTabsComponent,
    EmailSentMessageComponent,
    SeriesCardComponent,
    SeriesListComponent,
    UploadSeriesCardComponent,
    SeriesInfoCardComponent,
    ContentComponent,
    TopSpacerComponent,
    BackHeadlineComponent,
    MobileBooksComponent,
    BooksComponent,
    BannerComponent,
    SignInDialogComponent,
    SignInCallToActionComponent,
    SignInDialogContentComponent,
  ],
})
export class SharedModule {}
