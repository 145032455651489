import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  MinistryAccountService,
  MinistryAccountDataService,
} from './ministry-account';
import { MinistryService } from './ministry.service';
import { GetMinistryService } from './context/get-ministry.service';
import { MinistryDataService } from './data-interface/ministry-data.service';
import { MinistryUsernameValidator } from './ministry-username-validator.service';
import { SeriesDataService } from './data-interface/series-data.service';
import { SermonDataService } from './data-interface/sermon-data.service';
import { SpeakerDataService } from './data-interface/speaker-data.service';

// import { Auth, AuthDataService } from './auth';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    MinistryAccountService,
    MinistryAccountDataService,
    MinistryDataService,
    MinistryService,
    GetMinistryService,
    SeriesDataService,
    SermonDataService,
    SpeakerDataService,
    MinistryUsernameValidator,
  ],
})
export class MinistryModule {}
