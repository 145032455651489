<div>
  <zwi-widget>
    <div>
      <h4>Save complete</h4>
      <p>If you would like to add more sermons click Upload.</p>
    </div>
  </zwi-widget>
  <div class="bottom-element">
    <div class="zwi-block-section--horizontal">
      <button mat-button color="primary" (click)="navigateToSermons()">Sermon Manager</button>
      <button mat-raised-button color="primary" (click)="navigateToUpload()">Upload</button>
    </div>
  </div>
</div>
