import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';
import { AccountService } from '@zwiloo/user/account';
import { AccountDataService } from '@zwiloo/user/data-interface/account-data.service';
import { CallState, LoadingState } from '@zwiloo/util/state';

@Injectable({
  providedIn: 'root',
})
export class AccountSettingsService {
  email = new BehaviorSubject<string>('');
  public status = new BehaviorSubject<CallState>(LoadingState.INIT);
  constructor(
    private accountData: AccountDataService,
    private accountService: AccountService
  ) {}

  resetStatus() {
    this.status.next(LoadingState.INIT);
  }

  getEmail() {
    return this.accountService.getAccountEmail();
  }

  updateEmail(newEmail: string) {
    this.status.next(LoadingState.LOADING);
    this.accountData.updateEmail(newEmail).subscribe((r) => {
      this.status.next(LoadingState.LOADED);
      this.accountService.cacheAccount(newEmail);
    });
  }
}
