import { Subscription } from 'rxjs';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { MatDialog } from '@angular/material/dialog';

import { Series } from '@zwiloo/ministry/domain';
import { SubmitErrorStateMatcher } from '../../form/submit-error-state-matcher';
import { DefiniteSeriesMatchComponent } from './definite-series-match/definite-series-match.component';
import { AuthService } from '@zwiloo/user/auth';
import { StudioSeriesService } from '@zwiloo/studio/context/studio-series.service';

@Component({
  selector: 'zwi-series',
  templateUrl: './series.component.html',
  styleUrls: ['./series.component.scss'],
  providers: [StudioSeriesService],
})
export class SeriesComponent implements OnInit, OnDestroy {
  form = this.formBuilder.group({
    series: ['', Validators.required],
  });
  matcher = new SubmitErrorStateMatcher();
  series: Series[] = [];
  seriesWithSermons: Series[] = [];
  subscription: Subscription;
  sermonSubscription: Subscription;
  matchSubscription: Subscription;

  constructor(
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private studioSeries: StudioSeriesService
  ) {
    this.sermonSubscription = studioSeries.sermons.subscribe((sermons) => {
      sermons.forEach((s) => {
        const match = this.seriesWithSermons.find(
          (se) => s.series.id === se.id
        );
        if (match === undefined) {
          this.seriesWithSermons.push(s.series);
        }
      });
    });
    this.subscription = studioSeries.series.subscribe((s) => {
      this.series = s;
    });
    this.matchSubscription = studioSeries.match.subscribe(() => {
      const dialogRef = this.dialog.open(DefiniteSeriesMatchComponent);

      dialogRef.afterClosed().subscribe((result) => {});
    });
    // this.sermons.forEach((s) => {
    //   const match = this.seriesOptions.find((se) => s.series.id === se.id);
    //   if (match === undefined) {
    //     this.seriesOptions.push(s.series);
    //   }
    // });
  }

  ngOnInit(): void {
    this.studioSeries.initializePage();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.matchSubscription.unsubscribe();
    this.sermonSubscription.unsubscribe();
  }

  submit() {
    const name = this.form.get('series')?.value;
    if (name) {
      this.studioSeries.addSeries(name);
    }
  }
}
