<div class="zwi-embed-title">{{ title }}</div>
@if (sermon.id !== 0) {
  <div class="zwi-embed-subtitle">
    <div class="zwi-flex-center zwi-text-overflow--ellipsis">
      @if (isMinistry) {
        <span>{{ sermon.name }}</span>
        <span class="zwi-list-delimeter">·</span>
      }
      @if (hasReferences()) {
        <span>{{ getReferences(sermon.references) }}</span>
        <span class="zwi-list-delimeter">·</span>
      }
      @if (isMinistry === false && sermon.ministry.name) {
        <span>{{ sermon.ministry.name }}</span>
        <span class="zwi-list-delimeter">·</span>
      }
      <span class="zwi-text-overflow--ellipsis">{{ sermon.speaker.name }}</span>
    </div>

  </div>
}
<!--<div class="zwi-content">-->

<!--</div>-->
