import { NgModule } from '@angular/core';
import {
  provideRouter,
  RouterModule,
  withComponentInputBinding,
} from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorStateMatcher } from '@angular/material/core';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';

// import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpModule } from './http/http.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AdminInvitationModule } from './frontend/admin-invitation/admin-invitation.module';
import { VerifyEmailModule } from './frontend/verify-email/verify-email.module';
import { CoreModule } from './frontend/core/core.module';
import { FavoritesModule } from './frontend/favorites/favorites.module';
import { FormModule } from './frontend/form/form.module';
import { SubmitErrorStateMatcher } from './frontend/form/submit-error-state-matcher';
import { CancelModule } from './frontend/cancel/cancel.module';
import { HomeModule } from './frontend/home/home.module';
import { IndexModule } from './frontend/index/index.module';
import { LocalChurchModule } from './local-church/local-church.module';
import { LocalChurchModule as FroentendLocalChurchModule } from './frontend/local-church/local-church.module';
import { MobileSettingsModule } from './frontend/mobile-settings/mobile-settings.module';
import { MinistryModule as FrontendMinistryModule } from './frontend/ministry/ministry.module';
import { MinistryConvertModule } from './frontend/ministry-convert/ministry-convert.module';
import { MinistrySignUpModule } from './frontend/ministry-sign-up/ministry-sign-up.module';
import { SettingsModule as FrontendSettingsModule } from './frontend/settings/settings.module';
import { SettingsModule } from './settings/settings.module';
import { SignUpCompletionModule } from './frontend/sign-up-completion/sign-up-completion.module';
import { SignUpModule } from './frontend/sign-up/sign-up.module';
import { UserModule as FroentendUserModule } from './frontend/user/user.module';
import { routes as indexRoutes } from './frontend/index/index-routing.module';
import { routes as userRoutes } from './frontend/user/user.routing';
import { ValidatorsModule } from './frontend/validators/validators.module';
import { LegalModule } from './frontend/legal/legal.module';
import { MinistryModule } from './ministry/ministry.module';
import { PasswordResetModule } from './frontend/password-reset/password-reset.module';
import { SearchModule as FroentendSearchModule } from './frontend/search/search.module';
import { SearchModule } from './search/search.module';
import { SermonModule } from './sermon/sermon.module';
import { StudioModule as FroentendStudioModule } from './frontend/studio/studio.module';
import { StudioModule } from './studio/studio.module';
import { SubscriptionModule } from './frontend/subscription/subscription.module';
import { UserModule } from './user/user.module';
import { AccountComponent } from './frontend/account/account.component';
import { AccountService } from '@zwiloo/user/account';
import { SignInModule } from './frontend/sign-in/sign-in.module';
import { SharedModule } from '@zwiloo/frontend/shared/shared.module';

@NgModule({ declarations: [AppComponent, AccountComponent],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AdminInvitationModule,
        // AppRoutingModule,
        BrowserAnimationsModule,
        CancelModule,
        VerifyEmailModule,
        CoreModule,
        FavoritesModule,
        FormModule,
        FroentendSearchModule,
        FroentendStudioModule,
        HomeModule,
        HttpModule,
        IndexModule,
        ReactiveFormsModule,
        LegalModule,
        LocalChurchModule,
        MatButtonModule,
        MatIconModule,
        MatListModule,
        MatMenuModule,
        MatToolbarModule,
        MinistryModule,
        MinistryConvertModule,
        MinistrySignUpModule,
        MobileSettingsModule,
        SearchModule,
        SermonModule,
        FrontendSettingsModule,
        PasswordResetModule,
        RouterModule,
        SettingsModule,
        SignInModule,
        SignUpCompletionModule,
        SignUpModule,
        StudioModule,
        SubscriptionModule,
        UserModule,
        ValidatorsModule,
        FroentendLocalChurchModule,
        FrontendMinistryModule,
        FroentendUserModule,
        SharedModule], providers: [
        AccountService,
        { provide: ErrorStateMatcher, useClass: SubmitErrorStateMatcher },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { appearance: 'fill' },
        },
        provideRouter([...indexRoutes, ...userRoutes], withComponentInputBinding()),
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}
