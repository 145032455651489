import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PlayerService } from './context/player.service';
import { SermonDataService } from './data-interface/sermon-data.service';
import { SermonService } from './sermon.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [PlayerService, SermonDataService, SermonService],
})
export class SermonModule {}
