<ng-container *ngIf="status === 2">
  <ng-container *ngIf="addStatus === 2; else invite">
    <zwi-simple-page>
      <zwi-call-to-action>
        <ng-container title>You have been added as an admin for {{ ministry.name }}!</ng-container>
        <button mat-flat-button color="primary" routerLink="/index" action>Sign in</button>
      </zwi-call-to-action>
    </zwi-simple-page>
  </ng-container>
  <ng-template #invite>
    <zwi-simple-page>
      <zwi-call-to-action>
        <ng-container title>{{ ministry.name }} has invited you to be an admin. </ng-container>
        <ng-container content>An admin can upload and manage sermons for the ministry.</ng-container>
        <button mat-flat-button color="primary" (click)="accept()" action>Accept</button>
      </zwi-call-to-action>
    </zwi-simple-page>
  </ng-template>
</ng-container>
