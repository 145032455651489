<div class="zwi-page-sections">
  <zwi-signup-form (updateAccount)="updateAccount.emit($event)">
    <div class="zwi-buttons">
      <button (click)="previous()" mat-button type="button">Previous</button>
      <button mat-raised-button color="primary">Sign up</button>
    </div>
  </zwi-signup-form>
  <zwi-agreement-message></zwi-agreement-message>
  <zwi-body>
    Already have an account? <a href="/index">Sign in now</a>
  </zwi-body>
</div>


