<div class="zwi-section">
  <div class="zwi-flex-center">
    <div class="zwi-spacer"></div>
    <div class="zwi-section--horizontal">
      <mat-checkbox [checked]="showCode()" (change)="updateShowCode($event.checked)">Show code</mat-checkbox>
      <button mat-flat-button color="primary" (click)="copy()">Copy</button>
    </div>
  </div>
  @if (showCode()) {
    <textarea matInput [value]="iframeURL" class="zwi-full-width zwi-embed-url-textarea"></textarea>
  }
</div>
