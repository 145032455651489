import { BehaviorSubject, Subject } from 'rxjs';

import { Injectable, signal } from '@angular/core';

import { CallState, LoadingState } from '@zwiloo/util/state';
import { Series } from '@zwiloo/ministry/domain';
import { SeriesDataService } from '@zwiloo/ministry/data-interface/series-data.service';
import { SermonDataService } from '@zwiloo/ministry/data-interface/sermon-data.service';
import { Sermon } from '@zwiloo/sermon/domain';
import { SermonsDataService } from '../data-interface/sermons-data.service';
import { AccountService } from '@zwiloo/user/account';
import { Temporal } from '@js-temporal/polyfill';

@Injectable()
export class StudioSeriesService {
  series = new BehaviorSubject<Series[]>([]);
  match = new Subject<boolean>();
  sermonHistory = new BehaviorSubject<Sermon[]>([]);
  sermons = new BehaviorSubject<Sermon[]>([]);
  sermonOrderStatus = signal(LoadingState.INIT);
  status = new BehaviorSubject<CallState>(LoadingState.INIT);

  constructor(
    private accountService: AccountService,
    private seriesData: SeriesDataService,
    private sermonData: SermonDataService,
    private sermonsData: SermonsDataService
  ) {}

  addSeries(name: string) {
    this.status.next(LoadingState.LOADING);
    const id = Number.MAX_SAFE_INTEGER;
    const series = new Series(id, name);
    this.series.next([...this.series.value, series]);
    const m = this.accountService.getMinistry();
    return this.seriesData.addSeries(name, m.id).subscribe({
      next: (s) => {
        this.status.next(LoadingState.LOADED);
      },
      error: () => {
        const series = this.series.value.filter((s) => s.id !== id);
        this.series.next(series);
        this.match.next(true);
      },
    });
  }

  initializePage() {
    const m = this.accountService.getMinistry();
    this.seriesData.getSeries(m.id).subscribe((s) => {
      this.series.next(s);
    });
    this.getSermons();
  }

  getSeries(id: number) {
    this.seriesData.getSeries(id).subscribe((s) => {
      this.series.next(s);
    });
  }

  getSermons() {
    const m = this.accountService.getMinistry();
    this.sermonData.getSermons(m.id).subscribe((s) => {
      this.sermons.next(s);
    });
  }

  getCurrentSeries() {
    const m = this.accountService.getMinistry();
    const date = Temporal.Now.plainDateISO().add({ days: 2 });
    this.sermonData.getSermonHistory(m.id, date, 1, 1).subscribe((s) => {
      this.sermonHistory.next(s);
    });
  }

  setCurrentSeries(seriesID: number) {
    const m = this.accountService.getMinistry();
    this.seriesData.setCurrentSeries(m.id, seriesID).subscribe(() => {});
  }

  updateSermonOrder(s: Sermon[]) {
    this.sermonOrderStatus.set(LoadingState.LOADING);
    this.sermonsData.updateOrder(s).subscribe(() => {
      this.sermonOrderStatus.set(LoadingState.LOADED);
      this.getSermons();
    });
  }
}
