import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewContainerRef,
} from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';

import { BookTestament } from '@zwiloo/verse/domain/books-testaments';
import { BookSelectedService } from '@zwiloo/frontend/shared/book-menu/book-menu.component';

@Component({
  selector: 'zwi-books',
  templateUrl: './books.component.html',
  styleUrls: ['./books.component.scss'],
})
export class BooksComponent {
  @Input() oldTestament: BookTestament[] = [];
  @Input() newTestament: BookTestament[] = [];

  @Output() bookSelected = new EventEmitter<BookTestament>();

  constructor(private bookSelectedService: BookSelectedService) {}

  selectBook(book: BookTestament) {
    this.bookSelectedService.book.set(book);
  }
}
