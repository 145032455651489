import {
  Component,
  computed,
  EventEmitter,
  Input,
  Output,
  signal,
  Signal,
} from '@angular/core';

import { Sermon, sermonsByBook } from '@zwiloo/sermon/domain';
import {
  BOOKS_TESTAMENTS,
  BookTestament,
} from '@zwiloo/verse/domain/books-testaments';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { SharedModule } from '@zwiloo/frontend/shared/shared.module';
import { EmbedBookMenuComponent } from '../../ministry/embed-book-menu/embed-book-menu.component';
import { EmbedSermonListContainerComponent } from '../embed-sermon-list-container/embed-sermon-list-container.component';
import { SermonListItemComponent } from '../sermon-list/sermon-list-item/sermon-list-item.component';
import { SermonService } from '@zwiloo/frontend/embed/sermon.service';
import {
  BookChapter,
  includesBookChapter,
} from '@zwiloo/verse/domain/reference';

@Component({
  selector: 'zwi-embed-verse-sermons',
  standalone: true,
  imports: [
    EmbedBookMenuComponent,
    EmbedSermonListContainerComponent,
    MatButtonModule,
    NgForOf,
    NgIf,
    SharedModule,
    SermonListItemComponent,
    AsyncPipe,
  ],
  templateUrl: './embed-verse-sermons.component.html',
  styleUrl: './embed-verse-sermons.component.scss',
})
export class EmbedVerseSermonsComponent {
  // bookSermons: Observable<{ book: number; sermons: Sermon[] }[]>;
  @Input() sermons: Sermon[] = [];
  @Output() sermonSelected = new EventEmitter<Sermon>();
  selectedBookChapter: Signal<BookChapter | null> = signal(null);
  // selectedBook$ = new BehaviorSubject<BookTestament | null>(null);

  bookSermons = computed(() => {
    if (this.selectedBookChapter() === null) {
      const sermons = sermonsByBook(this.sermons);
      sermons.forEach((s) => {
        if (s.book !== Number.MAX_SAFE_INTEGER) {
          s.sermons.sort((a, b) => {
            if (a.references.length > 0 && b.references.length > 0) {
              return (
                a.references[0].begin.chapter - b.references[0].begin.chapter
              );
            } else {
              return 0;
            }
          });
        }
      });
      return sermons;
    } else {
      return [];
    }
  });

  filteredSermons = computed(() => {
    if (this.selectedBookChapter() !== null) {
      return this.sermons.filter((s) =>
        includesBookChapter(s.references, this.selectedBookChapter()!)
      );
    } else {
      return [];
    }
  });

  constructor(public sermonService: SermonService) {
    // this.bookSermons = this.selectedBook$.pipe(
    //   map((selected) => {
    //     const allSermonsByBook = sermonsByBook(this.sermons);
    //
    //     if (selected === null) {
    //       return allSermonsByBook;
    //     }
    //
    //     const bookSermons = allSermonsByBook.filter(
    //       (a) => a.book === selected?.id
    //     );
    //     return bookSermons;
    //   })
    // );
  }

  blankMessage(bookID: number): string {
    return `No sermons found for ${this.toBook(bookID)}.`;
  }

  emitSelectedSermon(s: Sermon) {
    this.sermonSelected.emit(s);
  }

  getOrder(s: Sermon, index: number): number {
    if (s.references && s.references.length > 0) {
      return s.references[0].begin.chapter;
    } else {
      return index + 1;
    }
  }

  removeSelected() {
    // @ts-ignore
    this.selectedBookChapter.set(null);
  }

  setBookChapter(b: BookChapter) {
    // @ts-ignore
    this.selectedBookChapter.set(b);
  }

  toBook(bookID: number): string {
    // const bookNum = Number(bookId);
    if (bookID !== Number.MAX_SAFE_INTEGER) {
      const bookIndex = bookID - 1;
      return BOOKS_TESTAMENTS[bookIndex].name;
    } else {
      return 'No References';
    }
  }
}
