<audio type="audio/mpeg" #audioRef (ended)="setStatusPaused()" (timeupdate)="updateTime($event)" (durationchange)="updateDuration($event)"></audio>
<div *ngIf="sermon()" class="zwi-player__container" [class]="{'zwi-player__container-lg': isTablet()}">
  <div class="zwi-player__progress-slider-container">
    <zwi-progress-slider (timeChange)="changeTime($event)" [sermon]="sermon()" [duration]="duration"></zwi-progress-slider>
  </div>
  @if (isMobile() == false) {
    <div class="zwi-player">
      <div class="zwi-player__current-time" style="margin-top: 3px; margin-right: 15px;">
        <zwi-time-display [currentTime]="currentTime()"></zwi-time-display>
      </div>
      <div class="zwi-player__end-time" style="margin-top: 3px; margin-left: 15px;">
        <zwi-time-display [currentTime]="duration"></zwi-time-display>
      </div>
    </div>
  }
  <div class="zwi-player-wrapper">
    <div class="zwi-player-content">
      <img width="30px" [src]="sermon()?.ministry?.profileImage" [alt]="sermon()?.ministry?.name" class="zwi-player-avatar">
      <div #informationElement class="zwi-player__information" [ngClass]="{'zwi-player__information--mobile': isMobile}" style="overflow: hidden;">
        <div #nameElement class="mat-subtitle-2 zwi-nowrap zwi-player-name">
          @if (shouldScroll()) {
            <marquee scrollamount="5">{{ name() }}</marquee>
          } @else {
            <span>{{ name() }}</span>
          }
        </div>
        @if (isMobile()) {
          @if (audioRef.readyState > 0) {
            <div class="mat-caption zwi-player__information__caption">{{ remainingTime() }}</div>
          }
        } @else if (hasReferences()) {
          <div class="mat-caption zwi-player__information__caption">{{ getReferences(sermon()?.references) }}</div>
        }
      </div>
    </div>
    <div class="zwi-player__middle">
      <button mat-icon-button [disabled]="!sermon()" (click)="subtractThirtySeconds()">
        <mat-icon>replay_30</mat-icon>
      </button>
      <zwi-player-button [sermon]="sermon()" [status]="status" (changePlay)="updateStatus($event)"></zwi-player-button>
      <button mat-icon-button [disabled]="!sermon()" (click)="addThirtySeconds()">
        <mat-icon>forward_30</mat-icon>
      </button>
    </div>

  </div>

</div>
