import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'zwi-info-header',
  templateUrl: './info-header.component.html',
  styleUrls: ['./info-header.component.scss'],
})
export class InfoHeaderComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
