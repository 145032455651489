<div class="layout-row layout-align-start-start">
  <div class="layout-column layout-align-center-center avatar__frame">
    <input type="file" id="fileInput" (change)="addFiles()" style="display: none">
    <img *ngIf="imageUrl; else placeholder" [src]="imageUrl" height="195px" width="195px"/>
    <ng-template #placeholder>
      <div class="avatar__placeholder" *ngIf="!imageUrl">
        <div class="layout-column layout-align-center-center avatar__placeholder-message">
          <div class="md-title" style="width: 110px;">Place Profile Image Here</div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
<zwi-widget>
  <button mat-flat-button color="primary" (click)="add($event)">
    <mat-icon>add_a_photo</mat-icon>
    Add profile picture
  </button>
</zwi-widget>
<div class="errors-spacer" style="margin-top: 16px;">
<!--  <div *ngIf="profileImage.errors">-->
<!--    <span class="textfield__error" [innerText]="profileImage.errors"></span>-->
<!--  </div>-->
</div>
