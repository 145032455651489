import { Observable } from 'rxjs';

import { Component, OnInit } from '@angular/core';

import { GetLocalChurchService } from '@zwiloo/local-church/context/get-local-church.service';
import { AuthService } from '@zwiloo/user/auth';
import { toObservable } from '@angular/core/rxjs-interop';

@Component({
  selector: 'zwi-local-church',
  templateUrl: './local-church.component.html',
  styleUrls: ['./local-church.component.scss'],
})
export class LocalChurchComponent implements OnInit {
  hasSession$: Observable<boolean>;

  constructor(
    public getLocalChurch: GetLocalChurchService,
    public authService: AuthService
  ) {
    this.hasSession$ = toObservable(authService.hasSession);
  }

  ngOnInit() {
    this.hasSession$.subscribe((s: boolean) => {
      if (s) {
        this.getLocalChurch.getLocalChurch();
      }
    });
  }
}
