import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@zwiloo/user/auth';

@Component({
  selector: 'zwi-mobile-settings',
  templateUrl: './mobile-settings.component.html',
  styleUrls: ['./mobile-settings.component.scss'],
})
export class MobileSettingsComponent {
  constructor(private auth: AuthService, private router: Router) {}

  navigateHome() {
    this.router.navigateByUrl('/home');
  }
  signOut() {
    this.auth.signOut();
    this.navigateHome();
  }
}
