import { Component } from '@angular/core';

@Component({
  selector: 'zwi-series-list',
  templateUrl: './series-list.component.html',
  styleUrls: ['./series-list.component.scss']
})
export class SeriesListComponent {

}
