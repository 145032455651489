import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AccountCloseDialogContentComponent } from './account-close-dialog-content/account-close-dialog-content.component';

@Component({
  selector: 'zwi-account-close-dialog',
  templateUrl: './account-close-dialog.component.html',
  styleUrls: ['./account-close-dialog.component.scss'],
})
export class AccountCloseDialogComponent {
  @Output() closed = new EventEmitter();
  @Input()
  set open(o: boolean) {
    if (o) {
      const dialogRef = this.dialog.open(AccountCloseDialogContentComponent, {
        width: '650px',
      });

      dialogRef.afterClosed().subscribe((result) => {
        this.closed.emit();
      });

      dialogRef.componentInstance.modal_principal_parent.subscribe(() => {
        dialogRef.close();
      });
    }
  }

  constructor(public dialog: MatDialog) {}
}
