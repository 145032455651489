import { Capacitor } from '@capacitor/core';
import { Observable, Subscription } from 'rxjs';

import {
  Component,
  computed,
  ElementRef,
  OnDestroy,
  OnInit,
  signal,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { toObservable } from '@angular/core/rxjs-interop';

import { Upload } from '@zwiloo/studio/domain';
import { UploadService } from '@zwiloo/studio/context/upload.service';
import { PlayerService, Status } from '@zwiloo/sermon/context/player.service';
import { Sermon } from '@zwiloo/sermon/domain';
import { AuthService } from '@zwiloo/user/auth';
import { Ministry } from '@zwiloo/ministry/domain';
import { AccountService } from '@zwiloo/user/account';
import { VerifyEmailService } from '@zwiloo/user/context/verify-email.service';
import { isMobile } from '@zwiloo/util/tokens';

@Component({
  selector: 'zwi-user-container',
  templateUrl: './user-container.component.html',
  styleUrls: ['./user-container.component.scss'],
})
export class UserContainerComponent implements OnDestroy, OnInit {
  ministry: Ministry;
  firstName = 'You';
  sermon?: Sermon;
  uploads: Upload[] = [];
  uploadSubsciption: Subscription;
  mobileMenuItems: { name: string; icon: string; path: string }[] = [
    { name: 'Home', icon: 'home', path: '/home' },
    { name: 'Church', icon: 'groups', path: '/local-church' },
    { name: 'Search', icon: 'search', path: '/search' },
    { name: 'Saved', icon: 'bookmark', path: '/saved' },
    { name: 'Following', icon: 'check_circle', path: '/following' },
  ];
  isNativePlatform = Capacitor.isNativePlatform();
  email: string | null = null;
  hasSession$: Observable<boolean>;
  open = false;
  bannerClosed = signal(false);
  showVerifyBanner = computed(() => {
    return (
      this.authService.hasSession() === true &&
      this.accountService.isVerified() === false &&
      this.bannerClosed() === false
    );
  });
  bannerLocation = 1;
  isMobile = computed(() => {
    return isMobile();
  });
  showToolbar = computed(() => {
    return this.isMobile() === false;
  });
  showTopSpacer = computed(() => {
    return this.isNativePlatform === true && this.showVerifyBanner() === false;
  });

  verifyContent = computed(() => {
    return `${
      this.isNativePlatform ? 'Tap' : 'Click'
    } the button below to receive a verification email at ${this.email}`;
  });
  isVerified$ = toObservable(this.accountService.isVerified);

  constructor(
    private elementRef: ElementRef,
    private router: Router,
    private uploadService: UploadService,
    private verifyEmailService: VerifyEmailService,
    public accountService: AccountService,
    public authService: AuthService
  ) {
    this.ministry = accountService.getMinistry();
    // this.currentTimeSubscription = playerService.currentTime.subscribe((t) => {
    //   this.currentTime = t;
    // });
    this.uploadSubsciption = uploadService.uploads.subscribe((u) => {
      this.uploads = u;
    });
    this.email = this.accountService.getAccountEmail();
    this.hasSession$ = toObservable(authService.hasSession);
    this.accountService.setIsVerified();
    // this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
    //     window.scrollTo(0, 0);
    //   }
    // });
  }

  ngOnInit(): void {
    this.bannerClosed.set(false);
    this.bannerLocation = 1;
    this.hasSession$.subscribe((s: boolean) => {
      if (s) {
        this.accountService.setIsVerified();
      }
      this.ministry = this.accountService.getMinistry();
    });
  }

  ngAfterViewInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const mainElement =
          this.elementRef.nativeElement.querySelector('.zwi-main');
        if (mainElement) {
          mainElement.scrollTop = 0;
        }
      }
    });
  }

  ngOnDestroy() {
    // this.playerSubsciption.unsubscribe();
    this.uploadSubsciption.unsubscribe();
  }

  closeVerifyBanner() {
    this.bannerClosed.set(true);
  }

  closeSignInDialog() {
    this.open = false;
  }

  openSignInDialog() {
    this.open = true;
  }

  navigateToSignUp() {
    this.uploadService.setSelected(0);
    this.router.navigateByUrl('/studio/upload');
  }

  navigateToUpload() {
    this.uploadService.setSelected(0);
    this.router.navigateByUrl('/studio/upload');
  }

  selectUpload(id: number) {
    this.uploadService.setSelected(id);
    this.router.navigateByUrl('/studio/upload');
  }

  sendEmailVerification() {
    if (this.email !== null) {
      this.verifyEmailService.sendVerifyToken(this.email);
      this.bannerLocation = 2;
    }
  }

  signOut() {
    this.authService.signOut();
  }
}
