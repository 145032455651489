import { Subject, Subscription } from 'rxjs';

import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UploadFormComponent } from '../upload-form/upload-form.component';
import { UploadService } from '@zwiloo/studio/context/upload.service';
import { Series, Speaker } from '@zwiloo/ministry/domain';
import {
  SaveStatus,
  SermonInfo,
  Upload,
  UploadStatus,
} from '@zwiloo/studio/domain';

@Component({
  selector: 'zwi-upload-info',
  templateUrl: './upload-info.component.html',
  styleUrls: ['./upload-info.component.scss'],
})
export class UploadInfoComponent implements OnInit, OnDestroy {
  // id: string;
  // id$: Observable<number>;
  // series = ['Genesis'];
  // speakers = ['David Smith', 'James Jones'];
  // upload$: Observable<Upload>;
  // uploads: Observable<any>;
  // @Input() uploads: Upload[] = [];
  @Input() upload?: Upload;
  @Output() uploadNavigate = new EventEmitter();

  @ViewChild(UploadFormComponent)
  private uploadFormComponent!: UploadFormComponent;

  series: Series[] = [];
  speakers: Speaker[] = [];
  speakerSubscription: Subscription;
  seriesSubscription: Subscription;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private uploadService: UploadService) {
    // this.uploads = store.pipe(select(fromStudio.selectAllUploads));
    // this.series = store.pipe(select(fromStudio.selectAllSeries));
    // this.speakers = store.pipe(select(fromStudio.selectAllSpeakers));
    this.speakerSubscription = uploadService.speakers.subscribe((speakers) => {
      this.speakers = speakers;
    });
    this.seriesSubscription = uploadService.series.subscribe((series) => {
      this.series = series;
    });

    // const current = this.uploads.find((u) => u.id === s);
    // if (current !== undefined) {
    //   this.upload = current;
    // }
    //
    // this.selectedSubscription = uploadService.selected.subscribe((s) => {
    //   const current = this.uploads.find((u) => u.id === s);
    //   if (current !== undefined) {
    //     this.upload = current;
    //   }
    // });

    // this.upload$ = store.pipe(select(fromStudio.getSelectedUpload));
    // this.upload = this.uploadBloc.currentUpload;
  }

  ngOnInit() {
    this.uploadService.getMinistryInfo();
    // this.upload$ = this.activatedRoute.paramMap.pipe(
    //   takeUntil(this.destroy$),
    //   withLatestFrom(this.uploads),
    //   map(([paramMap, entities]) => {
    //     const id: number = Number(paramMap.get('id'));
    //     return entities[id];
    //   })
    // );

    // this.id$ = this.activatedRoute.paramMap.pipe(
    //   takeUntil(this.destroy$),
    //   map((paramMap) => Number(paramMap.get('id')))
    // );
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getSaveStatusMessage() {
    switch (this.upload?.saveStatus) {
      case SaveStatus.Saved:
        return 'All changes are saved.';
      case SaveStatus.Editing:
        return 'Some changes are not yet saved.';
      default:
        return '';
    }
  }

  // getUploadStatusMessage() {
  //   return this.messages.messages.fileUploadStatus[this.getUploadStatus()];
  // }

  getFileUploadStatusMessage() {
    let result = 'Uploading';
    if (this.upload?.uploadStatus === UploadStatus.Processing) {
      result = 'Processing';
    } else if (this.upload?.uploadStatus === UploadStatus.Complete) {
      result = 'Complete';
    }
    return result;
  }

  private getSermonInfoValue() {
    return this.uploadFormComponent.getValue();
  }

  isFormValid() {
    if (this.uploadFormComponent !== undefined) {
      return this.uploadFormComponent.form.valid;
    } else {
      return true;
    }
  }

  isSaved() {
    return this.upload?.saveStatus === SaveStatus.Saved;
  }

  navigateToUpload() {
    this.uploadNavigate.emit();
  }

  showSave() {
    return this.upload?.saveStatus === SaveStatus.Unsaved || this.isSaved();
  }

  showSaveChanges() {
    return this.upload?.saveStatus === SaveStatus.Editing;
  }

  save() {
    if (this.upload !== undefined) {
      this.upload.sermonInfo = this.getSermonInfoValue();
      this.upload.saveStatus = SaveStatus.Saved;
      this.uploadService.updateUpload(this.upload);
      this.uploadService.save(this.upload.id);
    }
  }

  updateSermonInfo(sermonInfo: SermonInfo) {
    if (this.upload !== undefined) {
      this.upload.sermonInfo = sermonInfo;
      if (this.upload.saveStatus === SaveStatus.Saved) {
        this.upload.saveStatus = SaveStatus.Editing;
      }
      // this.uploadService.updateUpload(this.upload);
    }
  }

  // onUploadChanges(upload: Upload) {
  //   this.store.dispatch(UploadActions.update({ upload }));
  // }
}
