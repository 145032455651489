import { Component, OnInit } from '@angular/core';
import { Ministry } from '@zwiloo/ministry/domain';
import { AccountService } from '@zwiloo/user/account';

@Component({
  selector: 'zwi-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  menuItems: { name: string; path: string }[] = [
    { name: 'Email', path: '/settings/email' },
    { name: 'Password', path: '/settings/password' },
    { name: 'Account', path: '/settings/account' },
  ];
  ministry: Ministry;

  constructor(private accountService: AccountService) {
    this.ministry = accountService.getMinistry();
  }

  ngOnInit(): void {
    if (this.ministry.id !== 0) {
      this.menuItems.push(
        {
          name: 'Profile Image',
          path: '/settings/profile-image',
        },
        { name: 'Admin', path: '/settings/admin' },
        { name: 'Availability', path: '/settings/availability' }
      );
    }
  }
}
