<div class="zwi-sermon-list-container">
  <div class="zwi-sermon-list-header">
    <zwi-widget>
      <mat-chip-listbox [hideSingleSelectionIndicator]="true" [multiple]="false" (change)="updateActive($event)" aria-label="Ministry seremons selection">
        <mat-chip-option [highlighted]="active === 'Latest'" selected>Latest</mat-chip-option>
        <mat-chip-option [highlighted]="active === 'Verse'">Verse</mat-chip-option>
        <mat-chip-option [highlighted]="active === 'Series'">Series</mat-chip-option>
      </mat-chip-listbox>
    </zwi-widget>
  </div>
  <div class="zwi-sermon-list-body">
    @if (active === 'Series') {
      <zwi-embed-series-sermons [sermons]="sermons" (sermonSelected)="setSermonOrToggleStatus($event)" class="zwi-sermon-list-content"></zwi-embed-series-sermons>
    } @else if (active === 'Verse') {
      <zwi-embed-verse-sermons [sermons]="sermons" (sermonSelected)="setSermonOrToggleStatus($event)" class="zwi-sermon-list-content"></zwi-embed-verse-sermons>
    } @else {
      <zwi-embed-latest-sermons [sermons]="sermons" (sermonSelected)="setSermonOrToggleStatus($event)" class="zwi-sermon-list-content"></zwi-embed-latest-sermons>
    }
  </div>
</div>



<!--<div class="zwi-list-container">-->
<!--  @for (s of sermons; track s.id) {-->
<!--    <zwi-sermon-list-item (click)="setSermonOrToggleStatus(s)" [active]="sermonService.sermon().id === s.id" [sermon]="s">-->
<!--      <div title class="zwi-nowrap">{{ s.name }}</div>-->
<!--      <div subtitle class="zwi-flex-center zwi-nowrap">-->
<!--        @if (hasReferences(s)) {-->
<!--          <span class="zwi-nowrap">{{ getReferences(s.references) }}</span>-->
<!--          <span class="zwi-list-delimeter">·</span>-->
<!--        }-->
<!--        @if (s.ministry.name) {-->
<!--          <span class="zwi-nowrap">{{ s.ministry.name }}</span>-->
<!--          <span class="zwi-list-delimeter">·</span>-->
<!--        }-->
<!--        <span>{{ s.speaker.name }}</span>-->
<!--      </div>-->
<!--      <zwi-time-display duration [currentTime]="s.duration"></zwi-time-display>-->
<!--    </zwi-sermon-list-item>-->
<!--  }-->

<!--</div>-->
