import { Injectable, isDevMode } from '@angular/core';
import { Router } from '@angular/router';

import { AccountService } from '@zwiloo/user/account';
import { Capacitor } from '@capacitor/core';

@Injectable()
export class OnboardingGuard {
  constructor(private router: Router, private accountService: AccountService) {}

  canActivate() {
    // Only native applications go to sign-in page for onboarding
    if (
      Capacitor.isNativePlatform() &&
      this.accountService.isOnboardingComplete() === false
    ) {
      this.router.navigateByUrl('/sign-in');
    } else {
      this.router.navigateByUrl('/home');
    }
    return false;
  }

  canActivateChild(): boolean {
    return this.canActivate();
  }
}
