import { Component, Input } from '@angular/core';

@Component({
  selector: 'zwi-sermon-category-row',
  templateUrl: './sermon-category-row.component.html',
  styleUrls: ['./sermon-category-row.component.scss'],
})
export class SermonCategoryRowComponent {
  constructor() {}
}
