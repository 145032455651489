@if (isDesktop() && uploads.length > 0) {
  <div class="zwi-upload-section">
    <div>
      <div class="zwi-upload-section__header layout-row layout-align-space-between-center">
        <div>{{ statusMessage }}</div>
        <button (click)="toggleListHidden()" mat-icon-button>
          <mat-icon *ngIf="listHidden; else arrowDown">keyboard_arrow_up</mat-icon>
          <ng-template #arrowDown>
            <mat-icon>keyboard_arrow_down</mat-icon>
          </ng-template>
        </button>
      </div>
      <div class="zwi-upload-list" [hidden]="listHidden">


        <div (click)="uploadSelected(upload.id)" *ngFor="let upload of uploads">
          <div class="zwi-upload-list-item" [ngClass]="isSelectedClass(upload.id)">
            <p class="mat-subtitle-2">{{ getUploadName(upload)}}</p>
            <mat-progress-spinner *ngIf="isUploading(upload)" diameter="25" mode="determinate" value="upload.progress"></mat-progress-spinner>
            <mat-progress-spinner *ngIf="isProcessing(upload)" color="accent" mode="indeterminate" diameter="25"></mat-progress-spinner>

            <div *ngIf="isComplete(upload)">
              <i class="material-icons success-state">check_circle</i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}


<!--<div class="upload-section" *ngIf="uploads.length > 0">-->
<!--  <div>-->
<!--    <div class="zwi-upload-section__header layout-row layout-align-space-between-center">-->
<!--      <div>{{ progressMessage() }}</div>-->
<!--      <i *ngIf="!listHidden" (click)="toggleListHidden()" class="material-icons">keyboard_arrow_down</i>-->
<!--      <i *ngIf="listHidden" (click)="toggleListHidden()" class="material-icons">keyboard_arrow_up</i>-->
<!--    </div>-->
<!--    <div class="upload-list" [hidden]="listHidden">-->
<!--      <div (click)="uploadSelected(upload.id)" *ngFor="let upload of uploads">-->
<!--        <div class="upload-list-item layout-row layout-align-space-between-center">-->
<!--          <p matSubheader>{{ upload.title }}</p>-->
<!--          <mat-progress-spinner *ngIf="upload.uploadStatus === 1" value="sermon.uploadProgress"-->
<!--                       mode="determinate" color="primary" class="upload__progress-circle"></mat-progress-spinner>-->
<!--          <mat-progress-spinner *ngIf="upload.uploadStatus === 2" mode="indeterminate" color="accent"-->
<!--                       class="upload__progress-circle"></mat-progress-spinner>-->
<!--          <p *ngIf="upload.uploadStatus === 4">Upload failed</p>-->
<!--          <div *ngIf="upload.uploadStatus === 3">-->
<!--            <i class="material-icons success-state">check_circle</i>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<!--      <div (click)="uploadSelected(upload.id)" *ngFor="let upload of uploads">-->
<!--        <div class="upload-list-item layout-row layout-align-space-between-center">-->
<!--          <p matSubheader>{{ upload.title }}</p>-->
<!--          <mat-progress-spinner *ngIf="upload.uploadStatus === 1" value="sermon.uploadProgress"-->
<!--                                mode="determinate" color="primary" class="upload__progress-circle"></mat-progress-spinner>-->
<!--          <mat-progress-spinner *ngIf="upload.uploadStatus === 2" mode="indeterminate" color="accent"-->
<!--                                class="upload__progress-circle"></mat-progress-spinner>-->
<!--          <p *ngIf="upload.uploadStatus === 4">Upload failed</p>-->
<!--          <div *ngIf="upload.uploadStatus === 3">-->
<!--            <i class="material-icons success-state">check_circle</i>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--          <div *ngIf="isUploading(upload)">-->
<!--            <mat-progress-spinner *ngIf="isProcessing(upload); else uploading" color="accent" mode="indeterminate" diameter="25"></mat-progress-spinner>-->
<!--            <ng-template #uploading>-->
<!--              -->
<!--            </ng-template>-->
<!--          </div>-->
