import { Subscription } from 'rxjs';

import { Component, OnInit } from '@angular/core';

import { SubscribeChange } from '../../shared/subscribe-button/subscribe-button.component';
import { MinistryService } from '@zwiloo/ministry/ministry.service';
import { CallState, LoadingState } from '@zwiloo/util/state';
import { SearchService } from '@zwiloo/search/context/search.service';
import { Ministry } from '@zwiloo/ministry/domain';
import { AuthService } from '@zwiloo/user/auth';

@Component({
  selector: 'zwi-ministry',
  templateUrl: './ministry.component.html',
  styleUrls: ['./ministry.component.scss'],
  providers: [MinistryService],
})
export class MinistryComponent {
  ministries: Ministry[] = [];
  status: CallState = LoadingState.INIT;
  ministrySubscription: Subscription;
  statusSubscription: Subscription;

  constructor(
    private ministryService: MinistryService,
    public authService: AuthService,
    public search: SearchService
  ) {
    this.ministrySubscription = search.ministries.subscribe((m) => {
      this.ministries = m;
    });
    this.statusSubscription = search.status.subscribe((s) => {
      this.status = s;
    });
  }

  ngOnDestroy(): void {
    this.ministrySubscription.unsubscribe();
    this.statusSubscription.unsubscribe();
  }

  public changeSubscription(change: SubscribeChange) {
    // this.subscribeChange.emit(change);
    if (change.value === 'subscribe') {
      this.ministryService.subscribe(change.source.ministry);
    } else if (change.value === 'unsubscribe') {
      this.ministryService.unsubscribe(change.source.ministry);
    }
  }

  searchLoaded() {
    return this.status === LoadingState.LOADED;
  }

  // ministries$: Observable<Ministry[]>;
  // ministryStatus$: Observable<CallState>;
  //
  // constructor(private ministryService: MinistryService) {
  //   // this.ministries$ = store.select(fromMinistryStore.ministries);
  //   // this.ministryStatus$ = store.select(fromStore.ministryStatus);
  // }
  //
  // ngOnInit() {
  //   this.store.dispatch(MinistryActions.removeAllMinistries());
  // }
  //

  // onSubscriptionChange(subscriptionChange: SubscriptionChange) {
  //   if (subscriptionChange.subscribed) {
  //     this.store.dispatch(
  //       SubscriptionActions.subscribe({ ministry: subscriptionChange.ministry })
  //     );
  //   } else {
  //     this.store.dispatch(
  //       SubscriptionActions.unsubscribe({
  //         ministry: subscriptionChange.ministry,
  //       })
  //     );
  //   }
  // }
}
