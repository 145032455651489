import { Capacitor, CapacitorCookies, SetCookieOptions } from '@capacitor/core';

export function getCookie(name: string): string | null {
  if (document.cookie === '') {
    return null;
  } else {
    const c = document.cookie
      .split('; ')
      .find((row) => row.startsWith(`${name}=`));
    if (c === undefined) {
      return null;
    } else {
      return c.split('=')[1];
    }
  }
}

export function removeCookie(name: string, domain?: string) {
  if (getCookie(name) !== null) {
    let cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
    if (domain !== undefined) {
      cookie = `${cookie}; domain=${domain}`;
    }
    document.cookie = cookie;
  }
}

export const setCapacitorCookie = async (
  name: string,
  value: string,
  domain?: string
) => {
  let cookie: SetCookieOptions = {
    key: name,
    value,
  };
  if (cookie !== undefined) {
    cookie = {
      ...cookie,
      url: domain,
    };
  }
  await CapacitorCookies.setCookie({
    url: 'https://api.zwiloo.com',
    key: name,
    value,
  });
};

export const deleteCapacitorCookie = async (name: string) => {
  await CapacitorCookies.deleteCookie({
    key: name,
    url: 'https://api.zwiloo.com',
  });
};
