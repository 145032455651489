import { Component, EventEmitter, Input, Output } from '@angular/core';

// import { Store } from '@ngrx/store';

import { Sermon } from '@zwiloo/sermon/domain';
// import * as fromPlayer from '../../../player/reducers';
// import * as playerActions from '../../../player/actions/player';
import { ReferenceRange } from '@zwiloo/verse/domain/reference';
import { ReferenceFormatter } from '@zwiloo/verse/domain/reference-formatter';
import { ZwiReferenceFormats } from '@zwiloo/verse/domain/reference-formats';
import { PlayerService, Status } from '@zwiloo/sermon/context/player.service';
import { Subscription } from 'rxjs';

export class StarChange {
  constructor(
    public source: SermonComponent,
    public value: 'star' | 'unstar'
  ) {}
}

@Component({
  selector: 'zwi-sermon',
  templateUrl: './sermon.component.html',
  styleUrls: ['./sermon.component.scss'],
})
export class SermonComponent {
  @Input() bookmarkable = true;
  @Input() sermon: Sermon = new Sermon();
  @Output() starChange: EventEmitter<StarChange> = new EventEmitter();

  playingSermon: Sermon | null = null;
  sermonStatus: Status = 'EMPTY';

  sermonSubscription: Subscription;
  statusSubscription: Subscription;

  // constructor(private store: Store<fromPlayer.State>) {}
  constructor(private playerService: PlayerService) {
    this.sermonSubscription = playerService.sermon.subscribe((s) => {
      this.playingSermon = s;
    });
    this.statusSubscription = playerService.status.subscribe((s) => {
      this.sermonStatus = s;
    });
  }

  getDuration(d: number) {
    const mInSecond = 1000;
    const sInMinute = 60;
    const minutes = d / mInSecond / sInMinute;
    if (minutes < 1) {
      return `${Math.round(minutes * 60)} sec`;
    } else if (minutes < 60) {
      return `${Math.round(minutes)} min`;
    } else {
      const h = minutes / 60;
      const m = minutes - h * 60;
      return `${h} hr  ${Math.round(m)} min`;
    }
    return '';
  }

  getReferences(referenceRanges?: ReferenceRange[]) {
    if (referenceRanges !== undefined) {
      const formatter = new ReferenceFormatter();
      return referenceRanges
        .map((range) =>
          formatter.formatRange(
            range,
            ZwiReferenceFormats.display.referenceInput
          )
        )
        .join(', ');
    } else {
      return '';
    }
  }

  hasReferences() {
    return this.sermon?.references && this.sermon.references.length > 0;
  }
  // getVerses() {
  //   return this.sermon?.verses[0];
  // }
  //
  // hasVerses() {
  //   return (
  //     this.sermon?.verses &&
  //     this.sermon?.verses.length > 0 &&
  //     this.sermon?.verses[0] !== this.sermon?.name
  //   );
  // }

  goToMinistry(evt: Event) {
    evt.stopPropagation();
  }

  pauseSermon() {
    this.playerService.updateStatus('PAUSED');
  }

  playSermon() {
    this.playerService.updateStatus('PLAYING');
  }

  startSermon() {
    const sermon = JSON.parse(JSON.stringify(this.sermon));
    this.playerService.setSermon(sermon);
  }

  star(evt: Event) {
    this.starChange.emit(this._createStarChangeEvent('star'));
    evt.stopPropagation();
  }

  unstar(evt: Event) {
    this.starChange.emit(this._createStarChangeEvent('unstar'));
    evt.stopPropagation();
  }

  private _createStarChangeEvent(value: 'star' | 'unstar'): StarChange {
    return new StarChange(this, value);
  }
}
