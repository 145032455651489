import { of, merge, Subscription } from 'rxjs';

import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';

import { ReferencePickerComponent } from '../reference-picker/reference-picker.component';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'zwi-reference-picker-toggle',
  templateUrl: 'reference-picker-toggle.component.html',
  styleUrls: ['reference-picker-toggle.component.scss'],
  host: {
    class: 'zwi-reference-picker-toggle',
    '[class.zwi-reference-picker-toggle-active]':
      'referencePicker && referencePicker.opened',
    '[class.mat-accent]':
      'referencePicker && referencePicker.color === "accent"',
    '[class.mat-warn]': 'referencePicker && referencePicker.color === "warn"',
  },
  exportAs: 'zwiReferencePickerToggle',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReferencePickerToggleComponent
  implements AfterContentInit, OnChanges, OnDestroy
{
  private _stateChanges = Subscription.EMPTY;

  @Input('for') referencePicker!: ReferencePickerComponent;

  @Input()
  get disabled(): boolean {
    return this._disabled === undefined
      ? this.referencePicker.disabled
      : !!this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }
  private _disabled: boolean = false;

  constructor(private _changeDectorRef: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.referencePicker) {
      this._watchStateChanges();
    }
  }

  ngOnDestroy() {
    this._stateChanges.unsubscribe();
  }

  ngAfterContentInit() {
    this._watchStateChanges();
  }

  _open(event: Event): void {
    if (this.referencePicker && !this.disabled) {
      this.referencePicker.open();
      event.stopPropagation();
    }
  }

  private _watchStateChanges() {
    const referencePickerDisabled = this.referencePicker
      ? this.referencePicker._disabledChange.asObservable()
      : of();
    const inputDisabled =
      this.referencePicker && this.referencePicker._referencePickerInput
        ? this.referencePicker._referencePickerInput._disabledChange.asObservable()
        : of();
    const referencePickerToggled = this.referencePicker
      ? merge(
          this.referencePicker.openedStream,
          this.referencePicker.closedStream
        )
      : of();

    this._stateChanges.unsubscribe();
    this._stateChanges = merge(
      referencePickerDisabled,
      inputDisabled,
      referencePickerToggled
    ).subscribe(() => this._changeDectorRef.markForCheck());
  }
}
