import { Capacitor } from '@capacitor/core';

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'zwi-simple-form-page',
  templateUrl: './simple-form-page.component.html',
  styleUrls: ['./simple-form-page.component.scss'],
})
export class SimpleFormPageComponent implements OnInit {
  isNativePlatform = Capacitor.isNativePlatform();

  constructor() {}

  ngOnInit(): void {}
}
