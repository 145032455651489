import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import {
  ReferenceBodyComponent,
  ReferenceCell,
} from '../reference-body/reference-body.component';
import { Reference, ReferenceRange } from '@zwiloo/verse/domain/reference';
import { ReferenceAdapter } from '@zwiloo/verse/domain/reference-adapter';

const VERSES_PER_ROW = 10;

class VerseRange {
  constructor(public begin: number | null, public end: number | null) {}
}

@Component({
  selector: 'zwi-chapter-view',
  templateUrl: './chapter-view.component.html',
  styleUrls: ['./chapter-view.component.scss'],
})
export class ChapterViewComponent implements AfterContentInit {
  @Input()
  get activeReference(): Reference {
    return this._activeReference;
  }
  set activeReference(value: Reference) {
    const oldReference = this._activeReference;
    this._activeReference = value;
    if (!this._hasSameChapterAndBook(oldReference, this._activeReference)) {
      this._init();
    }
    // const { begin, end } = this._getVersesRange();
    // this._selectedBeginVerse = begin;
    // this._selectedEndVerse = end;
  }
  private _activeReference: Reference = new Reference();

  @Input()
  get selected(): ReferenceRange | null {
    return this._selected;
  }
  set selected(value: ReferenceRange | null) {
    this._selected = value;
    const { begin, end } = this._getVersesRange();
    this._selectedBeginVerse = begin;
    this._selectedEndVerse = end;
  }
  private _selected: ReferenceRange | null = null;

  @Input()
  get minReference(): Reference | null {
    return this._minReference;
  }
  set minReference(value: Reference | null) {
    this._minReference = value;
  }
  private _minReference: Reference | null = null;

  @Input()
  get maxReference(): Reference | null {
    return this._maxReference;
  }
  set maxReference(value: Reference | null) {
    this._maxReference = value;
  }
  private _maxReference: Reference | null = null;

  @Output()
  readonly selectedChange: EventEmitter<Reference> =
    new EventEmitter<Reference>();

  @ViewChild(ReferenceBodyComponent)
  _referenceBody!: ReferenceBodyComponent;
  _verses: ReferenceCell[][] = [[]];

  // _selectedVerses: number[] | null = null;
  _selectedBeginVerse: number | null = null;
  _selectedEndVerse: number | null = null;

  constructor(@Optional() public _referenceAdapter: ReferenceAdapter) {
    this.activeReference = this._referenceAdapter.first();
  }

  ngAfterContentInit() {
    this._init();
  }

  _init() {
    this._createVerseCells();
    this._setRanges();
  }

  _createVerseCells() {
    const versesInChapter = this._referenceAdapter.getNumVersesInChapter(
      this.activeReference
    );
    this._verses = [[]];
    for (let i = 0, cell = 0; i < versesInChapter; i++, cell++) {
      if (cell === VERSES_PER_ROW) {
        this._verses.push([]);
        cell = 0;
      }
      const verse = i + 1;
      this._verses[this._verses.length - 1].push(
        new ReferenceCell(verse, String(verse), String(verse), true)
      );
    }
    // TODO: Substitute for date names e.g., Genesis 1:1
    // return new ReferenceCell(verse, String(verse), String(verse), true);
  }

  _verseSelected(verse: number) {
    const selectedBook = this._referenceAdapter.getBook(this.activeReference);
    const selectedChapter = this._referenceAdapter.getChapter(
      this.activeReference
    );
    this.selectedChange.emit(
      this._referenceAdapter.createReference(
        selectedBook,
        selectedChapter,
        verse
      )
    );
  }

  _focusActiveCell() {
    this._referenceBody._focusActiveCell();
  }

  private _getVersesRange(): VerseRange {
    const startVerse = this._getStartVerse();
    const endVerse = this._getEndVerse();
    return new VerseRange(startVerse, endVerse);
  }

  private _getStartVerse(): number | null {
    if (this._selected !== null) {
      const verseInCurrentChapter = this._getVerseInCurrentChapter(
        this._selected.begin
      );
      if (verseInCurrentChapter !== null) {
        return verseInCurrentChapter;
      }
      // Start verse is 0 if the activeReference is a chapter after the start chapter and before or
      // the same as the end chapter
      if (
        this.activeReference.chapter > this._selected.begin.chapter &&
        this._selected.end !== null &&
        this.activeReference.chapter <= this._selected.end.chapter
      ) {
        return 0;
      }
      return null;
      // if (this._selected.begin.verse && result === null) {
      //   if (
      //     (this.activeReference.chapter >= this._selected.begin.chapter &&
      //       !this._selected.end) ||
      //     this.activeReference.chapter <= this._selected.end.chapter
      //   ) {
      //     result = 0;
      //   }
      // }
    } else {
      return null;
    }

    // let startVerse = this._getVerseInCurrentChapter(this._selected.begin);
    // if (this._selected.begin.verse && startVerse === null) {
    //   if (
    //     (this.activeReference.chapter >= this._selected.begin.chapter &&
    //       !this._selected.end) ||
    //     this.activeReference.chapter <= this._selected.end.chapter
    //   ) {
    //     startVerse = 0;
    //   }
    // }
    // return startVerse;
  }

  private _getEndVerse(): number | null {
    if (
      this._selected !== null &&
      this._selected.end !== null &&
      this._selected.end.verse
    ) {
      const verseInCurrentChapter = this._getVerseInCurrentChapter(
        this._selected.end
      );
      if (verseInCurrentChapter !== null) {
        return verseInCurrentChapter;
      }
      if (
        this.activeReference.chapter >= this._selected.begin.chapter &&
        this.activeReference.chapter < this._selected.end.chapter
      ) {
        return (
          this._referenceAdapter.getNumVersesInChapter(this.activeReference) + 1
        );
      } else {
        return null;
      }
    } else {
      return null;
    }

    // if (this._seled

    // let result = null;
    // if (this._selected !== null) {
    //   result = this._getVerseInCurrentChapter(this._selected.end);
    //   if (this._selected.end.verse && result === null) {
    //     if (
    //       this.activeReference.chapter >= this._selected.begin.chapter &&
    //       this.activeReference.chapter < this._selected.end.chapter
    //     ) {
    //       result =
    //         this._referenceAdapter.getNumVersesInChapter(this.activeReference) +
    //         1;
    //     }
    //   }
    // }
    //
    // return result;
    // let endVerse = this._getVerseInCurrentChapter(this._selected.end);
    // if (this._selected.end.verse && endVerse === null) {
    //   if (
    //     this.activeReference.chapter >= this._selected.begin.chapter &&
    //     this.activeReference.chapter < this._selected.end.chapter
    //   ) {
    //     endVerse =
    //       this._referenceAdapter.getNumVersesInChapter(this.activeReference) +
    //       1;
    //   }
    // }
    // return endVerse;
  }

  private _getVerseInCurrentChapter(
    reference: Reference | null
  ): number | null {
    return reference !== null &&
      this._hasSameChapterAndBook(reference, this.activeReference)
      ? this._referenceAdapter.getVerse(reference)
      : null;
  }

  private _hasSameChapterAndBook(
    r1: Reference | null,
    r2: Reference | null
  ): boolean {
    return !!(
      r1 &&
      r2 &&
      this._referenceAdapter.getChapter(r1) ===
        this._referenceAdapter.getChapter(r2) &&
      this._referenceAdapter.getBook(r1) === this._referenceAdapter.getBook(r2)
    );
  }

  private _setRanges() {
    const { begin, end } = this._getVersesRange();
    this._selectedBeginVerse = begin;
    this._selectedEndVerse = end;
  }

  getActiveCell() {
    if (this.activeReference) {
      const verse = this._referenceAdapter.getVerse(this.activeReference);
      return verse > 0 ? verse : 1;
    } else {
      return 1;
    }
  }
}
