import isEqual from 'lodash/isEqual';

import { Reference, ReferenceRange } from './reference';
import { endingVerses } from './ending-verses';

import { BOOKS_TESTAMENTS } from './books-testaments';

export interface ReferenceFormat {
  book: 'short' | 'long';
  chapter?: Boolean;
  verse?: Boolean;
}

export interface ReferenceRangeFormat {
  book: 'short' | 'long';
  begin: {
    chapter?: Boolean;
    verse?: Boolean;
  };
  end: {
    chapter?: Boolean;
    verse?: Boolean;
  };
}

export class ReferenceFormatter {
  constructor() {}

  format(reference: Reference, referenceFormat: ReferenceFormat): string {
    let val = '';

    if (referenceFormat.book === 'long') {
      val += BOOKS_TESTAMENTS[reference.book - 1].name;
    } else if (referenceFormat.book === 'short') {
      val += BOOKS_TESTAMENTS[reference.book - 1].abbreviation;
    }

    if (referenceFormat.chapter && reference.chapter) {
      val += ` ${reference.chapter}`;
    }

    if (referenceFormat.verse && reference.verse) {
      val += `:${reference.verse}`;
    }

    return val;
  }

  formatRange(
    referenceRange: ReferenceRange,
    referenceFormat: ReferenceRangeFormat
  ): string {
    // If start and end are the same then format the start
    if (isEqual(referenceRange.begin, referenceRange.end)) {
      return this.format(referenceRange.begin, {
        book: referenceFormat.book,
        ...referenceFormat.begin,
      });
    }

    let val = '';

    if (referenceFormat.book === 'long') {
      val += BOOKS_TESTAMENTS[referenceRange.begin.book - 1].name;
    } else if (referenceFormat.book === 'short') {
      val += BOOKS_TESTAMENTS[referenceRange.begin.book - 1].abbreviation;
    }

    if (referenceFormat.begin.chapter && referenceRange.begin.chapter) {
      val += ` ${referenceRange.begin.chapter}`;
    }

    if (
      referenceFormat.begin.verse &&
      referenceFormat.end.verse &&
      this._areWholeChapters(referenceRange)
    ) {
      if (
        referenceRange.end !== null &&
        !this._isSameChapter(referenceRange.begin, referenceRange.end)
      ) {
        val += `-${referenceRange.end.chapter}`;
      }
    } else {
      if (referenceFormat.begin.verse && referenceRange.begin.verse) {
        val += `:${referenceRange.begin.verse}`;
      }
      if (referenceRange.end) {
        val += '-';
        if (
          referenceFormat.end.chapter &&
          referenceRange.end.chapter &&
          !this._isSameChapter(referenceRange.begin, referenceRange.end)
        ) {
          val += `${referenceRange.end.chapter}:`;
        }

        if (referenceFormat.end.verse && referenceRange.end.verse) {
          val += String(referenceRange.end.verse);
        }
      }
    }

    return val;
  }

  _isSameChapter(r1: Reference, r2: Reference) {
    return r1.chapter === r2.chapter;
  }

  _areWholeChapters(rr: ReferenceRange) {
    return (
      rr.begin &&
      rr.begin.verse &&
      rr.begin.verse === 1 &&
      rr.end !== null &&
      rr.end.verse &&
      rr.end.chapter !== undefined &&
      endingVerses[rr.end.book - 1][rr.end.chapter - 1] === rr.end.verse
    );
  }
}
