<div class="zwi-section">
  <zwi-headline>Availability</zwi-headline>
  <div class="zwi-page-sections">
    <div class="zwi-section">
      <div>
        <zwi-title>RSS feed</zwi-title>
        <div class="zwi-flex-center zwi-block-section--horizontal">
          <zwi-body>{{ rssURL() }}</zwi-body>
          <button mat-flat-button color="primary" (click)="copy()">Copy</button>
        </div>
      </div>
      <div class="zwi-flex-start">
        <div class="zwi-flex-1 zwi-block-section">
          <zwi-title>Enable RSS feed</zwi-title>
          <button mat-flat-button color="primary">Enable</button>
        </div>
        <div class="zwi-flex-1 zwi-block-section">
          <zwi-title>FAQs</zwi-title>
          <div>What does my RSS feed do?</div>
          <zwi-body>Your RSS feed allows your podcast to appear in other podcast apps, in some cases automatically. The RSS feed is public.</zwi-body>
        </div>
      </div>
    </div>
    <div class="zwi-section">
      <zwi-section-headline>Switching is easy</zwi-section-headline>
      <form [formGroup]="previousFeedForm" (submit)="submitPreviousFeedURL()" class="zwi-block-section">
        <mat-form-field appearance="fill" class="zwi-full-width" style="max-width: 600px;">
          <mat-label>Enter your existing RSS feed</mat-label>
          <input formControlName="url" matInput>
          <mat-error>
            @if (previousFeedForm.get('url')?.errors?.invalid) {
              RSS feed has already been imported. Your RSS link is: {{ rssURL() }}
            }
          </mat-error>
        </mat-form-field>
        <div class="zwi-block-section">
          <zwi-body class="zwi-bold">Please read and agree to the following before you continue:</zwi-body>
          <zwi-body>I own, or have  licensed, all rights necessary to import this podcast to Zwilloo, and to grant the license under the Terms of Service.</zwi-body>
        </div>
        @if (previousFeed.status() === 2) {
          <div class="zwi-block-section">
            <zwi-body [bold]="true">The RSS feed imported successfully! Your new RSS link is:</zwi-body>
            <zwi-body>{{ rssURL() }}</zwi-body>
          </div>
        }
        @if (previousFeed.status() === 1) {
          <div class="zwi-flex-center" style="height: 36px">
            <mat-progress-spinner color="accent" mode="indeterminate" diameter="25"></mat-progress-spinner>
          </div>
        } @else {
          <button mat-flat-button color="primary" type="submit">Switch</button>
        }

      </form>

      <div class="zwi-flex-start zwi-section--horizontal">
        <div class="zwi-flex-1">
          <zwi-title>Find your show</zwi-title>
          <zwi-body>Just paste in your RSS feed, and we can automatically import your sermons and information.</zwi-body>
        </div>
        <div class="zwi-flex-1">
          <zwi-title>Redirect your feed</zwi-title>
          <zwi-body>We’ll give you a link so your old host knows where to send your content—you’ll keep all your followers on every platform.</zwi-body>
        </div>
        <div class="zwi-flex-1">
          <zwi-title>Add new sermons</zwi-title>
          <zwi-body>Add new sermons by uploading audio</zwi-body>
        </div>
      </div>
    </div>
  </div>
</div>
<!--<zwi-account-close-dialog [open]="open" (closed)="close()"></zwi-account-close-dialog>-->
