import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'zwi-nav-tabs',
  templateUrl: './nav-tabs.component.html',
  styleUrls: ['./nav-tabs.component.scss'],
})
export class NavTabsComponent implements OnInit {
  @Input() menuItems: { name: string; icon: string; path: string }[] = [];

  constructor() {}

  ngOnInit(): void {}
}
