import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';

import { SharedModule } from '@zwiloo/frontend/shared/shared.module';
import { MinistryConvertComponent } from './ministry-convert.component';
import { TypographyModule } from '@zwiloo/frontend/typography/typography.module';

@NgModule({
  declarations: [MinistryConvertComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    RouterModule,
    SharedModule,
    TypographyModule,
  ],
})
export class MinistryConvertModule {}
