import { Upload } from '@zwiloo/studio/domain';

export interface SingleChapterReference {
  book: string;
  chapter: number;
  verses: string;
}

export interface MultiChapterReference {
  book: string;
  startingChapter: number;
  startingVerses: string;
  endingChapter: number;
  endingVerses: string;
}

export class Reference {
  constructor(public book: number = 1, public chapter = 1, public verse = 0) {}
}

export class ReferenceRange {
  constructor(public begin = new Reference(), public end: Reference | null) {}
}

export function isValidReference(r: ReferenceRange): boolean {
  return r.begin?.book > 0;
}

export class BookChapter {
  constructor(public book = 0, public chapter = 0) {}
}

export function includesBookChapter(
  referenceRanges: ReferenceRange[],
  b: BookChapter
) {
  return referenceRanges.some((r) => {
    if (r.end === null) {
      r.end = r.begin;
    }
    if (b.chapter === 0) {
      return r.begin.book === b.book;
    } else {
      return (
        r.begin.book === b.book &&
        r.begin.chapter >= b.chapter &&
        r.end.chapter <= b.chapter
      );
    }
  });
}
