import { BehaviorSubject, Subject } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

import { Injectable, signal } from '@angular/core';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { SaveStatus, Upload, UploadStatus } from '../domain';
import { Sermon } from '@zwiloo/sermon/domain';
import { SermonDataService } from '@zwiloo/ministry/data-interface/sermon-data.service';

import { UploadDataService } from '../data-interface/upload-data.service';
import { Series, Speaker } from '@zwiloo/ministry/domain';
import { SeriesDataService } from '@zwiloo/ministry/data-interface/series-data.service';
import { SpeakerDataService } from '@zwiloo/ministry/data-interface/speaker-data.service';
import { CallState, LoadingState } from '@zwiloo/util/state';
import { AccountService } from '@zwiloo/user/account';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  // selected = new BehaviorSubject<number>(0);
  selected = signal(0);
  sermons = new Subject<Sermon[]>();
  public speakerLoadStatus = signal(LoadingState.INIT);
  public speakers = new Subject<Speaker[]>();
  public series = new Subject<Series[]>();
  public seriesLoadStatus = signal(LoadingState.INIT);
  public seriesStatus = new BehaviorSubject<CallState>(LoadingState.INIT);
  uploads = new BehaviorSubject<Upload[]>([]);
  public uploadStatus = new BehaviorSubject<CallState>(LoadingState.INIT);

  constructor(
    private accountService: AccountService,
    private sermonData: SermonDataService,
    private speakerData: SpeakerDataService,
    private seriesData: SeriesDataService,
    private uploadData: UploadDataService
  ) {}

  addSeriesAndSet(name: string, uploadNum: number, firstSermonId: number) {
    this.seriesStatus.next(LoadingState.LOADING);
    const m = this.accountService.getMinistry();
    this.seriesData.addSeries(name, m.id).subscribe({
      next: (r) => {
        this.setSeries(r.series.id, uploadNum);
        this.setSelected(firstSermonId);
      },
    });
  }

  getSermons() {
    const m = this.accountService.getMinistry();
    this.sermonData.getSermons(m.id).subscribe((s) => {
      this.sermons.next(s);
    });
  }

  getSeries() {
    const m = this.accountService.getMinistry();
    this.seriesLoadStatus.set(LoadingState.LOADING);
    this.seriesData.getSeries(m.id).subscribe((s) => {
      this.seriesLoadStatus.set(LoadingState.LOADED);
      this.series.next(s);
    });
  }

  getMinistryInfo() {
    const m = this.accountService.getMinistry();
    this.speakerLoadStatus.set(LoadingState.LOADING);
    this.speakerData.getSpeakers(m.id).subscribe((s) => {
      this.speakerLoadStatus.set(LoadingState.LOADED);
      this.speakers.next(s);
    });
    this.getSeries();
  }

  initalizeUpload() {
    this.getSermons();
    this.getSeries();
  }

  save(id: number) {
    const upload = this.uploads.getValue().find((u) => u.id === id);
    if (upload !== undefined) {
      // If upload complete, save upload, then check to see if all the uploads are complete
      // and navigate to upload complete page, if not navigate to next upload
      if (upload.uploadStatus === UploadStatus.Complete) {
        this.uploadData.save(upload).subscribe(() => {
          // If this sermon is currently selected and there is
          // another sermon then navigate to the next sermon
          // else navigate to complete, this is the last act
          // of the upload, navigate to the next state if necessary.
          // Next it should be removed
          if (this.uploads.getValue().length > 1) {
            this.selectNextUpload(id);
          } else {
            this.uploadStatus.next(LoadingState.LOADED);
          }
          this.uploads.next(this.uploads.getValue().filter((u) => u.id !== id));
        });
      } else {
        if (this.uploads.getValue().length > 1) {
          this.selectNextUpload(id);
        }
      }
    }
  }

  selectNextUpload(id: number) {
    const selectedIndex = this.uploads.getValue().findIndex((u) => u.id === id);
    const nextIndex =
      selectedIndex + 1 === this.uploads.value.length ? 0 : selectedIndex + 1;
    const nextUpload = this.uploads.value[nextIndex];
    this.setSelected(nextUpload.id);
  }

  setSeries(seriesID: number, uploadNum?: number) {
    let uploads = this.uploads.getValue();
    if (uploadNum) {
      uploads = uploads.slice(uploadNum * -1);
    }
    uploads.forEach((s) => {
      s.sermonInfo.seriesID = seriesID;
    });
    this.seriesStatus.next(LoadingState.LOADED);
  }

  setSelected(uploadID: number) {
    this.selected.set(uploadID);
  }

  updateUpload(upload: Upload) {
    const uploads = this.uploads.value.map((u) =>
      u.id === upload.id ? upload : u
    );
    this.uploads.next(uploads);
  }

  upload(upload: Upload) {
    // upload.uploadStatus = UploadStatus.Uploading;
    this.uploadStatus.next(LoadingState.LOADING);
    const uploads = [...this.uploads.value, upload];
    this.uploads.next(uploads);
    upload.uploadStatus = UploadStatus.Uploading;
    return this.uploadData.uploadFile(upload).pipe(
      filter((event) => {
        return this.processEvent(event, upload) === 'complete';
      }),
      switchMap(() => {
        upload.uploadStatus = UploadStatus.Processing;
        return this.uploadData.checkProcessing(upload.uploadedFile.id).pipe(
          tap((path: string) => {
            upload.uploadedFile = { ...upload.uploadedFile, path };
            upload.uploadStatus = UploadStatus.Complete;
            this.uploads.next([...this.uploads.value]);
          }),
          filter((_) => upload.saveStatus === SaveStatus.Saved),
          tap(() => {
            this.save(upload.id);
          })
        );
      })
    );
    // return adapter.addOne(upload, state);
  }

  private processEvent(event: HttpEvent<any>, upload: Upload): string {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        if (event.total !== undefined) {
          const progress = Math.round((100 * event.loaded) / event.total);
          upload.progress = progress;
        } else {
          // Cannot track progress so just assume 0, TODO: Make indeterminate progress indicator if 0
          upload.progress = 0;
        }
        return 'uploading';
      case HttpEventType.Response:
        const body = event.body;
        const tempAudioID = body.id;
        upload.uploadedFile = { id: tempAudioID };
        return 'complete';
      default:
        return 'error';
    }
  }
}
// return this.uploadData.checkProcessing(tempAudioID).pipe(
//   tap((path: string) => {
//     upload.uploadedFile = { ...upload.uploadedFile, path };
//     upload.uploadStatus = UploadStatus.Complete;
//   })
//
//
// );
// UploadAPIActions.processingSucceeded({ id, path })

// return UploadAPIActions.uploadError();

// on(UploadAPIActions.processingSucceeded, (state, { id, path }) => {
//   const upload = getUpload(state, id);
//   const uploadedFile = { ...upload.uploadedFile, path };
//   return adapter.updateOne(
//     { id, changes: { uploadStatus: UploadStatus.Complete, uploadedFile } },
//     state
//   );
// }),
