<div class="zwi-list-row" [class]="{'zwi-list-row-active': active}" (mouseover)="hover.set(true)" (mouseleave)="hover.set(false)">
  <div class="zwi-list-play">
    @if (active) {
      @if (sermonService.status() === 'PLAYING') {
        <mat-icon class="zwi-list-play-icon">pause</mat-icon>
      } @else {
        <mat-icon class="zwi-list-play-icon">play_arrow</mat-icon>
      }
    } @else if (hover()) {
      <mat-icon class="zwi-list-play-icon">play_arrow</mat-icon>
    } @else {
      @if (order !== null) {
        <div>{{ order }}</div>
      }
    }
  </div>
  <div class="zwi-list-title zwi-nowrap">{{ sermon.name }}</div>
  <div class="zwi-list-subtitle zwi-flex-center zwi-nowrap">
    @if (hasReferences(sermon)) {
      <span class="zwi-nowrap">{{ getReferences(sermon.references) }}</span>
      <span class="zwi-list-delimeter">·</span>
    }
    @if (sermon.ministry.name) {
      <span class="zwi-nowrap">{{ sermon.ministry.name }}</span>
      <span class="zwi-list-delimeter">·</span>
    }
    <span>{{ sermon.speaker.name }}</span>
  </div>

  <div class="zwi-list-duration">
    <zwi-time-display duration [currentTime]="sermon.duration" unit="ms"></zwi-time-display>
  </div>
</div>
<!--<div subtitle class="zwi-flex-center zwi-nowrap">-->
<!--  @if (hasReferences(s)) {-->
<!--    <span class="zwi-nowrap">{{ getReferences(s.references) }}</span>-->
<!--    <span class="zwi-list-delimeter">·</span>-->
<!--  }-->
<!--  @if (s.ministry.name) {-->
<!--    <span class="zwi-nowrap">{{ s.ministry.name }}</span>-->
<!--    <span class="zwi-list-delimeter">·</span>-->
<!--  }-->
<!--  <span>{{ s.speaker.name }}</span>-->
<!--</div>-->
<!--<zwi-time-display duration [currentTime]="s.duration"></zwi-time-display>-->
