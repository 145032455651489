<zwi-main-wrapper>
  <zwi-main-content>
    <div class="zwi-section">
      <mat-toolbar>
        <div class="zwi-page-title">
          <mat-icon class="zwi-page-title__icon">bookmark</mat-icon>
          <div class="zwi-page-title__text">Saved</div>
        </div>
      </mat-toolbar>
      <ng-container *ngIf="authService.sessionStatus() !== 2; else content"></ng-container>
      <ng-template #content>
        <zwi-sign-in-call-to-action *ngIf="authService.hasSession() === false; else favorites">
          <ng-container title>Sign in to save sermons</ng-container>
          <ng-container content>Once you sign in, you'll be able to save sermons.</ng-container>
        </zwi-sign-in-call-to-action>
        <ng-template #favorites>
          <zwi-favorite-sermons></zwi-favorite-sermons>
        </ng-template>
      </ng-template>
    </div>
  </zwi-main-content>
</zwi-main-wrapper>
