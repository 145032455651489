import { Subscription } from 'rxjs';

import { Component, computed, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { MatSnackBar } from '@angular/material/snack-bar';

import { AdminService } from '@zwiloo/settings/context/admin.service';
import { CallState, LoadingState } from '@zwiloo/util/state';
import { mobileWidth } from '@zwiloo/util/tokens';

@Component({
  selector: 'zwi-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
  providers: [AdminService],
})
export class AdminComponent implements OnInit {
  admins: string[] = [];
  email = new FormControl<string>('');
  status: CallState = LoadingState.INIT;
  adminsSubscription: Subscription;
  statusSubscription: Subscription;
  isMobile = computed(() => {
    return window.innerWidth <= mobileWidth;
  });

  constructor(private admin: AdminService, private _snackBar: MatSnackBar) {
    this.adminsSubscription = admin.admins.subscribe((a) => (this.admins = a));

    this.statusSubscription = admin.status.subscribe((s) => {
      this.status = s;
      if (s === 2) {
        this._snackBar.open('Admin invitation sent.', undefined, {
          duration: 5000,
        });
        this.email.reset();
      }
    });
  }

  ngOnInit(): void {
    this.admin.getAdmins();
  }

  add() {
    const e = this.email.value;
    if (e !== null) {
      this.admin.sendInvitation(e);
    }
  }

  delete() {}
}
