import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';

import { MobileSettingsComponent } from './mobile-settings.component';
import { SettingsModule } from '@zwiloo/frontend/settings/settings.module';
import { SharedModule } from '@zwiloo/frontend/shared/shared.module';
import { MobileAccountComponent } from './mobile-account/mobile-account.component';
import { MobilePasswordComponent } from './mobile-password/mobile-password.component';
import { MobileProfileImageComponent } from './mobile-profile-image/mobile-profile-image.component';
import { TypographyModule } from '@zwiloo/frontend/typography/typography.module';
import { MobileEmailComponent } from './mobile-email/mobile-email.component';

@NgModule({
  declarations: [
    MobileSettingsComponent,
    MobileAccountComponent,
    MobilePasswordComponent,
    MobileProfileImageComponent,
    MobileEmailComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    ReactiveFormsModule,
    RouterModule,
    SettingsModule,
    SharedModule,
    TypographyModule,
  ],
})
export class MobileSettingsModule {}
