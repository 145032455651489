import { Capacitor } from '@capacitor/core';

import { Component, computed, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'zwi-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent {
  @Output() close = new EventEmitter();

  isAndroid = Capacitor.getPlatform() === 'android';

  isNative = Capacitor.isNativePlatform();

  emitClose() {
    this.close.emit();
  }
}
