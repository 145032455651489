<div class="zwi-embed-container">
  <zwi-embed-cover-art [backgroundImage]="sermonService.sermon().ministry.profileImage"></zwi-embed-cover-art>
  <zwi-embed-logo></zwi-embed-logo>
  <div class="zwi-content">
    <zwi-embed-metadata [sermon]="sermonService.sermon()" [title]="sermonService.sermon().name"></zwi-embed-metadata>
  </div>
  <div class="zwi-player-wrapper">
    <zwi-embed-player></zwi-embed-player>
  </div>
</div>
