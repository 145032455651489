import { Observable, Subject } from 'rxjs';

import { Injectable, signal } from '@angular/core';

import { AccountDataService } from './data-interface/account-data.service';
import { tap } from 'rxjs/operators';
import { UserDataService } from './data-interface/user-data.service';
import { Ministry } from '@zwiloo/ministry/domain';
import { Favorite, Sermon } from '@zwiloo/sermon/domain';
import { getCookie } from '@zwiloo/util/cookie';

export class Account {
  constructor(
    public id = 0,
    public email = '',
    public password1 = '',
    public password2 = ''
  ) {}
}

@Injectable()
export class AccountService {
  isVerified = signal(true);
  constructor(
    private dataService: AccountDataService,
    private user: UserDataService
  ) {}

  cacheAccount(email: string) {
    const account = { email };
    localStorage.setItem('account', JSON.stringify(account));
  }

  getAccountEmail(): string | null {
    const a = localStorage.getItem('account');
    if (a !== null) {
      const session = JSON.parse(a);
      return session.email;
    }
    return null;
  }

  getMinistry(): Ministry {
    const m = localStorage.getItem('ministry');
    if (m !== null) {
      return JSON.parse(m) as Ministry;
    } else {
      return new Ministry();
    }
  }

  getSession(): string | null {
    return getCookie('session');
  }

  isOnboardingComplete() {
    const o = localStorage.getItem('onboardingComplete');
    return o !== null;
  }

  removeAccount() {
    localStorage.removeItem('account');
  }

  removeMinistry() {
    localStorage.removeItem('ministry');
  }

  setIsVerified() {
    // Optimistic update to prevent banner showing if unecessary
    this.isVerified.set(false);
    this.isVerified.set(true);
    this.user.getSession().subscribe((s) => {
      this.isVerified.set(s.isVerified);
    });
  }

  setOnboardingComplete() {
    localStorage.setItem('onboardingComplete', 'true');
  }

  updateProfileImage(url: string) {
    const m = localStorage.getItem('ministry');
    if (m !== null) {
      const ministry = JSON.parse(m);
      ministry.profileImage = url;
      localStorage.setItem('ministry', JSON.stringify(ministry));
    }
  }

  setMinistryInfo() {
    return this.user.loadMinistryInfo().pipe(
      tap((m) => {
        // An id of 0 represents an undefined ministry
        localStorage.setItem('ministry', JSON.stringify(m));
      })
    );
  }

  verifyEmail(userID: number, token: string) {
    return this.dataService.verifyEmail(userID, token);
  }

  signUp(account: Account): Observable<Account> {
    return this.dataService.signUp(account);
  }
}
