import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { Ministry } from '@zwiloo/ministry/domain';
import { SubscribeChange } from '@zwiloo/frontend/shared/subscribe-button/subscribe-button.component';
import { MinistryService } from '@zwiloo/ministry/ministry.service';

export class SubscriptionChange {
  constructor(public ministry: Ministry, public subscribed: boolean) {}
}

@Component({
  selector: 'zwi-ministry-list',
  templateUrl: './ministry-list.component.html',
  styleUrls: ['./ministry-list.component.scss'],
})
export class MinistryListComponent {
  @Input() ministries: Ministry[] = [];
  @Input() followable = true;
  @Output() subscribeChange: EventEmitter<SubscribeChange> = new EventEmitter();

  constructor(private ministryService: MinistryService) {}

  public changeSubscription(change: SubscribeChange) {
    this.subscribeChange.emit(change);
    // if (change.value === 'subscribe') {
    //   this.ministryService.subscribe(change.source.ministry);
    // } else if (change.value === 'unsubscribe') {
    //   this.ministryService.unsubscribe(change.source.ministry);
    // }
  }
}
