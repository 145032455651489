import { BehaviorSubject, Subject } from 'rxjs';

import { Injectable } from '@angular/core';

import { CallState, LoadingState } from '@zwiloo/util/state';
import {
  AccountDataService,
  VerifyResponse,
} from '../data-interface/account-data.service';

@Injectable()
export class VerifyEmailService {
  public result = new Subject<VerifyResponse>();
  public status = new BehaviorSubject<CallState>(LoadingState.INIT);

  constructor(public accountData: AccountDataService) {}

  sendVerifyToken(email: string) {
    return this.accountData.sendVerifyToken(email).subscribe();
  }

  verifyEmail(userID: number, token: string) {
    this.status.next(LoadingState.LOADING);
    this.accountData.verifyEmail(userID, token).subscribe((s) => {
      this.result.next(s);
      this.status.next(LoadingState.LOADED);
    });
  }
}
