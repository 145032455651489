import { Component, Inject, OnInit } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'zwi-definite-series-match',
  templateUrl: './definite-series-match.component.html',
  styleUrls: ['./definite-series-match.component.scss'],
})
export class DefiniteSeriesMatchComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<DefiniteSeriesMatchComponent>) {}

  ngOnInit() {}

  onClick(): void {
    this.dialogRef.close();
  }
}
