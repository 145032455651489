import { combineLatest, Subscription } from 'rxjs';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';

import { Sermon } from '@zwiloo/sermon/domain';
import { SaveStatus, Upload, UploadStatus } from '@zwiloo/studio/domain';
import { UploadService } from '@zwiloo/studio/context/upload.service';
import { PlayerService } from '@zwiloo/sermon/context/player.service';
import { MatChipListboxChange } from '@angular/material/chips';
import { Series } from '@zwiloo/ministry/domain';
import { seriesInfo, SeriesInfo } from '@zwiloo/ministry/domain/series';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { FormErrorService } from '@zwiloo/frontend/form/services/form-error.service';
import { CallState, LoadingState } from '@zwiloo/util/state';

@Component({
  selector: 'zwi-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent implements OnInit {
  active = 'Sermon';
  existingSeriesForm = this.formBuilder.group({
    series: [undefined, Validators.required],
  });
  newSeriesForm = this.formBuilder.group({ series: ['', Validators.required] });
  page = 'upload';
  selectedSubscription: Subscription;
  series: Series[] = [];
  seriesInfo: SeriesInfo[] = [];
  seriesCategory = 'New series';
  seriesRequired = false;
  sermons: Sermon[] = [];
  seriesStatusSubscription: Subscription;
  seriesStatus: CallState = LoadingState.INIT;
  seriesSubscription: Subscription;
  subscription: Subscription;
  subscriptionUploadStatus: Subscription;
  upload?: Upload;
  uploadNum = 0;
  uploads: Upload[] = [];
  uploadsSubsciption: Subscription;

  // uploadedProgress$: Subscription;
  // uploadPipeline: Subscription;
  // uploadedComplete$: Subscription;

  constructor(
    route: ActivatedRoute,
    public formBuilder: FormBuilder,
    public formError: FormErrorService,
    public uploadService: UploadService,
    private playerService: PlayerService,
    private router: Router // private authService: AuthService, // private router: Router, // private store: Store<fromStudio.State>, // private uploadDatabase: UploadDatabase
  ) {
    this.seriesSubscription = uploadService.series.subscribe((s) => {
      this.series = s;
    });
    this.seriesStatusSubscription = uploadService.seriesStatus.subscribe(
      (s) => {
        this.seriesStatus = s;
        if (s === 2) {
          this.page = 'info';
        }
      }
    );
    this.subscription = uploadService.sermons.subscribe((s) => {
      this.sermons = s;
      this.seriesInfo = seriesInfo(s);
    });
    this.uploadsSubsciption = uploadService.uploads.subscribe((u) => {
      this.uploads = u;
    });

    this.selectedSubscription = toObservable(uploadService.selected).subscribe(
      (s) => {
        this.updateCurrent(s);
      }
    );

    this.subscriptionUploadStatus = uploadService.uploadStatus.subscribe(
      (s) => {
        if (s === 1) {
          if (this.active === 'Series') {
            this.page = 'series';
          } else {
            this.page = 'info';
          }
        } else if (s === 2) {
          this.page = 'complete';
        }
      }
    );
  }

  ngOnInit() {
    this.uploadService.initalizeUpload();
    const selected = this.uploadService.selected();
    if (selected !== 0) {
      const uploads = this.uploadService.uploads.getValue();
      const current = uploads.find((u) => u.id === selected);
      if (current !== undefined) {
        this.upload = current;
        this.page = 'info';
      }
    } else {
      this.page = 'upload';
    }
  }

  ngOnDestroy() {
    this.uploadsSubsciption.unsubscribe();
    this.selectedSubscription.unsubscribe();
    this.uploadService.setSelected(0);
    // this.uploadedProgress$.unsubscribe();
    // this.uploadedComplete$.unsubscribe();
  }

  addFiles() {
    const fileInput: HTMLInputElement = document.getElementById(
      'fileInput'
    ) as HTMLInputElement;
    const files = fileInput.files;
    let highestID = 0;
    if (this.uploads.length > 0) {
      const sorted = this.uploads.sort((a, b) => b.id - a.id);
      highestID = sorted[0].id;
    }
    if (files !== null) {
      this.uploadNum = 0;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const id = ++highestID;
        const upload = new Upload(id, file.name, file);
        upload.saveStatus = SaveStatus.Unsaved;
        upload.uploadStatus = UploadStatus.Initial;
        this.uploadService.upload(upload).subscribe();
        ++this.uploadNum;
      }
      // Only set selected if sermon because setting selected will take page to 'info' and
      // series should go to series page
      if (this.active === 'Sermon') {
        this.uploadService.setSelected(highestID - this.uploadNum + 1);
      }
    }
  }

  navigateToUpload() {
    this.page = 'upload';
    this.active = 'Sermon';
    this.seriesCategory = 'New series';
    this.uploadService.initalizeUpload();
  }

  navigateToSermons() {
    this.router.navigateByUrl('/studio/sermons');
  }

  playSermon(sermon: Sermon) {
    this.playerService.setSermon(sermon);
    // this.store.dispatch(new playerActions.ChangeSermon(sermon));
  }

  submitSeries() {
    const seriesUploads = this.uploadService.uploads
      .getValue()
      .slice(this.uploadNum * -1);
    const firstSermonId = seriesUploads[0].id;
    if (this.seriesCategory === 'New series') {
      if (this.newSeriesForm.valid) {
        const seriesName = this.newSeriesForm.get('series')?.getRawValue();
        this.uploadService.addSeriesAndSet(
          seriesName,
          this.uploadNum,
          firstSermonId
        );
      }
    } else {
      if (this.existingSeriesForm.valid) {
        const seriesID = this.existingSeriesForm.get('series')?.getRawValue();
        this.uploadService.setSeries(seriesID, this.uploadNum);
        this.uploadService.setSelected(firstSermonId);
      }
    }
  }

  updateActive(c: MatChipListboxChange) {
    if (c.value) {
      this.active = c.value;
      if (this.active === 'Sermon') {
        this.seriesRequired = false;
      } else {
        this.seriesRequired = true;
      }
    }
  }

  updateCurrent(selectedID: number) {
    const current = this.uploads.find((u) => u.id === selectedID);
    if (current !== undefined) {
      this.upload = current;
      this.page = 'info';
    } else {
      this.page = 'upload';
    }
  }

  uploadFile() {
    const fileInput = <HTMLInputElement>document.getElementById('fileInput');
    if (fileInput !== null) {
      fileInput.click();
      fileInput.value = '';
    }
  }

  updateSeriesCategory(c: MatChipListboxChange) {
    if (c.value) {
      this.seriesCategory = c.value;
    }
  }
}
