import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  input,
  model,
  OnChanges,
  Output,
  output,
  SimpleChanges,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'zwi-embed-options',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
  ],
  templateUrl: './embed-options.component.html',
  styleUrl: './embed-options.component.scss',
})
export class EmbedOptionsComponent {
  height = model(0);
  @Input() heightOptions: number[] = [];
  @Input() minPercent = 42;
  @Input() minPixels = 280;
  @Output() widthChange = new EventEmitter<string>();

  // Properties
  _width = '100%';

  getValidWidth(inputString: string) {
    const parsedNumber = parseFloat(inputString);
    if (Number.isNaN(parsedNumber)) {
      return '100%';
    } else if (inputString.includes('%')) {
      // Extract the number before the percentage sign
      let number = parseFloat(inputString.slice(0, -1));

      // Clamp the number between 40 and 100 for percentage values
      number = Math.max(this.minPercent, Math.min(number, 100));
      return `${number}%`;
    } else {
      // Parse the number directly for non-percentage values
      let number = parseFloat(inputString);

      // Clamp the number between 280 and 600 for non-percentage values
      number = Math.max(this.minPixels, Math.min(number, 600));
      return `${number}px`;
    }
    // this.cdRef.markForCheck();
  }

  emitHeight(h: number) {
    this.height.set(h);
  }

  emitWidth(evt: Event) {
    const input = evt.target as HTMLInputElement;
    const inputString = input.value;
    const w = this.getValidWidth(inputString);
    this._width = w;
    this.widthChange.emit(w);
  }

  getLabel(h: number) {
    const l = Math.min(...this.heightOptions) === h ? 'Compact' : 'Normal';
    return `${l} (${h}px)`;
  }
}
