import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';

import { SharedModule } from '@zwiloo/frontend/shared/shared.module';
import { FavoritesComponent } from './favorites.component';
import { SermonsComponent } from './sermons/sermons.component';

@NgModule({
  declarations: [FavoritesComponent, SermonsComponent],
  imports: [
    CommonModule,
    MatChipsModule,
    MatIconModule,
    MatTabsModule,
    MatToolbarModule,
    SharedModule,
  ],
})
export class FavoritesModule {}
