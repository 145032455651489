import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '@zwiloo/frontend/core/services/auth-guard.service';
import { HomeComponent } from '../home/home.component';
import { FavoritesComponent } from '../favorites/favorites.component';
import { LocalChurchComponent } from '../local-church/local-church.component';
import { MinistryComponent } from '../ministry/ministry.component';
import { MinistryConvertComponent } from '../ministry-convert/ministry-convert.component';
import { MinistryGuard } from '@zwiloo/frontend/core/services/ministry-guard.service';
import { MobileSettingsComponent } from '../mobile-settings/mobile-settings.component';
import { SearchComponent } from '../search/search.component';
import { StudioComponent } from '../studio/studio.component';
import { DashboardComponent } from '../studio/dashboard/dashboard.component';
import { SeriesComponent } from '../studio/series/series.component';
import { SermonsComponent } from '../studio/sermons/sermons.component';
import { SpeakersComponent } from '../studio/speakers/speakers.component';
import { UploadComponent } from '../studio/upload/upload.component';
import { SubscriptionComponent } from '../subscription/subscription.component';
import { UserComponent } from './user/user.component';
import { UserContainerComponent } from './user-container.component';
import { SettingsComponent } from '@zwiloo/frontend/settings/settings.component';
import { AccountComponent } from '@zwiloo/frontend/settings/account/account.component';
import { AdminComponent } from '@zwiloo/frontend/settings/admin/admin.component';
import { AvailabilityComponent } from '@zwiloo/frontend/settings/availability/availability.component';
import { EmailComponent } from '@zwiloo/frontend/settings/email/email.component';
import { PasswordComponent } from '@zwiloo/frontend/settings/password/password.component';
import { ProfileImageComponent } from '@zwiloo/frontend/settings/profile-image/profile-image.component';
import { MobileProfileImageComponent } from '../mobile-settings/mobile-profile-image/mobile-profile-image.component';
import { MobilePasswordComponent } from '../mobile-settings/mobile-password/mobile-password.component';
import { MobileAccountComponent } from '../mobile-settings/mobile-account/mobile-account.component';
import { MobileEmailComponent } from '../mobile-settings/mobile-email/mobile-email.component';
import { PrivacyPolicyComponent } from '@zwiloo/frontend/legal/privacy-policy/privacy-policy.component';

export const routes: Routes = [
  {
    path: '',
    component: UserContainerComponent,
    children: [
      { path: '.well-known/change-password', redirectTo: '/mobile-password' },
      {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'account',
            component: AccountComponent,
            title: 'Settings - Account',
          },
          {
            path: 'admin',
            component: AdminComponent,
            title: 'Settings - Admin',
          },
          {
            path: 'availability',
            component: AvailabilityComponent,
            title: 'Settings - Availability',
          },
          {
            path: 'email',
            component: EmailComponent,
            title: 'Settings - Email',
          },
          { path: 'password', component: PasswordComponent, pathMatch: 'full' },
          { path: 'profile-image', component: ProfileImageComponent },
          { path: 'upload', component: UploadComponent },
        ],
      },
      {
        path: 'mobile-settings',
        canActivate: [AuthGuard],
        component: MobileSettingsComponent,
      },
      {
        path: 'mobile-account',
        canActivate: [AuthGuard],
        component: MobileAccountComponent,
      },
      {
        path: 'mobile-email',
        canActivate: [AuthGuard],
        component: MobileEmailComponent,
      },
      {
        path: 'mobile-password',
        canActivate: [AuthGuard],
        component: MobilePasswordComponent,
      },
      {
        path: 'mobile-profile-image',
        canActivate: [AuthGuard],
        component: MobileProfileImageComponent,
      },
      {
        path: 'studio',
        component: StudioComponent,
        canActivate: [MinistryGuard],
        children: [
          { path: 'dashboard', component: DashboardComponent },
          { path: 'series', component: SeriesComponent },
          { path: 'sermons', component: SermonsComponent },
          { path: 'speakers', component: SpeakersComponent },
          { path: 'upload', component: UploadComponent },
          // { path: 'upload-complete', component: UploadCompleteComponent },
          // { path: 'upload-info', component: UploadInfoComponent },
        ],
      },
      {
        path: '',
        component: UserComponent,
        children: [
          {
            path: 'home',
            component: HomeComponent,
            title: 'Home',
          },
          {
            path: 'local-church',
            component: LocalChurchComponent,
            title: 'Local Church',
          },
          {
            path: 'saved',
            component: FavoritesComponent,
            title: 'Saved',
          },
          {
            path: 'ministry-convert',
            component: MinistryConvertComponent,
          },
          {
            path: 'search',
            component: SearchComponent,
            title: 'Search',
          },
          {
            path: 'following',
            component: SubscriptionComponent,
            title: 'Following',
          },
          {
            path: ':username',
            component: MinistryComponent,
          },
          {
            path: '',
            redirectTo: '/index',
            pathMatch: 'full',
          },
        ],
      },
    ],
  },
];
// {
//   path: 'studio',
//   component: StudioComponent,
//   // canActivate: [AuthGuard],
//   children: [
//     { path: 'dashboard', component: DashboardComponent },
//     { path: 'series', component: SeriesComponent },
//     { path: 'sermons', component: SermonsComponent },
//     { path: 'speakers', component: SpeakersComponent },
//     { path: 'upload', component: UploadComponent },
//     // { path: 'upload-complete', component: UploadCompleteComponent },
//     // { path: 'upload-info/:id', component: UploadInfoComponent },
//   ],
// },

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserRoutingModule {}
