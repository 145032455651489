import { Capacitor } from '@capacitor/core';

import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AccountService } from '@zwiloo/user/account';

@Component({
  selector: 'zwi-sign-in',
  templateUrl: './sign-in.component.html',
})
export class SignInComponent {
  isNativePlatform = Capacitor.isNativePlatform();

  constructor(private accountService: AccountService, private router: Router) {}

  completeOnboarding() {
    this.accountService.setOnboardingComplete();
    this.router.navigateByUrl('/home');
  }
}
