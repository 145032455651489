import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'zwi-mobile-password',
  templateUrl: './mobile-password.component.html',
  styleUrls: ['./mobile-password.component.scss'],
})
export class MobilePasswordComponent {
  constructor(private router: Router) {}
  navigateToSettings() {
    this.router.navigateByUrl('/mobile-settings');
  }
}
