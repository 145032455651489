import { Component, EventEmitter, Input, Output } from '@angular/core';

import { EmbedSermonListContainerComponent } from '../embed-sermon-list-container/embed-sermon-list-container.component';
import { Sermon } from '@zwiloo/sermon/domain';
import { SermonService } from '../../sermon.service';
import { SermonListItemComponent } from '../sermon-list/sermon-list-item/sermon-list-item.component';

@Component({
  selector: 'zwi-embed-latest-sermons',
  standalone: true,
  imports: [EmbedSermonListContainerComponent, SermonListItemComponent],
  templateUrl: './embed-latest-sermons.component.html',
  styleUrl: './embed-latest-sermons.component.scss',
})
export class EmbedLatestSermonsComponent {
  @Input() sermons: Sermon[] = [];
  @Output() sermonSelected = new EventEmitter<Sermon>();

  constructor(public sermonService: SermonService) {}

  emitSelectedSermon(s: Sermon) {
    this.sermonSelected.emit(s);
  }
}
