import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { TermsOfUseDialogComponent } from '../terms-of-use-dialog/terms-of-use-dialog.component';

@Component({
  selector: 'zwi-agreement-message',
  templateUrl: './agreement-message.component.html',
  styleUrls: ['./agreement-message.component.scss'],
})
export class AgreementMessageComponent implements OnInit {
  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  openTermsOfUse() {
    const dialogRef = this.dialog.open(TermsOfUseDialogComponent, {
      width: '650px',
    });
  }
}
