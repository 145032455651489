import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Injectable, isDevMode } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@zwiloo/user/auth';
import { Capacitor } from '@capacitor/core';
import { getCookie } from '@zwiloo/util/cookie';
import { AccountService } from '@zwiloo/user/account';

@Injectable()
export class AnonymousGuardService {
  constructor(
    private router: Router,
    private accountService: AccountService,
    private authService: AuthService
  ) {}

  canActivate(): Observable<boolean> {
    if (isDevMode()) {
      return this.authService.getSession().pipe(
        map((s) => (s.id === '' ? true : false)),
        tap((result) => {
          if (result === false) {
            this.router.navigateByUrl('/home');
          }
        })
      );
    } else {
      const session = getCookie('session');
      if (this.authService.hasSession() === false) {
        return of(true);
      } else {
        this.router.navigateByUrl('/home');
        return of(false);
      }
    }
  }

  canActivateChild(): Observable<boolean> {
    return this.canActivate();
  }
}
