import { Component, computed, HostBinding } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@zwiloo/user/auth';
import { Upload } from '@zwiloo/studio/domain';
import { UploadService } from '@zwiloo/studio/context/upload.service';
import { isMobile } from '@zwiloo/util/tokens';

@Component({
  selector: 'zwi-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent {
  selected = 'stream';
  firstName = 'Test';
  menuItems: { name: string; icon: string; path: string }[] = [
    { name: 'Home', icon: 'home', path: '/home' },
    { name: 'Search', icon: 'search', path: '/search' },
    { name: 'Church', icon: 'groups', path: '/local-church' },
    { name: 'Saved', icon: 'bookmark', path: '/saved' },
  ];
  @HostBinding('class.zwi-user-wrapper-mobile') isMobile: boolean = false;

  constructor(
    // TODO: Add playing to header
    // public playingSermon: PlayingSermon,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    this.isMobile = isMobile();
  }
}
