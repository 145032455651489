import {
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Component,
  Inject,
  signal,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DomSanitizer } from '@angular/platform-browser';

import { EmbedOptionsComponent } from '../../shared/picker/embed-options/embed-options.component';
import { EmbedPickerIframeComponent } from '../../shared/picker/embed-picker-iframe/embed-picker-iframe.component';
import { EmbedPreviewComponent } from '../../shared/picker/embed-preview/embed-preview.component';

interface DialogData {
  sermonID: number;
}

@Component({
  selector: 'zwi-embed-picker',
  standalone: true,
  imports: [
    FormsModule,
    MatButtonModule,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    EmbedOptionsComponent,
    EmbedPickerIframeComponent,
    EmbedPreviewComponent,
  ],
  templateUrl: './embed-picker.component.html',
  styleUrl: './embed-picker.component.scss',
})
export class EmbedPickerComponent {
  height = signal(352);
  width = signal('100%');

  get containerStyle() {
    return {
      height: this.height(),
      width: this.width(),
    };
  }

  constructor(
    public dialogRef: MatDialogRef<EmbedPickerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private cdRef: ChangeDetectorRef,
    @Inject(DomSanitizer) private domSanitizer: DomSanitizer
  ) {}

  close() {
    this.dialogRef.close();
  }

  setHeight(h: number) {
    this.height.set(h);
  }

  setWidth(w: string) {
    this.width.set(w);
  }
}
