<div class="zwi-reference-header">
  <div class="zwi-reference-controls">
    <button mat-button type="button" class="zwi-reference-period-button"
            (click)="currentTitleClicked()"
            cdkAriaLive="polite">
      {{ titleButtonText }}
    </button>
    <div class="zwi-reference-spacer"></div>
    <button mat-icon-button type="button" class="zwi-reference-previous-button"
            [disabled]="!previousEnabled()" (click)="previousClicked()">
    </button>
    <button mat-icon-button type="button" class="zwi-reference-next-button"
            [disabled]="!nextEnabled()" (click)="nextClicked()">
    </button>
  </div>
</div>
