import { Injectable, signal, WritableSignal } from '@angular/core';

import { Status } from '@zwiloo/sermon/context/player.service';
import { Sermon } from '@zwiloo/sermon/domain';

@Injectable()
export class SermonService {
  sermon: WritableSignal<Sermon> = signal(new Sermon());
  status: WritableSignal<Status> = signal('EMPTY');
  constructor() {}
}
