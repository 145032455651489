import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatChipsModule } from '@angular/material/chips';
import { MatTabsModule } from '@angular/material/tabs';

import { SearchComponent } from './search.component';
import { SharedModule } from '@zwiloo/frontend/shared/shared.module';
import { MinistryComponent } from './ministry/ministry.component';
import { SermonComponent } from './sermon/sermon.component';

@NgModule({
  declarations: [MinistryComponent, SearchComponent, SermonComponent],
  imports: [CommonModule, MatChipsModule, MatTabsModule, SharedModule],
})
export class SearchModule {}
