import { BehaviorSubject, Subject } from 'rxjs';

import { Injectable, signal, WritableSignal } from '@angular/core';

import { Sermon } from '@zwiloo/sermon/domain';

export type Status = 'EMPTY' | 'PLAYING' | 'PAUSED';

@Injectable({
  providedIn: 'root',
})
export class PlayerService {
  soundID: WritableSignal<number | null> = signal(null);
  currentTime = signal(0);
  duration = new Subject<number>();

  status = new BehaviorSubject<Status>('EMPTY');
  sermon = new Subject<Sermon | null>();

  constructor() {}

  // updateCurrentTime(t: number) {
  //   this.currentTime.next(t);
  // }

  updateDuration(t: number) {
    this.duration.next(t);
  }

  updateStatus(s: Status) {
    this.status.next(s);
  }

  setSermon(s: Sermon | null) {
    this.sermon.next(s);
  }
}
