import { InjectionToken } from '@angular/core';
import { Overlay, ScrollStrategy } from '@angular/cdk/overlay';

export interface ReferenceFormats {
  display: {
    referenceInput: any;
    bookChapterLabel: any;
  };
}

export const ZwiReferenceFormats: ReferenceFormats = {
  display: {
    referenceInput: {
      book: 'long',
      begin: {
        chapter: true,
        verse: true,
      },
      end: {
        chapter: true,
        verse: true,
      },
    },
    bookChapterLabel: {
      book: 'long',
      chapter: true,
    },
  },
};

export const ZWI_REFERENCE_FORMATS = new InjectionToken<ReferenceFormats>(
  'reference-formats'
);

export const ZWI_REFERENCE_FORMATS_PROVIDER = {
  provide: ZWI_REFERENCE_FORMATS,
  useValue: ZwiReferenceFormats,
};
