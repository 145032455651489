import { BehaviorSubject, Subject } from 'rxjs';

import { Injectable, signal } from '@angular/core';

import { CallState, LoadingState } from '@zwiloo/util/state';
import { Sermon } from '@zwiloo/sermon/domain';
import { UserDataService } from '@zwiloo/user/data-interface/user-data.service';

@Injectable()
export class GetFavoritesService {
  public favorites = new Subject<Sermon[]>();
  status = signal(LoadingState.INIT);

  constructor(public userData: UserDataService) {}

  loadFavorites() {
    this.status.set(LoadingState.LOADING);
    this.userData.favorites().subscribe((sermons) => {
      sermons.forEach((s) => (s.favorite = true));
      this.favorites.next(sermons);
      this.status.set(LoadingState.LOADED);
    });
    // this.ministryService.loadMinistryInformation(username).subscribe((m) => {
    //   this.ministry.next(m);
    //   if (m.id !== 0) {
    //     this.ministryService.loadSermons(m.id).subscribe((s) => {
    //       this.sermons.next(s);
    //       this.loadingStatus.next(LoadingState.LOADED);
    //     });
    //   } else {
    //     this.loadingStatus.next(LoadingState.LOADED);
    //   }
    // });
  }
}
