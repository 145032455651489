import { BehaviorSubject, Subject } from 'rxjs';

import { Injectable, signal } from '@angular/core';

import {
  Ministry,
  setLocalChurchSubscribed,
  setLocalChurchUnsubscribed,
  setSubscribed,
} from '@zwiloo/ministry/domain';
import { MinistryDataService } from '@zwiloo/ministry/data-interface/ministry-data.service';
import { UserDataService } from '@zwiloo/user/data-interface/user-data.service';
import { CallState, LoadingState } from '@zwiloo/util/state';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  public localChurch = signal<Ministry[]>([]);
  public subscriptions = signal<Ministry[]>([]);

  // public localChurchStatus = new BehaviorSubject<CallState>(LoadingState.INIT);
  localChurchStatus = signal(LoadingState.INIT);

  // public subscriptionStatus = new BehaviorSubject<CallState>(LoadingState.INIT);
  subscriptionStatus = signal(LoadingState.INIT);

  constructor(
    private ministryData: MinistryDataService,
    private userService: UserDataService
  ) {}

  loadSubscriptions() {
    this.loadLocalChurch();
    this.subscriptionStatus.set(LoadingState.LOADING);
    this.userService.loadSubscriptions().subscribe((ministries) => {
      ministries.forEach((m) => setSubscribed(m));
      this.subscriptions.set(ministries);
      this.subscriptionStatus.set(LoadingState.LOADED);
    });
  }

  private loadLocalChurch() {
    this.localChurchStatus.set(LoadingState.LOADING);
    this.userService.loadLocalChurch().subscribe((ministries) => {
      ministries.forEach((m) => setLocalChurchSubscribed(m));
      this.localChurch.set(ministries);
      this.localChurchStatus.set(LoadingState.LOADED);
    });
  }

  // Run subcribe and unsubscribe in the subscription context
  // because of the logic needed to update local church
  // to update state of search bar if there is a current local church
  subscribeLocal(ministry: Ministry) {
    setLocalChurchSubscribed(ministry);
    this.ministryData.subscribeLocal(ministry.id).subscribe({
      next: () => {
        this.loadLocalChurch();
      },
      error: () => {
        setLocalChurchUnsubscribed(ministry);
      },
    });
  }

  unsubscribeLocal(ministry: Ministry) {
    setLocalChurchUnsubscribed(ministry);
    this.ministryData.unsubscribeLocal(ministry.id).subscribe({
      next: () => {
        this.loadLocalChurch();
      },
      error: () => {
        setLocalChurchSubscribed(ministry);
      },
    });
  }
}
