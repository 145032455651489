import {
  Component,
  computed,
  Input,
  input,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'zwi-time-display',
  standalone: true,
  templateUrl: './time-display.component.html',
  styleUrls: ['./time-display.component.scss'],
})
export class TimeDisplayComponent {
  unit = input('s');
  currentTime = input(0);
  // time: string = '';

  time = computed(() => {
    if (this.currentTime) {
      // return this.currentTime();
      return this.format(this.currentTime());
    } else {
      return '0:00';
    }
  });

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (
      simpleChanges.currentTime &&
      !simpleChanges.currentTime.isFirstChange()
    ) {
      // this.time = this.format(simpleChanges.currentTime.currentValue);
    }
  }

  format(time: number): string {
    const t = this.unit() === 's' ? time : time / 1000;

    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);

    const formattedSeconds = seconds.toString().padStart(2, '0');

    return hours > 0
      ? `${hours}:${minutes}:${formattedSeconds}`
      : `${minutes}:${formattedSeconds}`;
  }

  private formatSeconds(seconds: number): string {
    return seconds.toString().length === 2
      ? seconds.toString()
      : '0' + seconds.toString();
  }
}
