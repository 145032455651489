<zwi-main-wrapper>
  <zwi-main-content>
    <div class="zwi-section">
      <zwi-back-headline (navigateBack)="navigateToSettings()">Account</zwi-back-headline>
      <div class="zwi-block-section">
        <zwi-body>To close you account and delete your data</zwi-body>
        <button mat-flat-button (click)="openDialog()">Close account</button>
      </div>
    </div>
    <zwi-account-close-dialog [open]="open" (closed)="close()"></zwi-account-close-dialog>
  </zwi-main-content>
</zwi-main-wrapper>
