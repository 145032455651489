import * as dayjs from 'dayjs';
import { Observable, Subscription } from 'rxjs';

import { Component } from '@angular/core';
import { MatChipListboxChange } from '@angular/material/chips';

import { StarChange } from '@zwiloo/frontend/shared/sermon/sermon.component';
import { SermonService } from '@zwiloo/sermon/sermon.service';
import { GetFavoritesService } from '@zwiloo/user/context/get-favorites.service';
import { Sermon } from '@zwiloo/sermon/domain';

@Component({
  selector: 'zwi-favorite-sermons',
  templateUrl: './sermons.component.html',
  styleUrls: ['./sermons.component.scss'],
  providers: [SermonService],
})
export class SermonsComponent {
  active = 'All';
  favorites: Sermon[] = [];
  subscription: Subscription;

  constructor(
    public getFavorites: GetFavoritesService,
    public sermon: SermonService
  ) {
    this.subscription = getFavorites.favorites.subscribe((s) => {
      this.favorites = s;
    });
  }

  // ngOnInit() {
  //   this.store.dispatch(SermonActions.removeAllSermons());
  //   this.store.dispatch(FavoriteActions.getSermons());
  // }

  favoriteChange(starChange: StarChange) {
    if (starChange.value === 'star') {
      if (starChange.source.sermon !== undefined) {
        this.sermon.favorite(starChange.source.sermon);
      }
    } else {
      if (starChange.source.sermon !== undefined) {
        this.sermon.unfavorite(starChange.source.sermon);
      }
    }
  }

  updateActive(c: MatChipListboxChange) {
    if (c.value) {
      this.active = c.value;
    }
  }
}
