import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'zwi-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss'],
})
export class WidgetComponent implements OnInit {
  @Input() top: boolean = false;

  constructor() {}

  ngOnInit() {}
}
