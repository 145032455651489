import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Ministry } from '@zwiloo/ministry/domain';

@Injectable({
  providedIn: 'root',
})
export class MinistryDataService {
  constructor(private http: HttpClient) {}

  ministryInformation(username: string) {
    return this.http.get<Ministry>(`/api/ministries?username=${username}`);
  }

  ministryInformationById(id: number) {
    return this.http.get<Ministry>(`/api/ministries?id=${id}`);
  }

  search(criteria: string) {
    return this.http.get<Ministry[]>(`/api/ministries?q=${criteria}`);
  }

  subscribe(ministryID: number): Observable<{ ministryId: number }> {
    return this.http.post<{ ministryId: number }>('/api/subscriptions', {
      ministryID,
    });
  }

  unsubscribe(ministryID: number): Observable<{ ministryId: number }> {
    return this.http.delete<{ ministryId: number }>(
      `/api/subscriptions/${ministryID}`
    );
  }

  subscribeLocal(ministryID: number): Observable<{ ministryId: number }> {
    return this.http.post<{ ministryId: number }>(
      '/api/local-church-subscriptions',
      { ministryID }
    );
  }

  unsubscribeLocal(ministryID: number): Observable<{ ministryId: number }> {
    return this.http.delete<{ ministryId: number }>(
      `/api/local-church-subscriptions/${ministryID}`
    );
  }
}
