import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ProfileImageDataService {
  constructor(private http: HttpClient) {}

  upload(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http
      .post<{ imageURL: string }>('/api/ministries/profile-image', formData)
      .pipe(map((r) => r.imageURL));
  }
}
