<zwi-widget>
  <div class="zwi-flex-center">
    <mat-form-field appearance="fill">
      <mat-label>Select series</mat-label>
      <mat-select [formControl]="series">
        <mat-option *ngFor="let s of seriesOptions" [value]="s.id">{{s.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="zwi-spacer"></div>
    <button *ngIf="seriesSermons.length > 0" (click)="save()" mat-raised-button color="primary">Save</button>
  </div>
  <ng-container *ngIf="seriesSermons.length > 0; else empty">
    <div>Drag to edit order of sermons</div>
    <zwi-draggable-sermon-list [sermons]="seriesSermons" (sortChange)="setSortedSermons($event)"></zwi-draggable-sermon-list>
  </ng-container>
  <ng-template #empty>
    <div *ngIf="series.value; else select">No sermons found in this series.</div>
    <ng-template #select>
      <div>Select a series</div>
    </ng-template>
  </ng-template>
</zwi-widget>
