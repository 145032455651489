import { Injectable } from '@angular/core';
import { UntypedFormControl, ValidationErrors } from '@angular/forms';

import { map } from 'rxjs/operators';

import { UserDataService } from '../data-interface/user-data.service';
import { AccountService } from '@zwiloo/user/account';

@Injectable()
export class UserValidatorService {
  constructor(
    private accountService: AccountService,
    private userData: UserDataService
  ) {}

  public currentEmail = (
    control: UntypedFormControl
  ): ValidationErrors | null => {
    const email = control.value;
    const sessionEmail = this.accountService.getAccountEmail();

    return email === sessionEmail ? { currentEmail: true } : null;
  };

  public emailTaken = (control: UntypedFormControl) => {
    const email = control.value;

    return this.userData
      .getUser(email)
      .pipe(map((m) => (m.id !== 0 ? { emailTaken: true } : null)));
  };
}
