import { Component, EventEmitter, Input, Output } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { SignInDialogContentComponent } from './sign-in-dialog-content/sign-in-dialog-content.component';

@Component({
  selector: 'zwi-sign-in-dialog',
  templateUrl: './sign-in-dialog.component.html',
  styleUrls: ['./sign-in-dialog.component.scss'],
})
export class SignInDialogComponent {
  @Output() closed = new EventEmitter();
  @Input()
  set open(o: boolean) {
    if (o) {
      const dialogRef = this.dialog.open(SignInDialogContentComponent, {
        width: '650px',
      });

      dialogRef.afterClosed().subscribe((result) => {
        this.closed.emit();
      });

      dialogRef.componentInstance.modal_principal_parent.subscribe(() => {
        dialogRef.close();
      });
    }
  }

  constructor(public dialog: MatDialog) {}
}
