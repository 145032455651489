import { Injectable } from '@angular/core';

import { UserDataService } from '../data-interface/user-data.service';
import {
  AccountDataService,
  VerifyResponse,
} from '../data-interface/account-data.service';
import { CallState, LoadingState } from '@zwiloo/util/state';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResetPasswordService {
  public resetResult = new Subject<VerifyResponse>();
  public resetStatus = new BehaviorSubject<CallState>(LoadingState.INIT);
  public verifyResult = new Subject<VerifyResponse>();
  public verifyStatus = new BehaviorSubject<CallState>(LoadingState.INIT);

  constructor(
    private accountData: AccountDataService,
    public userData: UserDataService
  ) {}

  getUser(email: string) {
    return this.userData.getUser(email);
  }

  resetPassword(userID: number, token: string, password: string) {
    this.resetStatus.next(LoadingState.LOADING);
    return this.accountData
      .resetPassword(userID, token, password)
      .subscribe((s) => {
        this.resetResult.next(s);
        this.resetStatus.next(LoadingState.LOADED);
      });
  }

  sendResetToken(userID: number) {
    return this.accountData.sendResetToken(userID).subscribe();
  }

  verifyResetToken(userID: number, token: string) {
    this.verifyStatus.next(LoadingState.LOADING);
    this.accountData.verifyResetToken(userID, token).subscribe((s) => {
      this.verifyResult.next(s);
      this.verifyStatus.next(LoadingState.LOADED);
    });
  }
}
