import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { SignInResult } from '@zwiloo/account/domain';
import { UserDataService } from '@zwiloo/user/data-interface/user-data.service';
import { AccountService } from '@zwiloo/user/account';
import { LoadingState } from '@zwiloo/util/state';

@Injectable()
export class AuthService {
  sessionStatus = signal(LoadingState.INIT);
  hasSession = signal(false);
  constructor(
    private accountService: AccountService,
    private auth: AuthDataService,
    private user: UserDataService
  ) {}

  getSession() {
    return this.user.getSession();
  }

  setHasSession() {
    this.sessionStatus.set(LoadingState.LOADING);
    return this.user.getSession().subscribe((s) => {
      this.sessionStatus.set(LoadingState.LOADED);
      if (s.id === '') {
        this.hasSession.set(false);
      } else {
        this.hasSession.set(true);
      }
    });
  }

  signin(loginInfo: LoginInfo): Observable<SignInResult> {
    return this.auth.signin(loginInfo).pipe(
      tap((r) => {
        if (r.valid) {
          // console.log('signin', r.id);
          // this.setSessionCookie(r.id);
          this.accountService.cacheAccount(r.email);
        }
      })
    );
  }

  signOut() {
    this.accountService.removeAccount();
    this.accountService.removeMinistry();
    this.hasSession.set(false);
    this.auth.signout().subscribe();

    // console.log('signout', sessionID);
    // if (sessionID !== null) {
    //   if (isDevMode() === false) {
    //     removeCookie('session', 'zwiloo.com');
    //   } else {
    //     removeCookie('session');
    //   }
    // } else {
    //   return of(true);
    // }
  }

  // setSessionCookie(sessionID: string) {
  //   setCapacitorCookie('session', sessionID, 'zwiloo.com').then(() => {
  //     return;
  //   });
  //   if (Capacitor.isNativePlatform()) {
  //     document.cookie = `session=${sessionID};domain=zwiloo.com;`;
  //   } else {
  //     const maxAge = 60 * 60 * 24 * 365;
  //     let cookie = `session=${sessionID};max-age=${maxAge};`;
  //     if (isDevMode() === false) {
  //       cookie = `${cookie}domain=zwiloo.com`;
  //     }
  //   }
  //
  //   const maxAge = 60 * 60 * 24 * 365;
  //   let cookie = `session=${sessionID};domain=zwiloo.com;`;
  //   let cookie = `session=${sessionID};max-age=${maxAge};`;
  //   if (isDevMode() === false) {
  //     cookie = `${cookie}domain=zwiloo.com`;
  //   }
  //   document.cookie = cookie;
  // }
}

@Injectable()
export class AuthDataService {
  constructor(private http: HttpClient) {}
  signin(loginInfo: LoginInfo): Observable<SignInResult> {
    return this.http.post<SignInResult>(`/api/session`, loginInfo);
  }

  signout() {
    return this.http.delete(`/api/session`);
  }
}

export class LoginInfo {
  constructor(public email = '', public password = '') {}
}

// export class Session {
//   constructor(public userID: string, public expireDate: string) {}
// }

// function validateSession(s: Session): SessionValidation {
//   const expireDate = s.expireDate;
//   if (dayjs(expireDate).isAfter(dayjs())) {
//     return new SessionValidation(true, false, s);
//   } else {
//     return new SessionValidation(false, true, s);
//   }
// }
