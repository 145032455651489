import { Capacitor } from '@capacitor/core';

import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'zwi-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent {
  isNativePlatform = Capacitor.isNativePlatform();

  constructor(private router: Router) {}

  ngOnInit(): void {}

  navigateToIndex() {
    this.router.navigateByUrl('/index');
  }
}
