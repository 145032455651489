export const enum LoadingState {
  INIT,
  LOADING,
  LOADED,
}

export class ErrorState {
  constructor(public errorMessage: string) {}
}

export type CallState = LoadingState | ErrorState;

export function getError(callState: CallState): string | null {
  if ((callState as ErrorState).errorMessage !== undefined) {
    return (callState as ErrorState).errorMessage;
  }
  return null;
}
