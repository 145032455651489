<ng-container *ngIf="status === 2; else f">
  <zwi-back-headline (navigateBack)="back()">Account</zwi-back-headline>
  <zwi-email-sent-message [email]="form.get('email')?.getRawValue()"></zwi-email-sent-message>
</ng-container>
<ng-template #f>
  <form [formGroup]="form" (ngSubmit)="submit()">
    <mat-form-field appearance="fill" class="zwi-vertical-form__md-field" [ngClass]="{'zwi-form-field--full': isMobile()}">
      <mat-label>Email address</mat-label>
      <input matInput formControlName="email">
      <mat-error>
        <ng-container *ngIf="form.get('email')?.errors?.required">
          {{ formError.getErrorMessage('email', 'required') }}</ng-container>
        <ng-container *ngIf="form.get('email')?.errors?.email">
          {{ formError.getErrorMessage('email', 'email') }}</ng-container>
        <ng-container *ngIf="form.get('email')?.errors?.currentEmail">
          {{ formError.getErrorMessage('email', 'currentEmail') }}</ng-container>
        <ng-container *ngIf="form.get('email')?.errors?.emailTaken">
          {{ formError.getErrorMessage('email', 'emailTaken') }}</ng-container>
      </mat-error>
    </mat-form-field>
    <zwi-widget>
      <button mat-flat-button color="primary">Save changes</button>
    </zwi-widget>
  </form>
</ng-template>
