<div class="zwi-section">
  <zwi-headline>Profile Image</zwi-headline>
  <zwi-profile-image-form></zwi-profile-image-form>
</div>
<!--    <input (click)="onSubmit()" [disabled]='!isUploaded || sending || success'-->
<!--           class="mdl-button mdl-js-button mdl-button&#45;&#45;primary" type='submit' value='Save'/>-->
<!--  <div *ngIf="submitted">-->
<!--    <h3 class="md-display-1">Your profile image has been changed!</h3>-->
<!--  </div>-->
<!--  <button class="mdl-button mdl-js-button mdl-button&#45;&#45;raised mdl-button&#45;&#45;colored" style="margin-left: 16px;"-->
<!--          (click)="upload($event)">Upload Image-->
<!--  </button>-->
