<h2 mat-dialog-title>
  <div class="zwi-flex-center">
    <div>Embed sermon</div>
    <div class="zwi-spacer"></div>
    <mat-icon mat-dialog-close class="zwi-embed-close">close_small</mat-icon>
  </div>
</h2>
<mat-dialog-content>
  <div class="zwi-section">
    <div class="zwi-block-section">
      <zwi-embed-options [height]="height()" [heightOptions]="[352, 152]" (heightChange)="setHeight($event)" (widthChange)="setWidth($event)"></zwi-embed-options>
      <zwi-embed-preview category="sermon" [height]="height()" [id]="data.sermonID" [width]="width()" class="zwi-preview-container"></zwi-embed-preview>
      <zwi-embed-picker-iframe category="sermon" [height]="height()" [id]="data.sermonID" [width]="width()"></zwi-embed-picker-iframe>
    </div>
  </div>
</mat-dialog-content>
