<div class="zwi-section">
  <div class="zwi-flex-center" >
    <button (click)="navigateToUpload()" mat-icon-button>
      <mat-icon>arrow_back</mat-icon>
    </button>
    <zwi-headline>Sermon info</zwi-headline>
  </div>
  <div class="zwi-block-section">
    <div class="zwi-flex-start">
      <p class="md-body-2 title">Upload status:</p>
      <div class="zwi-spacer"></div>
      <button mat-raised-button color="primary" *ngIf="showSave()"
              (click)="save()"
              [disabled]="!isFormValid() || isSaved()">Save</button>
      <button mat-raised-button color="primary" *ngIf="showSaveChanges()"
              (click)="save()"
              [disabled]="!isFormValid()">Save changes
      </button>
    </div>
    <div class="zwi-flex-start">
      <div class="mat-subtitle-2">{{ getFileUploadStatusMessage() }}</div>
      <div class="zwi-spacer"></div>
      <div class="md-body-1">{{ getSaveStatusMessage() }}</div>
    </div>
  </div>
  <zwi-upload-form [series]="series" [speakers]="speakers" [sermonInfo]="upload?.sermonInfo" (sermonInfoChange)="updateSermonInfo($event)"></zwi-upload-form>
</div>


<!--<ng-container *ngFor="let upload of uploads | async">-->
<!--  <zwi-upload-form [hidden]="(id$ | async) !== upload.id" [series]="series" [speakers]="speakers" [upload]="upload"></zwi-upload-form>-->
<!--</ng-container>-->
