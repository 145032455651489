import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PasswordMatchValidator } from './password-match-validator.service';
import { UsernameValidator } from './username-validator.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [PasswordMatchValidator, UsernameValidator],
})
export class ValidatorsModule {}
