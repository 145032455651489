import { Component, Input } from '@angular/core';

import { Sermon } from '@zwiloo/sermon/domain';
import { ReferenceRange } from '@zwiloo/verse/domain/reference';
import { ReferenceFormatter } from '@zwiloo/verse/domain/reference-formatter';
import { ZwiReferenceFormats } from '@zwiloo/verse/domain/reference-formats';

@Component({
  selector: 'zwi-embed-metadata',
  standalone: true,
  imports: [],
  templateUrl: './embed-metadata.component.html',
  styleUrl: './embed-metadata.component.scss',
})
export class EmbedMetadataComponent {
  @Input() isMinistry = false;
  @Input() title = '';
  @Input() sermon: Sermon = new Sermon();

  constructor() {}

  getReferences(referenceRanges?: ReferenceRange[]) {
    if (referenceRanges !== undefined) {
      const formatter = new ReferenceFormatter();
      return referenceRanges
        .map((range) =>
          formatter.formatRange(
            range,
            ZwiReferenceFormats.display.referenceInput
          )
        )
        .join(', ');
    } else {
      return '';
    }
  }

  hasReferences() {
    return this.sermon.references && this.sermon.references.length > 0;
  }
}
