import { Injectable } from '@angular/core';

import { PasswordDataService } from '@zwiloo/settings/data-interface/password-data.service';
import { Subject } from 'rxjs';
import { CallState, LoadingState } from '@zwiloo/util/state';

@Injectable({
  providedIn: 'root',
})
export class PasswordService {
  status = new Subject<CallState>();
  constructor(private passwordData: PasswordDataService) {}

  updatePassword(p: string) {
    this.status.next(LoadingState.LOADING);
    this.passwordData.updatePassword(p).subscribe(() => {
      this.status.next(LoadingState.LOADED);
    });
  }
}
