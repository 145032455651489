import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import { ReferenceValidator } from './services/reference-validator.service';
import {
  ReferencePickerContent,
  ReferencePickerComponent,
  ZWI_REFERENCE_PICKER_SCROLL_STRATEGY_FACTORY_PROVIDER,
} from './reference-picker/reference-picker.component';
import { ReferencePickerInputDirective } from './reference-picker-input/reference-picker-input.directive';
import { ReferencePickerToggleComponent } from './reference-picker-toggle/reference-picker-toggle.component';
import {
  ReferencePortalComponent,
  ReferenceFooter,
  ReferenceHeader,
} from './reference-portal/reference-portal.component';
import { PortalModule } from '@angular/cdk/portal';
import { BibleViewComponent } from './bible-view/bible-view.component';
import { BookViewComponent } from './book-view/book-view.component';
import { ChapterViewComponent } from './chapter-view/chapter-view.component';
import { ReferenceBodyComponent } from './reference-body/reference-body.component';
import { ReferenceAdapter } from '@zwiloo/verse/domain/reference-adapter';
import { ReferenceRangeAdapter } from '@zwiloo/verse/domain/reference-range-adapter';
import { ZWI_REFERENCE_FORMATS_PROVIDER } from '@zwiloo/verse/domain/reference-formats';

@NgModule({
  declarations: [
    ReferenceFooter,
    ReferenceHeader,
    ReferencePickerComponent,
    ReferencePickerInputDirective,
    ReferencePickerToggleComponent,
    ReferencePickerContent,
    ReferencePortalComponent,
    ChapterViewComponent,
    BibleViewComponent,
    BookViewComponent,
    ReferenceBodyComponent,
  ],
  imports: [CommonModule, MatButtonModule, MatIconModule, PortalModule],
  exports: [
    ReferenceFooter,
    ReferenceHeader,
    ReferencePickerComponent,
    ReferencePickerInputDirective,
    ReferencePickerToggleComponent,
    ReferencePickerContent,
    ReferencePortalComponent,
    ChapterViewComponent,
    BibleViewComponent,
    BookViewComponent,
    ReferenceBodyComponent,
  ],
  providers: [
    ReferenceAdapter,
    ReferenceRangeAdapter,
    ReferenceValidator,
    ZWI_REFERENCE_FORMATS_PROVIDER,
    ZWI_REFERENCE_PICKER_SCROLL_STRATEGY_FACTORY_PROVIDER,
  ],
})
export class ReferencePickerModule {}
