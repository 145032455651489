import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  input,
  OnChanges,
  SecurityContext,
  SimpleChanges,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'zwi-embed-preview',
  standalone: true,
  imports: [],
  templateUrl: './embed-preview.component.html',
  styleUrl: './embed-preview.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmbedPreviewComponent implements OnChanges {
  base = `<iframe style="border-radius: 12px" src="${window.location.origin}/embed`;
  category = input('');
  height = input(0);
  width = input('');
  id = input(0);

  get sanitizedIframeURL() {
    let width = this.domSanitizer.sanitize(SecurityContext.HTML, this.width());
    if (width !== null && width.includes('px')) {
      width = width.slice(0, -2);
    }
    return this.domSanitizer.bypassSecurityTrustHtml(
      `${
        this.base
      }/${this.category()}/${this.id()}" frameborder="0" height="${this.height()}" width="${width}"></iframe>`
    );
  }
  constructor(
    @Inject(DomSanitizer) private domSanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.width || changes.height) {
      this.cdRef.markForCheck();
    }
  }
}
