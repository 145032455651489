import { Component, OnInit } from '@angular/core';
import { AuthService } from '@zwiloo/user/auth';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'zwi-studio',
  templateUrl: './studio.component.html',
  styleUrls: ['./studio.component.scss'],
})
export class StudioComponent implements OnInit {
  selected = 'stream';
  firstName = 'Test';
  menuItems: { name: string; icon: string; path: string }[] = [
    { name: 'Dashboard', icon: 'dashboard', path: '/studio/dashboard' },
    { name: 'Sermons', icon: 'play_circle_outline', path: '/studio/sermons' },
    { name: 'Speakers', icon: 'person', path: '/studio/speakers' },
    { name: 'Series', icon: 'list_alt', path: '/studio/series' },
    { name: 'Upload', icon: 'file_upload', path: '/studio/upload' },
  ];

  constructor(
    // TODO: Add playing to header
    // public playingSermon: PlayingSermon,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  changeHref(url: string) {
    window.location.href = url;
  }

  isSelected(name: string) {
    return this.selected === name;
  }

  select(link: string) {
    this.router.navigateByUrl(link);
  }

  signOut() {
    this.authService.signOut();
  }
}
