import { merge, Subject, Subscription } from 'rxjs';
import { take, filter } from 'rxjs/operators';

import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Inject,
  InjectionToken,
  Input,
  OnInit,
  Optional,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';

import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ESCAPE, UP_ARROW } from '@angular/cdk/keycodes';
import {
  Overlay,
  OverlayConfig,
  OverlayRef,
  PositionStrategy,
  ScrollStrategy,
} from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { matDatepickerAnimations } from '@angular/material/datepicker';

import { ReferencePortalComponent } from '../reference-portal/reference-portal.component';
import { ReferencePickerInputDirective } from '../reference-picker-input/reference-picker-input.directive';
import { Reference, ReferenceRange } from '@zwiloo/verse/domain/reference';
import { ReferenceAdapter } from '@zwiloo/verse/domain/reference-adapter';

export const ZWI_REFERENCE_PICKER_SCROLL_STRATEGY = new InjectionToken<
  () => ScrollStrategy
>('zwi-reference-picker-scroll-strategy');

export function ZWI_REFERENCE_PICKER_SCROLL_STRATEGY_FACTORY(
  overlay: Overlay
): () => ScrollStrategy {
  return () => overlay.scrollStrategies.reposition();
}

export const ZWI_REFERENCE_PICKER_SCROLL_STRATEGY_FACTORY_PROVIDER = {
  provide: ZWI_REFERENCE_PICKER_SCROLL_STRATEGY,
  providedIn: 'root',
  deps: [Overlay],
  useFactory: ZWI_REFERENCE_PICKER_SCROLL_STRATEGY_FACTORY,
};

// noinspection TypeScriptUnresolvedVariable
@Component({
  selector: 'zwi-reference-picker-content',
  templateUrl: '../reference-picker-content/reference-picker-content.html',
  styleUrls: ['../reference-picker-content/reference-picker-content.scss'],
  host: {
    class: 'zwi-reference-picker-content',
    '[class.zwi-reference-picker-content-touch]': 'referencePicker.touchUI',
  },
  animations: [
    matDatepickerAnimations.transformPanel,
    matDatepickerAnimations.fadeInCalendar,
  ],
  exportAs: 'zwiReferencePickerContent',
  encapsulation: ViewEncapsulation.None,
})
export class ReferencePickerContent {
  @ViewChild(ReferencePortalComponent)
  _reference!: ReferencePortalComponent;

  referencePicker!: ReferencePickerComponent;

  _isAbove: boolean = false;

  constructor(elementRef: ElementRef) {}

  ngAfterViewInit() {
    this._reference.focusActiveCell();
  }
}

@Component({
  selector: 'zwi-reference-picker',
  template: '',
})
export class ReferencePickerComponent {
  // The reference to open the picker to initially.
  @Input()
  get startAt(): Reference | null {
    const inputValue =
      this._referencePickerInput &&
      this._referencePickerInput.value &&
      this._referencePickerInput.value.begin
        ? this._referencePickerInput.value.begin
        : null;
    return this._startAt || inputValue;
    // return this._startAt || this._selected && this._selected.start ? this._selected.start : null;
  }
  set startAt(value: Reference | null) {
    this._startAt = value;
  }
  private _startAt: Reference | null = null;

  @Input()
  get startView(): 'bible' | 'book' | 'chapter' {
    if (this._startView) {
      return this._startView;
    } else {
      let startView: 'bible' | 'book' | 'chapter' = 'bible';
      if (this._selected && this._selected.begin) {
        if (this._selected.begin.chapter) {
          startView = 'chapter';
        } else {
          startView = 'book';
        }
      }
      return startView;
    }
  }
  set startView(value: 'bible' | 'book' | 'chapter') {
    this._startView = value;
  }
  private _startView: 'bible' | 'book' | 'chapter' | null = null;

  // @Input() startView: 'bible' | 'book' | 'chapter' = 'bible';

  @Input()
  get touchUi(): boolean {
    return this._touchUi;
  }
  set touchUi(value: boolean) {
    this._touchUi = coerceBooleanProperty(value);
  }
  private _touchUi = false;

  @Input()
  get disabled(): boolean {
    return this._disabled === undefined && this._referencePickerInput
      ? this._referencePickerInput.disabled
      : !!this._disabled;
  }
  set disabled(value: boolean) {
    const newValue = coerceBooleanProperty(value);

    if (newValue !== this._disabled) {
      this._disabled = newValue;
      this._disabledChange.next(newValue);
    }
  }
  private _disabled: boolean = false;

  @Output() readonly bookSelected: EventEmitter<Reference> = new EventEmitter();
  @Output() readonly chapterSelected: EventEmitter<Reference> =
    new EventEmitter();

  @Output('opened') openedStream: EventEmitter<void> = new EventEmitter<void>();
  @Output('closed') closedStream: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  get opened(): boolean {
    return this._opened;
  }
  set opened(value: boolean) {
    value ? this.open() : this.close();
  }
  private _opened = false;

  private _referencePortal!: ComponentPortal<ReferencePickerContent>;

  private _inputSubscription = Subscription.EMPTY;

  _referencePickerInput!: ReferencePickerInputDirective;

  readonly _disabledChange = new Subject<boolean>();

  get selected(): ReferenceRange | null {
    return this._selected;
  }
  set selected(value: ReferenceRange | null) {
    this._selected = value;
  }
  private _selected: ReferenceRange | null = null;

  _popupRef!: OverlayRef;

  private _popupComponentRef: ComponentRef<ReferencePickerContent> | null =
    null;

  private _focusedElementBeforeOpen: HTMLElement | null = null;

  private _dialogRef: MatDialogRef<ReferencePickerContent> | null = null;

  readonly _selectedChanged = new Subject<ReferenceRange>();

  constructor(
    private _dialog: MatDialog,
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef,
    @Optional() private _referenceAdapter: ReferenceAdapter,
    @Inject(ZWI_REFERENCE_PICKER_SCROLL_STRATEGY) private _scrollStrategy: any,
    @Optional() @Inject(DOCUMENT) private _document: any
  ) {
    let t = 1;
  }

  ngOnDestroy() {
    this._inputSubscription.unsubscribe();

    if (this._popupRef) {
      this._popupRef.dispose();
      this._popupComponentRef = null;
    }
  }

  // Need to understand if end not being sent null changes the downstream logic
  _select(reference: Reference): void {
    const oldValue = this._selected;
    let selected: ReferenceRange;
    if (oldValue !== null && this._isEndReference(reference)) {
      selected = {
        ...oldValue,
        end: reference,
      };
    } else {
      selected = {
        begin: reference,
        end: null,
      };
    }
    // if (this._areSameReference(selected.begin, selected.end)) {
    //   selected = { ...selected, end: null };
    //   selected = { ...selected, end: null };
    // }
    this._selected = selected;
    // if (oldValue !== this._selected) {
    //   const s = this.selected === null ? undefined : this.selected;
    //   this._selectedChanged.next(s);
    // }
    if (selected.end !== null) {
      this.close();
    }
  }

  _isEndReference(reference: Reference) {
    return (
      this.selected !== null &&
      this.selected.begin.verse !== 0 &&
      this.selected.end === null &&
      this._referenceAdapter.getValue(reference) >=
        this._referenceAdapter.getValue(this.selected.begin)
    );
  }
  // return (
  //   this.selected &&
  //   this.selected.begin &&
  //   this.selected.begin.verse &&
  //   !this.selected.end &&
  //   this._referenceAdapter.getValue(reference) >=
  //     this._referenceAdapter.getValue(this.selected.begin)
  // );

  _areSameReference(r1: Reference, r2: Reference): boolean {
    return (
      r1 &&
      r2 &&
      this._referenceAdapter.getValue(r1) ===
        this._referenceAdapter.getValue(r2)
    );
  }

  _selectBook(book: Reference): void {
    this.bookSelected.emit(book);
  }

  _selectChapter(chapter: Reference): void {
    this.chapterSelected.emit(chapter);
  }

  _setNullEnd() {
    if (this.selected !== null && this.selected.end === null) {
      this.selected.end = this.selected.begin;
      if (this._selectedChanged !== undefined) {
        this._selectedChanged.next(this.selected);
      }
    }
  }

  _registerInput(input: ReferencePickerInputDirective): void {
    this._referencePickerInput = input;
    // TODO: Have this work with ReferenceRange
    this._inputSubscription = this._referencePickerInput._valueChange.subscribe(
      (value: ReferenceRange | null | '') => {
        if (value !== null && value !== '') {
          this._selected = value;
        }
      }
    );
  }

  open(): void {
    if (this._opened || this.disabled) {
      return;
    }
    if (!this._referencePickerInput) {
      throw Error(
        'Attempted to open an ZwiReferencePicker with no associated input.'
      );
    }
    if (this._document) {
      this._focusedElementBeforeOpen = this._document.activeElement;
    }
    this._touchUi ? this._openAsDialog() : this._openAsPopup();
    this._opened = true;
    this.openedStream.emit();
  }

  close(): void {
    if (!this._opened) {
      return;
    }
    if (this._popupRef && this._popupRef.hasAttached()) {
      this._popupRef.detach();
    }
    if (this._dialogRef) {
      this._dialogRef.close();
      this._dialogRef = null;
    }
    if (this._referencePortal && this._referencePortal.isAttached) {
      this._referencePortal.detach();
    }
    this._setNullEnd();
    // TODO: Don't emit if the value hasn't changed or a Canceled button is clicked
    // if (oldValue !== this._selected) {
    //
    // }
    const s = this.selected === null ? undefined : this.selected;
    if (this._selectedChanged !== undefined && s !== undefined) {
      this._selectedChanged.next(s);
    }

    const completeClose = () => {
      if (this._opened) {
        this._opened = false;
        this.closedStream.emit();
        this._focusedElementBeforeOpen = null;
      }
    };

    if (
      this._focusedElementBeforeOpen &&
      typeof this._focusedElementBeforeOpen.focus === 'function'
    ) {
      this._focusedElementBeforeOpen.focus();
      setTimeout(completeClose);
    } else {
      completeClose();
    }
  }

  private _openAsDialog(): void {
    this._dialogRef = this._dialog.open<ReferencePickerContent>(
      ReferencePickerContent,
      {
        direction: 'ltr',
        viewContainerRef: this._viewContainerRef,
        panelClass: 'zwi-reference-picker-dialog',
      }
    );

    this._dialogRef.afterClosed().subscribe(() => this.close());
    this._dialogRef.componentInstance.referencePicker = this;
  }

  private _openAsPopup(): void {
    if (!this._referencePortal) {
      this._referencePortal = new ComponentPortal<ReferencePickerContent>(
        ReferencePickerContent,
        this._viewContainerRef
      );
    }

    if (!this._popupRef) {
      this._createPopup();
    }

    if (!this._popupRef.hasAttached()) {
      this._popupComponentRef = this._popupRef.attach(this._referencePortal);
      this._popupComponentRef.instance.referencePicker = this;
    }
  }

  private _createPopup(): void {
    const overlayConfig = new OverlayConfig({
      positionStrategy: this._createPopupPositionStrategy(),
      hasBackdrop: true,
      backdropClass: 'mat-overlay-transparent-backdrop',
      direction: 'ltr',
      scrollStrategy: this._scrollStrategy(),
      panelClass: 'zwi-reference-picker-popup',
    });

    this._popupRef = this._overlay.create(overlayConfig);

    merge(
      this._popupRef.backdropClick(),
      this._popupRef.detachments(),
      this._popupRef.keydownEvents().pipe(
        filter((event) => {
          return (
            event.keyCode === ESCAPE ||
            (this._referencePickerInput &&
              event.altKey &&
              event.keyCode === UP_ARROW)
          );
        })
      )
    ).subscribe(() => this.close());
  }

  private _createPopupPositionStrategy(): PositionStrategy {
    return this._overlay
      .position()
      .flexibleConnectedTo(
        this._referencePickerInput.getConnectedOverlayOrigin()
      )
      .withTransformOriginOn('.zwi-reference-picker-content')
      .withFlexibleDimensions(false)
      .withViewportMargin(8)
      .withPush(false)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        },
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'bottom',
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
        {
          originX: 'end',
          originY: 'top',
          overlayX: 'end',
          overlayY: 'bottom',
        },
      ]);
  }
}
