import { Injectable, signal, WritableSignal } from '@angular/core';

import { CallState, LoadingState } from '@zwiloo/util/state';
import {
  AccountDataService,
  VerifyResponse,
} from '../data-interface/account-data.service';
import { AccountService } from '@zwiloo/user/account';
import { AuthService } from '@zwiloo/user/auth';

@Injectable()
export class CancelAccountService {
  result: WritableSignal<VerifyResponse> = signal({ status: '' });
  status: WritableSignal<CallState> = signal(LoadingState.INIT);

  constructor(
    public account: AccountService,
    public accountData: AccountDataService,
    public authService: AuthService
  ) {}

  close(userID: number, token: string) {
    this.status.set(LoadingState.LOADING);
    this.accountData.deleteAccount(userID, token).subscribe((s) => {
      this.result.set(s);
      this.status.set(LoadingState.LOADED);
      this.authService.signOut();
    });
  }

  closeAccountToken() {
    const email = this.account.getAccountEmail();
    if (email !== null) {
      this.accountData.sendCloseAccountToken(email).subscribe();
    }
  }
}
