import { Subscription } from 'rxjs';

import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { VerifyResponse } from '@zwiloo/user';
import { CallState, LoadingState } from '@zwiloo/util/state';
import { AccountService } from '@zwiloo/user/account';
import { VerifyEmailService } from '@zwiloo/user/context/verify-email.service';
import { CancelAccountService } from '@zwiloo/user/context/cancel-account.service';

@Component({
  selector: 'zwi-cancel',
  templateUrl: './cancel.component.html',
  styleUrls: ['./cancel.component.scss'],
})
export class CancelComponent {
  result: VerifyResponse = { status: '' };
  status: CallState = LoadingState.INIT;
  statusSubscription: Subscription;
  successSubscription: Subscription;

  constructor(
    private accountService: AccountService,
    public cancelService: CancelAccountService,
    public verify: VerifyEmailService,
    private route: ActivatedRoute
  ) {
    this.successSubscription = verify.result.subscribe((r) => {
      this.result = r;
    });
    this.statusSubscription = verify.status.subscribe((s) => {
      this.status = s;
    });
  }
  ngOnInit(): void {
    const userID = this.route.snapshot.queryParamMap.get('u');
    const token = this.route.snapshot.queryParamMap.get('t');
    if (userID !== null && token !== null) {
      this.cancelService.close(Number(userID), token);
    }
  }
}
