<ng-container *ngIf="status === 2; else f">
  <zwi-section-headline>You converted your account to a ministry account for {{ ministry.name }}!</zwi-section-headline>
  <zwi-widget>
    <button mat-flat-button color="primary" routerLink="/studio/upload">Add sermons</button>
  </zwi-widget>
</ng-container>
<ng-template #f>
  <zwi-signup-ministry buttonLabel="Convert account" (updateMinistry)="updateMinistry($event)"></zwi-signup-ministry>
</ng-template>



