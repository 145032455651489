<zwi-content>
  <div class="zwi-page">
    <div class="zwi-header">
      <mat-toolbar *ngIf="showToolbar(); else topSpacer" class="zwi-toolbar">
        <a routerLink="/home" style="line-height: 1;">
          <img src="assets/img/landing-logo.png" alt="Zwiloo" height="35px" width="102px"/>
        </a>
        <span class="zwi-spacer"></span>
        <div class="zwi-flex-center zwi-section--horizontal">
          <a mat-flat-button *ngIf="ministry.id !== 0" (click)="navigateToUpload()" color="primary">Upload</a>
          <ng-container *ngIf="authService.sessionStatus() === 2">
            <div *ngIf="authService.hasSession(); else signIn" style="flex: none;">
              <button mat-button [matMenuTriggerFor]="accountMenu">
                {{ firstName }}
              </button>
              <mat-menu x-position="before" #accountMenu="matMenu">
                <!--                        <a *ngIf="isMinistry()" mat-menu-item routerLink="/studio/dashboard/dashboard">-->
                <a *ngIf="ministry.id !== 0" mat-menu-item routerLink="/studio/dashboard">
                  <span>Sermon Studio</span>
                </a>
                <!--                        <a *ngIf="!isMinistry()" mat-menu-item routerLink="/ministry-convert">-->
                <a *ngIf="ministry.id === 0" mat-menu-item routerLink="/ministry-convert">
                  <span>Convert Account</span>
                </a>
                <a mat-menu-item routerLink="/following">
                  <span>Following</span>
                </a>
                <a mat-menu-item routerLink="/settings/email">
                  <span>Settings</span>
                </a>
                <a mat-menu-item (click)="signOut()">
                  <span>Sign out</span>
                </a>
              </mat-menu>
            </div>
            <ng-template #signIn>
              <button mat-stroked-button color="primary" (click)="openSignInDialog()" action>Sign in</button>
              <a mat-flat-button [routerLink]="['/sign-up']" color="primary">Sign up</a>
            </ng-template>
          </ng-container>

          <zwi-sign-in-dialog [open]="open" (closed)="closeSignInDialog()"></zwi-sign-in-dialog>
        </div>
      </mat-toolbar>
      <ng-container *ngIf="showVerifyBanner()">
        <ng-container *ngIf="bannerLocation === 1; else verifySent">
          <zwi-banner *ngIf="isMobile(); else desktop" (close)="closeVerifyBanner()">
            <zwi-title [offset]="true" title>Your email isn’t verified.</zwi-title>
            <zwi-caption [center]="true" [offset]="true" content>{{ verifyContent() }}</zwi-caption>
            <button action mat-flat-button (click)="sendEmailVerification()">Request Email</button>
          </zwi-banner>
          <ng-template #desktop>
            <zwi-banner (close)="closeVerifyBanner()">
              <zwi-section-headline [offset]="true" title>Your email isn’t verified.</zwi-section-headline>
              <zwi-body [center]="true" [offset]="true" content>{{ verifyContent() }}</zwi-body>
              <button action mat-flat-button (click)="sendEmailVerification()">Request Email</button>
            </zwi-banner>
          </ng-template>
        </ng-container>
        <ng-template #verifySent>
          <zwi-banner *ngIf="isMobile(); else desktop" (close)="closeVerifyBanner()">
            <zwi-title [offset]="true" title>Your verification email has been sent.</zwi-title>
            <zwi-caption [center]="true" [offset]="true" content>A verification email was sent to {{ email }}. If you don't see it, check your spam or junk folder.</zwi-caption>
          </zwi-banner>
          <ng-template #desktop>
            <zwi-banner (close)="closeVerifyBanner()">
              <zwi-section-headline [offset]="true" title>Your verification email has been sent.</zwi-section-headline>
              <zwi-body [center]="true" [offset]="true" content>A verification email was sent to {{ email }}. If you don't see it, check your spam or junk folder.</zwi-body>
            </zwi-banner>
          </ng-template>
        </ng-template>
      </ng-container>

    </div>
    <div class="zwi-main">
      <ng-template #topSpacer>
        <ng-container *ngIf="showTopSpacer()">
          <zwi-top-spacer></zwi-top-spacer>
        </ng-container>
      </ng-template>
      <router-outlet></router-outlet>
    </div>
    <zwi-upload-manager [uploads]="uploads" (selectionChange)="selectUpload($event)"></zwi-upload-manager>
    <div id="zwi-footer" class="zwi-footer" style="pointer-events: none; display: block; overflow: visible; width: 100%; position: relative;" >
      <zwi-player></zwi-player>
      <zwi-bottom-app-bar [menuItems]="mobileMenuItems"></zwi-bottom-app-bar>
    </div>
  </div>
</zwi-content>


<!--<div style="position: fixed; left: 0; right: 0; bottom: 0; height: 64px; background: white; border: 1px solid lightgray;"></div>-->

