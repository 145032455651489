<form [formGroup]="form" (ngSubmit)="submit()" class="form-lg">
  <div class="zwi-form-group">
    <mat-form-field [style.width]="'100%'">
      <mat-label>Ministry name</mat-label>
      <input matInput formControlName="name" placeholder="Enter the name of your ministry">
      <mat-hint>How you will appear on Zwiloo</mat-hint>
      <mat-error>{{ formError.getErrorMessage('name', 'required') }}</mat-error>
    </mat-form-field>
  </div>
  <div class="zwi-form-group">
    <mat-form-field floatLabel="always" [style.width]="'100%'">
      <mat-label>Ministry username</mat-label>
      <input matInput formControlName="username" placeholder="Username">
      <span matTextPrefix>https://zwiloo.com/</span>
      <mat-error>
        <ng-container *ngIf="form.get('username')?.errors?.required">
          {{ formError.getErrorMessage('username', 'required') }}</ng-container>
        <ng-container *ngIf="form.get('username')?.errors?.characters">
          {{ formError.getErrorMessage('username', 'characters') }}</ng-container>
        <ng-container *ngIf="form.get('username')?.errors?.usernameTaken">
          {{ formError.getErrorMessage('username', 'usernameTaken') }}</ng-container>
      </mat-error>
    </mat-form-field>
  </div>
  <div class="layout-row layout-align-end-center">
    <button mat-flat-button color="primary">{{ buttonLabel }}</button>
  </div>
</form>
