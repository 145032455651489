import groupBy from 'lodash/groupBy';

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import { StarChange } from '../sermon/sermon.component';

import { Sermon } from '@zwiloo/sermon/domain';

@Component({
  selector: 'zwi-sermons-by-ministry',
  templateUrl: './ministry-sermons.component.html',
  styleUrls: ['./ministry-sermons.component.scss'],
})
export class MinistrySermonsComponent implements OnChanges, OnInit {
  @Input() sermons: Sermon[] = [];
  @Output() starChange: EventEmitter<StarChange> = new EventEmitter();
  ministries: { ministry: string; sermons: Sermon[] }[] = [];

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    this.ministries = this.sermons.reduce((acc, s) => {
      const match = acc.find((m) => m.ministry === s.ministry.name);
      if (match !== undefined) {
        match.sermons.push(s);
      } else {
        acc.push({
          ministry: s.ministry.name,
          sermons: [s],
        });
      }
      return acc;
    }, [] as { ministry: string; sermons: Sermon[] }[]);
    // this.ministries = groupBy(this.sermons, 'ministry');
  }

  favoriteChange(starChange: StarChange) {
    this.starChange.emit(starChange);
  }
}
