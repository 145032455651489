import { Subscription } from 'rxjs';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AccountService } from '@zwiloo/user/account';
import { VerifyResponse } from '@zwiloo/user';
import { CallState, LoadingState } from '@zwiloo/util/state';
import { VerifyEmailService } from '@zwiloo/user/context/verify-email.service';

@Component({
  selector: 'zwi-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss'],
  providers: [VerifyEmailService],
})
export class VerifyComponent implements OnInit {
  result: VerifyResponse = { status: '' };
  status: CallState = LoadingState.INIT;
  statusSubscription: Subscription;
  successSubscription: Subscription;

  constructor(
    private accountService: AccountService,
    private verify: VerifyEmailService,
    private route: ActivatedRoute
  ) {
    this.successSubscription = verify.result.subscribe((r) => {
      this.result = r;
    });
    this.statusSubscription = verify.status.subscribe((s) => {
      this.status = s;
    });
  }
  ngOnInit(): void {
    const userID = this.route.snapshot.queryParamMap.get('u');
    const token = this.route.snapshot.queryParamMap.get('t');
    if (userID !== null && token !== null) {
      this.verify.verifyEmail(Number(userID), token);
    }
  }
}
