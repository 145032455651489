import {
  Component,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

import { Sermon } from '@zwiloo/sermon/domain';
import { PlayerService, Status } from '@zwiloo/sermon/context/player.service';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[zwi-audio]',
})
export class AudioDirective {
  // @Input()
  // set sermon(s: Sermon) {
  //   this._sermon = s;
  //   this.elementRef.nativeElement.src = s.audio;
  //   this.playAudio();
  // }
  // get sermon() {
  //   return this.sermon;
  // }
  currentTime: number = 0;
  // currentTimeSubscription: Subscription;
  status: Status = 'EMPTY';
  private _sermon: Sermon = new Sermon();
  // @Output() changeStatus = new EventEmitter<Status>();

  statusSubscription: Subscription;
  subscription: Subscription;

  constructor(
    public elementRef: ElementRef,
    private playerService: PlayerService
  ) {
    // this.currentTimeSubscription = playerService.currentTime.subscribe((t) => {
    //   if (this.elementRef.nativeElement.currentTime !== t) {
    //     this.elementRef.nativeElement.currentTime = t;
    //     if (this.status === 'PAUSED') {
    //       playerService.updateStatus('PLAYING');
    //     }
    //   }
    // });
    this.subscription = playerService.sermon.subscribe((s) => {
      // var audio = new Howl({
      //   src: [s.audio],
      //   onplay: function () {
      //     // @ts-ignore
      //     requestAnimationFrame(this.step.bind(this));
      //   }.bind(this),
      // });
      // this.playerService.audio.set(audio);
      // audio.play();
      // console.log(audio);
      // playerService.updateStatus('PLAYING');
    });

    this.statusSubscription = playerService.status.subscribe((s) => {
      // this.status = s;
      // if (s === 'PLAYING') {
      //   this.playerService.audio()?.play();
      // } else if (s === 'PAUSED') {
      //   this.playerService.audio()?.pause();
      // }
    });
  }

  // step() {
  //   var self = this;
  //   const seek = this.playerService.audio()?.seek() || 0;
  //   this.playerService.updateCurrentTime(seek);
  //   if (this.playerService.audio()?.playing()) {
  //     requestAnimationFrame(self.step.bind(self));
  //   }
  // }

  // pauseAudio() {
  //   const pausePromise = this.elementRef.nativeElement.pause();
  //   this.changeStatus.emit('PAUSED');
  // }

  // playAudio() {
  //   const playPromise = this.elementRef.nativeElement.play();
  //   if (playPromise !== undefined) {
  //     playPromise
  //       .then(() => {
  //         this.changeStatus.emit('PLAYING');
  //       })
  //       .catch((error: any) => {});
  //   }
  // }
}
