import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MinistryService } from '@zwiloo/ministry/ministry.service';
import { SermonService } from '@zwiloo/sermon/sermon.service';
import { GetMinistryService } from '@zwiloo/ministry/context/get-ministry.service';
import { Observable, Subscription } from 'rxjs';
import { toObservable } from '@angular/core/rxjs-interop';
import { AuthService } from '@zwiloo/user/auth';

@Component({
  selector: 'zwi-ministry',
  templateUrl: './ministry.component.html',
  styleUrls: ['./ministry.component.scss'],
  providers: [GetMinistryService, MinistryService, SermonService],
})
export class MinistryComponent implements OnInit {
  username = '';
  hasSession$: Observable<boolean>;

  constructor(private route: ActivatedRoute, public authService: AuthService) {
    this.hasSession$ = toObservable(authService.hasSession);
  }

  ngOnInit(): void {
    const username = this.route.snapshot.params.username;
    this.username = username;
  }
}
