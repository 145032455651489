import { Injectable } from '@angular/core';

import { BOOKS_TESTAMENTS } from './books-testaments';
import { Reference, ReferenceRange } from './reference';
import { ReferenceFormatter, ReferenceFormat } from './reference-formatter';
import { endingVerses } from './ending-verses';

@Injectable()
export class ReferenceAdapter {
  createReference(book: number, chapter = 0, verse = 0): Reference {
    return {
      book: book,
      chapter: chapter,
      verse: verse,
    };
  }

  _createReferenceWithOverflow(
    book: number,
    chapter = 0,
    verse = 0
  ): Reference {
    const lastChapter = endingVerses[book - 1].length;
    if (chapter > lastChapter) {
      book = book + 1;
      chapter = 1;
    } else if (chapter == 0) {
      book = book - 1;
      chapter = endingVerses[book - 1].length;
    }
    return {
      book: book,
      chapter: chapter,
      verse: verse,
    };
  }

  addReferenceBooks(reference: Reference, books: number) {
    const newReference = this._createReferenceWithOverflow(
      this.getBook(reference) + books,
      this.getChapter(reference),
      this.getVerse(reference)
    );
    return newReference;
  }

  addReferenceChapters(reference: Reference, chapters: number) {
    const newReference = this._createReferenceWithOverflow(
      this.getBook(reference),
      this.getChapter(reference) + chapters,
      this.getVerse(reference)
    );
    return newReference;
  }

  getNumChaptersInBook(reference: Reference) {
    return endingVerses[reference.book - 1].length;
  }

  getNumVersesInChapter(reference: Reference) {
    return endingVerses[reference.book - 1][reference.chapter - 1];
  }

  getBook(reference: Reference): number {
    return reference.book;
  }

  getBookAbbreviations(): string[] {
    return BOOKS_TESTAMENTS.map((book: any) => book.abbreviation);
  }

  getBookName(reference: Reference): string {
    const bookNames = this.getBookNames();
    return bookNames[reference.book - 1];
  }

  getBookNames(): string[] {
    return BOOKS_TESTAMENTS.map((book: any) => book.name);
  }

  getChapter(reference: Reference): number {
    return reference.chapter;
  }

  getValue(reference: Reference): number {
    return (
      reference.book * 1000000 + reference.chapter * 1000 + reference.verse * 1
    );
  }

  getVerse(reference: Reference): number {
    return reference.verse;
  }

  first(): Reference {
    return {
      book: 1,
      chapter: 1,
      verse: 1,
    };
  }

  last(): Reference {
    return {
      book: 66,
      chapter: 22,
      verse: 21,
    };
  }

  bookFirst(book: number) {
    return new Reference(book, 1, 1);
  }

  bookLast(book: number): Reference {
    const lastChapter = endingVerses[book - 1].length;
    const lastVerse = endingVerses[book - 1][lastChapter - 1];
    return new Reference(book, lastChapter, lastVerse);
  }

  format(reference: Reference, displayFormat: ReferenceFormat): string {
    const formatter = new ReferenceFormatter();
    return formatter.format(reference, displayFormat);
  }
}
