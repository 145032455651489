import { Component, Input } from '@angular/core';

@Component({
  selector: 'zwi-email-sent-message',
  templateUrl: './email-sent-message.component.html',
  styleUrls: ['./email-sent-message.component.scss'],
})
export class EmailSentMessageComponent {
  @Input() email = '';
}
