import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'zwi-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.scss'],
})
export class BodyComponent implements OnInit {
  @Input() bold = false;
  @Input() center = false;
  @Input() offset = false;

  constructor() {}

  ngOnInit(): void {}
}
