<div class="zwi-flex-center zwi-block-section--horizontal">
  <zwi-book-menu [book]="(selectedBook$ | async)" (bookSelected)="onBookSelected($event)"></zwi-book-menu>
  <button *ngIf="(selectedBook$ | async) !== null" (click)="removeSelected()" mat-button color="primary" class="zwi-ml-md">All Books</button>
</div>
<div *ngFor="let b of bookSermons | async">
  <zwi-sermon-category-row>{{ toBook(b.book) }}</zwi-sermon-category-row>
  <zwi-sermon-list *ngIf="b.sermons.length > 0; else message" [bookmarkable]="bookmarkable" [sermons]="b.sermons" [sortBy]="'airDate'" (starChange)="favoriteChange($event)"></zwi-sermon-list>
  <ng-template #message>
    <div class="headline--blank">{{ blankMessage(b.book) }}</div>
  </ng-template>
</div>
