<zwi-simple-form-page *ngIf="step === 'ministry'; else account">
  <zwi-headline *ngIf="isNativePlatform === false; else backHeadline">Ministry  information</zwi-headline>
  <ng-template #backHeadline>
    <zwi-back-headline (navigateBack)="navigateToIndex()">Ministry information</zwi-back-headline>
  </ng-template>
  <zwi-signup-ministry *ngIf="step === 'ministry'" [ministry]="ministryAccount.ministry" (updateMinistry)="updateMinistry($event)"></zwi-signup-ministry>
</zwi-simple-form-page>
<ng-template #account>
  <zwi-simple-form-page>
    <zwi-headline>Create a Zwiloo account</zwi-headline>
    <zwi-signup-account *ngIf="step === 'account'" (changeStep)="changeStep($event)"
                        (updateAccount)="updateAccount($event)"></zwi-signup-account>
  </zwi-simple-form-page>
</ng-template>
