import { Component, Inject, OnInit } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'zwi-definite-speaker-match',
  templateUrl: './definite-speaker-match.component.html',
  styleUrls: ['./definite-speaker-match.component.scss'],
})
export class DefiniteSpeakerMatchComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<DefiniteSpeakerMatchComponent>) {}

  ngOnInit() {}

  onClick(): void {
    this.dialogRef.close();
  }
}
