import { Component, OnInit } from '@angular/core';

import { AdminService } from '@zwiloo/settings/context/admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { Ministry } from '@zwiloo/ministry/domain';
import { User } from '@zwiloo/user/domain';
import { CallState, LoadingState } from '@zwiloo/util/state';
import { LoginInfo } from '@zwiloo/user/auth';

@Component({
  selector: 'zwi-admin-invitation',
  templateUrl: './admin-invitation.component.html',
  styleUrls: ['./admin-invitation.component.scss'],
  providers: [AdminService],
})
export class AdminInvitationComponent implements OnInit {
  email = '';
  token = '';
  ministry = new Ministry();
  addStatus: CallState = LoadingState.INIT;
  addStatusSubscription: Subscription;
  status: CallState = LoadingState.INIT;
  statusSubscription: Subscription;
  subscription: Subscription;
  userSubscription: Subscription;
  user = new User();

  constructor(
    private admin: AdminService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.subscription = admin.ministry.subscribe((m) => {
      this.ministry = m;
    });
    this.userSubscription = admin.user.subscribe((u) => {
      this.user = u;
    });
    this.statusSubscription = admin.status.subscribe((s) => {
      this.status = s;
    });
    this.addStatusSubscription = admin.addStatus.subscribe((s) => {
      this.addStatus = s;
    });
  }

  ngOnInit(): void {
    const email = this.route.snapshot.queryParamMap.get('u');
    const token = this.route.snapshot.queryParamMap.get('t');
    if (email != null && token !== null) {
      this.email = atob(email);
      this.token = token;
      this.admin.verifyToken(token, email);
      this.admin.getUser(this.email);
    }
  }

  accept() {
    if (this.user.id == 0) {
      const e = btoa(this.email);
      this.router.navigateByUrl(
        `/complete-sign-up?e=${e}&m=${this.ministry.id}&t=${this.token}`
      );
    } else {
      this.admin.add(this.email, this.ministry.id, this.token);
    }
  }
}
