import { Component, Input } from '@angular/core';

import { Series } from '@zwiloo/ministry/domain';

@Component({
  selector: 'zwi-upload-series-card',
  templateUrl: './upload-series-card.component.html',
  styleUrls: ['./upload-series-card.component.scss'],
})
export class UploadSeriesCardComponent {
  @Input() series = new Series();
}
