import {
  Component,
  computed,
  Input,
  signal,
  WritableSignal,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSliderModule } from '@angular/material/slider';
import { NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';

import { TypographyModule } from '@zwiloo/frontend/typography/typography.module';
import { Status } from '@zwiloo/sermon/context/player.service';
import { Sermon } from '@zwiloo/sermon/domain';
import { TimeDisplayComponent } from '@zwiloo/frontend/shared/player/time-display/time-display.component';
import { EmbedCoverArtComponent } from '../shared/embed-cover-art/embed-cover-art.component';
import { EmbedPlayerComponent } from '../shared/embed-player/embed-player.component';
import { EmbedMetadataComponent } from '../shared/embed-metadata/embed-metadata.component';
import { EmbedLogoComponent } from '../shared/embed-logo/embed-logo.component';

import { SermonService } from '@zwiloo/frontend/embed/sermon.service';

@Component({
  selector: 'zwi-sermon-embed',
  standalone: true,
  imports: [
    EmbedCoverArtComponent,
    EmbedMetadataComponent,
    EmbedLogoComponent,
    EmbedPlayerComponent,
    TimeDisplayComponent,
    TypographyModule,
    MatButtonModule,
    MatIconModule,
    MatSliderModule,
    NgIf,
  ],
  templateUrl: './sermon-embed.component.html',
  styleUrl: './sermon-embed.component.scss',
  providers: [SermonService],
})
export class SermonEmbedComponent {
  @Input()
  set id(sermonId: string) {
    this.http.get<Sermon>(`/api/sermons/${sermonId}`).subscribe((s) => {
      this.sermonService.sermon.set(s);
    });
  }

  pendingPercent = 0;

  // Signals
  currentTime: WritableSignal<number> = signal(0);
  duration: WritableSignal<number> = signal(0);
  status: WritableSignal<Status> = signal('EMPTY');

  constructor(public sermonService: SermonService, private http: HttpClient) {}
}
