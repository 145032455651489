import { ReferenceRange } from '@zwiloo/verse/domain/reference';

export class Upload {
  constructor(
    public id: number,
    public fileName: string,
    public file: File,
    public sermonInfo: SermonInfo = new SermonInfo(),
    public scripture: string[] = [],
    public progress: number = 0,
    public saveStatus: SaveStatus = SaveStatus.Editing,
    public uploadStatus: UploadStatus = UploadStatus.Initial,
    public uploadedFile: UploadedFile = new UploadedFile()
  ) {}

  // constructor(public id: number, public fileName: string, public file: File) {}
  // title: string;
  // description: string;
  // scripture: string[];
  // series: string;
  // speaker: string;
  // progress: number;
  // saveStatus: SaveStatus;
  // uploadStatus: UploadStatus;
  // uploadedFile: UploadedFile;
  // references?: ReferenceRange[];

  // constructor(public id: number, public fileName: string, public file: File) {}
}

export class SermonInfo {
  constructor(
    public title: string = '',
    public description: string = '',
    public seriesID: number = 0,
    public speakerID: number = 0,
    public references: ReferenceRange[] = []
  ) {}
}

export enum SaveStatus {
  Editing,
  Saved,
  Unsaved,
}

export enum UploadStatus {
  Initial,
  Uploading,
  Processing,
  Complete,
  Failed,
}

// Previous implementation had a notion of a series, could upload a sermons in a series at the same time

export class UploadedFile {
  // id: number;
  // path?: string;
  constructor(public id: number = 0, public path?: string) {}
}

export class Verse {
  // book: string;
  // startingChapter: string;
  // startingVerse: string;
  // endingChapter: string;
  // endingVerse: string;
  constructor(
    public book: string = '',
    public startingChapter: string = '',
    public startingVerse: string = '',
    public endingChapter: string = '',
    public endingVerse: string = ''
  ) {}
}

export class UploadValues {
  constructor(
    public title: string = '',
    public description: string = '',
    public scripture: string[] = [],
    public series: string = '',
    public speaker: string = ''
  ) {}
}
