<ng-container [ngSwitch]="page">
  <div *ngSwitchCase="'upload'" class="zwi-section">
    <mat-chip-listbox [hideSingleSelectionIndicator]="true" [multiple]="false" (change)="updateActive($event)" aria-label="Select sermon category">
      <mat-chip-option [highlighted]="active === 'Sermon'">Sermon</mat-chip-option>
      <mat-chip-option [highlighted]="active === 'Series'">Series</mat-chip-option>
    </mat-chip-listbox>
    <div *ngIf="active === 'Sermon'; else seriesContent" class="zwi-tile-grid">
      <div class="zwi-tile-button" (click)="uploadFile()">
        <mat-icon class="zwi-tile__icon">add</mat-icon>
        <div class="mat-headline-5">Upload Sermons</div>
      </div>
      <div *ngFor="let sermon of sermons" class="zwi-tile zwi-tile-card">
        <div class="zwi-tile__content">
          <div class="zwi-tile__title">{{ sermon.name }}</div>
          <div class="zwi-tile__subtitle">{{ sermon.series.name }}</div>
          <!--          <div class="zwi-tile__line">{{ sermon.verse }}</div>-->
        </div>
        <div class="zwi-tile__actions">
          <button (click)="playSermon(sermon)" type="button" mat-icon-button>
            <mat-icon>play_arrow</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <ng-template #seriesContent>
      <zwi-series-list>
        <zwi-upload-series-card (click)="uploadFile()"></zwi-upload-series-card>
        <zwi-series-info-card *ngFor="let s of seriesInfo" [seriesInfo]="s"></zwi-series-info-card>
      </zwi-series-list>
    </ng-template>
    <input type="file" id="fileInput" (change)="addFiles()" multiple style="display: none">
  </div>
  <div *ngSwitchCase="'series'" class="zwi-section">
    <div class="zwi-flex-center" >
      <button (click)="navigateToUpload()" mat-icon-button>
        <mat-icon>arrow_back</mat-icon>
      </button>
      <zwi-headline>Add series name</zwi-headline>
    </div>
    <mat-chip-listbox [hideSingleSelectionIndicator]="true" [multiple]="false" (change)="updateSeriesCategory($event)" aria-label="Select series category">
      <mat-chip-option [highlighted]="seriesCategory === 'New series'">New series</mat-chip-option>
      <mat-chip-option [highlighted]="seriesCategory === 'Existing series'">Existing series</mat-chip-option>
    </mat-chip-listbox>
    <form *ngIf="seriesCategory === 'New series'; else currentSeries" [formGroup]="newSeriesForm" (ngSubmit)="submitSeries()">
      <div>
        <mat-form-field appearance="fill" class="zwi-vertical-form__md-field">
          <mat-label>Series name</mat-label>
          <input formControlName="series" matInput>
          <mat-error>
            <ng-container *ngIf="newSeriesForm.get('series')?.errors?.required">{{ formError.getErrorMessage('uploadSeries', 'newRequired') }}</ng-container>
          </mat-error>
        </mat-form-field>
      </div>
      <button mat-raised-button color="primary">Next</button>
    </form>
    <ng-template #currentSeries>
      <form [formGroup]="existingSeriesForm" (ngSubmit)="submitSeries()">
        <div>
          <mat-form-field appearance="fill">
            <mat-label>Current series</mat-label>
            <mat-select formControlName="series">
              <mat-option *ngFor="let s of series" [value]="s.id">{{s.name}}</mat-option>
            </mat-select>
            <mat-error>
              <ng-container *ngIf="newSeriesForm.get('series')?.errors?.required">{{ formError.getErrorMessage('uploadSeries', 'existingRequired') }}</ng-container>
            </mat-error>
          </mat-form-field>
        </div>
        <button mat-raised-button color="primary">Next</button>
      </form>
    </ng-template>
  </div>
  <ng-container *ngSwitchCase="'info'">
    <zwi-upload-info [upload]="upload" (uploadNavigate)="navigateToUpload()"></zwi-upload-info>
  </ng-container>
  <ng-container *ngSwitchCase="'complete'">
    <zwi-upload-complete (sermonsNavigate)="navigateToSermons()" (uploadNavigate)="navigateToUpload()"></zwi-upload-complete>
<!--    <div>-->
<!--      <mat-card>-->
<!--        <div class="md-padding">-->
<!--          <div>-->
<!--            <h4>Save complete</h4>-->
<!--            <p>If you would like to add more sermons click Upload.</p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </mat-card>-->
<!--      <div class="bottom-element">-->
<!--        <hr/>-->
<!--        <div class="layout-row layout-align-end-center">-->
<!--          <a mat-button color="primary" [routerLink]="['/studio/sermons']">Sermon Manager</a>-->
<!--          <button mat-raised-button color="primary" (click)="initUpload()">Upload</button>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </ng-container>
</ng-container>




