import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'zwi-section-headline',
  templateUrl: './section-headline.component.html',
  styleUrls: ['./section-headline.component.scss'],
})
export class SectionHeadlineComponent implements OnInit {
  @Input() offset = false;
  constructor() {}

  ngOnInit(): void {}
}
