import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Sermon } from '@zwiloo/sermon/domain';
import { Temporal } from '@js-temporal/polyfill';

@Injectable({
  providedIn: 'root',
})
export class SermonDataService {
  constructor(private http: HttpClient) {}

  getSermons(ministryID: number) {
    return this.http.get<Sermon[]>(`/api/ministries/${ministryID}/sermons`);
  }

  getSermonHistory(
    ministryID: number,
    date: Temporal.PlainDate,
    cursor: number,
    limit: number
  ) {
    return this.http.get<Sermon[]>(
      `/api/ministries/${ministryID}/sermon-history`,
      { params: { date: date.toString(), cursor, limit } }
    );
  }
}
