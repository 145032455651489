import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Account } from '@zwiloo/user/account';

type verifyStatus = 'success' | 'existing' | 'expired' | 'invalid' | '';
export interface VerifyResponse {
  status: verifyStatus;
}
@Injectable()
export class AccountDataService {
  constructor(private http: HttpClient) {}

  deleteAccount(userID: number, token: string) {
    return this.http.delete<VerifyResponse>(`/api/users/${userID}?t=${token}`);
  }

  resetPassword(userID: number, token: string, password: string) {
    return this.http.put<VerifyResponse>(`/api/users/${userID}/password`, {
      password,
      token,
    });
  }

  sendCloseAccountToken(email: string) {
    return this.http.get(`/api/users/${email}/close-account-token`);
  }

  sendResetToken(userID: number) {
    return this.http.get(`/api/users/${userID}/password-reset-token`);
  }

  sendVerifyToken(email: string) {
    return this.http.get(`/api/users/${email}/verify-email-token`);
  }

  updateEmail(newEmail: string) {
    return this.http.put<VerifyResponse>(`/api/users/email`, {
      newEmail,
    });
  }

  verifyResetToken(userID: number, token: string) {
    return this.http.post<VerifyResponse>(
      `/api/users/${userID}/verify-password-token`,
      {
        token,
      }
    );
  }

  verifyEmail(userID: number, token: string) {
    return this.http.post<VerifyResponse>(`/api/users/${userID}/verify-email`, {
      token,
    });
  }

  signUp(account: Account): Observable<Account> {
    return this.http.post<Account>(`/api/users`, account);
  }
}
