import { Component, Input } from '@angular/core';

@Component({
  selector: 'zwi-embed-cover-art',
  standalone: true,
  imports: [],
  templateUrl: './embed-cover-art.component.html',
  styleUrl: './embed-cover-art.component.scss',
})
export class EmbedCoverArtComponent {
  @Input() backgroundImage = '';
  get backgroundImageUrl() {
    return this.backgroundImage === '' ? '' : `url(${this.backgroundImage})`;
  }
}
