import { Observable, Subscription } from 'rxjs';

import { Component, OnInit } from '@angular/core';

import { SubscriptionService } from '@zwiloo/subscription/context/subscription.service';
import { CallState, LoadingState } from '@zwiloo/util/state';
import { SearchService } from '@zwiloo/search/context/search.service';
import { toObservable } from '@angular/core/rxjs-interop';
import { AuthService } from '@zwiloo/user/auth';

@Component({
  selector: 'zwi-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
  providers: [SearchService, SubscriptionService],
})
export class SubscriptionComponent implements OnInit {
  hasSession$: Observable<boolean>;
  status: CallState = LoadingState.INIT;
  statusSubscription: Subscription;

  constructor(
    private searchService: SearchService,
    private subscription: SubscriptionService,
    public authService: AuthService
  ) {
    this.hasSession$ = toObservable(authService.hasSession);
    this.statusSubscription = searchService.status.subscribe((s) => {
      this.status = s;
    });
  }

  ngOnInit() {
    this.hasSession$.subscribe((s: boolean) => {
      if (s) {
        this.subscription.loadSubscriptions();
      }
    });

    // this.user.loadSubscriptions();
    // this.this.store.dispatch(SubscriptionActions.init());
    // this.store.dispatch(LocalChurchActions.getSubscriptions());
    // this.store.dispatch(SubscriptionActions.getSubscriptions());
  }
}
