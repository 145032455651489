<table class="zwi-reference-table">
  <thead class="zwi-reference-table-header">
    <tr><th class="zwi-reference-table-header-divider" colspan="10"></th></tr>
  </thead>
  <tbody zwi-reference-body
         [activeCell]="getActiveCell()"
         [allowRange]="true"
         [rows]="_verses"
         [beginValue]="_selectedBeginVerse!"
         [endValue]="_selectedEndVerse!"
         (selectedValueChange)="_verseSelected($event)">
<!--         (keydown)="_handleReferenceBodyKeydown($event)">-->
  </tbody>
</table>
