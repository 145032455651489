import { Capacitor } from '@capacitor/core';

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Account, AccountService } from '@zwiloo/user/account';
import {
  Ministry,
  MinistryAccount,
  MinistryAccountService,
} from '@zwiloo/ministry/ministry-account';
import { AuthService, LoginInfo } from '@zwiloo/user/auth';

@Component({
  selector: 'zwi-ministry-sign-up',
  templateUrl: './ministry-sign-up.component.html',
  styleUrls: ['./ministry-sign-up.component.scss'],
  providers: [MinistryAccountService],
})
export class MinistrySignUpComponent implements OnInit {
  isNativePlatform = Capacitor.isNativePlatform();
  step = 'ministry';
  ministryAccount = new MinistryAccount();

  constructor(
    private accountService: AccountService,
    private auth: AuthService,
    private ministryAccountService: MinistryAccountService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  changeStep(step: string) {
    this.step = step;
  }

  navigateToIndex() {
    this.router.navigateByUrl('/index');
  }

  updateAccount(account: Account) {
    this.ministryAccount.account = account;
    this.ministryAccountService.signUp(this.ministryAccount).subscribe(() => {
      this.auth
        .signin(new LoginInfo(account.email, account.password1))
        .subscribe((r) => {
          if (r.valid) {
            this.accountService.setMinistryInfo().subscribe((_) => {
              this.auth.hasSession.set(true);
              this.router.navigateByUrl('/home');
            });
          }
        });
    });
    // this.store.dispatch(new ministryAccount.UpdateAccount(account));
    // this.store.dispatch(new ministryAccount.SignupMinistry());
  }

  updateMinistry(ministry: Ministry) {
    this.ministryAccount.ministry = ministry;
    this.changeStep('account');
    // this.store.dispatch(new ministryAccount.UpdateMinistry(ministry));
    // this.changeStep('account');
  }
}
