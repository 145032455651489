import { Capacitor } from '@capacitor/core';

import { Injectable, isDevMode } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@zwiloo/user/auth';
import { getCookie } from '@zwiloo/util/cookie';
import { AccountService } from '@zwiloo/user/account';

@Injectable()
export class AuthGuard {
  constructor(
    private router: Router,
    private accountService: AccountService,
    private authService: AuthService
  ) {}

  canActivate() {
    // DevMode and native run as localhost so we can't access
    // the cookie for the server, we just return true and
    // don't guard access through authguard, we will be optimistic that
    // the user has access and if the server returns a 401 response
    // they will be redirected to index
    // return true;
    if (Capacitor.isNativePlatform()) {
      return true;
    }

    const session = getCookie('session');
    if (this.authService.sessionStatus() === 2) {
      return this.authService.hasSession();
      if (this.authService.hasSession() === false) {
        this.router.navigateByUrl('/index');
        return false;
      } else {
        return true;
      }
    } else {
      // Use account email as a proxy for session on page load,
      // If the user is not signed in then 401 should redirect them to login
      if (this.accountService.getAccountEmail() === null) {
        this.router.navigateByUrl('/index');
        return false;
      } else {
        return true;
      }
    }
  }

  canActivateChild(): boolean {
    return this.canActivate();
  }
}
