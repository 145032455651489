import {
  Component,
  EventEmitter,
  input,
  Input,
  InputSignal,
  OnInit,
  Output,
} from '@angular/core';

import { Sermon } from '@zwiloo/sermon/domain';
import { Status } from '@zwiloo/sermon/context/player.service';

@Component({
  selector: 'zwi-player-button',
  templateUrl: './player-button.component.html',
  styleUrls: ['./player-button.component.scss'],
})
export class PlayerButtonComponent {
  sermon = input<Sermon | null>(null);
  @Input() status?: Status;
  @Output() changePlay = new EventEmitter<boolean>();

  isPlaying() {
    return this.status === 'PLAYING';
  }
}
