import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { SharedModule } from '@zwiloo/frontend/shared/shared.module';
import { TypographyModule } from '@zwiloo/frontend/typography/typography.module';
import { HeaderComponent } from './header/header.component';
import { DailySermonsComponent } from './daily-sermons/daily-sermons.component';

@NgModule({
  declarations: [HomeComponent, HeaderComponent, DailySermonsComponent],
  imports: [
    CommonModule,
    InfiniteScrollModule,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatToolbarModule,
    RouterModule,
    SharedModule,
    TypographyModule,
  ],
})
export class HomeModule {}
