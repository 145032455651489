import { Subject } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Favorite, Sermon } from './domain/sermon';

@Injectable({
  providedIn: 'root',
})
export class SermonService {
  private favorites = new Subject<Favorite[]>();
  private sermons = new Subject<Sermon[]>();
  public sermonsWithFavorites = new Subject<Sermon[]>();

  constructor(private http: HttpClient) {}

  markFavorites(sermons: Sermon[], favorites: Sermon[]) {
    return sermons.map((s) => {
      const index = favorites.findIndex((f) => f.id === s.id);
      if (index !== -1) {
        return {
          ...s,
          favorite: true,
        };
      } else {
        return s;
      }
    });
  }

  loadFavorites() {
    return this.http
      .get<Favorite[]>('/api/favorites')
      .subscribe((f) => this.favorites.next(f));
  }

  favorite(sermon: Sermon) {
    const previous = sermon.favorite;
    sermon.favorite = true;
    return this.http
      .post<{ sermonId: number }>('/api/favorites', { sermonId: sermon.id })
      .subscribe({
        error: () => {
          sermon.favorite = previous;
        },
      });
  }

  unfavorite(sermon: Sermon) {
    const previous = sermon.favorite;
    sermon.favorite = false;
    return this.http
      .delete<{ sermonId: number }>(`/api/favorites/${sermon.id}`)
      .subscribe({
        error: () => {
          sermon.favorite = previous;
        },
      });
  }
}
