import { Component, effect, signal, computed } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { MatSnackBar } from '@angular/material/snack-bar';

import { ErrorState } from '@zwiloo/util/state';
import { PreviousFeedService } from './view-model/previous-feed.service';

@Component({
  selector: 'zwi-availability',
  templateUrl: './availability.component.html',
  styleUrl: './availability.component.scss',
  providers: [PreviousFeedService],
})
export class AvailabilityComponent {
  rssURL = signal('https://feeds.zwiloo.com/test.rss');
  previousFeedForm = this.formBuilder.group({
    url: [''],
  });

  effectRef = effect(() => {
    if (this.previousFeed.status() instanceof ErrorState) {
      this.previousFeedForm.get('url')?.setErrors({ invalid: true });
    } else {
      this.previousFeedForm.get('url')?.setErrors(null);
    }
  });

  // effect(() => {
  //   // this.previousFeedForm.get('url')?.valueChanges.subscribe(() => {
  //   //   this.previousFeedForm.get('url')?.setErrors(null);
  //   // });
  // });

  constructor(
    private formBuilder: FormBuilder,
    public previousFeed: PreviousFeedService
  ) {}

  copy() {
    window.navigator.clipboard.writeText(this.rssURL());
  }

  submitPreviousFeedURL() {
    const p = this.previousFeedForm.get('url')?.value;
    if (p) {
      this.previousFeed.postPreviousFeedURL(p);
    }
  }
}
