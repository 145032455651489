import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatTabsModule } from '@angular/material/tabs';

import { SharedModule } from '@zwiloo/frontend/shared/shared.module';
import { LocalChurchComponent } from './local-church.component';
// import { MinistrySermonsComponent } from './ministry-sermons/ministry-sermons.component';
import { MinistryModule } from '@zwiloo/frontend/ministry/ministry.module';
import { TypographyModule } from '@zwiloo/frontend/typography/typography.module';

@NgModule({
  declarations: [LocalChurchComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatChipsModule,
    MatTabsModule,
    MinistryModule,
    RouterModule,
    SharedModule,
    TypographyModule,
  ],
})
export class LocalChurchModule {}
