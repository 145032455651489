import { Subscription } from 'rxjs';
import groupBy from 'lodash/groupBy';

import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { MinistryService } from '@zwiloo/ministry/ministry.service';
import { Sermon } from '@zwiloo/sermon/domain';

@Component({
  selector: 'zwi-sermon-information',
  templateUrl: './sermon-information.component.html',
  styleUrls: ['./sermon-information.component.scss'],
})
export class SermonInformationComponent {
  @Input()
  set sermons(sermons: Sermon[]) {
    this._sermons = sermons;
    this.sermonCount = sermons.length;
    const filteredSermons = sermons.filter(
      (sermon) => sermon.series.name !== 'Individual'
    );
    this.seriesCount = Object.keys(
      groupBy(filteredSermons, (s) => s.series.id)
    ).length;
  }
  get sermons() {
    return this._sermons;
  }
  private _sermons: Sermon[] = [];

  sermonCount: number = 0;
  seriesCount: number = 0;

  // constructor(public ministry: MinistryService) {
  //   this.subscription = ministry.sermons.subscribe((s) => {
  //     this.sermonCount = s.length;
  //     this.seriesCount = Object.keys(groupBy(s, 'series')).length;
  //   });
  // }
  //
  // ngOnInit() {}
  //
  // ngOnDestroy(): void {
  //   this.subscription.unsubscribe();
  // }
}
