<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" class="zwi-section">
  <mat-tab label="Add series">
    <zwi-add-series></zwi-add-series>
  </mat-tab>
  <mat-tab label="Edit series">
    <zwi-edit-series [seriesOptions]="seriesWithSermons"></zwi-edit-series>
<!--    <zwi-sermon-list [sermons]="added" [sortBy]="'airDate'" (starChange)="favoriteChange($event)"></zwi-sermon-list>-->
  </mat-tab>
  <mat-tab label="Current series">
    <zwi-current-series [seriesOptions]="seriesWithSermons"></zwi-current-series>
<!--    <zwi-verse-sermons [sermons]="sermons"></zwi-verse-sermons>-->
  </mat-tab>
</mat-tab-group>


