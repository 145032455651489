<div *ngIf="feedLoaded()" infiniteScroll
     [alwaysCallback]="true"
     [infiniteScrollDistance]="1"
     infiniteScrollContainer=".zwi-main-wrapper"
     [fromRoot]="true"
     (scrolled)="add()" class="zwi-block-section">
  <div class="zwi-page-sections">
    <zwi-main-content [spanMobile]="true">
      <mat-chip-listbox [hideSingleSelectionIndicator]="true" [multiple]="false" (change)="updateActive($event)" class="zwi-chip-nowrap" [class.zwi-mobile-chip-set]="isMobile()">
        <mat-chip-option *ngFor="let t of tabs()" [highlighted]="active() === t">{{ t }}</mat-chip-option>
      </mat-chip-listbox>
    </zwi-main-content>
    <zwi-main-content>
      @if (active() == 'Trending') {
        <div class="zwi-page-sections">
          @if (feed.dailyTrendingSermons().length > 0) {
            @for (t of feed.dailyTrendingSermons(); track t.id) {
              <div class="zwi-block-section">
                <zwi-section-headline>{{ formatDate(t.airDate) }}</zwi-section-headline>
                <zwi-sermon-list [bookmarkable]="authService.hasSession()" [sermons]="t.sermons" (starChange)="favoriteChange($event)"></zwi-sermon-list>
              </div>
            }
          }
        </div>
      } @else if (active() === 'Daily feed') {
        @if (authService.hasSession()) {
          @if (subscriptions.length > 0) {
            @if (feed.dailySermons().length > 0) {
              <div class="zwi-page-sections">
                @for (d of feed.dailySermons(); track d.id) {
                  <div class="zwi-block-section">
                    <zwi-section-headline>{{ formatDate(d.airDate) }}</zwi-section-headline>
                    <zwi-sermon-list [sermons]="d.sermons" (starChange)="favoriteChange($event)"></zwi-sermon-list>
                  </div>
                }
              </div>
            } @else {
              <div class="layout-column layout-align-center-center"
                   style="height: 450px; width: 100%; text-align: center">
                <div class="md-title placeholder--title">There are no sermons for the ministries you are following.</div>
              </div>
            }
          } @else {
            <div class="layout-column layout-align-center-center"
                 style="height: 450px; width: 100%; text-align: center">
              <div class="md-title placeholder--title">You are not subscribed to any ministries yet.</div>
              <p class="md-subhead">This screen will be replaced when you subscribe to ministries.</p>
              <a mat-raised-button color="primary" routerLink="/following">Find ministries</a>
            </div>
          }
        } @else {
          <zwi-sign-in-call-to-action>
            <ng-container title>Sign in to see daily sermons</ng-container>
            <ng-container content>Once you sign in, you'll see daily sermons from ministries you follow.</ng-container>
          </zwi-sign-in-call-to-action>
        }
      } @else if (active() === 'Recently added') {
        @if (authService.hasSession()) {
          @if (subscriptions.length > 0) {
            @if (feed.dailyAddedSermons().length > 0) {
              <div class="zwi-page-sections">
                @for (t of feed.dailyAddedSermons(); track t.id) {
                  <div class="zwi-block-section">
                    <zwi-section-headline>{{ t.createdAt }}</zwi-section-headline>
                    <zwi-sermon-list [bookmarkable]="authService.hasSession()" [sermons]="t.sermons" (starChange)="favoriteChange($event)"></zwi-sermon-list>
                  </div>
                }
              </div>
            } @else {
              <div class="layout-column layout-align-center-center"
                   style="height: 450px; width: 100%; text-align: center">
                <div class="md-title placeholder--title">There are no sermons for the ministries you are following.</div>
              </div>
            }
          } @else {
            <div class="layout-column layout-align-center-center"
                 style="height: 450px; width: 100%; text-align: center">
              <div class="md-title placeholder--title">You are not subscribed to any ministries yet.</div>
              <p class="md-subhead">This screen will be replaced when you subscribe to ministries.</p>
              <a mat-raised-button color="primary" routerLink="/following">Find ministries</a>
            </div>
          }
        } @else {
          <zwi-sign-in-call-to-action>
            <ng-container title>Sign in to see recently added sermons</ng-container>
            <ng-container content>Once you sign in, you'll see recently added sermons from ministries you follow.</ng-container>
          </zwi-sign-in-call-to-action>
        }
      } @else {
        @if (authService.hasSession()) {
          @if (localSubscriptions.length > 0) {
            @if (localDaily().length > 0) {
              <div class="zwi-page-sections">
                @for (l of localDaily(); track l.id) {
                  <div class="zwi-block-section">
                    <zwi-section-headline>{{ formatDate(l.airDate) }}</zwi-section-headline>
                    <zwi-sermon-list [sermons]="l.sermons" (starChange)="favoriteChange($event)"></zwi-sermon-list>
                  </div>
                }
                <!--                <div *ngFor="let l of localDaily()" class="zwi-block-section">-->
                <!--                  <zwi-section-headline>{{ formatDate(l.airDate) }}</zwi-section-headline>-->
                <!--                  <zwi-sermon-list [sermons]="l.sermons" (starChange)="favoriteChange($event)"></zwi-sermon-list>-->
                <!--                </div>-->
              </div>
            } @else {
              <div class="layout-column layout-align-center-center"
                   style="height: 450px; width: 100%; text-align: center">
                <div class="md-title placeholder--title">There are no sermons for your local church.</div>
              </div>
            }
          } @else {
            <div class="layout-column layout-align-center-center"
                 style="height: 450px; width: 100%; text-align: center">
              <div class="md-title placeholder--title">You are not following your local church yet.</div>
              <p class="md-subhead">This screen will be replaced when you subscribe to a local church.</p>
              <a mat-raised-button color="primary" routerLink="/following">Find your local church</a>
            </div>
          }
        } @else {
          <zwi-sign-in-call-to-action>
            <ng-container title>Sign in to see your local church sermons</ng-container>
            <ng-container content>Once you sign in, you'll see sermons from the local church you follow.</ng-container>
          </zwi-sign-in-call-to-action>


        }
      }
    </zwi-main-content>

         <!--          <div class="layout-column layout-align-center-center"-->
           <!--               style="height: 450px; width: 100%; text-align: center">-->
           <!--            <div class="md-title placeholder&#45;&#45;title">You are not following your local church yet.</div>-->
           <!--            <p class="md-subhead">This screen will be replaced when you subscribe to a local church.</p>-->
           <!--            <a mat-raised-button color="primary" routerLink="/following">Find your local church</a>-->
           <!--          </div>-->

<!--        <ng-container *ngIf="authService.hasSession() === false; else sermons">-->
<!--          <zwi-sign-in-call-to-action>-->
<!--            <ng-container title>Sign in to see your local church's sermons</ng-container>-->
<!--            <ng-container content>Once you sign in, you'll see daily sermons from your local church.</ng-container>-->
<!--          </zwi-sign-in-call-to-action>-->
<!--        </ng-container>-->
<!--        <ng-template #sermons>-->
<!--          <ng-container *ngIf="localSubscriptions.length > 0; else localMessage">-->
<!--            <ng-container *ngIf="localDaily().length > 0; else blankMessageLocal">-->
<!--              <div class="zwi-page-sections">-->
<!--                <div *ngFor="let l of localDaily()" class="zwi-block-section">-->
<!--                  <zwi-section-headline>{{ formatDate(l.airDate) }}</zwi-section-headline>-->
<!--                  <zwi-sermon-list [sermons]="l.sermons" (starChange)="favoriteChange($event)"></zwi-sermon-list>-->
<!--                </div>-->
<!--              </div>-->
<!--            </ng-container>-->
<!--            <ng-template #blankMessageLocal>-->
<!--              <div class="layout-column layout-align-center-center"-->
<!--                   style="height: 450px; width: 100%; text-align: center">-->
<!--                <div class="md-title placeholder&#45;&#45;title">There are no sermons for your local church.</div>-->
<!--              </div>-->
<!--            </ng-template>-->
<!--          </ng-container>-->

<!--          <ng-template #localMessage>-->
<!--            <div class="layout-column layout-align-center-center"-->
<!--                 style="height: 450px; width: 100%; text-align: center">-->
<!--              <div class="md-title placeholder&#45;&#45;title">You are not following your local church yet.</div>-->
<!--              <p class="md-subhead">This screen will be replaced when you subscribe to a local church.</p>-->
<!--              <a mat-raised-button color="primary" routerLink="/following">Find your local church</a>-->
<!--            </div>-->
<!--          </ng-template>-->
<!--        </ng-template>-->

  </div>
</div>
