<zwi-simple-form-page>
  <div class="z-wrapper">
    <div class="zwi-wrapper-content--sm">
      <div class="zwi-page-sections">
        <zwi-headline>Create a Zwiloo account</zwi-headline>
        <zwi-signup-form [email]="email" (updateAccount)="submit($event)">
          <button mat-button color="primary">Sign up</button>
        </zwi-signup-form>
        <div>
          <zwi-widget>
            <zwi-agreement-message></zwi-agreement-message>
          </zwi-widget>
        </div>
      </div>
    </div>
  </div>
</zwi-simple-form-page>
