import { filter, switchMap, tap } from 'rxjs/operators';

import { Injectable, signal } from '@angular/core';

import { CallState, LoadingState } from '../../util/state';
import { GetMinistryService } from '@zwiloo/ministry/context/get-ministry.service';
import { Ministry } from '@zwiloo/ministry/domain';
import { Sermon } from '@zwiloo/sermon/domain';
import { MinistryService } from '@zwiloo/ministry/ministry.service';
import { UserDataService } from '@zwiloo/user/data-interface/user-data.service';

@Injectable()
export class GetLocalChurchService {
  ministry = signal<Ministry>(new Ministry());
  // public ministry = new Subject<Ministry>();
  public dailySermons = signal<Sermon[]>([]);
  sermons = signal<Sermon[]>([]);
  public dailyLoadingStatus = signal(LoadingState.INIT);
  ministryStatus = signal<CallState>(LoadingState.INIT);
  loadingStatus = signal<CallState>(LoadingState.INIT);

  constructor(
    private ministryService: MinistryService,
    private getMinistryService: GetMinistryService,
    private userData: UserDataService
  ) {}

  getLocalChurch() {
    this.ministryStatus.set(LoadingState.LOADING);
    this.userData.loadLocalChurch().subscribe((l) => {
      this.ministryStatus.set(LoadingState.LOADED);
      if (l.length > 0) {
        this.ministry.set(l[0]);
      }
    });
  }

  loadLocalChurch() {
    this.ministryStatus.set(LoadingState.LOADING);
    this.userData
      .loadLocalChurch()
      .pipe(
        tap((l) => {
          if (l.length === 0) {
            this.ministryStatus.set(LoadingState.LOADED);
          }
        }),
        filter((l) => l.length > 0)
      )
      .pipe(
        switchMap((l) => {
          return this.ministryService.loadMinistryInformation(l[0].username);
        })
      )
      .pipe(
        tap((m) => {
          this.ministryStatus.set(LoadingState.LOADED);
          this.ministry.set(m);
        })
      )
      .pipe(
        filter((m) => m.id !== 0),
        switchMap((m) => {
          this.loadingStatus.set(LoadingState.LOADING);
          return this.ministryService.loadSermons(m.id);
        })
      )
      .subscribe((sermons) => {
        this.sermons.set(sermons);
        this.loadingStatus.set(LoadingState.LOADED);
      });
    // .subscribe((l) => {
    //   if (localChurch.length > 0) {
    //     const l = localChurch[0];
    //     this.ministryService.loadMinistryInformation(l.username);
    //   }
    // });
    // this.localChurch.next(l));
    // this.userService.localChurch.subscribe((localChurch) => {
    //   if (localChurch.length > 0) {
    //     const l = localChurch[0];
    //     this.ministryService
    //       .loadMinistryInformation(l.username)
    //       .subscribe((m) => {
    //         this.ministry.set(m);
    //         this.ministryStatus.set(LoadingState.LOADED);
    //         if (m.id !== 0) {
    //           // Load sermons;
    //           this.loadingStatus.set(LoadingState.LOADING);
    //           this.ministryService.loadSermons(l.id).subscribe((sermons) => {
    //             this.sermons.set(sermons);
    //             this.loadingStatus.set(LoadingState.LOADED);
    //           });
    //         } else {
    //           this.loadingStatus.set(LoadingState.LOADED);
    //         }
    //       });
    //     // this.getMinistryService.loadMinistry(l.username);
    //     // this.getMinistryService.loadingStatus.subscribe((c) => {
    //     //   this.loadingStatus.next(c);
    //     // });
    //   }
    //   // else {
    //   //   this.loadingStatus.next(LoadingState.LOADED);
    //   // }
    // });
    // this.userService.loadLocalChurch();
  }
}
