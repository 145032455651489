import { Component, Input, OnInit } from '@angular/core';
import { Sermon } from '@zwiloo/sermon/domain';
import { PlayerService, Status } from '@zwiloo/sermon/context/player.service';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'zwi-play-button',
  templateUrl: './play-button.component.html',
  styleUrls: ['./play-button.component.scss'],
})
export class PlayButtonComponent implements OnInit {
  @Input() sermon: Sermon = new Sermon();

  playingSermon: Sermon | null = null;
  sermonStatus: Status = 'EMPTY';

  sermonSubscription: Subscription;
  statusSubscription: Subscription;

  constructor(public dialog: MatDialog, private playerService: PlayerService) {
    this.sermonSubscription = playerService.sermon.subscribe((s) => {
      this.playingSermon = s;
    });
    this.statusSubscription = playerService.status.subscribe((s) => {
      this.sermonStatus = s;
    });
  }

  ngOnInit(): void {}

  pauseSermon() {
    this.playerService.updateStatus('PAUSED');
  }

  playSermon() {
    this.playerService.updateStatus('PLAYING');
  }

  startSermon() {
    const sermon = JSON.parse(JSON.stringify(this.sermon));
    this.playerService.setSermon(sermon);
  }
}
