<zwi-main-content>
  @if (ministryLoaded()) {
    <zwi-ministry-info-header *ngIf="ministryLoaded()" [ministry]="getMinistry.ministry()" [sermons]="sermons"></zwi-ministry-info-header>
  }
</zwi-main-content>
@if (ministryLoaded()) {
  <div class="zwi-section">
    <zwi-widget>
      <zwi-main-content [spanMobile]="true">
        <mat-chip-listbox [hideSingleSelectionIndicator]="true" [multiple]="false" (change)="updateActive($event)" class="zwi-chip-nowrap" [class.zwi-mobile-chip-set]="isMobile()" aria-label="Ministry seremons selection">
          <mat-chip-option value="Daily" [highlighted]="active === 'Daily'" selected>Daily feed</mat-chip-option>
          <mat-chip-option value="Added" [highlighted]="active === 'Added'">Recently added</mat-chip-option>
          <mat-chip-option [highlighted]="active === 'Verse'">Verse</mat-chip-option>
          <mat-chip-option [highlighted]="active === 'Series'">Series</mat-chip-option>
        </mat-chip-listbox>
      </zwi-main-content>
    </zwi-widget>
    @if (sermonsLoaded()) {
      <zwi-main-content>
        <ng-container *ngIf="hasSermons(); else blankMessage">
          <zwi-sermon-list *ngIf="active === 'Daily'" [bookmarkable]="authService.hasSession()" [sermons]="dailySermons" [sortBy]="'airDate'" (starChange)="favoriteChange($event)"></zwi-sermon-list>
          <zwi-sermon-list *ngIf="active === 'Added'" [bookmarkable]="authService.hasSession()" [sermons]="sermons" [sortBy]="'createdAt'" (starChange)="favoriteChange($event)"></zwi-sermon-list>
          <zwi-verse-sermons *ngIf="active === 'Verse'" [bookmarkable]="authService.hasSession()" [sermons]="sermons" (starChange)="favoriteChange($event)"></zwi-verse-sermons>
          <zwi-series-sermons *ngIf="active === 'Series'" [bookmarkable]="authService.hasSession()" [sermons]="sermons" (starChange)="favoriteChange($event)"></zwi-series-sermons>
        </ng-container>
        <ng-template #blankMessage>
          <zwi-widget>No sermons were found.</zwi-widget>
        </ng-template>
      </zwi-main-content>
    }
  </div>
}
