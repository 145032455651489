import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'zwi-mobile-profile-image',
  templateUrl: './mobile-profile-image.component.html',
  styleUrls: ['./mobile-profile-image.component.scss'],
})
export class MobileProfileImageComponent {
  constructor(private router: Router) {}
  navigateToSettings() {
    this.router.navigateByUrl('/mobile-settings');
  }
}
