<div *ngIf="status === 1; else content" class="zwi-main-content zwi-justify-center">
  <zwi-widget>
    <div>Loading</div>
    <!--    <zwi-progress-indicator></zwi-progress-indicator>-->
  </zwi-widget>
</div>
<ng-template #content>
  <ng-container [ngSwitch]="result.status">
    <ng-container *ngSwitchCase="'existing'">
      <zwi-simple-page>
        <zwi-call-to-action>
          <ng-container title>You've already verified your email.</ng-container>
          <button routerLink="/index" mat-raised-button color="primary" action>Continue to sign in</button>
        </zwi-call-to-action>
      </zwi-simple-page>
    </ng-container>
    <ng-container *ngSwitchCase="'success'">
      <zwi-simple-page>
        <zwi-call-to-action>
          <ng-container title>Success! Your account is all set up.</ng-container>
          <button routerLink="/index" mat-raised-button color="primary" action>Continue</button>
        </zwi-call-to-action>
      </zwi-simple-page>
    </ng-container>
    <ng-container *ngSwitchCase="'expired'">
      <zwi-simple-page>
        <zwi-call-to-action>
          <ng-container title>Email verification link has expired.</ng-container>
        </zwi-call-to-action>
      </zwi-simple-page>
    </ng-container>
  </ng-container>
</ng-template>


<!--<div class="layout-row">-->
<!--  <div class="flex-40 offset-30">-->
<!--    <div *ngIf="status === 1; else content" class="zwi-main-content">-->
<!--      <zwi-widget>-->
<!--        <div>Loading</div>-->
<!--        &lt;!&ndash;    <zwi-progress-indicator></zwi-progress-indicator>&ndash;&gt;-->
<!--      </zwi-widget>-->
<!--    </div>-->
<!--    <ng-template #content>-->
<!--      <div [ngSwitch]="result.status">-->
<!--        <div *ngSwitchCase="'existing'">-->
<!--          <zwi-body>You've already verified your email.</zwi-body>-->
<!--          <button routerLink="/index" mat-raised-button color="primary">Continue to sign in</button>-->
<!--        </div>-->
<!--        <div *ngSwitchCase="'success'">-->
<!--          <zwi-body>Success! You're account is all set up.</zwi-body>-->
<!--          <button routerLink="/index" mat-raised-button color="primary">Continue</button>-->
<!--        </div>-->
<!--        <div *ngSwitchCase="'expired'">-->
<!--          Email verification link has expired.-->
<!--        </div>-->
<!--      </div>-->
<!--      &lt;!&ndash;  <div>Success</div>&ndash;&gt;-->
<!--      &lt;!&ndash;  <div>Failed</div>&ndash;&gt;-->
<!--    </ng-template>-->
<!--  </div>-->
<!--</div>-->
