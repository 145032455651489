import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';

import { Sermon } from '@zwiloo/sermon/domain';
import { SermonEdit, SermonInfo } from '@zwiloo/studio/domain';

@Injectable({
  providedIn: 'root',
})
export class SermonsDataService {
  constructor(public http: HttpClient) {}

  delete(sermonID: number) {
    return this.http.delete(`/api/sermons/${sermonID}`);
  }

  update(sermonEdit: SermonEdit) {
    return this.http.put('/api/sermons', sermonEdit);
  }

  updateOrder(s: Sermon[]) {
    return this.http.put('/api/sermons?field=order', { sermons: s });
  }
}
