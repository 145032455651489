import { Component, computed, Input, signal } from '@angular/core';

import { Sermon } from '@zwiloo/sermon/domain';
import { FeedService } from '@zwiloo/feed/context/feed.service';
import { SermonService } from '@zwiloo/sermon/sermon.service';
import { AccountService } from '@zwiloo/user/account';
import { AuthService } from '@zwiloo/user/auth';
import { SubscriptionService } from '@zwiloo/subscription/context/subscription.service';
import { Router } from '@angular/router';
import { MatChipListboxChange } from '@angular/material/chips';
import { StarChange } from '@zwiloo/frontend/shared/sermon/sermon.component';
import { Ministry } from '@zwiloo/ministry/domain';
import { LoadingState } from '@zwiloo/util/state';
import { formatDay, formatHourAgo } from '@zwiloo/util/time';
import { isMobile } from '@zwiloo/util/tokens';

@Component({
  selector: 'zwi-daily-sermons',
  templateUrl: './daily-sermons.component.html',
  styleUrls: ['./daily-sermons.component.scss'],
})
export class DailySermonsComponent {
  @Input()
  set hasSession(s: boolean) {
    if (s) {
      this.active.set('Daily feed');
    } else {
      this.active.set('Trending');
    }

    if (s === false) {
      this.feed.initializeGuestPage();
    } else {
      this.feed.initializePage();
    }
  }
  @Input() localSubscriptions: Ministry[] = [];
  @Input() subscriptions: Ministry[] = [];
  infiniteScrollContainer = computed(() => {
    return isMobile() ? '.zwi-page-wrapper-mobile' : '.zwi-main';
  });
  isMobile() {
    return isMobile();
  }

  active = signal('Trending');
  feedLoaded = computed(() => {
    if (this.authService.hasSession()) {
      return this.feed.isFeedLoaded();
    } else {
      return this.feed.isTrendingLoaded();
    }
  });

  session: string | null = null;
  sermons: Sermon[] = [];
  trendingSermons: Sermon[] = [];

  localDaily = computed(() => {
    if (this.localSubscriptions.length === 0) {
      return [];
    }
    const l = this.localSubscriptions[0];
    return this.feed.dailySermons().map((s) => ({
      ...s,
      sermons: s.sermons.filter((sermon) => sermon.ministry.id === l.id),
    }));
  });
  tabs = computed(() => {
    let tabs;
    if (this.authService.hasSession()) {
      tabs = ['Daily feed', 'Recently added', 'Local church', 'Trending'];
    } else {
      tabs = ['Trending', 'Daily feed', 'Recently added', 'Local church'];
    }
    return tabs;
  });

  constructor(
    private sermon: SermonService,
    // TODO: Add playing to header
    // public playingSermon: PlayingSermon,
    private subscription: SubscriptionService,
    private router: Router,

    public accountService: AccountService,
    public authService: AuthService,
    public feed: FeedService
  ) {}

  add() {
    if (this.active() === 'Daily feed') {
      if (this.subscriptions.length > 0) {
        this.feed.addDaily();
        if (this.authService.hasSession()) {
          this.feed.getFeed();
        }
      }
    } else if (this.active() === 'Local church') {
      if (this.localSubscriptions.length > 0) {
        this.feed.addDaily();
        if (this.authService.hasSession()) {
          this.feed.getFeed();
        }
      }
    } else if (this.active() === 'Recently added') {
      this.feed.addAdded();
      if (this.authService.hasSession()) {
        this.feed.getAdded();
      }
    } else {
      this.feed.addTrending();
      if (this.authService.hasSession()) {
        this.feed.getTrending();
      } else {
        this.feed.getGuestTrending();
      }
    }
    // this.feed.addAdded();
    //
    // this.feed.addTrending();
    // if (this.authService.hasSession()) {
    //   this.feed.getAdded();
    //   this.feed.getFeed();
    //   this.feed.getTrending();
    // } else {
    //   this.feed.getGuestTrending();
    // }
  }

  allLoaded = computed(() => {
    return (
      this.feed.status() === LoadingState.LOADED &&
      this.feed.trendingStatus() === LoadingState.LOADED &&
      this.subscription.localChurchStatus() === LoadingState.LOADED &&
      this.subscription.subscriptionStatus() === LoadingState.LOADED
    );
  });

  formatDate(date: string): string {
    return formatDay(date);
  }

  favoriteChange(starChange: StarChange) {
    if (starChange.value === 'star') {
      if (starChange.source.sermon !== undefined) {
        this.sermon.favorite(starChange.source.sermon);
      }
    } else {
      if (starChange.source.sermon !== undefined) {
        this.sermon.unfavorite(starChange.source.sermon);
      }
    }
  }

  updateActive(c: MatChipListboxChange) {
    if (c.value) {
      this.active.set(c.value);
    }
  }

  protected readonly indexedDB = indexedDB;
}
