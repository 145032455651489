import { map } from 'rxjs/operators';

import { Component } from '@angular/core';

import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';

import { Ministry } from '@zwiloo/ministry/domain';
import { DashboardService } from '@zwiloo/studio/context/dashboard.service';
import { Subscription } from 'rxjs';
import { Sermon } from '@zwiloo/sermon/domain';
import { AccountService } from '@zwiloo/user/account';
import { MinistryEmbedPickerComponent } from '@zwiloo/frontend/embed/ministry/ministry-embed-picker/ministry-embed-picker.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'zwi-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [DashboardService],
})
export class DashboardComponent {
  ministry: Ministry;
  sermons: Sermon[] = [];
  sermonHistory: Sermon[] = [];
  subscriptionSermons: Subscription;
  subscriptionSermonHistory: Subscription;
  /** Based on the screen size, switch from standard to one column per row */
  cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return [
          // { title: 'Card 1', cols: 1, rows: 1 },
          { title: 'Recently Uploaded', cols: 1, rows: 1 },
          { title: 'Current Sermon', cols: 1, rows: 1 },
          // { title: 'Card 4', cols: 1, rows: 1 },
        ];
      }

      return [
        // { title: 'Card 1', cols: 2, rows: 1 },
        { title: 'Recently Uploaded', cols: 1, rows: 1 },
        { title: 'Current Sermon', cols: 1, rows: 1 },
        // { title: 'Card 3', cols: 1, rows: 2 },
        // { title: 'Card 4', cols: 1, rows: 1 },
      ];
    })
  );

  constructor(
    public dialog: MatDialog,
    private accountService: AccountService,
    private breakpointObserver: BreakpointObserver,
    private dashboard: DashboardService
  ) {
    this.ministry = accountService.getMinistry();
    this.subscriptionSermons = dashboard.sermons.subscribe((s) => {
      this.sermons = s.slice(0, 5);
    });
    this.subscriptionSermonHistory = dashboard.sermonHistory.subscribe((s) => {
      this.sermonHistory = s.slice(0, 1);
    });
  }

  ngOnInit() {
    this.dashboard.initializePage();
  }

  ngOnDestroy() {
    this.subscriptionSermons.unsubscribe();
    this.subscriptionSermonHistory.unsubscribe();
  }

  openEmbedPicker() {
    const dialogRef = this.dialog.open(MinistryEmbedPickerComponent, {
      data: { ministryID: this.ministry.id },
      minWidth: '700px',
    });
  }
}
