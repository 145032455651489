import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { SharedModule } from '@zwiloo/frontend/shared/shared.module';
import { EnterNewPasswordComponent } from './enter-new-password/enter-new-password.component';
import { EmailSentComponent } from './email-sent/email-sent.component';
import { TypographyModule } from '@zwiloo/frontend/typography/typography.module';

@NgModule({
  declarations: [EnterNewPasswordComponent, EmailSentComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    TypographyModule,
  ],
})
export class PasswordResetModule {}
