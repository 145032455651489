<form [formGroup]="form" class="zwi-vertical-form">
<!--  <div class="md-padding">-->
<!--    <div fxLayout="row" fxLayoutAlign="space-between start">-->
<!--      <p class="md-body-2 title">Upload status:</p>-->
<!--      <div style="margin-bottom: 10px;">-->
<!--        <button mat-raised-button color="primary" *ngIf="showSave()" class="mdl-button mdl-js-button mdl-button&#45;&#45;raised mdl-button&#45;&#45;colored pull-right"-->
<!--                (click)="save()"-->
<!--                [disabled]="!form.valid || isSaved()">Save</button>-->
<!--        <button mat-raised-button color="primary" *ngIf="showSaveChanges()"-->
<!--                (click)="save()"-->
<!--                [disabled]="!form.valid">Save changes-->
<!--        </button>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div fxLayout="row" fxLayoutAlign="space-between start">-->
<!--      <div class="mat-body-1">{{ getFileUploadStatusMessage() }}</div>-->
<!--      <div class="md-body-1">{{ getSaveStatusMessage() }}</div>-->
<!--    </div>-->
<!--  </div>-->
  <div>
    <mat-form-field appearance="fill" class="zwi-vertical-form__md-field">
      <mat-label>Title</mat-label>
      <input formControlName="title" matInput>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="fill" class="zwi-vertical-form__full-field">
      <mat-label>Description</mat-label>
      <textarea formControlName="description" matInput></textarea>
    </mat-form-field>
  </div>
  <div formArrayName="references">
    <div *ngFor="let r of references.controls; let i=index" class="zwi-flex-start zwi-block-section--horizontal">
      <mat-form-field appearance="fill">
        <mat-label>Select a reference</mat-label>
        <input [formControlName]="i" matInput [zwiReferencePicker]="picker">
        <zwi-reference-picker-toggle matSuffix [for]="picker"></zwi-reference-picker-toggle>
        <zwi-reference-picker #picker></zwi-reference-picker>
      </mat-form-field>
      <button mat-icon-button type="button" (click)="removeReference(i)" style="margin-top: 4px;">
        <mat-icon>cancel</mat-icon>
      </button>
    </div>
  </div>
  <zwi-widget [top]="true">
    <button mat-button type="button" (click)="addReference()">Add reference</button>
  </zwi-widget>
  Series
  @if (uploadService.speakerLoadStatus() === 2 && uploadService.seriesLoadStatus() === 2) {
    <div>
      @if (series !== undefined && series.length === 0) {
        <zwi-widget [top]="true">
          <button mat-button routerLink="/studio/series">Add series</button>
        </zwi-widget>
      } @else {
        <mat-form-field appearance="fill">
          <mat-label>Series</mat-label>
          <mat-select formControlName="series">
            <mat-option *ngFor="let s of series" [value]="s.id">
              {{ s.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      }
    </div>
    <div>
      <div>Speaker</div>
      @if (speakers !== undefined && speakers.length === 0) {
        <zwi-widget [top]="true">
          <button mat-button routerLink="/studio/speakers">Add speaker</button>
        </zwi-widget>
      } @else {
        <mat-form-field appearance="fill">
          <mat-label>Speaker</mat-label>
          <mat-select formControlName="speaker">
            <mat-option *ngFor="let s of speakers" [value]="s.id">
              {{ s.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      }
    </div>
  }

</form>
