<ng-container *ngIf="sermonEdit === undefined; else sermonForm">
  <div>
    <form [formGroup]="filterForm" class="zwi-flex-start zwi-block-section--horizontal">
      <mat-form-field appearance="fill">
        <mat-label>Book</mat-label>
        <mat-select formControlName="book">
          <mat-option *ngFor="let b of books" [value]="b">{{ b }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Speaker</mat-label>
        <mat-select formControlName="speaker">
          <mat-option *ngFor="let s of speakers" [value]="s.id">{{s.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Series</mat-label>
        <mat-select formControlName="series">
          <mat-option *ngFor="let s of series" [value]="s.id">{{s.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <button *ngIf="hasFilters()" (click)="removeFilters()" mat-button color="primary" class="zwi-button--horizontal">Clear Filters</button>
    </form>
  </div>
  <mat-list>
    <zwi-sermon-editor *ngFor="let s of filteredSermons" [sermon]="s" (sermonDelete)="deleteSermon($event)" (sermonEdit)="editSermon($event)"></zwi-sermon-editor>
  </mat-list>
</ng-container>
<ng-template #sermonForm>
  <div class="zwi-section">
    <div class="zwi-flex-center">
      <button (click)="clearSermon()" mat-icon-button>
        <mat-icon>arrow_back</mat-icon>
      </button>
      <zwi-headline>Sermon form</zwi-headline>
    </div>
    <div class="zwi-flex-start">
      <div class="zwi-spacer"></div>
      <button mat-raised-button color="primary" (click)="save()" [disabled]="!isFormValid()">Save changes
      </button>
    </div>
    <zwi-upload-form [series]="series" [speakers]="speakers" [sermonInfo]="sermonEdit?.sermonInfo"></zwi-upload-form>
  </div>
</ng-template>

<!--<zwi-sermon-list [sermons]="sermons"></zwi-sermon-list>-->
