import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

import { BookTestament } from '@zwiloo/verse/domain/books-testaments';
import { BookSelectedService } from '../book-menu.component';

@Component({
  selector: 'zwi-mobile-books',
  templateUrl: './mobile-books.component.html',
  styleUrls: ['./mobile-books.component.scss'],
})
export class MobileBooksComponent {
  oldTestament: BookTestament[] = [];
  newTestament: BookTestament[] = [];
  bookSelectedService: BookSelectedService | undefined;

  @Output() bookSelected = new EventEmitter<BookTestament>();

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: {
      oldTestament: BookTestament[];
      newTestament: BookTestament[];
      bookSelectedService: BookSelectedService;
    }
  ) {
    this.oldTestament = data.oldTestament;
    this.newTestament = data.newTestament;
    this.bookSelectedService = data.bookSelectedService;
  }

  selectBook(book: BookTestament) {
    if (this.bookSelectedService) {
      this.bookSelectedService.book.set(book);
    }
  }
}
