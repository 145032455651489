import { Sermon } from '@zwiloo/sermon/domain';

export class Series {
  constructor(public id = 0, public name = '', public imageNum = 0) {}
}

export class SeriesInfo {
  constructor(public name = '', public sermonNum = 0) {}
}

export function seriesInfo(sermons: Sermon[]): SeriesInfo[] {
  let info: SeriesInfo[] = [];
  sermons.forEach((s) => {
    const series = info.find((i) => i.name === s.series.name);
    if (series) {
      series.sermonNum = ++series.sermonNum;
    } else {
      info = [...info, new SeriesInfo(s.series.name, 1)];
    }
  });
  return info;
}
