<zwi-widget>
  <div class="zwi-flex zwi-flex-center">
    <zwi-search-bar [value]="criteria" (focus)="setMode('search')" (search)="search($event)" (valueChange)="setCriteria($event)" class="zwi-flex-1"></zwi-search-bar>
    <button *ngIf="mode === 'search'" mat-button color="primary" (click)="cancelSearch()" class="zwi-ml-md">Cancel</button>
  </div>
</zwi-widget>
<zwi-widget>
  <mat-chip-listbox [hideSingleSelectionIndicator]="true" [multiple]="false" (change)="updateActive($event)" [required]="true" aria-label="Ministry seremons selection">
    <mat-chip-option [highlighted]="active === 'Ministries'">Ministries</mat-chip-option>
    <mat-chip-option [highlighted]="active === 'Local Church'">Local Church</mat-chip-option>
  </mat-chip-listbox>
</zwi-widget>
@if (subscription.subscriptionStatus() === 2) {
  @if (mode === 'subscriptions') {
    @if (active === 'Ministries') {
      @if (subscriptions.length > 0) {
        <zwi-ministry-list [ministries]="subscriptions" (subscribeChange)="changeSubscription($event)"></zwi-ministry-list>
      } @else {
        <zwi-call-to-action>
          <ng-container title>You are not following any ministries.</ng-container>
          <ng-container content>Search for ministries to follow.</ng-container>
        </zwi-call-to-action>
      }
    } @else {
      @if (localChurch.length > 0) {
        <zwi-ministry-list [ministries]="localChurch" (subscribeChange)="changeLocalChurch($event)"></zwi-ministry-list>
      } @else {
        <zwi-call-to-action>
          <ng-container title>You are not following your local church.</ng-container>
          <ng-container content>Search for your local church to follow.</ng-container>
        </zwi-call-to-action>
      }
    }
  } @else {
    @if  (searchLoaded()) {
      @if (ministries.length > 0) {
        @if (active === 'Ministries') {
          <zwi-ministry-list [ministries]="ministries" (subscribeChange)="changeSubscription($event)"></zwi-ministry-list>
        } @else {
          <zwi-ministry-list [ministries]="ministries" (subscribeChange)="changeLocalChurch($event)"></zwi-ministry-list>
        }
      } @else {
        <zwi-widget>No results containing your search terms were found.</zwi-widget>
      }
    } @else {
      @if (active === 'Ministries') {
        <zwi-call-to-action>
          <ng-container title>Discover ministries</ng-container>
          <ng-container content>Search for ministries and follow.</ng-container>
        </zwi-call-to-action>
      } @else {
        <zwi-call-to-action>
          <ng-container title>Find your local chuch</ng-container>
          <ng-container content>Search for your local church and follow.</ng-container>
        </zwi-call-to-action>
      }
    }
  }
}

<!--  <ng-container *ngIf="mode === 'subscriptions'; else searchSection">-->
<!--    <ng-container *ngIf="active === 'Ministries'; else church">-->
<!--      <zwi-ministry-list *ngIf="subscriptions.length > 0; else noMinistries" [ministries]="subscriptions" (subscribeChange)="changeSubscription($event)"></zwi-ministry-list>-->
<!--      <ng-template #noMinistries>-->
<!--        <zwi-call-to-action>-->
<!--          <ng-container title>You are not following any ministries.</ng-container>-->
<!--          <ng-container content>Search for ministries to follow.</ng-container>-->
<!--        </zwi-call-to-action>-->
<!--      </ng-template>-->
<!--    </ng-container>-->

<!--    <ng-template #church>-->
<!--      <zwi-ministry-list *ngIf="localChurch.length > 0; else noLocalChurch" [ministries]="localChurch" (subscribeChange)="changeLocalChurch($event)"></zwi-ministry-list>-->
<!--      <ng-template #noLocalChurch>-->
<!--        <zwi-call-to-action>-->
<!--          <ng-container title>You are not following your local church.</ng-container>-->
<!--          <ng-container content>Search for your local church to follow.</ng-container>-->
<!--        </zwi-call-to-action>-->
<!--      </ng-template>-->
<!--    </ng-template>-->
<!--  </ng-container>-->
<!--  <ng-template #searchSection>-->
<!--    <ng-container *ngIf="searchLoaded(); else startMessage">-->
<!--      <ng-container *ngIf="ministries.length > 0; else noResultsMessage">-->
<!--        <zwi-ministry-list *ngIf="active === 'Ministries'; else church" [ministries]="ministries" (subscribeChange)="changeSubscription($event)"></zwi-ministry-list>-->
<!--        <ng-template #church>-->
<!--          <zwi-ministry-list [ministries]="ministries" (subscribeChange)="changeLocalChurch($event)"></zwi-ministry-list>-->
<!--        </ng-template>-->
<!--      </ng-container>-->
<!--      <ng-template #noResultsMessage>-->
<!--        <zwi-widget>No results containing your search terms were found.</zwi-widget>-->
<!--      </ng-template>-->
<!--    </ng-container>-->
<!--    <ng-template #startMessage>-->
<!--      <zwi-call-to-action *ngIf="active === 'Ministries'; else church">-->
<!--        <ng-container title>Discover ministries</ng-container>-->
<!--        <ng-container content>Search for ministries and follow.</ng-container>-->
<!--      </zwi-call-to-action>-->
<!--      <ng-template #church>-->
<!--        <zwi-call-to-action>-->
<!--          <ng-container title>Find your local chuch</ng-container>-->
<!--          <ng-container content>Search for your local church and follow.</ng-container>-->
<!--        </zwi-call-to-action>-->
<!--      </ng-template>-->
<!--    </ng-template>-->
<!--  </ng-template>-->

<!--<ng-container *ngIf="active === 'Ministries'">-->
<!--  <ng-container *ngIf="searchLoaded(); else subscriptionsList">-->
<!--    <zwi-ministry-list *ngIf="ministries.length > 0; else blankMessage" [ministries]="ministries" (subscribeChange)="changeSubscription($event)"></zwi-ministry-list>-->
<!--    <ng-template #blankMessage>-->
<!--      <zwi-widget>No results containing your search terms were found.</zwi-widget>-->
<!--    </ng-template>-->
<!--  </ng-container>-->
<!--  <ng-template #subscriptionsList>-->
<!--    <zwi-ministry-list [ministries]="subscriptions" (subscribeChange)="changeSubscription($event)"></zwi-ministry-list>-->
<!--  </ng-template>-->
<!--</ng-container>-->
<!--<ng-container *ngIf="active === 'Local Church'">-->
<!--  <zwi-ministry-list *ngIf="localChurch.length > 0; else search" [ministries]="localChurch" (subscribeChange)="changeLocalChurch($event)"></zwi-ministry-list>-->
<!--  <ng-template #search>-->
<!--    <zwi-ministry-list [ministries]="ministries" (subscribeChange)="changeLocalChurch($event)"></zwi-ministry-list>-->
<!--  </ng-template>-->
<!--</ng-container>-->
