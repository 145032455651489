import { Injectable, signal, WritableSignal } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { CallState, ErrorState, LoadingState } from '@zwiloo/util/state';

@Injectable({
  providedIn: 'root',
})
export class PreviousFeedService {
  status: WritableSignal<CallState> = signal(LoadingState.INIT);

  constructor(public http: HttpClient) {}

  postPreviousFeedURL(url: string) {
    const body = { url };
    this.status.set(LoadingState.LOADING);
    return this.http
      .post('/api/ministries/0/previous-feed-url', body)
      .subscribe({
        next: () => {
          this.status.set(LoadingState.LOADED);
        },
        error: () => {
          this.status.set(new ErrorState('Failed to save previous feed URL'));
        },
      });
  }
}
