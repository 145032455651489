import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';

import { SharedModule } from '@zwiloo/frontend/shared/shared.module';
import { IndexComponent } from './index.component';
// import { IndexRoutingModule } from './index-routing.module';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [IndexComponent],
  imports: [
    CommonModule,
    // IndexRoutingModule,
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    MatMenuModule,
  ],
})
export class IndexModule {}
