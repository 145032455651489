<form [formGroup]="form" (ngSubmit)="submit()" class="form-lg">
    <div class="zwi-form-group">
        <mat-form-field [style.width]="'100%'">
          <mat-label>Email address</mat-label>
          <input autocapitalize="none" matInput formControlName="email">
          <mat-error>
              <ng-container *ngIf="form.get('email')?.errors?.required">
                  {{ formError.getErrorMessage('email', 'required') }}</ng-container>
              <ng-container *ngIf="form.get('email')?.errors?.email">
                  {{ formError.getErrorMessage('email', 'email') }}</ng-container>
              <ng-container *ngIf="form.get('email')?.errors?.emailTaken">
                  {{ formError.getErrorMessage('email', 'emailTaken') }}</ng-container>
          </mat-error>
        </mat-form-field>
    </div>
    <div formGroupName="password">
        <div class="zwi-form-group">
            <mat-form-field [style.width]="'100%'">
              <mat-label>Password</mat-label>
                <input matInput formControlName="password1" type="password" placeholder="Password">
                <mat-error>
                    <ng-container *ngIf="form.get('password.password1')?.errors?.required">
                        {{ formError.getErrorMessage('password1', 'required') }}</ng-container>
                    <ng-container *ngIf="form.get('password.password1')?.errors?.minlength">
                        {{ formError.getErrorMessage('password1', 'minlength') }}</ng-container>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="zwi-form-group">
            <mat-form-field [style.width]="'100%'">
              <mat-label>Retype password</mat-label>
                <input matInput formControlName="password2" type="password"
                       placeholder="Confirm your password">
                <mat-hint>Retype password above</mat-hint>
                <mat-error>
                    <ng-container *ngIf="form.get('password.password2')?.errors?.required">
                        {{ formError.getErrorMessage('password2', 'required') }}</ng-container>
                    <ng-container *ngIf="form.get('password.password2')?.errors?.passwordMatch">
                        {{ formError.getErrorMessage('password2', 'passwordMatch') }}</ng-container>
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="layout-row layout-align-end-center">
        <ng-content></ng-content>
    </div>
</form>
