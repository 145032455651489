import { Observable, Subscription } from 'rxjs';

import { Component, Input, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { toObservable } from '@angular/core/rxjs-interop';

import { MatSnackBar } from '@angular/material/snack-bar';

import { Series } from '@zwiloo/ministry/domain';
import { Sermon } from '@zwiloo/sermon/domain';
import { StudioSeriesService } from '@zwiloo/studio/context/studio-series.service';
import { BookTestament } from '@zwiloo/verse/domain/books-testaments';
import { CallState } from '@zwiloo/util/state';

@Component({
  selector: 'zwi-edit-series',
  templateUrl: './edit-series.component.html',
  styleUrls: ['./edit-series.component.scss'],
})
export class EditSeriesComponent implements OnDestroy {
  @Input() seriesOptions: Series[] = [];
  series = new UntypedFormControl();
  seriesSermons: Sermon[] = [];
  sermons: Sermon[] = [];
  sortedSermons: Sermon[] = [];
  sermonSubscription: Subscription;
  sermonOrderStatus$: Observable<CallState | null> | undefined;

  constructor(
    private studioSeries: StudioSeriesService,
    private _snackBar: MatSnackBar
  ) {
    this.sermonSubscription = studioSeries.sermons.subscribe((s) => {
      this.sermons = s;
    });
    this.sermonOrderStatus$ = toObservable(studioSeries.sermonOrderStatus);
    this.sermonOrderStatus$.subscribe((s) => {
      if (s === 2) {
        this._snackBar.open('Series order updated', undefined, {
          duration: 5000,
        });
      }
    });
  }

  ngOnInit() {
    this.series.valueChanges.subscribe((selectedSeries) => {
      this.seriesSermons = this.sermons.filter(
        (s) => s.series.id === selectedSeries
      );
    });
  }

  ngOnDestroy(): void {
    this.sermonSubscription.unsubscribe();
  }

  setSortedSermons(s: Sermon[]) {
    this.sortedSermons = s.map((s, i) => ({ ...s, orderInSeries: i + 1 }));
  }

  save() {
    this.studioSeries.updateSermonOrder(this.sortedSermons);
  }
}
