import { Component, HostBinding, input } from '@angular/core';

import { isMobile } from '@zwiloo/util/tokens';

@Component({
  selector: 'zwi-main-content',
  templateUrl: './main-content.component.html',
  styleUrl: './main-content.component.scss',
})
export class MainContentComponent {
  spanMobile = input(false);

  @HostBinding('class.zwi-span') isMobile: boolean = false;

  ngOnInit() {
    if (this.spanMobile() && isMobile()) {
      this.isMobile = true;
    }
  }
}
