<div class="grid-container">
  <div class="grid-title">
    <div class="zwi-flex-center zwi-block-section--horizontal">
      <h1 class="mat-h1" style="margin-bottom: 0">{{ ministry.name }}</h1>
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>more_horizontal</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button (click)="openEmbedPicker()" mat-menu-item>Embed ministry</button>
      </mat-menu>
    </div>

  </div>
  <div style="flex: 1;">
    <div class="mat-h2">Recently Uploaded</div>
    <zwi-sermon-list [sermons]="sermons" [sortBy]="'createdAt'" [bookmarkable]="false"></zwi-sermon-list>
  </div>
  <div style="flex: 1;">
    <div class="mat-h2">Current Sermon</div>
    <zwi-sermon-list [sermons]="sermonHistory" [bookmarkable]="false"></zwi-sermon-list>
  </div>

</div>
