import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class PasswordDataService {
  constructor(private http: HttpClient) {}

  updatePassword(p: string) {
    return this.http.put('/api/users/password', { password: p });
  }
}
