import { Component, Input, signal } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';
import { SermonService } from '../../../sermon.service';
import { TimeDisplayComponent } from '@zwiloo/frontend/shared/player/time-display/time-display.component';
import { ReferenceRange } from '@zwiloo/verse/domain/reference';
import { ReferenceFormatter } from '@zwiloo/verse/domain/reference-formatter';
import { ZwiReferenceFormats } from '@zwiloo/verse/domain/reference-formats';
import { Sermon } from '@zwiloo/sermon/domain';

@Component({
  selector: 'zwi-sermon-list-item',
  standalone: true,
  imports: [MatIconModule, TimeDisplayComponent],
  templateUrl: './sermon-list-item.component.html',
  styleUrl: './sermon-list-item.component.scss',
})
export class SermonListItemComponent {
  @Input() active = false;
  @Input() order: number | null = null;
  @Input() sermon = new Sermon();
  hover = signal(false);

  constructor(public sermonService: SermonService) {}

  getReferences(referenceRanges?: ReferenceRange[]) {
    if (referenceRanges !== undefined) {
      const formatter = new ReferenceFormatter();
      return referenceRanges
        .map((range) =>
          formatter.formatRange(
            range,
            ZwiReferenceFormats.display.referenceInput
          )
        )
        .join(', ');
    } else {
      return '';
    }
  }

  hasReferences(s: Sermon) {
    return s.references && s.references.length > 0;
  }

  toggleStatus() {
    if (this.active && this.sermonService.status() === 'PLAYING') {
      this.sermonService.status.set('PAUSED');
    }
    this.sermonService.status.set('PLAYING');
  }
}
