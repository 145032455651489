import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { ResetPasswordService, VerifyResponse } from '@zwiloo/user';
import { CallState, LoadingState } from '@zwiloo/util/state';
import { Subscription } from 'rxjs';

@Component({
  selector: 'zwi-enter-new-password',
  templateUrl: './enter-new-password.component.html',
  styleUrls: ['./enter-new-password.component.scss'],
  providers: [ResetPasswordService],
})
export class EnterNewPasswordComponent implements OnInit {
  resetResult: VerifyResponse = { status: '' };
  resetStatus: CallState = LoadingState.INIT;
  resetStatusSubscription: Subscription;
  resetResultSubscription: Subscription;
  verifyResult: VerifyResponse = { status: '' };
  verifyStatus: CallState = LoadingState.INIT;
  verifyStatusSubscription: Subscription;
  verifyResultSubscription: Subscription;

  form = this.fb.group({
    password: ['', Validators.required],
  });

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private resetPassword: ResetPasswordService
  ) {
    this.resetResultSubscription = resetPassword.resetResult.subscribe((r) => {
      this.resetResult = r;
    });
    this.resetStatusSubscription = resetPassword.resetStatus.subscribe((s) => {
      this.resetStatus = s;
    });
    this.verifyResultSubscription = resetPassword.verifyResult.subscribe(
      (r) => {
        this.verifyResult = r;
      }
    );
    this.verifyStatusSubscription = resetPassword.verifyStatus.subscribe(
      (s) => {
        this.verifyStatus = s;
      }
    );
  }

  ngOnInit(): void {
    const userID = this.route.snapshot.queryParamMap.get('u');
    const token = this.route.snapshot.queryParamMap.get('t');
    if (userID !== null && token !== null) {
      this.resetPassword.verifyResetToken(Number(userID), token);
    }
  }

  submit() {
    const password = this.form.get('password')?.value;
    const userID = this.route.snapshot.queryParamMap.get('u');
    const token = this.route.snapshot.queryParamMap.get('t');
    if (password !== null && userID !== null && token !== null) {
      this.resetPassword.resetPassword(Number(userID), token, password);

      // this.accountService.verifyEmail(Number(userID), token).subscribe(() => {
      //   this.router.navigateByUrl('/index');
      // });
    }
    // this.accountService.signUp(account).subscribe((account) => {
    //   this.router.navigateByUrl(`/email-verification-sent?e=${account.email}`);
    // });
  }
}
