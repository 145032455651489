<div class="zwi-flex-center zwi-full-width">
  <zwi-headline>{{ greeting() }}</zwi-headline>
  <ng-container *ngIf="isMobile()">
    <ng-container *ngIf="hasSession; else account">
      <div class="zwi-spacer"></div>
      <mat-icon routerLink="/mobile-settings">settings</mat-icon>
    </ng-container>
    <ng-template #account>
      <div class="zwi-spacer"></div>
      <button mat-icon-button [matMenuTriggerFor]="accountMenu">
        <mat-icon>account_circle</mat-icon>
      </button>
<!--      <button mat-button [mat-menu-trigger-for]="accountMenu">-->
<!--        {{ firstName }}-->
<!--      </button>-->
      <mat-menu x-position="before" #accountMenu="matMenu">
        <!--                        <a *ngIf="isMinistry()" mat-menu-item routerLink="/studio/dashboard/dashboard">-->
        <span mat-menu-item (click)="openSignIn()">
          <span class="zwi-bold">Sign in</span>
        </span>
        <!--                        <a *ngIf="!isMinistry()" mat-menu-item routerLink="/ministry-convert">-->
        <a mat-menu-item routerLink="/sign-up">
          <span>Sign up</span>
        </a>
      </mat-menu>
    </ng-template>
  </ng-container>
</div>
<zwi-sign-in-dialog [open]="open" (closed)="close()"></zwi-sign-in-dialog>
