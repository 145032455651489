import { Component, Input, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';

import { FormsModule } from '@angular/forms';

@Component({
  selector: 'zwi-embed-picker-iframe',
  standalone: true,
  imports: [FormsModule, MatButtonModule, MatCheckboxModule, MatInputModule],
  templateUrl: './embed-picker-iframe.component.html',
  styleUrl: './embed-picker-iframe.component.scss',
})
export class EmbedPickerIframeComponent {
  base = `<iframe style="border-radius: 12px" src="${window.location.origin}/embed`;
  @Input() category = '';
  @Input() height = 0;
  @Input() id = 0;
  @Input() width = '';

  showCode = signal(false);
  get iframeURL() {
    let width = this.width;
    if (width.includes('px')) {
      width = width.slice(0, -2);
    }
    return `${this.base}/${this.category}/${this.id}" frameborder="0" height="${this.height}" width="${width}"></iframe>`;
  }

  copy() {
    window.navigator.clipboard.writeText(this.iframeURL);
  }

  updateShowCode(checked: boolean) {
    this.showCode.set(checked);
  }
}
