<div class="zwi-ministry-embed-container">
  <div class="zwi-ministry-embed-sermon-container">
    <zwi-ministry-embed-cover-art [backgroundImage]="getMinistry.ministry().profileImage"></zwi-ministry-embed-cover-art>
    <zwi-embed-logo></zwi-embed-logo>
    <zwi-embed-metadata [isMinistry]="true" [sermon]="sermon()" [title]="getMinistry.ministry().name"></zwi-embed-metadata>
    <zwi-embed-player [autoPlay]="true"></zwi-embed-player>
  </div>
  <div class="zwi-embed-background-container">
    <div class="zwi-embed-opacity-container">
      <zwi-embed-sermon-list [sermons]="sermons"></zwi-embed-sermon-list>


<!--      <div class="zwi-sermon-list-wrapper">-->
<!--        <zwi-embed-book-menu></zwi-embed-book-menu>-->
<!--        <zwi-sermon-list [sermons]="sermons"></zwi-sermon-list>-->
<!--      </div>-->
    </div>
  </div>
</div>
