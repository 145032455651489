import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';

import { SharedModule } from '@zwiloo/frontend/shared/shared.module';
import { TypographyModule } from '@zwiloo/frontend/typography/typography.module';
import { MinistryComponent } from './ministry.component';
import { MinistrySermonsComponent } from './ministry-sermons/ministry-sermons.component';

@NgModule({
  declarations: [MinistryComponent, MinistrySermonsComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatChipsModule,
    MatTabsModule,
    SharedModule,
    TypographyModule,
  ],
  exports: [MinistrySermonsComponent],
})
export class MinistryModule {}
