<!--<div class="zwi-sidenav">-->
<!--  <div class="zwi-sidenav__side">-->
<!--    <div class="zwi-side-menu">-->
<!--      <a routerLink="/studio/dashboard" routerLinkActive="zwi-side-menu__item&#45;&#45;active" class="zwi-side-menu__item">-->
<!--        <mat-icon class="zwi-side-menu__icon">dashboard</mat-icon>-->
<!--        <span>Dashboard</span>-->
<!--      </a>-->
<!--      <a routerLink="/studio/sermons" routerLinkActive="zwi-side-menu__item&#45;&#45;active" class="zwi-side-menu__item">-->
<!--        <mat-icon class="zwi-side-menu__icon">play_circle_outline</mat-icon>-->
<!--        <span>Sermons</span>-->
<!--      </a>-->
<!--      <a routerLink="/studio/speakers" routerLinkActive="zwi-side-menu__item&#45;&#45;active" class="zwi-side-menu__item">-->
<!--        <mat-icon class="zwi-side-menu__icon">person</mat-icon>-->
<!--        <span>Speakers</span>-->
<!--      </a>-->
<!--      <a routerLink="/studio/series" routerLinkActive="zwi-side-menu__item&#45;&#45;active" class="zwi-side-menu__item">-->
<!--        <mat-icon class="zwi-side-menu__icon">list</mat-icon>-->
<!--        <span>Series</span>-->
<!--      </a>-->
<!--      <a routerLink="/studio/upload" routerLinkActive="zwi-side-menu__item&#45;&#45;active" class="zwi-side-menu__item">-->
<!--        <mat-icon class="zwi-side-menu__icon">file_upload</mat-icon>-->
<!--        <span>Upload</span>-->
<!--      </a>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="zwi-side-menu__main">-->
<!--    <router-outlet></router-outlet>-->
<!--  </div>-->
<!--</div>-->

<div class="zwi-main__side">
  <zwi-nav-menu [menuItems]="menuItems"></zwi-nav-menu>
</div>
<div class="zwi-main__content">
  <div class="zwi-content__wrapper">
    <router-outlet></router-outlet>
  </div>
</div>
