<div class="zwi-sermon">
  <div class="zwi-sermon__main">
    <div class="zwi-sermon__content">
      <img width="30px" [src]="sermon.ministry.profileImage" [alt]="sermon.ministry.name" class="zwi-sermon__avatar">
      <div class="zwi-sermon__label">
        <div class="zwi-sermon__line zwi-sermon__overline">
          <a [routerLink]="['/' + sermon?.ministry?.username]" (click)="goToMinistry($event)" class="zwi-sermon__line">{{ sermon?.ministry?.name }}</a>
          @if (sermon?.speaker?.name && sermon.speaker.name !== 'Anonymous') { <!-- This is a conditional statement -->
            <span class="zwi-list-delimeter">·</span>
            <span>{{ sermon?.speaker?.name }}</span>
          }
        </div>
        <div class="zwi-sermon__title">{{ sermon?.name }}</div>
        <div *ngIf="hasReferences()" class="zwi-sermon__line  zwi-sermon__caption">{{ getReferences(sermon?.references) }}</div>
      </div>
      <div class="zwi-spacer"></div>
      <ng-container *ngIf="bookmarkable">
        <button *ngIf="sermon?.favorite; else unfavorite" (click)="unstar($event)" mat-icon-button>
          <mat-icon>bookmark</mat-icon>
        </button>
        <ng-template #unfavorite>
          <button (click)="star($event)" mat-icon-button>
            <mat-icon>bookmark_border</mat-icon>
          </button>
        </ng-template>
      </ng-container>
    </div>
    <div class="zwi-sermon__play">
      <ng-container *ngIf="playingSermon?.id === sermon.id; else start">
        <mat-icon *ngIf="sermonStatus === 'PAUSED'" color="primary" class="zwi-sermon__play-button" (click)="playSermon()">play_circle</mat-icon>
        <mat-icon *ngIf="sermonStatus === 'PLAYING'" color="primary" class="zwi-sermon__play-button" (click)="pauseSermon()">pause_circle</mat-icon>
      </ng-container>
      <ng-template #start>
        <mat-icon color="primary" class="zwi-sermon__play-button" (click)="startSermon()">play_circle</mat-icon>
      </ng-template>
      <div class="zwi-sermon__duration">{{ getDuration(sermon.duration) }}</div>
<!--      <div class="zwi-sermon__duration">28 min</div>-->
    </div>
  </div>
</div>
<div class="zwi-card">

<!--  <mat-list-item>-->
<!--    <img matListItemAvatar width="30px" [src]="sermon.ministry.profileImage" [alt]="sermon.ministry.name">-->
<!--    <span matListItemTitle>{{ sermon?.name }}</span>-->
<!--    <span matListItemLine>{{ sermon?.speaker?.name }}</span>-->
<!--    <span matListItemLine *ngIf="hasReferences()">{{ getReferences(sermon?.references) }}</span>-->
<!--    <a matListItemLine [routerLink]="['/' + sermon?.ministry?.username]">{{ sermon?.ministry?.name }}</a>-->
<!--  </mat-list-item>-->
    <!--    <img (click)="playSermon(sermon)" matListAvatar [src]="sermon.avatar">-->
<!--    <zwi-play-button [sermon]="sermon" mat-list-icon>play_arrow</zwi-play-button>-->
<!--    <img matListItemAvatar width="30px" [src]="sermon.ministry.profileImage" [alt]="sermon.ministry.name">-->
<!--    <span matListItemTitle>{{ sermon?.name }}</span>-->
<!--    <p matListItemLine>{{ sermon?.speaker?.name }}</p>-->
<!--    <p matListItemLine>{{ getReferences(sermon?.references) }}</p>-->
<!--    <a matListItemLine [routerLink]="['/' + sermon?.ministry?.username]">{{ sermon?.ministry?.name }}</a>-->
<!--    <button *ngIf="sermon?.favorite; else unfavorite" (click)="unstar()" mat-icon-button>-->
<!--      <mat-icon>star</mat-icon>-->
<!--    </button>-->
<!--    <ng-template #unfavorite>-->
<!--      <button (click)="star()" mat-icon-button>-->
<!--        <mat-icon>star_border</mat-icon>-->
<!--      </button>-->
<!--    </ng-template>-->

</div>


