<!--<div class="zwi-flex-center zwi-block-section&#45;&#45;horizontal">-->
<!--  <zwi-book-menu [book]="(selectedBook$ | async)" (bookSelected)="onBookSelected($event)"></zwi-book-menu>-->
<!--  <button *ngIf="(selectedBook$ | async) !== null" (click)="removeSelected()" mat-button color="primary" class="zwi-ml-md">All Books</button>-->
<!--</div>-->
<!--<div *ngFor="let b of bookSermons()">-->
<div class="zwi-verse-sermons-container">
  <div class="zwi-verse-sermons-header">
    <div class="zwi-flex-center zwi-block-section--horizontal">
      <zwi-embed-book-menu [bookChapter]="selectedBookChapter()" (bookChapterSelected)="setBookChapter($event)"></zwi-embed-book-menu>
      <button *ngIf="selectedBookChapter() !== null" (click)="removeSelected()" mat-button color="primary" class="zwi-ml-md">All Books</button>
    </div>
  </div>
  <div class="zwi-verse-sermons-body">
    <zwi-embed-sermon-list-container>
      @if (selectedBookChapter() === null) {
        @for (b of bookSermons(); track b.book) {
            <zwi-sermon-category-row class="zwi-verse-sermons-category">{{ toBook(b.book) }}</zwi-sermon-category-row>
            @for (s of b.sermons; track s.id) {
              <zwi-sermon-list-item [active]="sermonService.sermon().id === s.id" [order]="getOrder(s, $index)" [sermon]="s" (click)="emitSelectedSermon(s)"></zwi-sermon-list-item>
            }
            <ng-template #message>
              <div class="headline--blank">{{ blankMessage(b.book) }}</div>
            </ng-template>
        }
      } @else {
        @for (s of filteredSermons(); track s.id) {
          <zwi-sermon-list-item [active]="sermonService.sermon().id === s.id" [sermon]="s" (click)="emitSelectedSermon(s)"></zwi-sermon-list-item>
        }
      }
    </zwi-embed-sermon-list-container>
  </div>
</div>



