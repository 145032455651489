import { Temporal } from '@js-temporal/polyfill';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';

import { Component, computed } from '@angular/core';
import { Router } from '@angular/router';
import { toObservable } from '@angular/core/rxjs-interop';

import { AuthService } from '@zwiloo/user/auth';

import { FeedService } from '@zwiloo/feed/context/feed.service';
import { Sermon } from '@zwiloo/sermon/domain';
import { StarChange } from '@zwiloo/frontend/shared/sermon/sermon.component';
import { SermonService } from '@zwiloo/sermon/sermon.service';
import { SubscriptionService } from '@zwiloo/subscription/context/subscription.service';
import { Ministry } from '@zwiloo/ministry/domain';
import { CallState, LoadingState } from '@zwiloo/util/state';
import { Capacitor } from '@capacitor/core';
import { MatChipListboxChange } from '@angular/material/chips';
import { AccountService } from '@zwiloo/user/account';
import { isMobile } from '@zwiloo/util/tokens';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [FeedService, SermonService, SubscriptionService],
})
export class HomeComponent {
  // daily: { airDate: string, sermons: [daily: string]: Sermon[] } = {};

  localSubscriptions: Ministry[] = [];
  subscriptions: Ministry[] = [];
  subscriptionsSubscription: Subscription;
  localChurchSubscription: Subscription;
  hasSession$: Observable<boolean>;

  // feedStatus: CallState = LoadingState.INIT;
  // localChurchStatusSubscription: Subscription;
  // localChurchStatus: CallState = LoadingState.INIT;

  // subscriptionStatusSubscription: Subscription;
  // subscriptionStatus: CallState = LoadingState.INIT;
  selected = 'stream';

  isMobile = computed(() => {
    return isMobile();
  });
  showDaily = computed(() => {
    return (
      this.authService.sessionStatus() === 2 &&
      (this.authService.hasSession() == false || this.subscriptionsLoaded())
    );
  });

  subscriptionsLoaded = computed(() => {
    return (
      this.subscription.localChurchStatus() === LoadingState.LOADED &&
      this.subscription.subscriptionStatus() === LoadingState.LOADED
    );
  });

  constructor(
    private feed: FeedService,
    private sermon: SermonService,
    // TODO: Add playing to header
    // public playingSermon: PlayingSermon,
    private subscription: SubscriptionService,
    private router: Router,
    public accountService: AccountService,
    public authService: AuthService
  ) {
    this.subscriptionsSubscription = toObservable(
      subscription.subscriptions
    ).subscribe((s) => {
      this.subscriptions.push(...s);
    });
    this.localChurchSubscription = toObservable(
      subscription.localChurch
    ).subscribe((s) => {
      this.localSubscriptions = s;
      this.subscriptions.push(...s);
    });
    this.hasSession$ = toObservable(authService.hasSession);

    // this.feedStatusSubscription = feed.status.subscribe((s) => {
    //   this.feedStatus = s;
    // });

    // this.localChurchStatusSubscription =
    //   subscription.localChurchStatus.subscribe((s) => {
    //     this.localChurchStatus = s;
    //   });
    // this.subscriptionStatusSubscription =
    //   subscription.subscriptionStatus.subscribe((s) => {
    //     this.subscriptionStatus = s;
    //   });
  }

  ngOnInit() {
    this.hasSession$.subscribe((s: boolean) => {
      if (s) {
        this.subscription.loadSubscriptions();
      }
    });
  }

  // feedLoaded() {
  //   return this.feedStatus === LoadingState.LOADED;
  // }

  // subscriptionsLoaded() {
  //   return (
  //     this.localChurchStatus === LoadingState.LOADED &&
  //     this.subscriptionStatus === LoadingState.LOADED
  //   );
  // }
}
