import { Temporal } from '@js-temporal/polyfill';

export function formatDay(date: string): string {
  const today = Temporal.Now.plainDateISO();
  const yesterday = today.subtract({ days: 1 });
  const d = Temporal.Instant.from(date).toZonedDateTimeISO('UTC').toPlainDate();
  if (d.toString() === today.toString()) {
    return 'Today';
  } else if (d.toString() === yesterday.toString()) {
    return 'Yesterday';
  }
  return d.toLocaleString('en', {
    month: 'long',
    day: 'numeric',
  });
}

export function formatHourAgo(date: string): string {
  const now = Temporal.Now.instant();
  const addedDate = Temporal.Instant.from(date);
  const duration = now.since(addedDate);
  const hours = Math.floor(duration.total('hours'));
  return hours === 1 ? '1 hour ago' : `${hours} hours ago`;
}
