import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Series } from '@zwiloo/ministry/domain';

@Injectable({
  providedIn: 'root',
})
export class SeriesDataService {
  constructor(private http: HttpClient) {}

  addSeries(name: string, ministryID: number) {
    return this.http.post<{ series: Series }>(
      `/api/ministries/${ministryID}/series`,
      {
        name: name,
      }
    );
  }

  getSeries(id: number) {
    return this.http.get<Series[]>(`/api/ministries/${id}/series`);
  }

  setCurrentSeries(ministryID: number, seriesID: number) {
    return this.http.post<{ seriesID: number }>(
      `/api/ministries/${ministryID}/current-series`,
      {
        seriesID,
      }
    );
  }
}
