import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'zwi-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {
  @Input() url = '';

  constructor() {}

  ngOnInit() {}
}
