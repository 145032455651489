import { Component, Input } from '@angular/core';
import { SeriesInfo } from '@zwiloo/ministry/domain/series';

@Component({
  selector: 'zwi-series-info-card',
  templateUrl: './series-info-card.component.html',
  styleUrls: ['./series-info-card.component.scss'],
})
export class SeriesInfoCardComponent {
  @Input() seriesInfo = new SeriesInfo();
}
