<zwi-main-wrapper>
  <zwi-main-content>
    <zwi-header [hasSession]="hasSession$ | async"></zwi-header>
  </zwi-main-content>
  @if (showDaily()) {
    <zwi-daily-sermons [hasSession]="authService.hasSession()" [localSubscriptions]="localSubscriptions" [subscriptions]="subscriptions"></zwi-daily-sermons>
  }
</zwi-main-wrapper>



