import * as Sentry from '@sentry/angular';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();

  Sentry.init({
    dsn: 'https://85d92cee7f214f929f6673bf61df07bb@o4504356246454272.ingest.sentry.io/4504356252418048',
    integrations: [Sentry.browserTracingIntegration()],
    tracePropagationTargets: ['localhost', 'https://api.zwiloo.com/api'],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
