import { BehaviorSubject, Subject } from 'rxjs';

import { Injectable } from '@angular/core';

import { CallState, LoadingState } from '@zwiloo/util/state';
import { Speaker } from '../domain/speaker';
import { SpeakerDataService } from '../data-interface/speaker-data.service';
import { AccountService } from '@zwiloo/user/account';

@Injectable()
export class SpeakerService {
  public speakers = new BehaviorSubject<Speaker[]>([]);
  public match = new Subject<boolean>();
  public status = new BehaviorSubject<CallState>(LoadingState.INIT);

  constructor(
    private accountService: AccountService,
    private speaker: SpeakerDataService
  ) {}

  addSpeaker(name: string) {
    this.status.next(LoadingState.LOADING);
    const id = Number.MAX_SAFE_INTEGER;
    const speaker = new Speaker(id, name);
    this.speakers.next([...this.speakers.value, speaker]);
    const m = this.accountService.getMinistry();
    return this.speaker.addSpeaker(name, m.id).subscribe({
      next: (s) => {
        this.status.next(LoadingState.LOADED);
      },
      error: () => {
        const speakers = this.speakers.value.filter((s) => s.id !== id);
        this.speakers.next(speakers);
        this.match.next(true);
      },
    });
  }

  getSpeakers(id: number) {
    this.speaker.getSpeakers(id).subscribe((s) => {
      this.speakers.next(s);
    });
  }
}
