import { Injectable, signal } from '@angular/core';
import { ProfileImageDataService } from '../data-interface/profile-image-data.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { Sermon } from '@zwiloo/sermon/domain';
import { CallState, LoadingState } from '@zwiloo/util/state';
import { AccountService } from '@zwiloo/user/account';
import { UserDataService } from '@zwiloo/user/data-interface/user-data.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileImageService {
  public profileImageUrl = signal('');
  public loadingStatus = new BehaviorSubject<CallState>(LoadingState.INIT);

  constructor(
    private account: AccountService,
    private profileImageData: ProfileImageDataService,
    private userData: UserDataService
  ) {}

  loadProfileImage() {
    this.userData.loadMinistryInfo().subscribe((m) => {
      this.profileImageUrl.set(m.profileImage);
    });
  }

  upload(file: File) {
    this.profileImageData.upload(file).subscribe((p) => {
      this.profileImageUrl.set(p);
      this.account.updateProfileImage(p);
    });
  }
}
