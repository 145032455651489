<zwi-top-spacer *ngIf="isNativePlatform"></zwi-top-spacer>
<div class="zwi-simple-page-body">
  <div class="zwi-simple-page-header">
    <a routerLink="/home" style="line-height: 1;">
      <img src='assets/img/landing-logo.png' alt="Zwiloo" height="35px" width="102px"/>
    </a>
  </div>
  <div class="zwi-page-content">
    <ng-content></ng-content>
  </div>
</div>
