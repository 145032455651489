<!--<div class="zwi-section">-->
<!--  <div>Test</div>-->
<!--</div>-->

<zwi-main-wrapper>
  <zwi-main-content>
    <zwi-widget>
      <zwi-search-bar [value]="criteria" (valueChange)="setCriteria($event)" (search)="onSearch($event)"></zwi-search-bar>
    </zwi-widget>
    <div class="zwi-section">
      <zwi-widget>
        <mat-chip-listbox [hideSingleSelectionIndicator]="true" [multiple]="false" (change)="updateActive($event)" aria-label="Search type selection">
          <mat-chip-option [highlighted]="active === 'Ministry'">Ministry</mat-chip-option>
          <mat-chip-option [highlighted]="active === 'Speaker'">Speaker</mat-chip-option>
          <mat-chip-option [highlighted]="active === 'Verse'">Verse</mat-chip-option>
          <mat-chip-option [highlighted]="active === 'Title'">Title</mat-chip-option>
        </mat-chip-listbox>
      </zwi-widget>
      <zwi-ministry *ngIf="active === 'Ministry'"></zwi-ministry>
      <zwi-sermon-results *ngIf="active === 'Speaker'" ></zwi-sermon-results>
      <zwi-sermon-results *ngIf="active === 'Verse'" ></zwi-sermon-results>
      <zwi-sermon-results *ngIf="active === 'Title'" ></zwi-sermon-results>
    </div>
  </zwi-main-content>
</zwi-main-wrapper>

<!--<div class="zwi-main-content" [class.zwi-mobile-main]="isMobile()">-->
<!--  <zwi-widget>-->
<!--    <zwi-search-bar [value]="criteria" (valueChange)="setCriteria($event)" (search)="onSearch($event)"></zwi-search-bar>-->
<!--  </zwi-widget>-->
<!--  <div class="zwi-section">-->
<!--    <zwi-widget>-->
<!--      <mat-chip-listbox [hideSingleSelectionIndicator]="true" [multiple]="false" (change)="updateActive($event)" aria-label="Search type selection">-->
<!--        <mat-chip-option [highlighted]="active === 'Ministry'">Ministry</mat-chip-option>-->
<!--        <mat-chip-option [highlighted]="active === 'Speaker'">Speaker</mat-chip-option>-->
<!--        <mat-chip-option [highlighted]="active === 'Verse'">Verse</mat-chip-option>-->
<!--        <mat-chip-option [highlighted]="active === 'Title'">Title</mat-chip-option>-->
<!--      </mat-chip-listbox>-->
<!--    </zwi-widget>-->
<!--    <zwi-ministry *ngIf="active === 'Ministry'"></zwi-ministry>-->
<!--    <zwi-sermon-results *ngIf="active === 'Speaker'" ></zwi-sermon-results>-->
<!--    <zwi-sermon-results *ngIf="active === 'Verse'" ></zwi-sermon-results>-->
<!--    <zwi-sermon-results *ngIf="active === 'Title'" ></zwi-sermon-results>-->
<!--  </div>-->
<!--</div>-->

<!--<div class="zwi-main-content">-->
<!--  <zwi-widget>-->
<!--    <zwi-search-bar (search)="onSearch($event)"></zwi-search-bar>-->
<!--  </zwi-widget>-->
<!--  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" (selectedTabChange)="onSelectedTabChange($event)" class="zwi-section">-->
<!--    <mat-tab label="Ministry">-->
<!--      <zwi-ministry></zwi-ministry>-->
<!--    </mat-tab>-->
<!--    <mat-tab label="Speaker">-->
<!--      <zwi-sermon-results></zwi-sermon-results>-->
<!--    </mat-tab>-->
<!--    <mat-tab label="Verse">-->
<!--      <zwi-sermon-results></zwi-sermon-results>-->
<!--    </mat-tab>-->
<!--    <mat-tab label="Title">-->
<!--      <zwi-sermon-results></zwi-sermon-results>-->
<!--    </mat-tab>-->
<!--  </mat-tab-group>-->
<!--</div>-->
