import { Component } from '@angular/core';

@Component({
  selector: 'zwi-sign-in-call-to-action',
  templateUrl: './sign-in-call-to-action.component.html',
  styleUrls: ['./sign-in-call-to-action.component.scss'],
})
export class SignInCallToActionComponent {
  open = false;

  openSignIn() {
    this.open = true;
  }

  close() {
    this.open = false;
  }
}
