import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'zwi-mobile-email',
  templateUrl: './mobile-email.component.html',
  styleUrls: ['./mobile-email.component.scss'],
})
export class MobileEmailComponent {
  constructor(private router: Router) {}
  navigateToSettings() {
    this.router.navigateByUrl('/mobile-settings');
  }
}
