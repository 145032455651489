import { Capacitor } from '@capacitor/core';

import { Component, computed, Input } from '@angular/core';
import { MatChipListboxChange } from '@angular/material/chips';
import { mobileWidth } from '@zwiloo/util/tokens';

@Component({
  selector: 'zwi-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() hasSession: boolean | null = false;
  open = false;

  isMobile = computed(() => {
    return window.innerWidth <= mobileWidth;
  });

  greeting = computed(() => {
    const hour = new Date().getHours();
    let timeOfDay = 'morning';
    if (hour >= 12 && hour < 18) {
      timeOfDay = 'afternoon';
    } else if (hour >= 18) {
      timeOfDay = 'evening';
    }
    return `Good ${timeOfDay}`;
  });

  openSignIn() {
    this.open = true;
  }

  close() {
    this.open = false;
  }
}
