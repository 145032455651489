import { groupBy } from 'lodash';

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

import { Sermon } from '@zwiloo/sermon/domain';
import { EmbedSermonListContainerComponent } from '../embed-sermon-list-container/embed-sermon-list-container.component';
import { SermonListItemComponent } from '../sermon-list/sermon-list-item/sermon-list-item.component';
import { KeyValuePipe, NgForOf } from '@angular/common';
import { SharedModule } from '@zwiloo/frontend/shared/shared.module';
import { SermonService } from '@zwiloo/frontend/embed/sermon.service';

@Component({
  selector: 'zwi-embed-series-sermons',
  standalone: true,
  imports: [
    EmbedSermonListContainerComponent,
    SermonListItemComponent,
    KeyValuePipe,
    NgForOf,
    SharedModule,
  ],
  templateUrl: './embed-series-sermons.component.html',
  styleUrl: './embed-series-sermons.component.scss',
})
export class EmbedSeriesSermonsComponent implements OnChanges {
  @Input() sermons: Sermon[] = [];
  @Output() sermonSelected = new EventEmitter<Sermon>();
  series: { [series: string]: Sermon[] } = {};

  constructor(public sermonService: SermonService) {}

  ngOnChanges(changes: SimpleChanges) {
    const series = groupBy(this.sermons, 'series.name');
    Object.values(series).forEach((s) =>
      s.sort(({ orderInSeries: a }, { orderInSeries: b }) => a - b)
    );
    this.series = series;
  }

  emitSelectedSermon(s: Sermon) {
    this.sermonSelected.emit(s);
  }
}
