import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Favorite } from '@zwiloo/sermon/domain';
import { Ministry } from '@zwiloo/ministry/domain';
import { User } from '../domain';
import { Session } from '@zwiloo/account/domain';

@Injectable({
  providedIn: 'root',
})
export class UserDataService {
  constructor(private http: HttpClient) {}

  getSession() {
    return this.http.get<Session>(`/api/users/me/session`);
  }

  getUser(email: string) {
    return this.http.get<User>(`/api/users/${email}`);
  }

  favorites() {
    return this.http.get<Favorite[]>('/api/favorites');
  }

  loadLocalChurch() {
    return this.http.get<Ministry[]>('/api/local-church-subscriptions');
  }

  loadSubscriptions() {
    return this.http.get<Ministry[]>('/api/subscriptions');
  }

  loadMinistryInfo() {
    return this.http.get<Ministry>('/api/users/me/ministry');
  }
}
