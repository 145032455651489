import { Component, EventEmitter, Input, Output } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { Sermon } from '@zwiloo/sermon/domain';
import { ReferenceRange } from '@zwiloo/verse/domain/reference';
import { ReferenceFormatter } from '@zwiloo/verse/domain/reference-formatter';
import { ZwiReferenceFormats } from '@zwiloo/verse/domain/reference-formats';
import { PlayerService, Status } from '@zwiloo/sermon/context/player.service';
import { EmbedPickerComponent } from '@zwiloo/frontend/embed/sermon/embed-picker/embed-picker.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'zwi-sermon-editor',
  templateUrl: './sermon-editor.component.html',
})
export class SermonEditorComponent {
  @Input() sermon: Sermon = new Sermon();
  @Output() sermonDelete = new EventEmitter<Sermon>();
  @Output() sermonEdit = new EventEmitter<Sermon>();

  playingSermon: Sermon | null = null;
  sermonStatus: Status = 'EMPTY';

  sermonSubscription: Subscription;
  statusSubscription: Subscription;

  constructor(public dialog: MatDialog, private playerService: PlayerService) {
    this.sermonSubscription = playerService.sermon.subscribe((s) => {
      this.playingSermon = s;
    });
    this.statusSubscription = playerService.status.subscribe((s) => {
      this.sermonStatus = s;
    });
  }

  deleteSermon(s: Sermon) {
    this.sermonDelete.emit(s);
  }

  editSermon(s: Sermon) {
    this.sermonEdit.emit(s);
  }

  openEmbedPicker(sermonID: number) {
    const dialogRef = this.dialog.open(EmbedPickerComponent, {
      data: { sermonID },
      minWidth: '700px',
    });
  }

  getReferences(referenceRanges?: ReferenceRange[]) {
    if (referenceRanges !== undefined) {
      const formatter = new ReferenceFormatter();
      return referenceRanges
        .map((range) =>
          formatter.formatRange(
            range,
            ZwiReferenceFormats.display.referenceInput
          )
        )
        .join(', ');
    } else {
      return '';
    }
  }

  hasReferences() {
    return this.sermon?.references && this.sermon.references.length > 0;
  }

  playSermon(s: Sermon) {
    this.playerService.setSermon(s);
  }
}
