import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SectionHeadlineComponent } from './section-headline/section-headline.component';
import { DisplayComponent } from './display/display.component';
import { CaptionComponent } from './caption/caption.component';
import { BodyComponent } from './body/body.component';
import { HeadlineComponent } from './headline/headline.component';
import { TitleComponent } from './title/title.component';

@NgModule({
  declarations: [
    CaptionComponent,
    DisplayComponent,
    SectionHeadlineComponent,
    BodyComponent,
    HeadlineComponent,
    TitleComponent,
  ],
  exports: [
    CaptionComponent,
    DisplayComponent,
    SectionHeadlineComponent,
    BodyComponent,
    HeadlineComponent,
    TitleComponent,
  ],
  imports: [CommonModule],
})
export class TypographyModule {}
