import { Capacitor } from '@capacitor/core';

import { Component, Input } from '@angular/core';

@Component({
  selector: 'zwi-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class ContentComponent {
  @Input() scrollX = false;
  @Input() scrollY = true;

  classObject = {
    'zwi-pinch-zoom': Capacitor.isNativePlatform() === false,
    'zwi-scroll-x': this.scrollX,
    'zwi-scroll-y': this.scrollY,
  };
}
