import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormGroup,
  ValidationErrors,
} from '@angular/forms';

@Injectable()
export class FormErrorService {
  messages: any = {
    firstName: {
      required: 'Please enter a name.',
    },
    email: {
      active: 'The account is not active.',
      currentEmail: 'This email is your current account email.',
      email: "The address isn't a valid email.",
      required: 'Please enter an email address.',
      valid: "Couldn't find your account.",
      emailTaken: 'This email has been registered. Try another?',
    },
    password: {
      valid: 'Wrong password. Try again.',
    },
    password1: {
      required: 'Please enter a password.',
      minlength: 'Password must be at least 6 characters.',
    },
    password2: {
      passwordMatch: 'Hint: Must match the password above.',
      required: 'Hint: Must match the password above.',
    },
    name: {
      required: "Please enter the ministry's name.",
    },
    uploadSeries: {
      existingRequired: 'Please select a series.',
      newRequired: 'Please enter a series name.',
    },
    username: {
      required: 'Please enter a username.',
      characters: 'Usernames should be letters and numbers only.',
      usernameTaken: 'Someone already has that username. Try another?',
    },
  };

  getErrorMessage(field: string, error: string): string {
    return this.messages[field][error];
    // for (const field in formErrors) {
    //   formErrors[field] = '';
    //   const control = form.get(field);

    //   if (control && control.dirty && !control.valid) {
    //     const fieldName = this.getFieldName(field);
    //     const messages = this.messages[fieldName];
    //     for (const key in control.errors) {
    //       formErrors[field] += messages[key] + ' ';
    //     }
    //   }
    // }
  }

  // getErrorMessage(control: AbstractControl, path: string[]): string {
  //   let errors = control.errors;
  //   let controlMessages = this.messages[path[path.length - 1]];
  //   let messageKeys = Object.keys(controlMessages)
  // 	for (let key of messageKeys) {
  // 		if (key in errors) {
  // 			return controlMessages[key]
  // 		}
  // 	}
  // }

  // private getFieldName(field: string): string {
  //   var match: string[] = field.match(/\w+/g);
  //   return match[match.length - 1];
  // }

  // getErrorMessage(path: string[]): any {
  // 	let errors = this.form.get(path).errors;
  // 	let messages = this.messages[path[path.length - 1]];
  // 	let messageKeys = Object.keys(messages)
  // 	for (let key of messageKeys) {
  // 		if (key in errors) {
  // 			return messages[key]
  // 		}
  // 	}
  // }
  // show(control: AbstractControl, submitted: boolean): boolean {
  //   return (submitted || this.isTouched(control)) && control.errors !== null;
  // }

  // private isContainerControl(control: AbstractControl) {
  //   return control.hasOwnProperty('controls') ? true : false;
  // }

  // private isArrayTouched(control: AbstractControl) {
  //   return control['controls'].forEach((x) => {
  //     return x.touched === true;
  //   })
  // }

  // private isGroupTouched(control: AbstractControl) {
  //   var controls = control['controls'];
  //   return Object.keys(controls).every((key) => {
  //     return controls[key].touched === true;
  //   });
  // }

  // private isTouched(control: AbstractControl) {
  //   if (this.isContainerControl(control)) {
  //     if (Array.isArray(control['controls'])) {
  //       return this.isArrayTouched(control);
  //     }
  //     else {
  //       return this.isGroupTouched(control);
  //     }
  //   }
  //   else {
  //     return control.touched;
  //   }
  // }
}
