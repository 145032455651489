import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'zwi-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent {
  @Input() value = '';
  @Output() focus: EventEmitter<string> = new EventEmitter<string>();
  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  clear() {
    this.valueChange.emit('');
  }

  emitFocus() {
    this.focus.emit('focused');
  }

  onInput(evt: Event) {
    const target = <HTMLTextAreaElement>evt.target;
    if (target !== null) {
      this.valueChange.emit(target.value);
    }
  }

  hasValue() {
    return Boolean(this.value);
  }

  onSearch() {
    this.search.emit(this.value);
  }
}
