import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardService } from './context/dashboard.service';
import { StudioSeriesService } from './context/studio-series.service';
import { StudioSermonsService } from './context/studio-sermons.service';
import { UploadService } from './context/upload.service';
import { SermonsDataService } from './data-interface/sermons-data.service';
import { UploadDataService } from './data-interface/upload-data.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    DashboardService,
    SermonsDataService,
    StudioSeriesService,
    StudioSermonsService,
    UploadDataService,
    UploadService,
  ],
})
export class StudioModule {}
