import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'zwi-sign-in-dialog-content',
  templateUrl: './sign-in-dialog-content.component.html',
  styleUrls: ['./sign-in-dialog-content.component.scss'],
})
export class SignInDialogContentComponent {
  @Output() public modal_principal_parent = new EventEmitter();
  constructor(private router: Router) {}

  closeDialog() {
    this.modal_principal_parent.emit('CLOSE_PARENT_MODAL');
  }
}
