<mat-toolbar *ngIf="isNativePlatform === false; else topSpacer" class="zwi-toolbar">
  <a routerLink="/home" style="line-height: 1;">
    <img src="assets/img/landing-logo.png" alt="Zwiloo" height="35px" width="102px"/>
  </a>
</mat-toolbar>
<ng-template #topSpacer>
  <zwi-top-spacer></zwi-top-spacer>
</ng-template>
<div class="zwi-page-content">
  <div class="z-wrapper">
    <div class="zwi-wrapper-content--sm">
      <ng-content></ng-content>
    </div>
  </div>
</div>


