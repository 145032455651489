import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { VerifyEmailService } from '@zwiloo/user/context/verify-email.service';

@Component({
  selector: 'zwi-email-sent',
  templateUrl: './email-sent.component.html',
  styleUrls: ['./email-sent.component.scss'],
  providers: [VerifyEmailService],
})
export class EmailSentComponent implements OnInit {
  email = '';

  constructor(
    private route: ActivatedRoute,
    private verify: VerifyEmailService
  ) {}

  ngOnInit(): void {
    const e = this.route.snapshot.queryParamMap.get('e');
    if (e !== null) {
      this.email = e;
      this.verify.sendVerifyToken(e);
    }
  }
}
