/**
 * Created by richbill on 8/25/17.
 */
import { forkJoin, Observable, Subject, zip } from 'rxjs';
import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {
  MinistryAccount,
  Ministry,
  setLocalChurchSubscribed,
  setLocalChurchUnsubscribed,
  setSubscribed,
  setUnsubscribed,
} from './domain';
import { Sermon } from '@zwiloo/sermon/domain/sermon';
import { UserService } from '@zwiloo/user/user.service';
import { SermonService } from '@zwiloo/sermon/sermon.service';
import { MinistryDataService } from './data-interface/ministry-data.service';
import { SermonDataService } from '@zwiloo/ministry/data-interface/sermon-data.service';
import { FavoritesService } from '@zwiloo/user/context/favorites.service';
import { Temporal } from '@js-temporal/polyfill';

@Injectable()
export class MinistryService {
  public ministry = new Subject<Ministry>();
  public dailySermons = new Subject<Sermon[]>();
  public sermons = new Subject<Sermon[]>();

  constructor(
    private http: HttpClient,
    private favorites: FavoritesService,
    private ministryData: MinistryDataService,
    private sermon: SermonService,
    private sermonData: SermonDataService,
    private user: UserService
  ) {}

  isMinistry(): Observable<any> {
    return this.http.get<any>('/app/accounts/is-ministry');
  }

  guestLoadMinistryInformation(username: string): Observable<Ministry> {
    return this.ministryData.ministryInformation(username);
  }

  guestLoadMinistryInformationById(id: number): Observable<Ministry> {
    return this.ministryData.ministryInformationById(id);
  }

  loadMinistryInformation(username: string): Observable<Ministry> {
    this.user.loadLocalChurch();
    this.user.loadSubscriptions();
    return zip(
      this.ministryData.ministryInformation(username),
      this.user.subscriptions,
      this.user.localChurch
    ).pipe(
      map(
        ([ministry, subscriptions, localChurch]: [
          Ministry,
          Ministry[],
          Ministry[]
        ]) => {
          const markedMinistry = this.markSubscriptions(
            [ministry],
            localChurch,
            subscriptions
          );
          if (markedMinistry.length > 0) {
            return markedMinistry[0];
          } else {
            return new Ministry();
          }
        }
      )
    );

    // subscribe(([ministry, subscriptions, localChurch]) => {
    //   const markedMinistry = this.markSubscriptions(
    //     [ministry],
    //     localChurch,
    //     subscriptions
    //   );
    //   if (markedMinistry.length > 0) {
    //     this.ministry.next(markedMinistry[0]);
    //   }
    // });
  }

  markSubscriptions(
    ministries: Ministry[],
    localChurches: Ministry[],
    subscriptions: Ministry[],
    unsubcribedType: 'subscription' | 'localChurch' = 'subscription'
  ): Ministry[] {
    return ministries.map((m) => {
      const localChurch = localChurches.find((l) => l.id === m.id);
      const subscription = subscriptions.find((s) => s.id === m.id);
      if (localChurch !== undefined) {
        setLocalChurchSubscribed(m);
      } else if (subscription !== undefined) {
        setSubscribed(m);
      } else {
        if (unsubcribedType === 'subscription') {
          setUnsubscribed(m);
        } else {
          setLocalChurchUnsubscribed(m);
        }
      }
      return m;
    });
  }

  loadDailySermons(id: number): Observable<Sermon[]> {
    const date = Temporal.Now.plainDateISO();
    return this.favorites.hydrateFavorite(
      this.sermonData.getSermonHistory(id, date, 1, 2000)
    );
  }

  loadSermons(id: number): Observable<Sermon[]> {
    return this.favorites.hydrateFavorite(this.sermonData.getSermons(id));
  }

  guestLoadSermons(id: number): Observable<Sermon[]> {
    return this.sermonData.getSermons(id);
  }

  signup(ministryAccount: MinistryAccount): Observable<any> {
    return this.http.post('/api/ministries', ministryAccount);
  }

  subscribe(ministry: Ministry) {
    setSubscribed(ministry);
    this.ministryData.subscribe(ministry.id).subscribe({
      error: () => {
        setUnsubscribed(ministry);
      },
    });
  }

  unsubscribe(ministry: Ministry) {
    setUnsubscribed(ministry);
    this.ministryData.unsubscribe(ministry.id).subscribe({
      error: () => {
        setSubscribed(ministry);
      },
    });
  }

  subscribeLocal(ministry: Ministry) {
    setLocalChurchSubscribed(ministry);
    this.ministryData.subscribeLocal(ministry.id).subscribe({
      error: () => {
        setLocalChurchUnsubscribed(ministry);
      },
    });
  }

  unsubscribeLocal(ministry: Ministry) {
    setLocalChurchUnsubscribed(ministry);
    this.ministryData.unsubscribeLocal(ministry.id).subscribe({
      error: () => {
        setLocalChurchSubscribed(ministry);
      },
    });
  }
}
