<form [formGroup]="form" (ngSubmit)="submit()">
  <mat-form-field appearance="fill" class="zwi-form-field--medium">
    <mat-label>First and last name</mat-label>
    <input formControlName="speaker" matInput>
    <mat-error *ngIf="form.get('speaker')?.hasError('required')">Please enter a name</mat-error>
  </mat-form-field>
  <button mat-raised-button color="primary" style="margin-left: 8px;">Add</button>
</form>
<div class="mat-subtitle-2">Speakers</div>
<mat-list>
  <mat-list-item *ngFor="let speaker of speakers">
    <mat-icon matListItemIcon>person</mat-icon>
    <span matListItemTitle>{{ speaker.name }}</span>
  </mat-list-item>
</mat-list>
