import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import {
  DOWN_ARROW,
  END,
  ENTER,
  HOME,
  LEFT_ARROW,
  PAGE_DOWN,
  PAGE_UP,
  RIGHT_ARROW,
  UP_ARROW,
} from '@angular/cdk/keycodes';
import { ReferenceCell } from '../reference-body/reference-body.component';

import { ReferenceBodyComponent } from '../reference-body/reference-body.component';
import { ReferenceAdapter } from '@zwiloo/verse/domain/reference-adapter';
import { Reference, ReferenceRange } from '@zwiloo/verse/domain/reference';

export const booksPerPage = 66;
export const booksPerRow = 7;

@Component({
  selector: 'zwi-bible-view',
  templateUrl: './bible-view.component.html',
  styleUrls: ['./bible-view.component.scss'],
})
export class BibleViewComponent implements AfterContentInit {
  @Input()
  get activeReference(): Reference {
    return this._activeReference;
  }
  set activeReference(value: Reference) {
    const oldReference = this._activeReference;
    this._activeReference = value;
  }
  private _activeReference = new Reference();

  @Input()
  get selected(): ReferenceRange | null {
    return this._selected;
  }
  set selected(value: ReferenceRange | null) {
    this._selected = value;
    if (this._selected) {
      this._selectedBook = this._referenceAdapter.getBook(this._selected.begin);
    }
  }
  private _selected: ReferenceRange | null = null;

  @Input()
  get minReference(): Reference | null {
    return this._minReference;
  }
  set minReference(value: Reference | null) {
    this._minReference = value;
  }
  private _minReference: Reference | null = null;

  @Input()
  get maxReference(): Reference | null {
    return this._maxReference;
  }
  set maxReference(value: Reference | null) {
    this._maxReference = value;
  }
  private _maxReference: Reference | null = null;

  @Output() readonly selectedChange: EventEmitter<Reference> =
    new EventEmitter<Reference>();
  @Output() readonly bookSelected: EventEmitter<Reference> =
    new EventEmitter<Reference>();

  @ViewChild(ReferenceBodyComponent) _referenceBody!: ReferenceBodyComponent;

  _books: ReferenceCell[][] = [[]];

  _selectedBook = 0;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    @Optional() public _referenceAdapter: ReferenceAdapter
  ) {
    this.activeReference = this._referenceAdapter.first();
  }

  ngAfterContentInit() {
    this._init();
  }

  _init() {
    this._books = [];
    let row: number[] = [];
    const bookAbbreviations = this._referenceAdapter.getBookAbbreviations();
    for (let i = 1; i <= booksPerPage; i++) {
      row.push(i);
      if (row.length === booksPerRow) {
        // TODO: change to book name
        this._books.push(
          row.map((book) =>
            this._createCellForBook(book, bookAbbreviations[book - 1])
          )
        );
        row = [];
      }
    }
    if (row.length > 0) {
      this._books.push(
        row.map((book) =>
          this._createCellForBook(book, bookAbbreviations[book])
        )
      );
    }
    this._changeDetectorRef.markForCheck();
  }

  _bookSelected(book: number) {
    const reference = this._referenceAdapter.createReference(book);
    this.bookSelected.emit(reference);
    // const chapter = this._referenceAdapter.getChapter(this.activeReference);
    this.selectedChange.emit(reference);
  }

  _focusActiveCell() {
    this._referenceBody._focusActiveCell();
  }

  private _createCellForBook(book: number, bookName: string) {
    return new ReferenceCell(book, bookName, bookName, true);
  }

  getActiveCell() {
    if (this.activeReference) {
      return this._referenceAdapter.getBook(this.activeReference);
    } else {
      return 1;
    }
  }
}
