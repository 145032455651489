import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';

import { SermonDataService } from '@zwiloo/ministry/data-interface/sermon-data.service';
import { Sermon } from '@zwiloo/sermon/domain';
import { AccountService } from '@zwiloo/user/account';
import { Temporal } from '@js-temporal/polyfill';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  sermons = new BehaviorSubject<Sermon[]>([]);
  sermonHistory = new BehaviorSubject<Sermon[]>([]);

  constructor(
    private accountService: AccountService,
    private sermonData: SermonDataService
  ) {}

  initializePage() {
    const m = this.accountService.getMinistry();

    this.sermonData.getSermons(m.id).subscribe((s) => {
      this.sermons.next(s);
    });

    const date = Temporal.Now.plainDateISO().add({ days: 2 });
    this.sermonData.getSermonHistory(m.id, date, 1, 5).subscribe((s) => {
      this.sermonHistory.next(s);
    });
  }
}
