import { Temporal } from '@js-temporal/polyfill';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Sermon } from '@zwiloo/sermon/domain';

@Injectable({
  providedIn: 'root',
})
export class FeedDataService {
  constructor(private http: HttpClient) {}

  getAdded(date: Temporal.PlainDate, cursor: number, limit: number) {
    return this.http.get<{ sermons: Sermon[]; nextCursor: number }>(
      `/api/added`,
      { params: { cursor, limit } }
    );
  }

  getFeed(date: Temporal.PlainDate, cursor: number, limit: number) {
    return this.http.get<{ sermons: Sermon[]; nextCursor: number }>(
      `/api/feed`,
      { params: { date: date.toString(), cursor, limit } }
    );
  }

  getTrending(date: Temporal.PlainDate, cursor: number, limit: number) {
    return this.http.get<{ sermons: Sermon[]; nextCursor: number }>(
      `/api/feed/trending`,
      { params: { date: date.toString(), cursor, limit } }
    );
  }
}
