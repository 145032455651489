import {
  Component,
  computed,
  EventEmitter,
  input,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { MatSliderDragEvent } from '@angular/material/slider';

import { Sermon } from '@zwiloo/sermon/domain';
import { PlayerService } from '@zwiloo/sermon/context/player.service';

@Component({
  selector: 'zwi-progress-slider',
  templateUrl: './progress-slider.component.html',
  styleUrls: ['./progress-slider.component.scss'],
})
export class ProgressSliderComponent {
  @Input() duration: number = 0;
  sermon = input<Sermon | null>(null);
  // @Input() sermon?: Sermon;
  @Output() timeChange = new EventEmitter();
  @Output() dragStart = new EventEmitter();
  constructor(private playerService: PlayerService) {}
  // get percent(): number {
  //   return this.playerService.currentTime();
  // }
  // _percent: number = 0;

  percent = computed(() => {
    const currentTime = this.playerService.currentTime();
    return this.duration ? (currentTime / this.duration) * 100 : 0;
    // if (this.duration) {
    //   this._percent = (currentTime / this.duration) * 100;
    // } else {
    //   this._percent = 0;
    // }
  });

  // @Input()
  // set currentTime(currentTime: number) {
  //   console.log('percent', 'slider');
  //   if (this.duration) {
  //     this._percent = (currentTime / this.duration) * 100;
  //     console.log('percent', this._percent);
  //   } else {
  //     this._percent = 0;
  //   }
  // }

  emitDragEnd(evt: MatSliderDragEvent) {
    this.timeChange.emit(evt.value);
  }
}
