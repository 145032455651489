import dayjs from 'dayjs';
import { Subscription } from 'rxjs';

import { Component, Input, signal, WritableSignal } from '@angular/core';

import { EmbedLogoComponent } from '../shared/embed-logo/embed-logo.component';
import { EmbedMetadataComponent } from '../shared/embed-metadata/embed-metadata.component';
import { TimeDisplayComponent } from '@zwiloo/frontend/shared/player/time-display/time-display.component';
import { EmbedPlayerComponent } from '../shared/embed-player/embed-player.component';
import { MinistryEmbedCoverArtComponent } from './ministry-embed-cover-art/ministry-embed-cover-art.component';
import { SermonListComponent } from './sermon-list/sermon-list.component';
import { Sermon } from '@zwiloo/sermon/domain';
import { GetMinistryService } from '@zwiloo/ministry/context/get-ministry.service';
import { CallState, LoadingState } from '@zwiloo/util/state';
import { ReferenceRange } from '@zwiloo/verse/domain/reference';
import { ReferenceFormatter } from '@zwiloo/verse/domain/reference-formatter';
import { ZwiReferenceFormats } from '@zwiloo/verse/domain/reference-formats';
import { Ministry } from '@zwiloo/ministry/domain';
import { SermonService } from '../sermon.service';
import { EmbedBookMenuComponent } from '@zwiloo/frontend/embed/ministry/embed-book-menu/embed-book-menu.component';
import { MatChipsModule } from '@angular/material/chips';
import { SharedModule } from '@zwiloo/frontend/shared/shared.module';

@Component({
  selector: 'zwi-ministry-embed',
  standalone: true,
  imports: [
    EmbedBookMenuComponent,
    EmbedLogoComponent,
    EmbedMetadataComponent,
    EmbedPlayerComponent,
    MinistryEmbedCoverArtComponent,
    SermonListComponent,
    TimeDisplayComponent,
    MatChipsModule,
    SharedModule,
  ],
  templateUrl: './ministry-embed.component.html',
  styleUrl: './ministry-embed.component.scss',
  providers: [SermonService],
})
export class MinistryEmbedComponent {
  @Input() id = 0;
  added: Sermon[] = [];
  ministry = new Ministry();
  sermons: Sermon[] = [];
  statusSubscription: Subscription;
  sermonSubscription: Subscription;
  status: CallState = LoadingState.INIT;

  sermon: WritableSignal<Sermon> = signal(new Sermon());

  constructor(
    public getMinistry: GetMinistryService,
    private sermonService: SermonService
  ) {
    this.sermonSubscription = getMinistry.sermons.subscribe((s) => {
      this.sermons = s;
      this.added = s.sort((a, b) =>
        dayjs(a.createdAt).isBefore(b.createdAt) ? -1 : 1
      );
    });

    this.statusSubscription = getMinistry.loadingStatus.subscribe((s) => {
      this.status = s;
    });
  }

  ngOnInit(): void {
    this.getMinistry.guestLoadMinistryById(this.id);
  }

  ngOnDestroy(): void {
    this.statusSubscription.unsubscribe();
  }

  ministryLoaded() {
    return this.status === LoadingState.LOADED;
  }

  setSermon(s: Sermon) {
    if (s.id === this.sermon().id) {
      if (this.sermonService.status() === 'PLAYING') {
        this.sermonService.status.set('PAUSED');
      } else {
        this.sermonService.status.set('PLAYING');
      }
    }
    this.sermon.set(s);
  }
}
