<zwi-simple-page>
  <div class="z-wrapper">
    <div class="zwi-wrapper-content">
      <div class="zwi-page-sections">
        <zwi-headline *ngIf="isNativePlatform === false; else backHeadline">Privacy Policy</zwi-headline>
        <ng-template #backHeadline>
          <zwi-back-headline (navigateBack)="navigateToIndex()">Privacy Policy</zwi-back-headline>
        </ng-template>
        <div class="zwi-section">
          <div class="zwi-block-section">
            <zwi-section-headline>Introduction</zwi-section-headline>
            <zwi-body>Welcome to Zwiloo, the app that lets you listen to sermons and follow ministries. We know that privacy is important to you, so we want to be transparent about how we collect, use, and share your data.</zwi-body>
          </div>
          <div class="zwi-block-section">
            <zwi-section-headline>Data Collection</zwi-section-headline>
            <zwi-body>When you use Zwiloo, we collect the following data:</zwi-body>
            <ul class="zwi-bullet-list">
              <li>Personal information: If you create an account, we collect your name, email address, and date of birth.</li>
              <li>Device information: We collect your device's unique identifier, operating system, and mobile carrier.</li>
              <li>Usage data: We collect information about how you use our app, such as the audio recordings you listen to and the features you use.</li>
              <li>Location data: We collect your IP address, which can be used to track your location.</li>
            </ul>
          </div>
          <div class="zwi-block-section">
            <zwi-section-headline>Data Use</zwi-section-headline>
            <zwi-body>We use the data we collect to:</zwi-body>
            <ul class="zwi-bullet-list">
              <li>Provide you with the services you have requested: This includes providing you with access to audio recordings, allowing you to upload your own recordings, and letting you share recordings with others.</li>
              <li>Improve our app: We use data to track how you use our app so that we can make it better. For example, we might use data to see which features are most popular or to identify areas where we can improve the user experience.</li>
              <li>Personalize your experience: We might use your data to personalize your experience on Zwiloo. For example, we might recommend audio recordings that we think you might like based on your listening history.</li>
              <li>Send you marketing communications: We might use your data to send you email newsletters or promotional offers. You can always opt out of these communications at any time.</li>
            </ul>
          </div>
          <div class="zwi-block-section">
            <zwi-section-headline>Data Sharing</zwi-section-headline>
            <zwi-body>We may share your data with third-party service providers who help us operate our app, such as cloud storage providers and analytics companies. These third-party service providers are required to protect your data in accordance with this privacy policy.</zwi-body>
            <zwi-body>We may also share your data with law enforcement agencies if we are required to do so by law.</zwi-body>
          </div>
          <div class="zwi-block-section">
            <zwi-section-headline>Data Retention</zwi-section-headline>
            <zwi-body>We will retain your data for as long as you have an account with us or as long as we need it to provide you with the services you have requested. We will then delete your data in accordance with our data retention policy.</zwi-body>
          </div>
          <div class="zwi-block-section">
            <zwi-section-headline>Data Deletion</zwi-section-headline>
            <zwi-body>You can request that we delete your data at any time by contacting us. We will delete your data within a reasonable timeframe.</zwi-body>
          </div>
          <div class="zwi-block-section">
            <zwi-section-headline>Your Rights</zwi-section-headline>
            <zwi-body>You have the following rights with respect to your data:</zwi-body>
            <ul class="zwi-bullet-list">
              <li>The right to access your data: You can request a copy of your data at any time.</li>
              <li>The right to correct your data: If your data is inaccurate, you can request that we correct it.</li>
              <li>The right to delete your data: You can request that we delete your data at any time.</li>
              <li>The right to object to the processing of your data: You can object to the processing of your data for certain purposes, such as marketing.</li>
              <li>The right to withdraw your consent: If you have given us your consent to process your data, you can withdraw your consent at any time.</li>
            </ul>
          </div>

          <div class="zwi-block-section">
            <zwi-section-headline>How to Contact Us</zwi-section-headline>
            <zwi-body>If you have any questions about this privacy policy, please contact us at richard&#64;zwiloo.com</zwi-body>
          </div>

          <div class="zwi-block-section">
            <zwi-section-headline>Changes to This Privacy Policy</zwi-section-headline>
            <zwi-body>We use the data we collect to:</zwi-body>
          </div>

          <div class="zwi-block-section">
            <zwi-section-headline>Changes to This Privacy Policy</zwi-section-headline>
            <zwi-body>We may update this privacy policy from time to time. The latest version of the privacy policy will always be available on our website.</zwi-body>
          </div>
          <div class="zwi-block-section">
            <zwi-body>This privacy policy is effective as of July 21, 2023.</zwi-body>
          </div>
        </div>
      </div>
    </div>
  </div>
</zwi-simple-page>
