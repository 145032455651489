<h2 mat-dialog-title>
  <div class="zwi-flex-center">
    <div>Embed ministry</div>
    <div class="zwi-spacer"></div>
    <mat-icon mat-dialog-close class="zwi-embed-close">close_small</mat-icon>
  </div>
</h2>
<mat-dialog-content>
  <div class="zwi-section">
    <div class="zwi-block-section">
      <zwi-embed-options [height]="height" [heightOptions]="[752, 552]" [minPercent]="70" [minPixels]="450" (heightChange)="setHeight($event)" (widthChange)="setWidth($event)"></zwi-embed-options>
      <zwi-embed-preview category="ministry" [height]="height" [id]="data.ministryID" [width]="width" class="zwi-preview-container"></zwi-embed-preview>
      <zwi-embed-picker-iframe category="ministry" [height]="height" [id]="data.ministryID" [width]="width"></zwi-embed-picker-iframe>
    </div>
  </div>
</mat-dialog-content>
