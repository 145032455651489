export interface BookTestament {
  name: string;
  id: number;
  abbreviation: string;
  testament: string;
}

export const BOOKS_TESTAMENTS: BookTestament[] = [
  {
    id: 1,
    name: 'Genesis',
    abbreviation: 'Gen',
    testament: 'Old Testament',
  },
  { id: 2, name: 'Exodus', abbreviation: 'Ex', testament: 'Old Testament' },
  {
    id: 3,
    name: 'Leviticus',
    abbreviation: 'Lev',
    testament: 'Old Testament',
  },
  {
    id: 4,
    name: 'Numbers',
    abbreviation: 'Num',
    testament: 'Old Testament',
  },
  {
    name: 'Deuteronomy',
    id: 5,
    abbreviation: 'Deut',
    testament: 'Old Testament',
  },
  {
    name: 'Joshua',
    id: 6,
    abbreviation: 'Josh',
    testament: 'Old Testament',
  },
  {
    name: 'Judges',
    id: 7,
    abbreviation: 'Judg',
    testament: 'Old Testament',
  },
  { name: 'Ruth', id: 8, abbreviation: 'Ruth', testament: 'Old Testament' },
  {
    name: '1 Samuel',
    id: 9,
    abbreviation: '1 Sam',
    testament: 'Old Testament',
  },
  {
    name: '2 Samuel',
    id: 10,
    abbreviation: '2 Sam',
    testament: 'Old Testament',
  },
  {
    name: '1 Kings',
    id: 11,
    abbreviation: '1 Kings',
    testament: 'Old Testament',
  },
  {
    name: '2 Kings',
    id: 12,
    abbreviation: '2 Kings',
    testament: 'Old Testament',
  },
  {
    name: '1 Chronicles',
    id: 13,
    abbreviation: '1 Chron',
    testament: 'Old Testament',
  },
  {
    name: '2 Chronicles',
    id: 14,
    abbreviation: '2 Chron',
    testament: 'Old Testament',
  },
  {
    name: 'Ezra',
    id: 15,
    abbreviation: 'Ezra',
    testament: 'Old Testament',
  },
  {
    name: 'Nehemiah',
    id: 16,
    abbreviation: 'Neh',
    testament: 'Old Testament',
  },
  {
    name: 'Esther',
    id: 17,
    abbreviation: 'Est',
    testament: 'Old Testament',
  },
  { name: 'Job', id: 18, abbreviation: 'Job', testament: 'Old Testament' },
  { name: 'Psalm', id: 19, abbreviation: 'Ps', testament: 'Old Testament' },
  {
    name: 'Proverbs',
    id: 20,
    abbreviation: 'Prov',
    testament: 'Old Testament',
  },
  {
    name: 'Ecclesiastes',
    id: 21,
    abbreviation: 'Eccles',
    testament: 'Old Testament',
  },
  {
    name: 'Song of Solomon',
    id: 22,
    abbreviation: 'Song',
    testament: 'Old Testament',
  },
  {
    name: 'Isaiah',
    id: 23,
    abbreviation: 'Isa',
    testament: 'Old Testament',
  },
  {
    name: 'Jeremiah',
    id: 24,
    abbreviation: 'Jer',
    testament: 'Old Testament',
  },
  {
    name: 'Lamentations',
    id: 25,
    abbreviation: 'Lam',
    testament: 'Old Testament',
  },
  {
    name: 'Ezekiel',
    id: 26,
    abbreviation: 'Ezek',
    testament: 'Old Testament',
  },
  {
    name: 'Daniel',
    id: 27,
    abbreviation: 'Dan',
    testament: 'Old Testament',
  },
  {
    name: 'Hosea',
    id: 28,
    abbreviation: 'Hos',
    testament: 'Old Testament',
  },
  {
    name: 'Joel',
    id: 29,
    abbreviation: 'Joel',
    testament: 'Old Testament',
  },
  {
    name: 'Amos',
    id: 30,
    abbreviation: 'Amos',
    testament: 'Old Testament',
  },
  {
    name: 'Obadiah',
    id: 31,
    abbreviation: 'Obad',
    testament: 'Old Testament',
  },
  {
    name: 'Jonah',
    id: 32,
    abbreviation: 'Jonah',
    testament: 'Old Testament',
  },
  {
    name: 'Micah',
    id: 33,
    abbreviation: 'Mic',
    testament: 'Old Testament',
  },
  {
    name: 'Nahum',
    id: 34,
    abbreviation: 'Nah',
    testament: 'Old Testament',
  },
  {
    name: 'Habakkuk',
    id: 35,
    abbreviation: 'Hab',
    testament: 'Old Testament',
  },
  {
    name: 'Zephaniah',
    id: 36,
    abbreviation: 'Zeph',
    testament: 'Old Testament',
  },
  {
    name: 'Haggai',
    id: 37,
    abbreviation: 'Hag',
    testament: 'Old Testament',
  },
  {
    name: 'Zechariah',
    id: 38,
    abbreviation: 'Zech',
    testament: 'Old Testament',
  },
  {
    name: 'Malachi',
    id: 39,
    abbreviation: 'Mal',
    testament: 'Old Testament',
  },
  {
    name: 'Matthew',
    id: 40,
    abbreviation: 'Matt',
    testament: 'New Testament',
  },
  {
    name: 'Mark',
    id: 41,
    abbreviation: 'Mark',
    testament: 'New Testament',
  },
  {
    name: 'Luke',
    id: 42,
    abbreviation: 'Luke',
    testament: 'New Testament',
  },
  {
    name: 'John',
    id: 43,
    abbreviation: 'John',
    testament: 'New Testament',
  },
  {
    name: 'Acts',
    id: 44,
    abbreviation: 'Acts',
    testament: 'New Testament',
  },
  {
    name: 'Romans',
    id: 45,
    abbreviation: 'Rom',
    testament: 'New Testament',
  },
  {
    name: '1 Corinthians',
    id: 46,
    abbreviation: '1 Cor',
    testament: 'New Testament',
  },
  {
    name: '2 Corinthians',
    id: 47,
    abbreviation: '2 Cor',
    testament: 'New Testament',
  },
  {
    name: 'Galatians',
    id: 48,
    abbreviation: 'Gal',
    testament: 'New Testament',
  },
  {
    name: 'Ephesians',
    id: 49,
    abbreviation: 'Eph',
    testament: 'New Testament',
  },
  {
    name: 'Philippians',
    id: 50,
    abbreviation: 'Phil',
    testament: 'New Testament',
  },
  {
    name: 'Colossians',
    id: 51,
    abbreviation: 'Col',
    testament: 'New Testament',
  },
  {
    name: '1 Thessalonians',
    id: 52,
    abbreviation: '1 Thess',
    testament: 'New Testament',
  },
  {
    name: '2 Thessalonians',
    id: 53,
    abbreviation: '2 Thess',
    testament: 'New Testament',
  },
  {
    name: '1 Timothy',
    id: 54,
    abbreviation: '1 Tim',
    testament: 'New Testament',
  },
  {
    name: '2 Timothy ',
    id: 55,
    abbreviation: '2 Tim',
    testament: 'New Testament',
  },
  {
    name: 'Titus',
    id: 56,
    abbreviation: 'Titus',
    testament: 'New Testament',
  },
  {
    name: 'Philemon',
    id: 57,
    abbreviation: 'Philem',
    testament: 'New Testament',
  },
  {
    name: 'Hebrews',
    id: 58,
    abbreviation: 'Heb',
    testament: 'New Testament',
  },
  {
    name: 'James',
    id: 59,
    abbreviation: 'James',
    testament: 'New Testament',
  },
  {
    name: '1 Peter',
    id: 60,
    abbreviation: '1 Pet',
    testament: 'New Testament',
  },
  {
    name: '2 Peter',
    id: 61,
    abbreviation: '2 Pet',
    testament: 'New Testament',
  },
  {
    name: '1 John',
    id: 62,
    abbreviation: '1 John',
    testament: 'New Testament',
  },
  {
    name: '2 John',
    id: 63,
    abbreviation: '2 John',
    testament: 'New Testament',
  },
  {
    name: '3 John',
    id: 64,
    abbreviation: '3 John',
    testament: 'New Testament',
  },
  { name: 'Jude', id: 65, abbreviation: 'Jude', testament: 'New Testament' },
  {
    name: 'Revelation',
    id: 66,
    abbreviation: 'Rev',
    testament: 'New Testament',
  },
];

export function bookNumber(book: string): number {
  const index = BOOKS_TESTAMENTS.findIndex(b => b.name === book);
  return index !== -1 ? index + 1 : -1;
}
