<table class="zwi-reference-table">
  <thead class="zwi-reference-table-header">
    <tr><th class="zwi-reference-table-header-divider" colspan="7"></th></tr>
  </thead>
  <tbody zwi-reference-body
         [activeCell]="getActiveCell()"
         [rows]="_books"
         [beginValue]="_selectedBook"
         [endValue]="_selectedBook"
         [numCols]="7"
         [cellAspectRatio]="4 / 7"
         (selectedValueChange)="_bookSelected($event)">
<!--         (keydown)="_handleReferenceBodyKeydown($event)">-->
<!--         [selectedValue]="[_selectedBook]"-->
  </tbody>
</table>
