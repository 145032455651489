import { delay, map, retryWhen, take } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';

import { Upload } from '../domain';
import { isValidReference } from '@zwiloo/verse/domain/reference';

@Injectable({
  providedIn: 'root',
})
export class UploadDataService {
  constructor(private http: HttpClient) {}

  uploadFile(upload: Upload) {
    const formData = new FormData();
    formData.append('file', upload.file);
    const url = '/api/sermons/audio';
    // '/app/ministry/upload-audio'
    const req = new HttpRequest('POST', url, formData);
    return this.http.request(req);
    // return this.http.request(req).pipe(
    //   map(event => this._getProgress(event)),
    //   tap(percentage => upload.progress = percentage),
    //   map(id => {
    //     const update = {...upload, uploadedFile: {id}};
    //     return update;
    //   }),
    //   last()
    // );
  }

  checkProcessing(tempAudioId: number) {
    return this.http.get<any>(`/api/temp-audio/${tempAudioId}`).pipe(
      map((response) => {
        if (response.file === '') {
          throw response;
        }
        // const uploadedFile = { ...upload.uploadedFile, path: response.path };
        // const update = { ...upload, uploadedFile };
        return response.file;
      }),
      retryWhen((errors) => errors.pipe(delay(1000), take(1000)))
    );
  }

  save(upload: Upload) {
    removeInvalidReferences(upload);
    return this.http.post('/api/sermons', { ...upload });
  }
}

function removeInvalidReferences(upload: Upload) {
  upload.sermonInfo.references = upload.sermonInfo.references.filter((r) =>
    isValidReference(r)
  );
}
