import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'zwi-main-wrapper',
  templateUrl: './main-wrapper.component.html',
  styleUrl: './main-wrapper.component.scss',
})
export class MainWrapperComponent {
  @HostBinding('class.zwi-main-wrapper') isMainWrapper: boolean = true;
}
