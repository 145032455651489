import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import { Series } from '@zwiloo/ministry/domain';
import { Subscription } from 'rxjs';
import { StudioSeriesService } from '@zwiloo/studio/context/studio-series.service';
import { Validators } from '@angular/forms';
import { SubmitErrorStateMatcher } from '@zwiloo/frontend/form/submit-error-state-matcher';
import { MatDialog } from '@angular/material/dialog';
import { DefiniteSeriesMatchComponent } from '@zwiloo/frontend/studio/series/definite-series-match/definite-series-match.component';

@Component({
  selector: 'zwi-add-series',
  templateUrl: './add-series.component.html',
  styleUrls: ['./add-series.component.scss'],
})
export class AddSeriesComponent {
  form = this.formBuilder.group({
    series: ['', Validators.required],
  });
  matcher = new SubmitErrorStateMatcher();
  series: Series[] = [];
  subscription: Subscription;
  matchSubscription: Subscription;

  constructor(
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private studioSeries: StudioSeriesService
  ) {
    this.subscription = studioSeries.series.subscribe((s) => {
      this.series = s;
    });
    this.matchSubscription = studioSeries.match.subscribe(() => {
      const dialogRef = this.dialog.open(DefiniteSeriesMatchComponent);

      dialogRef.afterClosed().subscribe((result) => {});
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.matchSubscription.unsubscribe();
  }

  submit() {
    const name = this.form.get('series')?.value;
    if (name) {
      this.studioSeries.addSeries(name);
    }
  }
}
