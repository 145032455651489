import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { CancelAccountService } from '@zwiloo/user/context/cancel-account.service';

@Component({
  selector: 'zwi-mobile-account',
  templateUrl: './mobile-account.component.html',
  styleUrls: ['./mobile-account.component.scss'],
})
export class MobileAccountComponent {
  open = false;

  constructor(
    private cancelAccountService: CancelAccountService,
    private router: Router
  ) {}
  closeAccount() {
    this.cancelAccountService.closeAccountToken();
    this.close();
  }

  navigateToSettings() {
    this.router.navigateByUrl('/mobile-settings');
  }

  openDialog() {
    this.open = true;
  }

  close() {
    this.open = false;
  }
}
