<!--<mat-list-->
<!--  cdkDropList-->
<!--  [cdkDropListData]="sermons"-->
<!--  class="example-list"-->
<!--  (cdkDropListDropped)="drop($event)">-->
<!--  <mat-list-item cdkDrag cdkDragPreviewContainer="parent" class="example-box" *ngFor="let sermon of sermons">-->

<!--    <mat-icon mat-list-icon (click)="playSermon(sermon)">play_arrow</mat-icon>-->
<!--    <h3 matLine>{{ sermon?.name }}</h3>-->
<!--    <p matLine>{{ sermon?.speaker?.name }}</p>-->
<!--    <p matLine>{{ getReferences(sermon?.references) }}</p>-->
<!--&lt;!&ndash;    <zwi-sermon [sermon]="sermon"></zwi-sermon>&ndash;&gt;-->
<!--  </mat-list-item>-->
<!--</mat-list>-->
<div
  cdkDropList
  [cdkDropListData]="sermons"
  class="example-list"
  (cdkDropListDropped)="drop($event)">
  <div class="example-box" *ngFor="let sermon of sermons" cdkDrag>
    <div class="zwi-sermon__label">
      <div class="zwi-sermon__line zwi-sermon__overline">
        <a [routerLink]="['/' + sermon?.ministry?.username]" class="zwi-sermon__line">{{ sermon?.ministry?.name }}</a>
        <span class="zwi-list-delimeter">·</span>
        <span>{{ sermon?.speaker?.name }}</span>
      </div>
      <div class="zwi-sermon__title">{{ sermon?.name }}</div>
      <div *ngIf="hasReferences(sermon)" class="zwi-sermon__line  zwi-sermon__caption">{{ getReferences(sermon?.references) }}</div>
    </div>
    <div class="material-icons">drag_handle</div>
<!--    <div>{{ sermon?.name }}</div>-->
<!--    <div>{{ sermon?.speaker?.name }}</div>-->
<!--    <div>{{ getReferences(sermon?.references) }}</div>-->
  </div>
</div>
