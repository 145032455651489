import { Capacitor } from '@capacitor/core';

import {
  Component,
  Injectable,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewContainerRef,
  computed,
  WritableSignal,
  signal,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import {
  MatBottomSheet,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { TemplatePortal } from '@angular/cdk/portal';
import { MobileBooksComponent } from './mobile-books/mobile-books.component';

import {
  BookTestament,
  BOOKS_TESTAMENTS,
} from '@zwiloo/verse/domain/books-testaments';
import { mobileWidth } from '@zwiloo/util/tokens';
import { Observable } from 'rxjs';

@Injectable()
export class BookSelectedService {
  book: WritableSignal<BookTestament | null> = signal(null);
}

@Component({
  selector: 'zwi-book-menu',
  templateUrl: './book-menu.component.html',
  styleUrls: ['./book-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [BookSelectedService],
})
export class BookMenuComponent {
  @ViewChild(TemplateRef) content: TemplateRef<unknown> | undefined;
  @ViewChild('menuTarget') target: ElementRef | undefined;
  private _bottomSheetRef: MatBottomSheetRef<MobileBooksComponent> | null =
    null;
  oldTestament: BookTestament[];
  newTestament: BookTestament[];
  private _overlayRef: OverlayRef | undefined;
  private _portal: TemplatePortal<any> | undefined;

  @Output() bookSelected: EventEmitter<BookTestament | null> =
    new EventEmitter<BookTestament | null>();
  @Input()
  set book(book: BookTestament | null) {
    this.bookSelectedService.book.set(book);
  }
  get book() {
    return this.bookSelectedService.book();
  }
  book$: Observable<BookTestament | null> | undefined;

  constructor(
    private bookSelectedService: BookSelectedService,
    private _bottomSheet: MatBottomSheet,
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef
  ) {
    this.oldTestament = BOOKS_TESTAMENTS.filter((bookTestament) => {
      return bookTestament.testament === 'Old Testament';
    });

    this.newTestament = BOOKS_TESTAMENTS.filter((bookTestament) => {
      return bookTestament.testament === 'New Testament';
    });
    this.book$ = toObservable(bookSelectedService.book);
    this.book$.subscribe((b) => {
      this.bookSelected.emit(b);
      this.close();
    });
  }

  isMobile = computed(() => {
    return Capacitor.isNativePlatform() || window.innerWidth <= mobileWidth;
  });

  open() {
    if (this.isMobile()) {
      this._bottomSheetRef = this._bottomSheet.open(MobileBooksComponent, {
        data: {
          oldTestament: this.oldTestament,
          newTestament: this.newTestament,
          bookSelectedService: this.bookSelectedService,
        },
      });
    } else {
      if (this.target !== undefined) {
        const positionStrategy = this._overlay
          .position()
          .flexibleConnectedTo(this.target)
          .withPositions([
            {
              originX: 'start',
              originY: 'bottom',
              overlayX: 'start',
              overlayY: 'top',
            },
          ]);

        this._overlayRef = this._overlay.create({
          hasBackdrop: true,
          positionStrategy,
        });
        this._overlayRef
          .backdropClick()
          .subscribe(() => this._overlayRef?.detach());
        if (this.content !== undefined) {
          this._portal = new TemplatePortal(
            this.content,
            this._viewContainerRef
          );
        }
      }
      if (this._overlayRef !== undefined) {
        this._overlayRef.attach(this._portal);
      }
    }
  }

  close() {
    if (this.isMobile()) {
      if (this._bottomSheetRef) {
        this._bottomSheetRef.dismiss();
      }
    } else if (this._overlayRef !== undefined) {
      this._overlayRef.detach();
    }
  }
}
