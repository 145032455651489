import { BehaviorSubject, Subject } from 'rxjs';

import { Injectable, signal } from '@angular/core';
import { Router } from '@angular/router';

import { MinistryService } from '@zwiloo/ministry/ministry.service';
import { CallState, LoadingState } from '@zwiloo/util/state';
import { Ministry } from '@zwiloo/ministry/domain';
import { Sermon } from '@zwiloo/sermon/domain';

@Injectable()
export class GetMinistryService {
  public ministry = signal<Ministry>(new Ministry());
  public dailySermons = signal<Sermon[]>([]);
  public sermons = new Subject<Sermon[]>();
  public dailyLoadingStatus = signal(LoadingState.INIT);
  public loadingStatus = new BehaviorSubject<CallState>(LoadingState.INIT);

  constructor(
    public ministryService: MinistryService,
    private router: Router
  ) {}

  guestLoadMinistry(username: string) {
    this.loadingStatus.next(LoadingState.LOADING);
    this.ministryService
      .guestLoadMinistryInformation(username)
      .subscribe((m) => {
        this.ministry.set(m);
        if (m.id !== 0) {
          this.ministryService.guestLoadSermons(m.id).subscribe((s) => {
            this.sermons.next(s);
            this.loadingStatus.next(LoadingState.LOADED);
          });
        } else {
          this.loadingStatus.next(LoadingState.LOADED);
        }
      });
  }

  guestLoadMinistryById(id: number) {
    this.loadingStatus.next(LoadingState.LOADING);
    this.ministryService.guestLoadMinistryInformationById(id).subscribe((m) => {
      this.ministry.set(m);
      if (m.id !== 0) {
        this.ministryService.guestLoadSermons(m.id).subscribe((s) => {
          this.sermons.next(s);
          this.loadingStatus.next(LoadingState.LOADED);
        });
      } else {
        this.loadingStatus.next(LoadingState.LOADED);
      }
    });
  }

  loadMinistry(username: string) {
    this.loadingStatus.next(LoadingState.LOADING);
    this.ministryService.loadMinistryInformation(username).subscribe((m) => {
      this.ministry.set(m);
      if (m.id !== 0) {
        this.ministryService.loadSermons(m.id).subscribe((s) => {
          this.sermons.next(s);
          this.loadingStatus.next(LoadingState.LOADED);
        });
        this.ministryService.loadDailySermons(m.id).subscribe((s) => {
          this.dailySermons.set(s);
          this.dailyLoadingStatus.set(LoadingState.LOADED);
        });
      } else {
        this.loadingStatus.next(LoadingState.LOADED);
      }
    });
  }
}
// this.userService.localChurch.subscribe((localChurch) => {
//   if (localChurch.length > 0) {
//     const l = localChurch[0];
//     this.ministryService
//       .loadMinistryInformation(l.username)
//       .subscribe((m) => {
//         this.ministry.next(m);
//         if (m.id !== 0) {
//           // Load sermons;
//           this.ministryService.loadSermons(l.id).subscribe((sermons) => {
//             this.sermons.next(sermons);
//             this.loadingStatus.next(LoadingState.LOADED);
//           });
//         } else {
//           this.loadingStatus.next(LoadingState.LOADED);
//         }
//       });
//     // this.getMinistryService.loadMinistry(l.username);
//     this.getMinistryService.loadingStatus.subscribe((c) => {
//       this.loadingStatus.next(c);
//     });
//   }
//   // else {
//   //   this.loadingStatus.next(LoadingState.LOADED);
//   // }
// });
