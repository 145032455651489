<mat-list-item>
  <zwi-play-button [sermon]="sermon" matListItemIcon></zwi-play-button>

  <span matListItemTitle>{{ sermon?.name }}</span>
  <span matListItemLine>{{ sermon?.speaker?.name }}</span>
  <span *ngIf="hasReferences()" matListItemLine>{{ getReferences(sermon?.references) }}</span>
<!--  <button mat-icon-button> [matMenuTriggerFor]="menu">-->
<!--    <mat-icon>more_vert</mat-icon>-->
<!--  </button>-->
<!--  <mat-menu #menu="matMenu">-->
<!--    <button mat-menu-item>Edit</button>-->
<!--    <button mat-menu-item>Delete</button>-->
<!--  </mat-menu>-->
  <button matListItemMeta [matMenuTriggerFor]="menu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button (click)="editSermon(sermon)" mat-menu-item>Edit</button>
    <button (click)="deleteSermon(sermon)" mat-menu-item>Delete</button>
    <button (click)="openEmbedPicker(sermon.id)" mat-menu-item>Embed sermon</button>
  </mat-menu>


</mat-list-item>
