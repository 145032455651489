import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'zwi-caption',
  templateUrl: './caption.component.html',
  styleUrls: ['./caption.component.scss'],
})
export class CaptionComponent implements OnInit {
  @Input() center = false;
  @Input() offset = false;
  constructor() {}

  ngOnInit(): void {}
}
