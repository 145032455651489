import { Subscription } from 'rxjs';

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

import { Sermon } from '@zwiloo/sermon/domain';
import { StudioSermonsService } from '@zwiloo/studio/context/studio-sermons.service';
import { Series, Speaker } from '@zwiloo/ministry/domain';
import { sermonBooks } from '@zwiloo/sermon/domain';
import { SermonEdit, SermonInfo } from '@zwiloo/studio/domain';
import { UploadFormComponent } from '@zwiloo/frontend/studio/upload/upload-form/upload-form.component';
import { LoadingState } from '@zwiloo/util/state';
import { BOOKS_TESTAMENTS } from '@zwiloo/verse/domain/books-testaments';
import { UploadService } from '@zwiloo/studio/context/upload.service';

@Component({
  selector: 'zwi-sermons',
  templateUrl: './sermons.component.html',
  styleUrls: ['./sermons.component.scss'],
  providers: [StudioSermonsService],
})
export class SermonsComponent implements OnInit, OnDestroy {
  @ViewChild(UploadFormComponent)
  private uploadFormComponent!: UploadFormComponent;
  sermons: Sermon[] = [];
  sermonEdit?: SermonEdit;
  filteredSermons: Sermon[] = [];
  filterForm = this.fb.group({
    book: [''],
    speaker: [''],
    series: [''],
  });
  books: String[] = [];
  pendingDeleteSermon = new Sermon();
  series: Series[] = [];
  speakers: Speaker[] = [];
  snackbarRef: MatSnackBarRef<any> | undefined;
  subscriptionSermons: Subscription;
  speakerSubscription: Subscription;
  seriesSubscription: Subscription;
  subscriptionUpdateStatus: Subscription;

  constructor(
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private studioSermons: StudioSermonsService,
    private uploadService: UploadService
  ) {
    this.subscriptionSermons = studioSermons.sermons.subscribe((s) => {
      this.sermons = s;
      this.filteredSermons = s;
      const allBooks = this.sermons.map((sermon) => sermonBooks(sermon)).flat();
      this.books = [...new Set(allBooks)]
        .sort()
        .map((id) => BOOKS_TESTAMENTS.find((b) => b.id === id)?.name ?? '');
    });
    this.speakerSubscription = uploadService.speakers.subscribe((speakers) => {
      this.speakers = speakers;
    });
    this.seriesSubscription = uploadService.series.subscribe((series) => {
      this.series = series;
    });
    // this.subscriptionSpeakers = studioSermons.speakers.subscribe((s) => {
    //   this.speakers = s;
    // });
    // this.subscriptionSeries = studioSermons.series.subscribe((s) => {
    //   this.series = s;
    // });
    this.subscriptionUpdateStatus = studioSermons.updateStatus.subscribe(
      (s) => {
        if (s === LoadingState.LOADED) {
          this.studioSermons.initializePage();
          this.sermonEdit = undefined;
          this.snackBar.open('Sermon information updated.', undefined, {
            duration: 3000,
          });
        }
      }
    );
  }

  ngOnInit(): void {
    this.studioSermons.initializePage();

    this.filterForm.valueChanges.subscribe((values) => {
      this.filteredSermons = this.sermons.filter((s) => {
        const books = sermonBooks(s);
        const bookID =
          values.book === ''
            ? undefined
            : BOOKS_TESTAMENTS.find((b) => b.name === values.book)?.id;
        const bookCheck = bookID === undefined || books.includes(bookID);
        // values.book === ''
        //   ? true
        //   : books.length > 0 && books.includes(values.book);
        const seriesCheck =
          values.series === '' ? true : s.series.id === values.series;
        const speakerCheck =
          values.speaker === '' ? true : s.speaker.id === values.speaker;
        return bookCheck && seriesCheck && speakerCheck;
      });
    });
  }

  ngOnDestroy() {
    if (this.pendingDeleteSermon.id !== 0) {
      this.studioSermons.finalizeDeletion(this.pendingDeleteSermon.id);
      this.snackbarRef?.dismiss();
    }
  }

  clearSermon() {
    this.sermonEdit = undefined;
  }

  deleteSermon(s: Sermon) {
    this.pendingDeleteSermon = s;
    this.studioSermons.deleteSermon(s.id);
    this.snackbarRef = this.snackBar.open('Sermon deleted.', 'Undo', {
      duration: 5000,
    });
    let isActive = true;
    this.snackbarRef.afterDismissed().subscribe((event) => {
      if (!event.dismissedByAction) {
        if (this.pendingDeleteSermon.id > 0) {
          this.studioSermons.finalizeDeletion(this.pendingDeleteSermon.id);
        }
      }
      this.resetPendingDelete();
    });
    this.snackbarRef.onAction().subscribe(() => {
      this.resetPendingDelete();
      this.studioSermons.initializePage();
    });
  }

  editSermon(s: Sermon) {
    const sermonInfo = new SermonInfo(
      s.name,
      s.description,
      s.series.id,
      s.speaker.id,
      s.references
    );
    this.sermonEdit = new SermonEdit(s.id, sermonInfo);
  }

  // new File();
  // const upload = new Upload(
  //   1,
  //   s.audio,
  //   new File(['blank'], 'blank'),
  //   s.name,
  //   s.description,
  //   s.references
  // );
  // this.upload = s;

  hasFilters() {
    return (
      this.filterForm.value.book !== '' ||
      this.filterForm.value.speaker !== '' ||
      this.filterForm.value.series !== ''
    );
  }

  isFormValid() {
    if (this.uploadFormComponent !== undefined) {
      return this.uploadFormComponent.form.valid;
    } else {
      return true;
    }
  }

  removeFilters() {
    this.filterForm.setValue({
      book: '',
      series: '',
      speaker: '',
    });
  }

  resetPendingDelete() {
    this.pendingDeleteSermon = new Sermon();
  }

  save() {
    if (this.sermonEdit) {
      const sermonInfo = this.getSermonInfoValue();
      this.studioSermons.updateSermon({ id: this.sermonEdit.id, sermonInfo });
    }
  }

  private getSermonInfoValue(): SermonInfo {
    return this.uploadFormComponent.getValue();
  }
}
