import { Observable, Subscription } from 'rxjs';

import {
  Component,
  computed,
  effect,
  input,
  Input,
  OnDestroy,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';

import { StarChange } from '@zwiloo/frontend/shared/sermon/sermon.component';
import { Sermon } from '@zwiloo/sermon/domain';

import { SermonService } from '@zwiloo/sermon/sermon.service';
import { CallState, LoadingState } from '@zwiloo/util/state';
import { GetMinistryService } from '@zwiloo/ministry/context/get-ministry.service';
import { MatChipListboxChange } from '@angular/material/chips';
import { AuthService } from '@zwiloo/user/auth';
import { isMobile } from '@zwiloo/util/tokens';

@Component({
  selector: 'zwi-ministry-sermons',
  templateUrl: './ministry-sermons.component.html',
  styleUrls: ['./ministry-sermons.component.scss'],
})
export class MinistrySermonsComponent implements OnDestroy {
  username = input('');
  @Input() hasSession = false;
  hasSession$: Observable<boolean>;
  active = 'Daily';
  added: Sermon[] = [];
  dailySermons: Sermon[] = [];
  sermons: Sermon[] = [];
  statusSubscription: Subscription;
  dailySubscription: Subscription;
  subscription: Subscription;
  status: CallState = LoadingState.INIT;

  sermonsLoaded = computed(() => {
    return this.getMinistry.dailyLoadingStatus() === LoadingState.LOADED;
  });

  private usernameEffect = effect(() => {
    if (this.username() !== '') {
      this.getMinistry.loadMinistry(this.username());
    }
  });
  constructor(
    public getMinistry: GetMinistryService,
    public authService: AuthService,
    public sermon: SermonService
  ) {
    this.hasSession$ = toObservable(authService.hasSession);
    this.subscription = getMinistry.sermons.subscribe((s) => {
      this.sermons = s;
    });
    this.dailySubscription = toObservable(getMinistry.dailySermons).subscribe(
      (s) => {
        this.dailySermons = s;
      }
    );

    this.statusSubscription = getMinistry.loadingStatus.subscribe((s) => {
      this.status = s;
    });
  }

  ngOnInit() {
    this.hasSession$.subscribe((s: boolean) => {
      if (s) {
        this.getMinistry.loadMinistry(this.username());
      } else {
        this.getMinistry.guestLoadMinistry(this.username());
      }
    });
  }

  ngOnDestroy(): void {
    this.statusSubscription.unsubscribe();
    this.subscription.unsubscribe();
  }

  isMobile() {
    return isMobile();
  }

  favoriteChange(starChange: StarChange) {
    if (starChange.value === 'star') {
      if (starChange.source.sermon !== undefined) {
        this.sermon.favorite(starChange.source.sermon);
      }
    } else {
      if (starChange.source.sermon !== undefined) {
        this.sermon.unfavorite(starChange.source.sermon);
      }
    }
  }

  hasSermons() {
    if (this.active === 'Daily') {
      return this.dailySermons.length > 0;
    } else {
      return this.sermons.length > 0;
    }
  }

  ministryLoaded() {
    return this.status === LoadingState.LOADED;
  }

  updateActive(c: MatChipListboxChange) {
    // Do not update active because it will remove highlight if an already selected
    // chip is clicked again
    if (c.value) {
      this.active = c.value;
    }
  }
}
