import { Component, OnInit } from '@angular/core';

import { ConvertAccountService } from '@zwiloo/ministry/context/convert-account.service';
import { Ministry } from '@zwiloo/ministry/ministry-account';
import { CallState, LoadingState } from '@zwiloo/util/state';
import { LoginInfo } from '@zwiloo/user/auth';
import { Subscription } from 'rxjs';

@Component({
  selector: 'zwi-ministry-convert',
  templateUrl: './ministry-convert.component.html',
  styleUrls: ['./ministry-convert.component.scss'],
  providers: [ConvertAccountService],
})
export class MinistryConvertComponent implements OnInit {
  ministry = new Ministry();
  status: CallState = LoadingState.INIT;
  statusSubscription: Subscription;

  constructor(private convertAccount: ConvertAccountService) {
    this.statusSubscription = convertAccount.status.subscribe((s) => {
      this.status = s;
    });
  }

  ngOnInit(): void {}

  updateMinistry(ministry: Ministry) {
    this.ministry = ministry;
    this.convertAccount.convertAccount(ministry);
    // this.ministryAccount.ministry = ministry;
    // this.changeStep('account');
    // this.store.dispatch(new ministryAccount.UpdateMinistry(ministry));
    // this.changeStep('account');
  }
}
