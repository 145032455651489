import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

import { SharedModule } from '@zwiloo/frontend/shared/shared.module';
import { SignUpCompletionComponent } from './sign-up-completion.component';
import { TypographyModule } from '@zwiloo/frontend/typography/typography.module';

@NgModule({
  declarations: [SignUpCompletionComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    ReactiveFormsModule,
    SharedModule,
    TypographyModule,
  ],
})
export class SignUpCompletionModule {}
