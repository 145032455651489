<zwi-widget>
  <form [formGroup]="form" (ngSubmit)="submit()">
    <mat-form-field appearance="fill" class="zwi-form-field--medium">
      <mat-label>Series name</mat-label>
      <input formControlName="series" matInput>
      <mat-error *ngIf="form.get('series')?.hasError('required')">Please enter a name</mat-error>
    </mat-form-field>
    <button mat-raised-button color="primary" style="margin-left: 8px;">Add</button>
  </form>
  <div class="mat-subtitle-2">Series</div>
  <mat-list>
    <mat-list-item *ngFor="let series of series">
      <mat-icon matListItemIcon>list_alt</mat-icon>
      <span matListItemTitle>{{ series.name }}</span>
    </mat-list-item>
  </mat-list>
</zwi-widget>
