import { Temporal } from '@js-temporal/polyfill';

import { Series, Speaker, Ministry } from '@zwiloo/ministry/domain';
import { formatDay, formatHourAgo } from '@zwiloo/util/time';

export class Sermon {
  constructor(
    public id = 0,
    public name = '',
    public description = '',
    public ministry = new Ministry(),
    public username = '',
    public series = new Series(),
    public orderInSeries = 0,
    public speaker = new Speaker(),
    public references: ReferenceSpan[] = [],
    public audio = '',
    public duration = 0,
    public favorite = false,
    public airDate = '',
    public createdAt = ''
  ) {}
}

export class Favorite extends Sermon {
  constructor(public notes: string) {
    super();
  }
}

export class ReferenceSpan {
  constructor(public begin: Reference, public end: Reference) {}
}

export class Reference {
  constructor(
    public book: number,
    public chapter: number,
    public verse: number
  ) {}
}

// export class Favorite extends Sermon {
//   favorite = false;
//
//   constructor(public notes: string = '') {
//     super();
//   }
// }

export function toFavorite(sermon: Sermon, favorite = false): Favorite {
  return {
    ...sermon,
    favorite,
    notes: '',
  };
}

export function sermonBooks(s: Sermon) {
  return s.references.map((r) => r.begin.book);
}

export function sermonAirDate(
  s: Sermon[],
  init: { airDate: string; sermons: Sermon[]; id: number }[] = []
) {
  return s.reduce((acc, next) => {
    const match = acc.find((a) => a.airDate === next.airDate);
    if (match !== undefined) {
      match.sermons.push(next);
    } else {
      acc.push({ airDate: next.airDate, sermons: [next], id: next.id });
    }
    return acc;
  }, init as { airDate: string; sermons: Sermon[]; id: number }[]);
}

export function sermonCreatedAt(
  s: Sermon[],
  init: { createdAt: string; sermons: Sermon[]; id: number }[] = []
) {
  const now = Temporal.Now.instant();
  return s.reduce((acc, next) => {
    const createdAt = Temporal.Instant.from(next.createdAt);
    const duration = now.since(createdAt);

    let groupKey: string;
    if (duration.total('hours') < 1) {
      groupKey = 'Now';
    } else if (duration.total('hours') < 24) {
      groupKey = formatHourAgo(next.createdAt);
    } else {
      groupKey = formatDay(next.createdAt);
    }

    const match = acc.find((a) => a.createdAt === groupKey);
    if (match !== undefined) {
      match.id = next.id;
      match.sermons.push(next);
    } else {
      acc.push({ createdAt: groupKey, sermons: [next], id: next.id });
    }
    return acc;
  }, init as { createdAt: string; sermons: Sermon[]; id: number }[]);
}

export function sermonsByBook(sermons: Sermon[]) {
  return sermons
    .reduce((acc, s) => {
      let bookID: number;
      if (s.references !== null && s.references.length > 0) {
        bookID = s.references[0].begin.book;
      } else {
        bookID = Number.MAX_SAFE_INTEGER;
      }
      const match = acc.find((a) => a.book === bookID);
      if (match) {
        match.sermons.push(s);
      } else {
        acc.push({
          book: bookID,
          sermons: [s],
        });
      }
      return acc;
    }, [] as { book: number; sermons: Sermon[] }[])
    .sort(({ book: a }, { book: b }) => a - b);
}
