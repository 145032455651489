import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Ministry } from '@zwiloo/ministry/domain';

export class SubscribeChange {
  constructor(
    public source: SubscribeButtonComponent,
    public value:
      | 'subscribe'
      | 'unsubscribe'
      | 'localChurchSubscribe'
      | 'localChurchUnsubscribe'
  ) {}
}

@Component({
  selector: 'zwi-subscribe-button',
  templateUrl: './subscribe-button.component.html',
  styleUrls: ['./subscribe-button.component.scss'],
})
export class SubscribeButtonComponent {
  @Input() ministry: Ministry = new Ministry();
  @Output() subscribeChange: EventEmitter<SubscribeChange> = new EventEmitter();

  // ministries$: Observable<any>;
  // subscription$: Observable<any>;

  constructor() {
    // this.ministries$ = store.select(fromSubscription.ministries);
    // this.ministry$ = store.select(fromStore.ministryInformation);
    // this.subscription$ = combineLatest(this.ministries$, this.ministry$).pipe(
    //   map(([ministries, ministry]) => {
    //     if (ministry) {
    //       return subscriptions.find(s => s.id === ministry.id) !== undefined;
    //     } else {
    //       return false;
    //     }
    //   })
    // );
  }

  subscribe() {
    this.subscribeChange.emit(new SubscribeChange(this, 'subscribe'));
  }

  unsubscribe() {
    this.subscribeChange.emit(new SubscribeChange(this, 'unsubscribe'));
  }

  subscribeLocalChurch() {
    this.subscribeChange.emit(
      new SubscribeChange(this, 'localChurchSubscribe')
    );
  }

  unsubscribeLocalChurch() {
    this.subscribeChange.emit(
      new SubscribeChange(this, 'localChurchUnsubscribe')
    );
  }
}
