import {
  Component,
  computed,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';

import { mobileWidth } from '@zwiloo/util/tokens';

@Component({
  selector: 'zwi-back-headline',
  templateUrl: './back-headline.component.html',
  styleUrls: ['./back-headline.component.scss'],
})
export class BackHeadlineComponent {
  @Input() backPath = '';
  @Output() navigateBack = new EventEmitter();

  constructor(private router: Router) {}

  classObject = computed(() => {
    return {
      'zwi-block-section': this.isMobile(),
      'zwi-flex-center': this.isMobile() === false,
    };
  });

  isMobile = computed(() => {
    return Capacitor.isNativePlatform() || window.innerWidth <= mobileWidth;
  });

  back() {
    this.navigateBack.emit();
  }
}
