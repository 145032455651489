<zwi-main-wrapper>
  <zwi-main-content>
    <mat-progress-bar *ngIf="status === 1" mode="indeterminate"></mat-progress-bar>
    <mat-toolbar>
      <div class="zwi-page-title">
        <mat-icon class="zwi-page-title__icon">check_circle</mat-icon>
        <div class="zwi-page-title__text">Following</div>
      </div>
    </mat-toolbar>
    <div class="zwi-main-content">
      <ng-container *ngIf="authService.sessionStatus() !== 2; else content"></ng-container>
      <ng-template #content>
        <zwi-sign-in-call-to-action *ngIf="authService.hasSession() === false; else subscriptions">
          <ng-container title>Sign in to follow ministries</ng-container>
          <ng-container content>Once you sign in, you'll be able to follow ministries.</ng-container>
        </zwi-sign-in-call-to-action>
        <ng-template #subscriptions>
          <zwi-subscriptions></zwi-subscriptions>
        </ng-template>
      </ng-template>
    </div>
  </zwi-main-content>
</zwi-main-wrapper>
