import { Capacitor } from '@capacitor/core';
import { Subscription } from 'rxjs';

import {
  Component,
  computed,
  ElementRef,
  signal,
  ViewChild,
  viewChild,
  WritableSignal,
} from '@angular/core';
// import { Sermon } from '../../../sermon/models';
import { Sermon } from '@zwiloo/sermon/domain';
import { AudioDirective } from '../audio/audio.directive';
import { PlayerService, Status } from '@zwiloo/sermon/context/player.service';
import { mobileWidth } from '@zwiloo/util/tokens';
import { ReferenceRange } from '@zwiloo/verse/domain/reference';
import { ReferenceFormatter } from '@zwiloo/verse/domain/reference-formatter';
import { ZwiReferenceFormats } from '@zwiloo/verse/domain/reference-formats';

// import { Store } from '@ngrx/store';
// import * as fromPlayer from '../../reducers';

@Component({
  selector: 'zwi-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss'],
})
export class PlayerComponent {
  sermon = signal<Sermon | null>(null);
  @ViewChild(AudioDirective) audio!: AudioDirective;
  @ViewChild('audioRef') audioRef!: ElementRef<HTMLMediaElement>;
  informationElement = viewChild<HTMLDivElement>('informationElement');
  nameElement = viewChild<HTMLDivElement>('nameElement');
  currentTime: WritableSignal<number> = this.playerService.currentTime;
  duration: number = 0;
  isNativePlatform = Capacitor.isNativePlatform();
  src: string | undefined;
  status: Status = 'EMPTY';

  // currentTimeSubscription: Subscription;
  durationSubscription: Subscription;
  sermonSubscription: Subscription;
  statusSubscription: Subscription;

  isMobile = computed(() => {
    return window.innerWidth <= mobileWidth;
  });
  isTablet = computed(() => {
    return this.isMobile() === false && this.isNativePlatform;
  });
  name = computed(() => {
    const name = this.sermon()?.name;
    const reference = this.getReferences(this.sermon()?.references);

    // Desktop includes the reference below the name
    // Mobile includes time remaining so should include reference
    if (this.isMobile()) {
      if (name === reference) {
        return name;
      } else {
        return `${name} - ${reference}`;
      }
    } else {
      return name;
    }
  });
  remainingTime = computed(() => {
    const remainingTime = this.duration - this.currentTime();
    const hours = Math.floor(remainingTime / 3600);
    const minutes = Math.floor((remainingTime % 3600) / 60);
    const seconds = Math.floor(remainingTime % 60);

    if (hours > 0) {
      return minutes === 0 ? `${hours}hr left` : `${hours}hr ${minutes}m left`;
    } else if (minutes > 0) {
      return seconds === 0
        ? `${minutes}m left`
        : `${minutes}m ${seconds}s left`;
    } else {
      return `${seconds}s left`;
    }
  });
  shouldScroll = computed(() => {
    // @ts-ignore
    const informationEl = this.informationElement()?.nativeElement;
    // @ts-ignore
    const nameEl = this.nameElement()?.nativeElement;
    // console.log('container', container);

    if (informationEl !== undefined && nameEl !== undefined) {
      console.log('informationEl.clientWidth', nameEl.scrollWidth);
      console.log('nameEl.clientWidth', nameEl.clientWidth);
      return nameEl.clientWidth > informationEl.clientWidth;
    } else {
      return false;
    }
  });

  constructor(private playerService: PlayerService) {
    this.durationSubscription = playerService.duration.subscribe((d) => {
      this.duration = d;
    });

    this.sermonSubscription = playerService.sermon.subscribe((s) => {
      this.sermon.set(s);
      if (s !== null) {
        if (Capacitor.getPlatform() !== 'android') {
          navigator.mediaSession.metadata = new MediaMetadata({
            title: s.name,
            artist: s.ministry.name,
            album: s.series.name,
            artwork: [
              {
                src: s.ministry.profileImage,
              },
            ],
          });
        }

        this.audioRef.nativeElement.src = s.audio;

        this.playerService.updateStatus('PLAYING');
      }
    });

    this.statusSubscription = playerService.status.subscribe((s) => {
      this.status = s;
      if (s === 'PLAYING') {
        if (this.audioRef.nativeElement.paused === true) {
          this.audioRef.nativeElement.play();
        }
      } else if (s === 'PAUSED') {
        this.audioRef.nativeElement.pause();
      }
    });
  }

  ngOnViewInit() {
    if (Capacitor.getPlatform() !== 'android') {
      this.setupMediaSessionHandlers();
    }
  }

  setupMediaSessionHandlers() {
    const actionHandlers: Map<MediaSessionAction, (a?: any) => void> = new Map([
      [
        'play',
        () => {
          this.playerService.updateStatus('PLAYING');
        },
      ],
      [
        'pause',
        () => {
          this.playerService.updateStatus('PAUSED');
        },
      ],
      [
        'seekto',
        (details: any) => {
          this.audioRef.nativeElement.currentTime = details.seekTime;
        },
      ],
    ]);

    for (const [action, handler] of actionHandlers) {
      try {
        navigator.mediaSession.setActionHandler(action, handler);
      } catch (error) {
        console.log(`${action} is not supported.`);
      }
    }

    const events = [
      'playing',
      'paused',
      'durationchange',
      'ratechange',
      'timechange',
    ];
    for (const event of events) {
      this.audioRef.nativeElement.addEventListener(
        event,
        this.updateMediaSessionState
      );
    }
  }

  updateMediaSessionState() {
    navigator.mediaSession.playbackState = this.audioRef.nativeElement.paused
      ? 'paused'
      : 'playing';
    navigator.mediaSession.setPositionState({
      duration: this.audioRef.nativeElement.duration,
      playbackRate: this.audioRef.nativeElement.playbackRate,
      position: this.audioRef.nativeElement.currentTime,
    });
  }

  addThirtySeconds() {
    const audioElement = this.audioRef.nativeElement;
    const currentTime = audioElement.currentTime;
    const newTime = currentTime + 30;
    audioElement.currentTime = newTime;
    this.playerService.updateStatus('PLAYING');
  }

  subtractThirtySeconds() {
    const audioElement = this.audioRef.nativeElement;
    const currentTime = audioElement.currentTime;
    const newTime = currentTime - 30;
    audioElement.currentTime = newTime;
    this.playerService.updateStatus('PLAYING');
  }

  updateStatus(play: boolean) {
    if (play) {
      this.playerService.updateStatus('PLAYING');
    } else {
      this.playerService.updateStatus('PAUSED');
    }
  }

  changeTime(val: number) {
    this.playerService.updateStatus('PAUSED');
    const audioElement = this.audioRef.nativeElement;
    const newTime = (val / 100) * this.duration;
    audioElement.currentTime = newTime;
    this.playerService.updateStatus('PLAYING');
  }

  hasReferences() {
    const length = this.sermon()?.references?.length;
    return length !== undefined && length > 0;
  }

  getReferences(referenceRanges?: ReferenceRange[]) {
    if (referenceRanges !== undefined) {
      const formatter = new ReferenceFormatter();
      return referenceRanges
        .map((range) =>
          formatter.formatRange(
            range,
            ZwiReferenceFormats.display.referenceInput
          )
        )
        .join(', ');
    } else {
      return '';
    }
  }

  setStatusPaused() {
    this.playerService.updateStatus('PAUSED');
  }

  updateDuration(evt: Event) {
    const elmt: HTMLMediaElement = evt.srcElement as HTMLMediaElement;
    this.playerService.updateDuration(elmt.duration);
  }

  updateTime(evt: Event) {
    const elmt: HTMLMediaElement = evt.srcElement as HTMLMediaElement;
    this.playerService.currentTime.set(elmt.currentTime);
  }
}
