import isEqual from 'lodash/isEqual';

import { Injectable } from '@angular/core';

import { Reference, ReferenceRange } from './reference';
import { BOOKS_TESTAMENTS } from './books-testaments';
import { ReferenceAdapter } from './reference-adapter';

const bookRe = /^(\d?\s?\w+)$/;
const bookChapterRe = /^(\d?\s?\w+)\s(\d+)$/;
const bookChapterVerseRe = /^(\d?\s?\w+)\s(\d+):(\d+)$/;
const bookChapterTwoVersesRe = /^(\d?\s?\w+)\s(\d+):(\d+)-(\d+)$/;
const bookTwoChapterRe = /^(\d?\s?\w+)\s(\d+)-(\d+)$/;
const bookTwoChaptersTwoVersesRe = /^(\d?\s?\w+)\s(\d+):(\d+)-(\d+):(\d+)$/;

@Injectable({
  providedIn: 'root',
})
export class ReferenceRangeAdapter {
  constructor(public referenceAdapter: ReferenceAdapter) {}

  getBookNames(): string[] {
    return BOOKS_TESTAMENTS.map((book) => book.name);
  }

  isSame(r1: ReferenceRange, r2: ReferenceRange) {
    return isEqual(r1, r2);
  }

  _parseBook(value: string): ReferenceRange {
    const matches = bookRe.exec(value) as string[];
    const bookName = matches[1];
    const bookIndex = this.getBookNames().findIndex((n) => n === bookName);
    const start = this.referenceAdapter.createReference(bookIndex);
    const end = this.referenceAdapter.createReference(bookIndex);
    return new ReferenceRange(start, end);
  }

  _parseBookChapter(value: string): ReferenceRange {
    // TODO: capture errors in regex matching, matches could be null
    // const matches = bookChapterRe.exec(value);
    const matches = bookChapterRe.exec(value) as string[];
    const bookName = matches[1];
    const bookIndex = this.getBookNames().findIndex((n) => n === bookName);
    const chapter = Number(matches[2]);
    const start = this.referenceAdapter.createReference(bookIndex, chapter);
    const end = this.referenceAdapter.createReference(bookIndex, chapter);
    return new ReferenceRange(start, end);
  }

  _parseBookChapterVerse(value: string): ReferenceRange {
    const matches = bookChapterVerseRe.exec(value) as string[];
    const bookName = matches[1];
    const bookIndex = this.getBookNames().findIndex((n) => n === bookName);
    const chapter = Number(matches[2]);
    const verse = Number(matches[3]);
    const start = this.referenceAdapter.createReference(
      bookIndex,
      chapter,
      verse
    );
    const end = this.referenceAdapter.createReference(
      bookIndex,
      chapter,
      verse
    );
    return new ReferenceRange(start, end);
  }

  _parseBookChapterTwoVerses(value: string): ReferenceRange {
    const matches = bookChapterTwoVersesRe.exec(value) as string[];
    const bookName = matches[1];
    const bookIndex = this.getBookNames().findIndex((n) => n === bookName);
    const chapter = Number(matches[2]);
    const verse1 = Number(matches[3]);
    const verse2 = Number(matches[4]);
    const start = this.referenceAdapter.createReference(
      bookIndex,
      chapter,
      verse1
    );
    const end = this.referenceAdapter.createReference(
      bookIndex,
      chapter,
      verse2
    );
    return new ReferenceRange(start, end);
  }

  _parseBookTwoChapter(value: string): ReferenceRange {
    const matches = bookTwoChapterRe.exec(value) as string[];
    const bookName = matches[1];
    const bookIndex = this.getBookNames().findIndex((n) => n === bookName);
    const chapter1 = Number(matches[2]);
    const verse1 = 1;
    const chapter2 = Number(matches[3]);
    const reference2 = this.referenceAdapter.createReference(
      bookIndex,
      chapter2
    );
    const verse2 = this.referenceAdapter.getNumVersesInChapter(reference2);
    const start = this.referenceAdapter.createReference(
      bookIndex,
      chapter1,
      verse1
    );
    const end = this.referenceAdapter.createReference(
      bookIndex,
      chapter2,
      verse2
    );
    return new ReferenceRange(start, end);
  }

  _parseBookTwoChaptersTwoVerses(value: string): ReferenceRange {
    const matches = bookTwoChaptersTwoVersesRe.exec(value) as string[];
    const bookName = matches[1];
    const bookIndex = this.getBookNames().findIndex((n) => n === bookName);
    const chapter1 = Number(matches[2]);
    const verse1 = Number(matches[3]);
    const chapter2 = Number(matches[4]);
    const verse2 = Number(matches[5]);
    const start = this.referenceAdapter.createReference(
      bookIndex,
      chapter1,
      verse1
    );
    const end = this.referenceAdapter.createReference(
      bookIndex,
      chapter2,
      verse2
    );
    return new ReferenceRange(start, end);
  }

  // parseBookChapterTwoVerses(): Reference {
  //
  // }

  parse(value: string): ReferenceRange {
    // TODO: Capture if no regex matches
    switch (true) {
      case bookRe.test(value):
        return this._parseBook(value);
      case bookChapterRe.test(value):
        return this._parseBookChapter(value);
      case bookChapterVerseRe.test(value):
        return this._parseBookChapterVerse(value);
      case bookChapterTwoVersesRe.test(value):
        return this._parseBookChapterTwoVerses(value);
      case bookTwoChapterRe.test(value):
        return this._parseBookTwoChapter(value);
      case bookTwoChaptersTwoVersesRe.test(value):
        return this._parseBookTwoChaptersTwoVerses(value);
    }
    return new ReferenceRange(new Reference(), null);
    // const book = /^(\d?\s?\w+)$/;
    // const bookChapter = /^(\d?\s?\w+)\s(\d+)$/;
    // const bookChapterVerse = /^(\d?\s?\w+)\s(\d+):(\d+)$/;
    // const bookChapterTwoVerses = /^(\d?\s?\w+)\s(\d+):(\d+)-(\d+)$/;
    // const bookTwoChapter = /^(\d?\s?\w+)\s(\d+)-(\d+)$/;
    // const bookTwoChaptersTwoVerses = /^(\d?\s?\w+)\s(\d+):(\d+)-(\d+):(\d+)$/;
  }
}
