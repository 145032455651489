<form [formGroup]="form" (ngSubmit)="submit()" name="login">
  <mat-form-field class="zwi-form-field--full">
    <mat-label>Email</mat-label>
    <input matInput formControlName="email" placeholder="Email Address" name="email" autocomplete="email" autocapitalize="off" autocorrect="off" autocomplete="off" spellcheck="false">
    <mat-error>
      <ng-container *ngIf="hasResetError(); else standard">
        <ng-container *ngIf="form.get('email')?.errors?.resetRequired; else resetValid">
          {{ formError.getErrorMessage('email', 'required') }}</ng-container>
        <ng-template #resetValid>
          <ng-container *ngIf="form.controls.email.errors?.resetValid">
            {{ formError.getErrorMessage('email', 'valid') }}</ng-container>
        </ng-template>
      </ng-container>
      <ng-template #standard>
        <ng-container *ngIf="form.get('email')?.errors?.required; else valid">{{ formError.getErrorMessage('email', 'required') }}</ng-container>
        <ng-template #valid>
          <ng-container *ngIf="form.controls.email.errors?.valid; else active">{{ formError.getErrorMessage('email', 'valid') }}</ng-container>
        </ng-template>
        <ng-template #active>
          <ng-container *ngIf="form.controls.email.errors?.active">{{ formError.getErrorMessage('email', 'active') }}</ng-container>
        </ng-template>
      </ng-template>
    </mat-error>
  </mat-form-field>
  <!--    <div *ngIf="form.controls.email.errors?.valid">{{ form.controls.email.errors?.valid }}</div>-->
  <mat-form-field class="zwi-form-field--full">
    <mat-label>Password</mat-label>
    <input matInput formControlName="password" type="password" type="this.form.get('date').setErrors(null)" name="password" autocomplete="current-password" placeholder="Password">
    <mat-error *ngIf="form.controls.password.errors?.required; else valid">{{ formError.getErrorMessage('password1', 'required') }}</mat-error>
    <ng-template #valid>
      <mat-error *ngIf="form.controls.password.errors?.valid">{{ formError.getErrorMessage('password', 'valid') }}</mat-error>
    </ng-template>
  </mat-form-field>
  <div class="zwi-widget zwi-flex-start">
    <div class="zwi-spacer"></div>
    <button mat-raised-button color="primary" type="submit">Sign In</button>
  </div>
</form>
<button (click)="resetPassword()" mat-button>Forgot Password?</button>
