<zwi-info-header>
  <zwi-avatar [url]="ministry.profileImage" avatar>Avatar</zwi-avatar>
  <zwi-display headline>{{ ministry.name }}</zwi-display>
  <zwi-sermon-information caption [sermons]="sermons"></zwi-sermon-information>
  <zwi-body *ngIf="ministry.localChurchSubscribed" overline>Local Church</zwi-body>
  <zwi-subscribe-button *ngIf="ministry.localChurchSubscribed === false"  [ministry]="ministry" (subscribeChange)="changeSubscription($event)" action></zwi-subscribe-button>
</zwi-info-header>
<!--  <zwi-caption caption>Caption-->
<!--  </zwi-caption>-->

<!--<div class="zwi-main-content">-->
<!--  <div class="zwi-banner">-->
<!--    <div class="layout-row layout-align-start-center">-->
<!--      <zwi-avatar></zwi-avatar>-->
<!--      <div style="margin-left: 16px;">-->
<!--        <div style="display: flex; align-items: center;">-->
<!--          <zwi-ministry-name [ministry]="ministry$ | async"></zwi-ministry-name>-->
<!--          <zwi-subscribe-button [ministry]="ministry$ | async"></zwi-subscribe-button>-->
<!--        </div>-->
<!--        <zwi-sermon-information></zwi-sermon-information>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
