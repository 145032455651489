<div class="zwi-info-header">
  <ng-content select="[avatar]"></ng-content>
  <div class="zwi-info-header__body zwi-block-section">
    <div class="zwi-block-section">
      <ng-content select="[overline]"></ng-content>
      <ng-content select="[headline]"></ng-content>
      <ng-content select="[caption]"></ng-content>
    </div>
    <div class="zwi-info-header__action">
      <ng-content select="[action]"></ng-content>
    </div>
  </div>
</div>
