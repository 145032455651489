import { Component, computed } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';

import { SearchService } from '@zwiloo/search/context/search.service';
import { Sermon } from '@zwiloo/sermon/domain';
import { MatChipListboxChange } from '@angular/material/chips';
import { AccountService } from '@zwiloo/user/account';
import { AuthService } from '@zwiloo/user/auth';
import { isMobile } from '@zwiloo/util/tokens';

@Component({
  selector: 'zwi-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  providers: [SearchService],
})
export class SearchComponent {
  active = 'Ministry';

  criteria = '';
  session: string | null = null;

  isMobile = computed(() => {
    return isMobile();
  });

  constructor(
    private accountService: AccountService,
    private search: SearchService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    // this.session = this.accountService.getSession();
  }

  onSearch(criteria: string) {
    if (this.active === 'Ministry') {
      // TODO: search ministry
      if (this.authService.hasSession() === false) {
        this.search.guestSearchMinistries(criteria);
      } else {
        this.search.searchMinistries(criteria);
      }
      // this.store.dispatch(
      //   MinistryActions.searchMinistries({
      //     criteria,
      //   })
      // );
    } else if (this.active === 'Speaker') {
      if (this.authService.hasSession() === false) {
        this.search.guestSearchSermonsBySpeaker(criteria);
      } else {
        this.search.searchSermonsBySpeaker(criteria);
      }
      // this.store.dispatch(
      //   SearchSermonActions.searchSermonsBySpeaker({ criteria })
      // );
      // TODO: search sermons
      // this.store.dispatch(
      //   new SermonActions.Search({
      //     criteria,
      //     field: this.activeTab.toLowerCase(),
      //   })
      // );
    } else if (this.active === 'Verse') {
      if (this.authService.hasSession() === false) {
        this.search.guestSearchSermonsByVerse(criteria);
      } else {
        this.search.searchSermonsByVerse(criteria);
      }
      // this.store.dispatch(
      //   SearchSermonActions.searchSermonsByVerse({ criteria })
      // );
    } else {
      if (this.authService.hasSession() === false) {
        this.search.guestSearchSermons(criteria);
      } else {
        this.search.searchSermons(criteria);
      }
      // this.store.dispatch(SearchSermonActions.searchSermons({ criteria }));
    }
  }

  setCriteria(c: string) {
    this.criteria = c;
  }

  // onSelectedTabChange(event: MatTabChangeEvent) {
  //   const tabs = ['Ministry', 'Speaker', 'Verse', 'Title'];
  //   const tab = tabs[event.index];
  //   this.active = tab;
  //   this.search.reset();
  // }

  updateActive(c: MatChipListboxChange) {
    if (c.value) {
      this.active = c.value;
      this.search.reset();
    }
  }
}
