import { Observable, Subscriber } from 'rxjs';
import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class UsernameValidator {
  username: string;

  constructor(public http: HttpClient) {
    this.username = '';
  }

  public isYou = (control: UntypedFormControl) => {
    if (control.value === this.username) {
      return { isYou: true };
    } else {
      return null;
    }
  };

  updateUsername(username: string) {
    this.username = username;
  }

  public usernameExists = (control: UntypedFormControl) => {
    return this._getNewEmailObservable(control).pipe(
      map((response) => {
        return response == null ? { usernameExists: true } : null;
      })
    );
  };

  public usernameTaken = (username: string) => {
    return (control: UntypedFormControl) => {
      const newUsername = control.value;
      // When the username is undefined it is assumed that the username is valid
      // this may happen because the username has not been provided or that it has not
      // yet returned from the server, this may be subject to change
      if (
        username === undefined ||
        newUsername === username ||
        newUsername === ''
      ) {
        // return null;
        return Observable.create(function (observer: Subscriber<any>) {
          observer.next(null);
          observer.complete();
        });
      } else {
        return this._getNewEmailObservable(control);
      }
    };
  };

  isValidEmail(control: UntypedFormControl): { [s: string]: boolean } | null {
    const re =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+(?:[A-Z]{2}|com|org|net|gov|mil|biz|info|mobi|name|aero|jobs|museum)\b/;
    const valid = re.test(control.value);
    if (valid) {
      return null;
    } else {
      return { isValidEmail: true };
    }
  }

  _getNewEmailObservable(control: UntypedFormControl) {
    const criteria = { username: control.value };
    return this.http.post('/app/account/new-email', criteria);
  }
}
