import { BehaviorSubject, Subject } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { AdminDataService } from '../data-interface/admin-data.service';
import { TokenDataService } from '@zwiloo/token/data-interface/token-data.service';
import { Ministry } from '@zwiloo/ministry/domain';

import { UserDataService } from '@zwiloo/user/data-interface/user-data.service';
import { User } from '@zwiloo/user/domain';
import { Account, AccountService } from '@zwiloo/user/account';
import { CallState, LoadingState } from '@zwiloo/util/state';

@Injectable()
export class AdminService {
  public admins = new Subject<string[]>();
  public addStatus = new BehaviorSubject<CallState>(LoadingState.INIT);
  public ministry = new Subject<Ministry>();
  public user = new Subject<User>();
  public status = new BehaviorSubject<CallState>(LoadingState.INIT);

  constructor(
    private account: AccountService,
    private adminData: AdminDataService,
    private userData: UserDataService,
    private tokenData: TokenDataService
  ) {}

  add(email: string, ministryID: number, tokenID: string) {
    this.addStatus.next(LoadingState.LOADING);
    this.adminData.add(email, ministryID, tokenID).subscribe(() => {
      this.addStatus.next(LoadingState.LOADED);
    });
  }

  addWithNewAccount(
    email: string,
    ministryID: number,
    tokenID: string,
    account: Account
  ) {
    this.addStatus.next(LoadingState.LOADING);
    this.account
      .signUp(account)
      .pipe(
        mergeMap((a) => this.account.verifyEmail(a.id, tokenID)),
        mergeMap((_) => this.adminData.add(email, ministryID, tokenID))
      )
      .subscribe(() => {
        this.addStatus.next(LoadingState.LOADED);
      });
  }

  getAdmins() {
    const m = this.account.getMinistry();
    this.adminData.getAdmins(m.id).subscribe((a) => this.admins.next(a));
  }

  sendInvitation(email: string) {
    this.status.next(LoadingState.LOADING);
    this.adminData.sendInvitation(email).subscribe(() => {
      this.status.next(LoadingState.LOADED);
    });
  }

  getUser(email: string) {
    this.userData.getUser(email).subscribe((u) => this.user.next(u));
  }

  verifyToken(token: string, email: string) {
    this.status.next(LoadingState.LOADING);
    this.tokenData
      .verifyToken(token, email)
      .pipe(
        filter((r) => r.id !== 0),
        mergeMap((r) => this.adminData.getMinistryToken(r.id))
      )
      .subscribe((m) => {
        this.ministry.next(m);
        this.status.next(LoadingState.LOADED);
      });
  }
}
