import { BehaviorSubject, forkJoin, Subject } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Ministry } from '@zwiloo/ministry/domain';
import { Sermon } from '@zwiloo/sermon/domain';
import { CallState, LoadingState } from '@zwiloo/util/state';
import { UserDataService } from '@zwiloo/user/data-interface/user-data.service';
import { MinistryDataService } from '@zwiloo/ministry/data-interface/ministry-data.service';
import { MinistryService } from '@zwiloo/ministry/ministry.service';
import { SermonDataService } from '@zwiloo/sermon/data-interface/sermon-data.service';
import { FavoritesService } from '@zwiloo/user/context/favorites.service';

@Injectable()
export class SearchService {
  public ministries = new Subject<Ministry[]>();
  public sermons = new Subject<Sermon[]>();
  public status = new BehaviorSubject<CallState>(LoadingState.INIT);

  constructor(
    private http: HttpClient,
    private favorites: FavoritesService,
    private ministryData: MinistryDataService,
    private ministry: MinistryService,
    private sermonData: SermonDataService,
    private userData: UserDataService
  ) {}

  guestSearchMinistries(criteria: string) {
    this.status.next(LoadingState.LOADING);
    this.ministryData.search(criteria).subscribe((ministries) => {
      this.ministries.next(ministries);
      this.status.next(LoadingState.LOADED);
    });
  }

  guestSearchSermons(criteria: string) {
    this.status.next(LoadingState.LOADING);
    this.sermonData.searchSermons(criteria).subscribe((s) => {
      this.sermons.next(s);
      this.status.next(LoadingState.LOADED);
    });
  }

  guestSearchSermonsBySpeaker(criteria: string) {
    this.status.next(LoadingState.LOADING);
    this.sermonData.searchSermonsBySpeaker(criteria).subscribe((s) => {
      this.sermons.next(s);
      this.status.next(LoadingState.LOADED);
    });
  }

  guestSearchSermonsByVerse(criteria: string) {
    this.status.next(LoadingState.LOADING);
    this.sermonData.searchSermonsByVerse(criteria).subscribe((s) => {
      this.sermons.next(s);
      this.status.next(LoadingState.LOADED);
    });
  }

  searchMinistries(criteria: string) {
    this.status.next(LoadingState.LOADING);
    forkJoin({
      ministries: this.ministryData.search(criteria),
      localChurches: this.userData.loadLocalChurch(),
      subscriptions: this.userData.loadSubscriptions(),
    }).subscribe(({ ministries, localChurches, subscriptions }) => {
      const m = this.ministry.markSubscriptions(
        ministries,
        localChurches,
        subscriptions
      );
      this.ministries.next(m);
      this.status.next(LoadingState.LOADED);
    });
  }

  searchLocalChurch(criteria: string) {
    this.status.next(LoadingState.LOADING);
    forkJoin({
      ministries: this.ministryData.search(criteria),
      localChurches: this.userData.loadLocalChurch(),
    }).subscribe(({ ministries, localChurches }) => {
      const m = this.ministry.markSubscriptions(
        ministries,
        localChurches,
        [],
        'localChurch'
      );
      this.ministries.next(m);
      this.status.next(LoadingState.LOADED);
    });
  }

  searchSermons(criteria: string) {
    this.status.next(LoadingState.LOADING);
    this.favorites
      .hydrateFavorite(this.sermonData.searchSermons(criteria))
      .subscribe((s) => {
        this.sermons.next(s);
        this.status.next(LoadingState.LOADED);
      });
  }

  searchSermonsBySpeaker(criteria: string) {
    this.status.next(LoadingState.LOADING);
    this.favorites
      .hydrateFavorite(this.sermonData.searchSermonsBySpeaker(criteria))
      .subscribe((s) => {
        this.sermons.next(s);
        this.status.next(LoadingState.LOADED);
      });
  }

  searchSermonsByVerse(criteria: string) {
    this.status.next(LoadingState.LOADING);
    this.favorites
      .hydrateFavorite(this.sermonData.searchSermonsByVerse(criteria))
      .subscribe((s) => {
        this.sermons.next(s);
        this.status.next(LoadingState.LOADED);
      });
  }

  reset() {
    this.status.next(LoadingState.INIT);
    this.ministries.next([]);
    this.sermons.next([]);
  }
}
