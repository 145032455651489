import { Component, EventEmitter, Output } from '@angular/core';

import { Account } from '@zwiloo/user/account';

@Component({
  selector: 'zwi-signup-account',
  templateUrl: './signup-account.component.html',
})
export class SignupAccountComponent {
  @Output() changeStep = new EventEmitter<string>();
  @Output() updateAccount = new EventEmitter<Account>();

  previous() {
    this.changeStep.emit('ministry');
  }
}
