<!--  <div [ngClass]="{'header-panel&#45;&#45;playing': playingSermon.sermon}" class="header-panel">-->
<!--  <div [ngClass]="{'header-panel&#45;&#45;playing': playingSermon.sermon}" class="header-panel">-->
<div class="zwi-main__side zwi-md-block">
  <zwi-nav-menu [menuItems]="menuItems"></zwi-nav-menu>
</div>
<div class="zwi-main__content">
  <router-outlet></router-outlet>
</div>

<!--<div class="zwi-main__content">-->
<!--  <div class="zwi-content__wrapper">-->
<!--    -->
<!--  </div>-->
<!--</div>-->
<!--  <player></player>-->
