import { Subscription } from 'rxjs';

import { Component, Input, OnInit } from '@angular/core';

import { Series } from '@zwiloo/ministry/domain';
import { StudioSeriesService } from '@zwiloo/studio/context/studio-series.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Sermon } from '@zwiloo/sermon/domain';

@Component({
  selector: 'zwi-current-series',
  templateUrl: './current-series.component.html',
  styleUrls: ['./current-series.component.scss'],
})
export class CurrentSeriesComponent implements OnInit {
  series = new UntypedFormControl(null, Validators.required);
  currentSeriesID = 0;
  subscriptionSermonHistory: Subscription;
  @Input() seriesOptions: Series[] = [];
  // subscription: Subscription;

  constructor(private studioSeries: StudioSeriesService) {
    this.subscriptionSermonHistory = studioSeries.sermonHistory.subscribe(
      (s) => {
        if (s.length > 0) {
          const currentSermon = s[0];
          this.currentSeriesID = currentSermon.series.id;
          this.series.setValue(this.currentSeriesID);
        }
      }
    );
  }

  ngOnInit() {
    this.studioSeries.getCurrentSeries();
  }

  submit() {
    if (this.series.valid) {
      const seriesID = this.series.value;
      // Do not set current series if it hasn't changed because it will restart the series
      // which might be confusing, may want this behavior in which case you would want
      // to give a warning.
      if (seriesID !== this.currentSeriesID) {
        this.studioSeries.setCurrentSeries(seriesID);
      }
    }
  }
}
