<div class="zwi-section">
  <zwi-headline>Admin</zwi-headline>
  <div>
    <mat-form-field appearance="fill" class="zwi-vertical-form__md-field" [ngClass]="{'zwi-form-field--full': isMobile()}">
      <mat-label>Email</mat-label>
      <input [formControl]="email" matInput>
    </mat-form-field>
    <div>
      <button mat-flat-button color="primary" (click)="add()">Add</button>
    </div>
  </div>
  <mat-list>
    <mat-list-item *ngFor="let a of admins">
      <div matLine>{{ a }}</div>
    </mat-list-item>
  </mat-list>
</div>
