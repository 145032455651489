import {
  Component,
  computed,
  effect,
  ElementRef,
  Input,
  signal,
  ViewChild,
  ViewEncapsulation,
  WritableSignal,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatSliderModule } from '@angular/material/slider';

import { Status } from '@zwiloo/sermon/context/player.service';
import { Sermon } from '@zwiloo/sermon/domain';
import { TimeDisplayComponent } from '@zwiloo/frontend/shared/player/time-display/time-display.component';
import { SermonService } from '../../sermon.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'zwi-embed-player',
  standalone: true,
  imports: [MatIconModule, MatSliderModule, TimeDisplayComponent],
  templateUrl: './embed-player.component.html',
  styleUrl: './embed-player.component.scss',
})
export class EmbedPlayerComponent {
  sermon$: Observable<Sermon>;
  @Input() autoPlay = false;
  @ViewChild('audioRef') audioRef!: ElementRef<HTMLMediaElement>;
  bc = new BroadcastChannel('test_notification');

  // Signals
  currentTime: WritableSignal<number> = signal(0);
  duration: WritableSignal<number> = signal(0);
  status: WritableSignal<Status> = signal('EMPTY');

  // Computed
  percent = computed(() => {
    return this.duration() ? (this.currentTime() / this.duration()) * 100 : 0;
  });
  remainingTime = computed(() => {
    return this.duration() - this.currentTime();
  });

  constructor(public sermonService: SermonService) {
    effect(() => {
      if (this.sermonService.status() === 'PLAYING') {
        this.audioRef.nativeElement.play();
      } else if (this.sermonService.status() === 'PAUSED') {
        this.audioRef.nativeElement.pause();
      }
    });

    this.sermon$ = toObservable(this.sermonService.sermon);
  }

  ngOnInit() {
    this.bc.onmessage = (e) => {
      this.sermonService.status.set('PAUSED');
    };

    this.sermon$.subscribe((s) => {
      if (this.audioRef !== undefined) {
        if (s.id !== 0) {
          if (this.autoPlay) {
            this.pause();
            this.audioRef.nativeElement.src = s.audio;
            this.play();
          } else {
            this.audioRef.nativeElement.src = s.audio;
          }
        }
      }
    });
  }

  addTenSeconds() {
    const newTime = this.getNewTime(10);
    this.changeTime(newTime);
  }

  changeTime(time: number) {
    const isPlaying = this.sermonService.status() === 'PLAYING';
    this.sermonService.status.set('PAUSED');
    const audioElement = this.audioRef.nativeElement;
    audioElement.currentTime = time;
    if (isPlaying) {
      this.sermonService.status.set('PLAYING');
    }
  }

  changeTimeByPercent(val: number) {
    const newTime = (val / 100) * this.duration();
    this.changeTime(newTime);
  }
  getNewTime(change: number) {
    const audioElement = this.audioRef.nativeElement;
    const currentTime = audioElement.currentTime;
    const newTime = currentTime + change;
    return newTime;
  }

  pause() {
    this.sermonService.status.set('PAUSED');
  }

  play() {
    this.bc.postMessage('pause');
    this.sermonService.status.set('PLAYING');
  }

  subtractTenSeconds() {
    const newTime = this.getNewTime(-10);
    this.changeTime(newTime);
  }

  updateDuration(evt: Event) {
    const elmt: HTMLMediaElement = evt.srcElement as HTMLMediaElement;
    this.duration.set(elmt.duration);
  }

  updateTime(evt: Event) {
    const elmt: HTMLMediaElement = evt.srcElement as HTMLMediaElement;
    this.currentTime.set(elmt.currentTime);
  }
}
