import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PasswordService } from './context/password.service';
import { PasswordDataService } from './data-interface/password-data.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [PasswordDataService, PasswordService],
})
export class SettingsModule {}
