import { Component, OnInit } from '@angular/core';
import { Account, AccountService } from '@zwiloo/user/account';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from '@zwiloo/settings/context/admin.service';
import { CallState, LoadingState } from '@zwiloo/util/state';
import { Subscription } from 'rxjs';
import { AuthService, LoginInfo } from '@zwiloo/user/auth';

@Component({
  selector: 'zwi-signup-completion',
  templateUrl: './sign-up-completion.component.html',
  styleUrls: ['./sign-up-completion.component.scss'],
  providers: [AdminService],
})
export class SignUpCompletionComponent implements OnInit {
  account = new Account();
  status: CallState = LoadingState.INIT;
  statusSubscription: Subscription;
  email = '';
  ministryId = 0;
  token = '';

  constructor(
    private admin: AdminService,
    private accountService: AccountService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.statusSubscription = admin.addStatus.subscribe((s) => {
      this.status = s;
      if (s === 2) {
        const info = new LoginInfo(this.account.email, this.account.password1);
        this.auth.signin(info).subscribe((r) => {
          this.accountService.setMinistryInfo().subscribe((_) => {
            this.router.navigateByUrl('/home');
          });
        });
      }
    });
  }

  ngOnInit(): void {
    const email = this.route.snapshot.queryParamMap.get('e');
    const ministryId = this.route.snapshot.queryParamMap.get('m');
    const token = this.route.snapshot.queryParamMap.get('t');
    if (email !== null) {
      this.email = atob(email);
    }
    if (ministryId !== null) {
      this.ministryId = Number(ministryId);
    }
    if (token !== null) {
      this.token = token;
    }
  }

  submit(account: Account) {
    this.account = account;
    this.admin.addWithNewAccount(
      this.email,
      this.ministryId,
      this.token,
      account
    );
    // this.accountService.signUp(account).subscribe((account) => {
    //   this.router.navigateByUrl(`/email-verification-sent?e=${account.email}`);
    // });
  }
}
