import { Capacitor } from '@capacitor/core';

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'zwi-bottom-app-bar',
  templateUrl: './bottom-app-bar.component.html',
  styleUrls: ['./bottom-app-bar.component.scss'],
})
export class BottomAppBarComponent {
  @Input() menuItems: { name: string; icon: string; path: string }[] = [];
  isIOS = Capacitor.getPlatform() === 'ios';
}
