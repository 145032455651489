import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Session } from '@zwiloo/account/domain';

import { Account } from '@zwiloo/user/account';

export class Ministry {
  constructor(public name = '', public username = '') {}
}

export class MinistryAccount {
  constructor(
    public ministry = new Ministry(),
    public account = new Account()
  ) {}
}

@Injectable()
export class MinistryAccountService {
  constructor(private dataService: MinistryAccountDataService) {}

  signUp(account: MinistryAccount) {
    return this.dataService.signUp(account);
  }
}

@Injectable()
export class MinistryAccountDataService {
  constructor(private http: HttpClient) {}

  convertAccount(ministry: Ministry) {
    return this.http.post<Ministry>('/api/users/ministry', { ministry });
  }

  signUp(account: MinistryAccount) {
    return this.http.post<Session>('/api/ministries', account);
  }
}
