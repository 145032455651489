import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TokenStatus } from '../domain/token';

@Injectable({
  providedIn: 'root',
})
export class TokenDataService {
  constructor(private http: HttpClient) {}

  verifyToken(token: string, email: string) {
    return this.http.get<TokenStatus>(`/api/token/${token}/?u=${email}`);
  }
}
