import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { MatButtonModule } from '@angular/material/button';

import { SharedModule } from '@zwiloo/frontend/shared/shared.module';
import { TypographyModule } from '@zwiloo/frontend/typography/typography.module';

@NgModule({
  declarations: [PrivacyPolicyComponent],
  imports: [CommonModule, MatButtonModule, SharedModule, TypographyModule],
})
export class LegalModule {}
