<div class="zwi-section">
  <zwi-widget>
    <mat-chip-listbox [hideSingleSelectionIndicator]="true" [multiple]="false" (change)="updateActive($event)" aria-label="Saved sermons selection">
      <mat-chip-option [highlighted]="active === 'All'" selected>All</mat-chip-option>
      <mat-chip-option [highlighted]="active === 'Ministry'">Ministry</mat-chip-option>
      <mat-chip-option [highlighted]="active === 'Verse'">Verse</mat-chip-option>
      <mat-chip-option [highlighted]="active === 'Series'">Series</mat-chip-option>
    </mat-chip-listbox>
  </zwi-widget>
  <ng-container *ngIf="getFavorites.status() !== 2 || favorites.length > 0; else blankMessage">
    <zwi-sermon-list *ngIf="active === 'All'" [sermons]="favorites" [sortBy]="'airDate'" (starChange)="favoriteChange($event)"></zwi-sermon-list>
    <zwi-sermons-by-ministry *ngIf="active === 'Ministry'" [sermons]="favorites" (starChange)="favoriteChange($event)"></zwi-sermons-by-ministry>
    <zwi-verse-sermons *ngIf="active === 'Verse'" [sermons]="favorites" (starChange)="favoriteChange($event)"></zwi-verse-sermons>
    <zwi-series-sermons *ngIf="active === 'Series'" [sermons]="favorites" (starChange)="favoriteChange($event)"></zwi-series-sermons>
  </ng-container>
  <ng-template #blankMessage>
    <zwi-call-to-action>
      <ng-container title>You have not saved any sermons yet.</ng-container>
      <ng-container content>This screen will be replaced when you save sermons.</ng-container>
    </zwi-call-to-action>
  </ng-template>
</div>
