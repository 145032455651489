import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountService } from './account';
import { AuthService, AuthDataService } from './auth';
import { FavoritesService } from './context/favorites.service';
import { CancelAccountService } from './context/cancel-account.service';
import { VerifyEmailService } from './context/verify-email.service';
import { AccountDataService } from './data-interface/account-data.service';
import { UserDataService } from './data-interface/user-data.service';
import { UserValidatorService } from './validators/user-validator.service';
import { UserService } from './user.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    AccountDataService,
    AuthService,
    AuthDataService,
    CancelAccountService,
    UserDataService,
    UserService,
    UserValidatorService,
    VerifyEmailService,
  ],
})
export class UserModule {}
