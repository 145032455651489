import { Subscription } from 'rxjs';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { MatDialog } from '@angular/material/dialog';

import { Speaker } from '@zwiloo/ministry/domain';
import { SpeakerService } from '@zwiloo/ministry/context/speaker.service';
import { SubmitErrorStateMatcher } from '../../form/submit-error-state-matcher';
import { DefiniteSpeakerMatchComponent } from './definite-speaker-match/definite-speaker-match.component';
import { AccountService } from '@zwiloo/user/account';

@Component({
  selector: 'zwi-speakers',
  templateUrl: './speakers.component.html',
  styleUrls: ['./speakers.component.scss'],
  providers: [SpeakerService],
})
export class SpeakersComponent implements OnInit, OnDestroy {
  form = this.formBuilder.group({
    speaker: ['', Validators.required],
  });
  matcher = new SubmitErrorStateMatcher();
  speakers: Speaker[] = [];
  subscription: Subscription;
  matchSubscription: Subscription;

  constructor(
    public dialog: MatDialog,
    private accountService: AccountService,
    private formBuilder: UntypedFormBuilder,
    private speaker: SpeakerService
  ) {
    this.subscription = speaker.speakers.subscribe((s) => {
      this.speakers = s;
    });
    this.matchSubscription = speaker.match.subscribe(() => {
      const test = 'test';
      const dialogRef = this.dialog.open(DefiniteSpeakerMatchComponent);

      dialogRef.afterClosed().subscribe((result) => {});
    });
  }

  ngOnInit(): void {
    const m = this.accountService.getMinistry();
    this.speaker.getSpeakers(m.id);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.matchSubscription.unsubscribe();
  }

  submit() {
    const name = this.form.get('speaker')?.value;
    if (name) {
      this.speaker.addSpeaker(name);
    }
  }
}
