import { Component, computed, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { AccountSettingsService } from '@zwiloo/settings/context/accont.service';
import { AuthService } from '@zwiloo/user/auth';
import { UserValidatorService } from '@zwiloo/user/validators/user-validator.service';
import { FormErrorService } from '@zwiloo/frontend/form/services/form-error.service';
import { CallState, LoadingState } from '@zwiloo/util/state';
import { mobileWidth } from '@zwiloo/util/tokens';
import { AccountService } from '@zwiloo/user/account';
@Component({
  selector: 'zwi-account-form',
  templateUrl: './account-form.component.html',
  styleUrls: ['./account-form.component.scss'],
  providers: [AccountSettingsService],
})
export class AccountFormComponent implements OnInit {
  status: CallState = LoadingState.INIT;
  form = this.formBuilder.group({
    email: [
      '',
      Validators.compose([
        Validators.required,
        Validators.email,
        this.userValidator.currentEmail,
      ]),
      this.userValidator.emailTaken,
    ],
  });
  isMobile = computed(() => {
    return window.innerWidth <= mobileWidth;
  });

  constructor(
    private accountSettingsService: AccountSettingsService,
    private accountService: AccountService,
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    public formError: FormErrorService,
    private userValidator: UserValidatorService
  ) {
    this.accountSettingsService.status.subscribe((s) => {
      this.status = s;
    });
    const e = this.accountService.getAccountEmail();
    if (e !== null) {
      this.form.get('email')?.setValue(e);
    }
  }

  ngOnInit(): void {}

  back() {
    this.accountSettingsService.resetStatus();
  }

  submit() {
    if (this.form.valid) {
      const email = this.form.get('email')?.getRawValue();
      this.accountSettingsService.updateEmail(email);
    }
  }
}
