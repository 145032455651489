import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'zwi-tab-button',
  templateUrl: './tab-button.component.html',
  styleUrls: ['./tab-button.component.scss'],
})
export class TabButtonComponent implements OnInit {
  @Input() icon: string = '';
  @Input() label: string = '';
  @Input() path: string = '';

  constructor() {}

  ngOnInit(): void {}
}
