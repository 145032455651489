import { groupBy } from 'lodash';

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import { StarChange } from '../sermon/sermon.component';

import { Sermon } from '@zwiloo/sermon/domain';
import { SermonService } from '@zwiloo/sermon/sermon.service';

@Component({
  selector: 'zwi-series-sermons',
  templateUrl: './series-sermons.component.html',
  styleUrls: ['./series-sermons.component.scss'],
})
export class SeriesSermonsComponent implements OnChanges, OnInit {
  @Input() bookmarkable = true;
  @Input() sermons: Sermon[] = [];
  @Output() starChange: EventEmitter<StarChange> = new EventEmitter();
  series: { [series: string]: Sermon[] } = {};

  constructor(private sermon: SermonService) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    this.series = groupBy(this.sermons, 'series.name');
  }

  favoriteChange(starChange: StarChange) {
    this.starChange.emit(starChange);
  }
}
