import { Component } from '@angular/core';
import { ProfileImageService } from '@zwiloo/settings/context/profile-image.service';

@Component({
  selector: 'zwi-profile-image',
  templateUrl: './profile-image.component.html',
  styleUrls: ['./profile-image.component.scss'],
  providers: [ProfileImageService],
})
export class ProfileImageComponent {}
