import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { AccountComponent } from './account/account.component';
import { AdminComponent } from './admin/admin.component';
import { AvailabilityComponent } from './availability/availability.component';
import { PasswordComponent } from './password/password.component';
import { ProfileImageComponent } from './profile-image/profile-image.component';
import { SettingsComponent } from './settings.component';
import { SharedModule } from '@zwiloo/frontend/shared/shared.module';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AccountFormComponent } from './account-form/account-form.component';
import { PasswordFormComponent } from './password-form/password-form.component';
import { ProfileImageFormComponent } from './profile-image-form/profile-image-form.component';
import { TypographyModule } from '@zwiloo/frontend/typography/typography.module';
import { EmailComponent } from './email/email.component';
import { AccountCloseDialogContentComponent } from './account-close-dialog/account-close-dialog-content/account-close-dialog-content.component';
import { MatDialogModule } from '@angular/material/dialog';
import { AccountCloseDialogComponent } from './account-close-dialog/account-close-dialog.component';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    AccountComponent,
    AdminComponent,
    AvailabilityComponent,
    PasswordComponent,
    ProfileImageComponent,
    SettingsComponent,
    AccountFormComponent,
    PasswordFormComponent,
    ProfileImageFormComponent,
    EmailComponent,
    AccountCloseDialogContentComponent,
    AccountCloseDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    TypographyModule,
    MatDialogModule,
    MatOptionModule,
    MatSelectModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    AccountCloseDialogComponent,
    AccountFormComponent,
    PasswordFormComponent,
    ProfileImageFormComponent,
  ],
})
export class SettingsModule {}
