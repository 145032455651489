import {
  Component,
  computed,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { Upload, UploadStatus } from '@zwiloo/studio/domain';
import { Capacitor } from '@capacitor/core';
import { mobileWidth } from '@zwiloo/util/tokens';
import { UploadService } from '@zwiloo/studio/context/upload.service';

@Component({
  selector: 'zwi-upload-manager',
  templateUrl: './upload-manager.component.html',
  styleUrls: ['./upload-manager.component.scss'],
})
export class UploadManagerComponent implements OnInit {
  @Input()
  get uploads(): Upload[] {
    return this._uploads;
  }
  set uploads(u: Upload[]) {
    this._uploads = u;
    this.setStatusMessage();
  }
  private _uploads: Upload[] = [];
  @Output() selectionChange = new EventEmitter<number>();
  listHidden: boolean = false;
  statusMessage = '';

  isDesktop = computed(() => {
    return (
      Capacitor.isNativePlatform() === false && window.innerWidth > mobileWidth
    );
  });

  constructor(private uploadService: UploadService) {}

  ngOnInit() {}

  setStatusMessage() {
    const completeCount = this.uploads.filter(
      (u) => u.uploadStatus === UploadStatus.Complete
    ).length;
    const failedCount = this.uploads.filter(
      (u) => u.uploadStatus === UploadStatus.Failed
    ).length;
    const uploadingCount = this.uploads.filter(
      (u) =>
        u.uploadStatus === UploadStatus.Uploading ||
        u.uploadStatus === UploadStatus.Processing
    ).length;
    if (uploadingCount > 0) {
      this.statusMessage = `Uploading ${uploadingCount} item${
        uploadingCount > 1 ? 's' : ''
      }`;
    } else {
      if (failedCount === 0) {
        this.statusMessage = `${completeCount} upload${
          completeCount > 1 ? 's' : ''
        } complete`;
      } else {
        this.statusMessage = `Upload complete (${failedCount} failed)`;
      }
    }
  }

  getUploadName(upload: Upload) {
    return upload.sermonInfo.title ? upload.sermonInfo.title : upload.fileName;
  }

  isComplete(upload: Upload) {
    return upload.uploadStatus === UploadStatus.Complete;
  }

  isProcessing(upload: Upload) {
    return upload.uploadStatus === UploadStatus.Processing;
  }

  isSelectedClass(id: number) {
    return id === this.uploadService.selected()
      ? 'zwi-upload-list-item-selected'
      : '';
  }

  isUploading(upload: Upload) {
    return upload.uploadStatus === UploadStatus.Uploading;
  }

  toggleListHidden() {
    this.listHidden = !this.listHidden;
  }

  uploadStatus(id: number) {}

  uploadSelected(id: number) {
    this.selectionChange.emit(id);
  }
}
