<div *ngIf="verifyStatus === 1; else content" class="zwi-main-content zwi-justify-center">
  <zwi-widget>
    <div>Loading</div>
    <!--    <zwi-progress-indicator></zwi-progress-indicator>-->
  </zwi-widget>
</div>
<ng-template #content>
  <ng-container [ngSwitch]="verifyResult.status">
    <ng-container *ngSwitchCase="'success'">
      <div *ngIf="resetStatus === 2; else resetContent">
        <div [ngSwitch]="resetResult.status">
          <zwi-simple-page *ngSwitchCase="'success'">
            <zwi-call-to-action>
              <ng-container title>Your password has been reset.</ng-container>
              <button routerLink="/index" mat-raised-button color="primary" action>Sign in</button>
            </zwi-call-to-action>
          </zwi-simple-page>
        </div>
      </div>
      <ng-template #resetContent>
        <zwi-simple-page>
          <zwi-call-to-action>
            <ng-container title>Change your password</ng-container>
            <form [formGroup]="form" (ngSubmit)="submit()" class="form-lg" content>
              <div class="zwi-form-group">
                <mat-form-field>
                  <mat-label>New Password</mat-label>
                  <input matInput placeholder="Enter new password" formControlName="password">
                </mat-form-field>
              </div>
              <button [disabled]="resetStatus === 1" mat-flat-button color="primary">Change password</button>
            </form>
          </zwi-call-to-action>
        </zwi-simple-page>
      </ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="'existing'">
      <zwi-simple-page>
        <zwi-call-to-action>
          <ng-container title>The reset link has already been used.</ng-container>
          <button routerLink="/index" mat-raised-button color="primary" action>Continue to home page</button>
        </zwi-call-to-action>
      </zwi-simple-page>
    </ng-container>
    <div *ngSwitchCase="'expired'">
      <zwi-simple-page>
        <zwi-call-to-action>
          <ng-container title>The reset link has expired.</ng-container>
          <button routerLink="/index" mat-raised-button color="primary" action>Continue to home page</button>
        </zwi-call-to-action>
      </zwi-simple-page>
    </div>
  </ng-container>
</ng-template>


<!--<div class="layout-row">-->
<!--  <div class="flex-40 offset-30">-->
<!--    <div *ngIf="verifyStatus === 1; else verifyContent" class="zwi-main-content">-->
<!--      <zwi-widget>-->
<!--        <div>Loading</div>-->
<!--      </zwi-widget>-->
<!--    </div>-->
<!--    <ng-template #verifyContent>-->
<!--      <ng-container [ngSwitch]="verifyResult.status">-->
<!--        <ng-container *ngSwitchCase="'success'">-->
<!--          <div *ngIf="resetStatus === 2; else resetContent">-->
<!--            <div [ngSwitch]="resetResult.status">-->
<!--              <div *ngSwitchCase="'success'">-->
<!--                <zwi-body>Your password has been reset.</zwi-body>-->
<!--                <button routerLink="/index" mat-raised-button color="primary">Sign in</button>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <ng-template #resetContent>-->
<!--            <zwi-display>Change your password</zwi-display>-->
<!--            <form [formGroup]="form" (ngSubmit)="submit()" class="form-lg">-->
<!--              <div class="zwi-form-group">-->
<!--                <mat-form-field>-->
<!--                  <mat-label>New Password</mat-label>-->
<!--                  <input matInput placeholder="Enter new password" formControlName="password">-->
<!--                </mat-form-field>-->
<!--              </div>-->
<!--              <div class="layout-row layout-align-end-center">-->
<!--                <button [disabled]="resetStatus === 1" mat-button color="primary">Change password</button>-->
<!--              </div>-->
<!--            </form>-->
<!--          </ng-template>-->
<!--        </ng-container>-->
<!--        <ng-container *ngSwitchCase="'existing'">-->
<!--          <zwi-body>The reset link has already been used.</zwi-body>-->
<!--          <button routerLink="/index" mat-raised-button color="primary">Continue to home page</button>-->
<!--        </ng-container>-->
<!--        <div *ngSwitchCase="'expired'">-->
<!--          The reset link has expired.-->
<!--          <button routerLink="/index" mat-raised-button color="primary">Continue to home page</button>-->
<!--        </div>-->
<!--      </ng-container>-->
<!--    </ng-template>-->
<!--  </div>-->
<!--</div>-->
