import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProfileImageService } from '@zwiloo/settings/context/profile-image.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'zwi-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss'],
})
export class ImageUploaderComponent implements OnInit {
  @Input() imageUrl = '';
  @Output() uploaded = new EventEmitter<File>();

  constructor(private profileImage: ProfileImageService) {}

  ngOnInit(): void {}

  addFiles() {
    const fileInput: HTMLInputElement = document.getElementById(
      'fileInput'
    ) as HTMLInputElement;
    if (fileInput !== null) {
      const files = fileInput.files;
      if (files !== null && files.length > 0) {
        this.uploaded.emit(files[0]);
      }
    }

    // for (let file of files) {
    //   const formData = new FormData();
    //   formData.append('file', file);
    //
    //   const xhr = new XMLHttpRequest();
    //   const url = '/app/account/uploadFile-profile-image/';
    //   xhr.open('POST', url);
    //   xhr.setRequestHeader('X-CSRFToken', getCookie('csrftoken'));
    //   xhr.send(formData);
    //   xhr.onreadystatechange = () => {
    //     if (xhr.readyState != 4) return;
    //     if (xhr.status === 200) {
    //       this.onUploaded(xhr);
    //     }
    //     if (xhr.status != 200) {
    //     }
    //   };
    // }
  }

  add(event: any) {
    var fileInput = document.getElementById('fileInput');
    if (fileInput) {
      fileInput.click();
    }
    event.preventDefault();
  }
}
