<div class="zwi-flex-center">
  <div class="zwi-spacer"></div>
  <div class="zwi-flex-center zwi-block-section--horizontal">
    <mat-form-field>
      <mat-label>Height</mat-label>
      <mat-select [value]="height" (selectionChange)="emitHeight($event.value)">
        @for (h of heightOptions; track $index) {
          <mat-option [value]="h">{{ getLabel(h) }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-icon class="zwi-embed-by">close_small</mat-icon>
    <mat-form-field style="line-height: 24px;">
      <mat-label>Width</mat-label>
      <input matInput [value]="_width" (change)="emitWidth($event)">
    </mat-form-field>
  </div>

</div>
