<zwi-content>
  <div class="zwi-page-content" style="height: 100%">
    <div class="zwi-center" [class]="{'zwi-visual-center': isNativePlatform === false}" style="height: 100%">
      <div class="zwi-section">
        <div class="z-wrapper">
          <img src='assets/img/landing-logo.png' width="200px"/>
        </div>
        <div>
          <div class='zwi-subtitle title-message text-center'>Zwiloo streams daily sermons from ministries around the world</div>
          <div class='md-subhead text-center'>Signing up lets you follow the ministries you care about</div>
        </div>
        <div class="z-wrapper">
          <div class='landing-card zwi-wrapper-content--sm' style="background: white; z-index: 10;">
            <div class="zwi-text-section">
              <zwi-login-form (signedIn)="completeOnboarding()"></zwi-login-form>
              <a [routerLink]="['/sign-up']" target="_self" style="display: block">
                <div class='mdl-card__actions mdl-card--border card-bottom'>
                  <h4 class='md-headline text-center'>Sign up now</h4>
                </div>
              </a>
              <div class="zwi-justify-center">
                <div style="margin-right: 20px;">Are you a ministry? <a
                  class="bold" [routerLink]="['/ministry-sign-up']" target="_self">Sign up here</a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="zwi-bottom zwi-text-center">
      <a [routerLink]="['/home']" (click)="completeOnboarding()">Continue signed out</a>
    </div>
  </div>
</zwi-content>
