import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';

import { AfterViewInit, Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { AuthService } from '@zwiloo/user/auth';
import { Router } from '@angular/router';

import { mobileWidth } from '@zwiloo/util/tokens';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  selected = 'stream';
  firstName = 'Test';
  menuItems: { name: string; icon: string; link: string }[] = [
    { name: 'Home', icon: 'home', link: '/home' },
    { name: 'Local Church', icon: 'group', link: '/local-church' },
    { name: 'Archive', icon: 'archive', link: '/archive/all' },
    { name: 'Search', icon: 'search', link: '/search' },
  ];
  title = 'web';

  constructor(
    // TODO: Add playing to header
    // public playingSermon: PlayingSermon,
    private authService: AuthService,
    private meta: Meta,
    private router: Router
  ) {
    if (Capacitor.isNativePlatform()) {
      meta.updateTag(
        {
          name: 'viewport',
          content:
            'viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no',
        },
        'name=viewport'
      );
    }

    // TODO: Waiting on https://github.com/apache/cordova-plugin-screen-orientation/pull/116
    // if (Capacitor.isNativePlatform()) {
    //   screen.orientation.lock('portrait');
    // }
  }

  async ngOnInit() {
    this.authService.setHasSession();
    if (Capacitor.isNativePlatform()) {
      await SplashScreen.hide();
      setTimeout(async () => {
        await StatusBar.setOverlaysWebView({ overlay: true });
      });
      setTimeout(async () => {
        await StatusBar.setStyle({ style: Style.Light });
      });
    }
  }

  changeHref(url: string) {
    window.location.href = url;
  }

  isSelected(name: string) {
    return this.selected === name;
  }

  select(selected: string) {
    this.selected = selected;
    const item = this.menuItems.find((i) => i.name === selected);
    if (item !== undefined) {
      this.router.navigate([item.link]);
    }
  }
}
