import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgIf } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';
import { SharedModule } from '@zwiloo/frontend/shared/shared.module';

import {
  BookTestament,
  BOOKS_TESTAMENTS,
} from '@zwiloo/verse/domain/books-testaments';
import { EmbedBookMenuDialogComponent } from '../embed-book-menu-dialog/embed-book-menu-dialog.component';
import { BookChapter } from '@zwiloo/verse/domain/reference';

@Component({
  selector: 'zwi-embed-book-menu',
  standalone: true,
  imports: [
    EmbedBookMenuDialogComponent,
    MatButtonModule,
    MatIconModule,
    NgIf,
    SharedModule,
  ],
  templateUrl: './embed-book-menu.component.html',
  styleUrl: './embed-book-menu.component.scss',
})
export class EmbedBookMenuComponent {
  @Input() bookChapter: BookChapter | null = null;
  @Output() bookChapterSelected = new EventEmitter<BookChapter>();

  constructor(public dialog: MatDialog) {}

  open() {
    const dialogRef = this.dialog.open(EmbedBookMenuDialogComponent, {
      height: '400px',
      width: '600px',
    });
    const bcSelectedSubscription =
      dialogRef.componentInstance.bookChapterSelected.subscribe((b) => {
        this.bookChapterSelected.emit(b);
        bcSelectedSubscription.unsubscribe();
      });
  }

  verseReference() {
    if (this.bookChapter !== null && this.bookChapter.book !== 0) {
      const book = BOOKS_TESTAMENTS[this.bookChapter.book - 1].name;
      return this.bookChapter?.chapter === 0
        ? book
        : `${book} ${this.bookChapter.chapter}`;
    } else {
      return 'Book';
    }
  }
}
