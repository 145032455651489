<zwi-main-wrapper>
  @if (authService.sessionStatus() === 2) {
    @if (authService.hasSession() === false) {
      <zwi-main-content>
        <div class="zwi-page-sections">
          <zwi-headline>Local church</zwi-headline>
          <zwi-sign-in-call-to-action>
            <ng-container title>Sign in to view your local church</ng-container>
            <ng-container content>Once you sign in, you'll be able to listen your local church's sermons.</ng-container>
          </zwi-sign-in-call-to-action>
        </div>
      </zwi-main-content>
    } @else {
      @if (getLocalChurch.ministryStatus() === 2) {
        @if (getLocalChurch.ministry().id !== 0) {
          <zwi-ministry-sermons [username]="getLocalChurch.ministry().username"></zwi-ministry-sermons>
        } @else {
          <div class="flex-90 flex-offset-5">
            <div class="placeholder layout-column layout-align-center-center"
                 style="height: 300px; width: 100%; text-align: center">
              <div class="material-icons">group</div>
              <div class="md-title placeholder--title">You are not following your local church yet.</div>
              <a mat-raised-button color="primary" routerLink="/following">Add local church</a>
            </div>
          </div>
        }
      }
    }
  }
</zwi-main-wrapper>

