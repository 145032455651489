import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'zwi-upload-complete',
  templateUrl: './upload-complete.component.html',
  styleUrls: ['./upload-complete.component.scss'],
})
export class UploadCompleteComponent implements OnInit {
  @Output() uploadNavigate = new EventEmitter();
  @Output() sermonsNavigate = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  navigateToUpload() {
    this.uploadNavigate.emit();
  }

  navigateToSermons() {
    this.sermonsNavigate.emit();
  }
}
