import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Ministry } from '@zwiloo/ministry/domain';

@Injectable({
  providedIn: 'root',
})
export class AdminDataService {
  constructor(private http: HttpClient) {}

  add(email: string, ministryID: number, tokenID: string) {
    return this.http.post(`/api/ministries/${ministryID}/admins`, {
      email,
      tokenID,
    });
  }

  getAdmins(ministryID: number) {
    return this.http
      .get<{ admins: string[] }>(`/api/ministries/${ministryID}/admins`)
      .pipe(map((r) => r.admins));
  }

  sendInvitation(email: string) {
    return this.http.post('/api/ministries/admins', { email });
  }

  getMinistryToken(tokenID: number) {
    return this.http.get<Ministry>(`/api/token/${tokenID}/ministry`);
  }
}
