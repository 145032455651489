import { Injectable } from '@angular/core';
import { AbstractControl, Validator } from '@angular/forms';

@Injectable()
export class PasswordMatchValidator implements Validator {
  validate(control: AbstractControl): { [key: string]: any } | null {
    if (control.get('password1')?.value === control.get('password2')?.value) {
      control.get('password2')?.setErrors(null);
      return null;
    } else {
      control.get('password2')?.setErrors({ passwordMatch: true });
      return { passwordMatch: true };
    }
  }
}
