import { Subscription } from 'rxjs';

import { Component } from '@angular/core';

import { Sermon } from '@zwiloo/sermon/domain';
import { CallState, LoadingState } from '@zwiloo/util/state';
import { SearchService } from '@zwiloo/search/context/search.service';
import { StarChange } from '@zwiloo/frontend/shared/sermon/sermon.component';
import { SermonService } from '@zwiloo/sermon/sermon.service';
import { AuthService } from '@zwiloo/user/auth';

@Component({
  selector: 'zwi-sermon-results',
  templateUrl: './sermon.component.html',
  styleUrls: ['./sermon.component.scss'],
  providers: [SermonService],
})
export class SermonComponent {
  sermons: Sermon[] = [];
  status: CallState = LoadingState.INIT;
  sermonSubscription: Subscription;
  statusSubscription: Subscription;

  constructor(
    public authService: AuthService,
    public search: SearchService,
    public sermon: SermonService
  ) {
    this.sermonSubscription = search.sermons.subscribe((s) => {
      this.sermons = s;
    });
    this.statusSubscription = search.status.subscribe((s) => {
      this.status = s;
    });
  }

  ngOnDestroy(): void {
    this.sermonSubscription.unsubscribe();
    this.statusSubscription.unsubscribe();
  }

  favoriteChange(starChange: StarChange) {
    if (starChange.value === 'star') {
      if (starChange.source.sermon !== undefined) {
        this.sermon.favorite(starChange.source.sermon);
      }
    } else {
      if (starChange.source.sermon !== undefined) {
        this.sermon.unfavorite(starChange.source.sermon);
      }
    }
  }

  searchLoaded() {
    return this.status === LoadingState.LOADED;
  }
}
