import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ResetPasswordService } from '@zwiloo/user';

@Component({
  selector: 'zwi-email-sent',
  templateUrl: './email-sent.component.html',
  styleUrls: ['./email-sent.component.scss'],
  providers: [ResetPasswordService],
})
export class EmailSentComponent implements OnInit {
  email = '';

  constructor(
    private route: ActivatedRoute,
    private resetPassword: ResetPasswordService,
    @Inject(MAT_DIALOG_DATA) public data: { email: string; id: number }
  ) {}

  ngOnInit(): void {
    if (this.data.email !== null && this.data.id !== null) {
      this.email = this.data.email;
      this.resetPassword.sendResetToken(this.data.id);
    }
  }
}
