<zwi-simple-form-page>
  <div class="z-wrapper">
    <div class="zwi-wrapper-content--sm">
      <div class="zwi-page-sections">
        <zwi-headline *ngIf="isNativePlatform === false; else backHeadline">Create a Zwiloo account</zwi-headline>
        <ng-template #backHeadline>
          <zwi-back-headline (navigateBack)="navigateToIndex()">Create a Zwiloo account</zwi-back-headline>
        </ng-template>
        <zwi-signup-form (updateAccount)="submit($event)">
          <button mat-flat-button color="primary">Sign up</button>
        </zwi-signup-form>
        <div>
          <zwi-widget>
            <zwi-agreement-message></zwi-agreement-message>
          </zwi-widget>
          <zwi-widget>
            Already have an account? <a href="/index">Sign in now</a>
          </zwi-widget>
        </div>
        <div *ngIf="isMobile()" class="zwi-column-center">
          <zwi-title>Are you a ministry? <a
            class="bold" [routerLink]="['/ministry-sign-up']" target="_self">Sign up here</a>
          </zwi-title>
        </div>

      </div>
      <div *ngIf="isMobile() === false" class="zwi-upper-right">
        <zwi-title>Are you a ministry? <a
          class="bold" [routerLink]="['/ministry-sign-up']" target="_self">Sign up here</a>
        </zwi-title>
      </div>
    </div>
  </div>
</zwi-simple-form-page>
