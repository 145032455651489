import { Component, OnInit } from '@angular/core';

import { GetFavoritesService } from '@zwiloo/user/context/get-favorites.service';
import { AuthService } from '@zwiloo/user/auth';
import { toObservable } from '@angular/core/rxjs-interop';
import { Observable } from 'rxjs';

@Component({
  selector: 'zwi-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss'],
  providers: [GetFavoritesService],
})
export class FavoritesComponent implements OnInit {
  hasSession$: Observable<boolean>;
  constructor(
    private getFavorites: GetFavoritesService,
    public authService: AuthService
  ) {
    this.hasSession$ = toObservable(authService.hasSession);
  }

  ngOnInit(): void {
    if (this.authService.hasSession()) {
      this.getFavorites.loadFavorites();
    }
    this.hasSession$.subscribe((s: boolean) => {
      if (s) {
        this.getFavorites.loadFavorites();
      }
    });
  }
}
