import { Injectable } from '@angular/core';
import { UntypedFormControl, ValidationErrors } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { Ministry } from '@zwiloo/ministry/domain';

@Injectable()
export class MinistryUsernameValidator {
  constructor(private http: HttpClient) {}

  public checkCharacters = (
    control: UntypedFormControl
  ): ValidationErrors | null => {
    const LETTERSNUMBERS_REGEXP = /^([a-zA-Z0-9]+)$/;
    return LETTERSNUMBERS_REGEXP.test(control.value)
      ? null
      : { characters: { value: control.value } };
  };

  public usernameTaken = (control: UntypedFormControl) => {
    const username = control.value;

    return this.http
      .get<Ministry>(`/api/ministries?username=${username}`)
      .pipe(map((m) => (m.id !== 0 ? { usernameTaken: true } : null)));
  };
}
