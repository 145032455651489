<div *ngIf="status === 1; else content" class="zwi-main-content">
  <zwi-widget>
    <div>Loading</div>
  </zwi-widget>
</div>
<ng-template #content>
  <ng-container *ngIf="searchLoaded(); else blankMessage">
    <zwi-sermon-list *ngIf="sermons.length > 0; else noResults" [bookmarkable]="authService.hasSession()" [sermons]="sermons" (starChange)="favoriteChange($event)"></zwi-sermon-list>
    <ng-template #noResults>
      <zwi-widget>No results containing your search terms were found.</zwi-widget>
    </ng-template>
  </ng-container>
  <ng-template #blankMessage>
    <zwi-widget>Search for sermons</zwi-widget>
  </ng-template>
</ng-template>
