<form [formGroup]="form" (submit)="submit()">
  <mat-form-field appearance="fill" class="zwi-vertical-form__md-field" [ngClass]="{'zwi-form-field--full': isMobile()}">
    <mat-label>New password</mat-label>
    <input formControlName="password1" matInput type="password">
    <mat-error>
      <ng-container *ngIf="form.get('password1')?.errors?.required">
        {{ formError.getErrorMessage('password1', 'required') }}</ng-container>
      <ng-container *ngIf="form.get('password1')?.errors?.minlength">
        {{ formError.getErrorMessage('password1', 'minlength') }}</ng-container>
    </mat-error>
  </mat-form-field>
  <mat-form-field appearance="fill" class="zwi-vertical-form__md-field" [ngClass]="{'zwi-form-field--full': isMobile()}">
    <mat-label>Verify new password</mat-label>
    <input formControlName="password2" matInput type="password">
    <mat-error>
      <ng-container *ngIf="form.get('password2')?.errors?.required">
        {{ formError.getErrorMessage('password2', 'required') }}</ng-container>
      <ng-container *ngIf="form.get('password2')?.errors?.passwordMatch">
        {{ formError.getErrorMessage('password2', 'passwordMatch') }}</ng-container>
    </mat-error>
  </mat-form-field>
  <zwi-widget>
    <button mat-flat-button color="primary">Save changes</button>
  </zwi-widget>
</form>
