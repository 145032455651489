import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';

import { Sermon } from '@zwiloo/sermon/domain';
import { ReferenceRange } from '@zwiloo/verse/domain/reference';
import { ReferenceFormatter } from '@zwiloo/verse/domain/reference-formatter';
import { ZwiReferenceFormats } from '@zwiloo/verse/domain/reference-formats';
import { PlayerService } from '@zwiloo/sermon/context/player.service';

@Component({
  selector: 'zwi-draggable-sermon-list',
  templateUrl: './draggable-sermon-list.component.html',
  styleUrls: ['./draggable-sermon-list.component.scss'],
})
export class DraggableSermonListComponent implements OnInit {
  @Input()
  get sermons(): Sermon[] {
    return this._sermons;
  }
  set sermons(s: Sermon[]) {
    this._sermons = s.sort((a, b) => {
      if (a.orderInSeries < b.orderInSeries) {
        return -1;
      } else if (a.orderInSeries > b.orderInSeries) {
        return 1;
      }
      return 0;
    });
  }
  private _sermons: Sermon[] = [];
  @Output() sortChange = new EventEmitter<Sermon[]>();

  constructor(private playerService: PlayerService) {}

  ngOnInit(): void {}

  drop(event: CdkDragDrop<Sermon[]>) {
    moveItemInArray(
      event.container.data,
      event.previousIndex,
      event.currentIndex
    );
    this.sortChange.emit(this.sermons);
  }

  getReferences(referenceRanges?: ReferenceRange[]) {
    if (referenceRanges !== undefined) {
      const formatter = new ReferenceFormatter();
      return referenceRanges
        .map((range) =>
          formatter.formatRange(
            range,
            ZwiReferenceFormats.display.referenceInput
          )
        )
        .join(', ');
    } else {
      return '';
    }
  }

  hasReferences(sermon: Sermon) {
    return sermon?.references && sermon.references.length > 0;
  }

  playSermon(s: Sermon) {
    this.playerService.setSermon(s);
  }
}
