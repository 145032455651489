import { Subject } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Sermon } from '@zwiloo/sermon/domain';

@Injectable({
  providedIn: 'root',
})
export class SermonDataService {
  sermon = new Subject<Sermon>();

  constructor(private http: HttpClient) {}

  searchSermons(criteria: string) {
    return this.http.get<Sermon[]>(`/api/sermons?q=${criteria}`);
  }

  searchSermonsBySpeaker(criteria: string) {
    return this.http.get<Sermon[]>(`/api/sermons?speaker=${criteria}`);
  }

  searchSermonsByVerse(criteria: string) {
    return this.http.get<Sermon[]>(`/api/sermons?verse=${criteria}`);
  }
}
