import { Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ReferenceValidator {
  constructor(public http: HttpClient) {}

  public check = (control: UntypedFormControl) => {
    const verse = {
      book: control.value.book,
      chapter: control.value.chapter,
      verses: control.value.verses,
    };
    return this.http.post('/app/verse/check-verses', JSON.stringify(verse));
  };

  public checkMulti = (control: UntypedFormControl) => {
    const verse = {
      book: control.value.book,
      startingChapter: control.value.startingChapter,
      startingVerses: control.value.startingVerses,
      endingChapter: control.value.endingChapter,
      endingVerses: control.value.endingVerses,
    };
    return this.http.post(
      '/app/verse/check-multi-verse',
      JSON.stringify(verse)
    );
  };

  public verseComplete = (control: UntypedFormControl) => {
    if (control.value === 'Book') {
      return { verseComplete: false };
    } else {
      return null;
    }
  };
}
