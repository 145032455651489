<mat-card appearance="outlined">
  <mat-card-content>
    <div class="zwi-book-menu">
      <div class="zwi-book-menu__old-testament">
        <div class="mat-subtitle-2">Old Testament</div>
        <div class="zwi-book-menu__books">
          <div *ngFor="let book of oldTestament" (click)="selectBook(book)" class="zwi-book-menu__item">{{ book.name }}</div>
        </div>
      </div>
      <div class="zwi-book-menu__new-testament">
        <div class="mat-subtitle-2">New Testament</div>
        <div class="zwi-book-menu__books">
          <div *ngFor="let book of newTestament" (click)="selectBook(book)" class="zwi-book-menu__item">{{ book.name }}</div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
