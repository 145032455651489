@if (playingSermon?.id === sermon.id) {
  @if (sermonStatus === 'PLAYING') {
    <mat-icon (click)="pauseSermon()" class="zwi-play-button">pause</mat-icon>
  } @else {
    <mat-icon (click)="playSermon()" class="zwi-play-button">play_arrow</mat-icon>
  }
} @else {
  <mat-icon (click)="startSermon()" class="zwi-play-button">play_arrow</mat-icon>
}


