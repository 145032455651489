import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';

import { FormErrorService } from '@zwiloo/frontend/form/services/form-error.service';
import { MinistryUsernameValidator } from '@zwiloo/ministry/ministry-username-validator.service';
import { Ministry } from '@zwiloo/ministry/ministry-account';

@Component({
  selector: 'zwi-signup-ministry',
  templateUrl: './signup-ministry.component.html',
  providers: [MinistryUsernameValidator],
})
export class SignupMinistryComponent {
  @Input() buttonLabel = 'Next step';
  @Input() ministry = new Ministry();
  @Output() updateMinistry = new EventEmitter();

  form = this.fb.group({
    name: [this.ministry.name, Validators.required],
    username: [
      this.ministry.username,
      Validators.compose([
        Validators.required,
        this.ministryUsernameValidator.checkCharacters,
      ]),
      this.ministryUsernameValidator.usernameTaken,
    ],
  });
  name: string = '';

  constructor(
    public fb: UntypedFormBuilder,
    public formError: FormErrorService,
    public ministryUsernameValidator: MinistryUsernameValidator
  ) {}

  ngOnChanges() {
    this.form.setValue(this.ministry);
  }

  submit() {
    if (!this.form.invalid) {
      this.updateMinistry.emit(this.form.value);
    } else {
      const invalidEl = document.querySelectorAll('input.ng-invalid');
      const firstInput: HTMLInputElement = <HTMLInputElement>invalidEl.item(0);
      firstInput.focus();
    }
  }
}
