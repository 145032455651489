import { Capacitor } from '@capacitor/core';

import { Component, computed } from '@angular/core';

@Component({
  selector: 'zwi-top-spacer',
  templateUrl: './top-spacer.component.html',
  styleUrls: ['./top-spacer.component.scss'],
})
export class TopSpacerComponent {
  classObject = computed(() => {
    return {
      'zwi-top-spacer--android': Capacitor.getPlatform() === 'android',
    };
  });
}
