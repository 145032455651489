import { take } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  Output,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

export class ReferenceCell {
  constructor(
    public value: number,
    public displayValue: string,
    public ariaLabel: string,
    public enabled: boolean
  ) {}
}

@Component({
  selector: '[zwi-reference-body]',
  templateUrl: './reference-body.component.html',
  styleUrls: ['./reference-body.component.scss'],
  host: {
    class: 'zwi-reference-body',
    role: 'grid',
  },
  exportAs: 'zwiReferenceBody',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReferenceBodyComponent {
  @Input() label: string = '';
  @Input() rows: ReferenceCell[][] = [[]];

  @Input() beginValue = 0;

  @Input() endValue = 0;

  @Input() previewEnd: number | null = null;

  @Input() previewBegin: number | null = null;

  // @Input()
  // get selectedValue() {
  //   return this._selectedValue;
  // }
  // set selectedValue(value) {
  //   let selectedValue: number[] = [];
  //   if (value && value[0] !== null && value[0] !== undefined) {
  //     selectedValue = value;
  //   }
  //   this._selectedValue = [...selectedValue];
  //   this._rangeValue = [...selectedValue];
  // }
  // private _selectedValue: number[] = [];

  @Input() numCols = 10;
  @Input() allowDisabledSelection = false;
  @Input() allowRange = false;
  @Input() activeCell = 0;
  @Input() cellAspectRatio = 1;
  @Output() readonly selectedValueChange: EventEmitter<number> =
    new EventEmitter<number>();

  // _rangeValue: number[] = [];

  constructor(private _elementRef: ElementRef, private _ngZone: NgZone) {}

  _cellClicked(cell: ReferenceCell): void {
    if (!this.allowDisabledSelection && !cell.enabled) {
      return;
    }
    this.selectedValueChange.emit(cell.value);
  }

  get _firstRowOffset(): number {
    return this.rows && this.rows.length && this.rows[0].length
      ? this.numCols - this.rows[0].length
      : 0;
  }

  _isActiveCell(rowIndex: number, colIndex: number) {
    let cellNumber = rowIndex * this.numCols + colIndex;
    return cellNumber == this.activeCell;
  }

  _onHover(value: number) {
    // if (this.allowRange && this.selectedValue.length === 1) {
    if (this.allowRange && this.endValue === 0) {
      // Only add value if it is greater than the selectedValue
      // otherwise remove rangeValue
      if (value > this.beginValue) {
        this.endValue = value;
        // if (this._rangeValue.length === 2) {
        //   this._rangeValue.pop();
        // }
        // this._rangeValue.push(value);
      } else {
        // this._rangeValue.pop();
      }
    }
  }

  // _removeHover() {
  //   if (this.allowRange && this.selectedValue.length === 1) {
  //     if (this._rangeValue.length === 2) {
  //       this._rangeValue.pop();
  //     }
  //   }
  // }

  _isCellInRange(value: number) {
    // if (this._rangeValue.length !== 2) {
    if (this.endValue === 0) {
      return false;
    }
    return value >= this.beginValue && value <= this.endValue;
  }

  _isStartCell(value: number): boolean {
    const hasEndValue =
      this.endValue !== null && this.beginValue !== this.endValue;
    return this._selectedCell(value) === 'start' && hasEndValue;
  }

  _isSoloCell(value: number) {
    const hasEndValue =
      this.endValue !== null && this.beginValue !== this.endValue;
    return (
      this._selectedCell(value) === 'start' &&
      this._isStartCell(value) === false
    );
  }

  _selectedCell(value: number): 'start' | 'end' | false {
    if (this.beginValue === value) {
      return 'start';
    } else if (this.endValue === value) {
      return 'end';
    } else {
      return false;
    }
    // const index = this.selectedValue && this.selectedValue.indexOf(value);
    // if (index === undefined || index === -1) {
    //   return false;
    // } else {
    //   return index === 0 ? 'start' : 'end';
    // }
  }

  _focusActiveCell() {
    this._ngZone.runOutsideAngular(() => {
      this._ngZone.onStable
        .asObservable()
        .pipe(take(1))
        .subscribe(() => {
          this._elementRef.nativeElement
            .querySelector('.zwi-reference-body-active')
            .focus();
        });
    });
  }
}
