import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';

import { SharedModule } from '@zwiloo/frontend/shared/shared.module';
import { TypographyModule } from '@zwiloo/frontend/typography/typography.module';
import { UserRoutingModule } from './user.routing';
import { UserComponent } from './user/user.component';
import { UserContainerComponent } from './user-container.component';

@NgModule({
  declarations: [UserComponent, UserContainerComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatToolbarModule,
    SharedModule,
    TypographyModule,
    UserRoutingModule,
  ],
})
export class UserModule {}
