import {
  Component,
  computed,
  EventEmitter,
  Output,
  signal,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import {
  MatButtonToggleChange,
  MatButtonToggleModule,
} from '@angular/material/button-toggle';
import { MatDialogRef } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';

import { BOOKS_TESTAMENTS } from '@zwiloo/verse/domain/books-testaments';
import { endingVerses } from '@zwiloo/verse/domain/ending-verses';
import {
  BookChapter,
  includesBookChapter,
} from '@zwiloo/verse/domain/reference';

@Component({
  selector: 'zwi-embed-book-menu-dialog',
  standalone: true,
  imports: [
    FormsModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatExpansionModule,
  ],
  templateUrl: './embed-book-menu-dialog.component.html',
  styleUrl: './embed-book-menu-dialog.component.scss',
})
export class EmbedBookMenuDialogComponent {
  @Output() bookChapterSelected = new EventEmitter<BookChapter>();
  order = signal('biblical');
  books = computed(() => {
    if (this.order() === 'biblical') {
      return [...BOOKS_TESTAMENTS];
    } else {
      return [...BOOKS_TESTAMENTS].sort((a, b) => a.name.localeCompare(b.name));
    }
  });

  constructor(public dialogRef: MatDialogRef<EmbedBookMenuDialogComponent>) {}

  chapters(bookId: number) {
    return endingVerses[bookId - 1];
  }

  setOrder(c: MatButtonToggleChange) {
    this.order.set(c.value);
  }

  emitBookChapterSelected(b: BookChapter) {
    this.bookChapterSelected.emit(b);
    this.dialogRef.close();
  }
}
