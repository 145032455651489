<ng-template [cdkPortalOutlet]="_referenceHeaderPortal"></ng-template>
<div class="zwi-reference-content" [ngSwitch]="currentView" cdkMonitorSubtreeFocus tabindex="-1">
  <zwi-chapter-view
    *ngSwitchCase="'chapter'"
    [activeReference]="activeReference"
    [selected]="selected"
    [maxReference]="maxReference"
    [minReference]="minReference"
    (close)="close()"
    (selectedChange)="_verseSelected($event)">
  </zwi-chapter-view>
  <zwi-book-view
    *ngSwitchCase="'book'"
    [activeReference]="activeReference"
    [selected]="selected"
    [maxReference]="maxReference"
    [minReference]="minReference"
    (close)="close()"
    (chapterSelected)="_chapterSelected($event)"
    (selectedChange)="_goToReferenceInView($event, 'chapter')">
  </zwi-book-view>
  <zwi-bible-view
    *ngSwitchCase="'bible'"
    [activeReference]="activeReference"
    [selected]="selected"
    [maxReference]="maxReference"
    [minReference]="minReference"
    (bookSelected)="_bookSelected($event)"
    (close)="close()"
    (selectedChange)="_goToReferenceInView($event, 'book')">
  </zwi-bible-view>
</div>
<ng-template *ngIf="currentView !== 'bible'" [cdkPortalOutlet]="_referenceFooterPortal"></ng-template>
<!--    [(activeReference)]="activeReference"-->
