import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from '@zwiloo/user/account';
import { CancelAccountService } from '@zwiloo/user/context/cancel-account.service';

@Component({
  selector: 'zwi-account-close-dialog-content',
  templateUrl: './account-close-dialog-content.component.html',
  styleUrls: ['./account-close-dialog-content.component.scss'],
})
export class AccountCloseDialogContentComponent {
  ack = false;
  email: string | null = '';
  sent = false;
  @Output() public modal_principal_parent = new EventEmitter();
  constructor(
    private router: Router,
    private accountService: AccountService,
    private cancelAccountService: CancelAccountService
  ) {
    this.email = accountService.getAccountEmail();
  }

  closeDialog() {
    this.modal_principal_parent.emit('CLOSE_PARENT_MODAL');
  }

  sendCloseAccountEmail() {
    this.sent = true;
    this.cancelAccountService.closeAccountToken();
  }

  updateAck(checked: boolean) {
    this.ack = checked;
  }
}
