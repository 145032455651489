<div class="zwi-section">
  <div class="zwi-order-options">
    <mat-button-toggle-group [value]="order()" (change)="setOrder($event)">
      <mat-button-toggle value="biblical">Biblical</mat-button-toggle>
      <mat-button-toggle value="alpha">Alphabetical</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div>
    <mat-accordion displayMode="flat">
      @for (b of books(); track b.id) {
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            {{ b.name }}
          </mat-expansion-panel-header>
          <div class="zwi-block-section">
            <button mat-flat-button (click)="emitBookChapterSelected({book: b.id, chapter: 0})">All chapters</button>
            <div class="zwi-chapter-grid">
              @for (c of chapters(b.id); track $index) {
                <div class="zwi-chapter-cell" (click)="emitBookChapterSelected({book: b.id, chapter: $index + 1})">{{ $index + 1 }}</div>
              }
            </div>
          </div>
        </mat-expansion-panel>
      }
    </mat-accordion>
  </div>

</div>

