<div class="zwi-banner" [class]="{'zwi-banner-native': isNative}">
  <div class="zwi-column-center zwi-block-section">
    <div class="zwi-column-center">
      <ng-content select="[title]"></ng-content>
      <ng-content select="[content]"></ng-content>
    </div>
    <ng-content select="[action]"></ng-content>
  </div>
  <mat-icon class="zwi-banner-close" (click)="emitClose()" [class]="{'zwi-banner-close--android': isAndroid}">close</mat-icon>
</div>
