import { Subject } from 'rxjs';

import {
  AfterContentInit,
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import { ComponentPortal, ComponentType, Portal } from '@angular/cdk/portal';

import { BibleViewComponent } from '../bible-view/bible-view.component';
import { BookViewComponent } from '../book-view/book-view.component';
import { ChapterViewComponent } from '../chapter-view/chapter-view.component';
import { ReferenceAdapter } from '@zwiloo/verse/domain/reference-adapter';
import { Reference, ReferenceRange } from '@zwiloo/verse/domain/reference';
import {
  ReferenceFormats,
  ZWI_REFERENCE_FORMATS,
} from '@zwiloo/verse/domain/reference-formats';

export type ZwiReferenceView = 'chapter' | 'book' | 'bible';

@Component({
  selector: 'zwi-reference-header',
  templateUrl: '../reference-header/reference-header.component.html',
  exportAs: 'zwiReferenceHeader',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReferenceHeader {
  constructor(
    @Inject(forwardRef(() => ReferencePortalComponent))
    public referencePortal: ReferencePortalComponent,
    @Inject(ZWI_REFERENCE_FORMATS) private _referenceFormats: ReferenceFormats,
    @Optional() private _referenceAdapter: ReferenceAdapter,
    changeDetectorRef: ChangeDetectorRef
  ) {
    this.referencePortal.stateChanges.subscribe(() =>
      changeDetectorRef.markForCheck()
    );
  }

  get titleButtonText(): string {
    if (this.referencePortal.currentView === 'chapter') {
      return this._referenceAdapter.format(
        this.referencePortal.activeReference,
        this._referenceFormats.display.bookChapterLabel
      );
    }
    if (this.referencePortal.currentView === 'book') {
      return this._referenceAdapter.getBookName(
        this.referencePortal.activeReference
      );
    } else {
      return '';
    }
  }

  get titleButtonLabel(): string {
    return 'Title';
  }

  get prevButtonLabel(): string {
    return {
      chapter: 'chapter',
      book: 'book',
      bible: 'bible',
    }[this.referencePortal.currentView];
  }

  get nextButtonLabel(): string {
    return {
      chapter: 'chapter',
      book: 'book',
      bible: 'bible',
    }[this.referencePortal.currentView];
  }

  currentTitleClicked(): void {
    this.referencePortal.currentView =
      this.referencePortal.currentView === 'book' ? 'bible' : 'book';
    this.referencePortal.setBibleMinMax();
  }

  previousClicked(): void {
    this.referencePortal.activeReference =
      this.referencePortal.currentView === 'chapter'
        ? this._referenceAdapter.addReferenceChapters(
            this.referencePortal.activeReference,
            -1
          )
        : this._referenceAdapter.addReferenceBooks(
            this.referencePortal.activeReference,
            -1
          );
  }

  nextClicked(): void {
    this.referencePortal.activeReference =
      this.referencePortal.currentView === 'chapter'
        ? this._referenceAdapter.addReferenceChapters(
            this.referencePortal.activeReference,
            1
          )
        : this._referenceAdapter.addReferenceBooks(
            this.referencePortal.activeReference,
            1
          );
  }

  previousEnabled(): boolean {
    if (this.referencePortal.minReference !== null) {
      return !this._isSameView(
        this.referencePortal.activeReference,
        this.referencePortal.minReference
      );
    } else {
      return false;
    }
  }

  nextEnabled(): boolean {
    if (this.referencePortal.maxReference !== null) {
      return !this._isSameView(
        this.referencePortal.activeReference,
        this.referencePortal.maxReference
      );
    } else {
      return false;
    }
  }

  private _isSameView(r1: Reference, r2: Reference): boolean {
    if (this.referencePortal.currentView === 'chapter') {
      return (
        this._referenceAdapter.getBook(r1) ===
          this._referenceAdapter.getBook(r2) &&
        this._referenceAdapter.getChapter(r1) ===
          this._referenceAdapter.getChapter(r2)
      );
    }
    if (this.referencePortal.currentView === 'book') {
      return (
        this._referenceAdapter.getBook(r1) ===
        this._referenceAdapter.getBook(r2)
      );
    } else {
      return false;
    }
  }
}

@Component({
  selector: 'zwi-reference-footer',
  templateUrl: '../reference-footer/reference-footer.component.html',
  exportAs: 'zwiReferenceFooter',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReferenceFooter {
  constructor(
    @Inject(forwardRef(() => ReferencePortalComponent))
    public referencePortal: ReferencePortalComponent,
    @Inject(ZWI_REFERENCE_FORMATS) private _referenceFormats: ReferenceFormats,
    @Optional() private _referenceAdapter: ReferenceAdapter,
    changeDetectorRef: ChangeDetectorRef
  ) {
    this.referencePortal.stateChanges.subscribe(() =>
      changeDetectorRef.markForCheck()
    );
  }

  done() {
    this.referencePortal.close();
  }
}

@Component({
  selector: 'zwi-reference-portal',
  templateUrl: './reference-portal.component.html',
  styleUrls: ['./reference-portal.component.scss'],
  host: {
    class: 'zwi-reference',
  },
  exportAs: 'zwiReference',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReferencePortalComponent
  implements AfterContentInit, AfterViewChecked, OnDestroy, OnChanges
{
  @Input() headerComponent!: ComponentType<any>;

  _referenceHeaderPortal!: Portal<any>;

  @Input() footerComponent!: ComponentType<any>;

  _referenceFooterPortal!: Portal<any>;

  private _moveFocusOnNextTick = false;

  @Input()
  get startAt(): Reference | null {
    return this._startAt;
  }
  set startAt(value: Reference | null) {
    this._startAt = value;
  }
  private _startAt: Reference | null = null;

  @Input() startView: ZwiReferenceView = 'chapter';

  @Input()
  get selected(): ReferenceRange | null {
    return this._selected;
  }
  set selected(value: ReferenceRange | null) {
    this._selected = value;
  }
  private _selected: ReferenceRange | null = null;

  @Input()
  get minReference(): Reference | null {
    return this._minReference;
  }
  set minReference(value: Reference | null) {
    this._minReference = value;
  }
  private _minReference: Reference | null = null;

  @Input()
  get maxReference(): Reference | null {
    return this._maxReference;
  }
  set maxReference(value: Reference | null) {
    this._maxReference = value;
  }
  private _maxReference: Reference | null = null;

  @Output() readonly selectedChange: EventEmitter<Reference> =
    new EventEmitter<Reference>();
  @Output() readonly bookSelected: EventEmitter<Reference> =
    new EventEmitter<Reference>();
  @Output() readonly chapterSelected: EventEmitter<Reference> =
    new EventEmitter<Reference>();

  @Output() closeDialog = new EventEmitter();

  @ViewChild(BibleViewComponent)
  bibleView!: BibleViewComponent;
  @ViewChild(BookViewComponent) bookView!: BookViewComponent;
  @ViewChild(ChapterViewComponent)
  chapterView!: ChapterViewComponent;

  get currentView(): ZwiReferenceView {
    return this._currentView;
  }
  set currentView(value: ZwiReferenceView) {
    this._currentView = value;
    this._moveFocusOnNextTick = true;
  }
  private _currentView!: ZwiReferenceView;

  stateChanges = new Subject<void>();

  get activeReference(): Reference {
    return this._activeReference;
  }
  set activeReference(value: Reference) {
    this._activeReference = value;
    this.stateChanges.next();
  }
  private _activeReference = new Reference();

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    @Optional() public _referenceAdapter: ReferenceAdapter
  ) {}

  ngAfterContentInit() {
    this._referenceHeaderPortal = new ComponentPortal(
      this.headerComponent || ReferenceHeader
    );
    this._referenceFooterPortal = new ComponentPortal(
      this.footerComponent || ReferenceFooter
    );
    this.minReference = this._referenceAdapter.first();
    this.maxReference = this._referenceAdapter.last();
    if (this.selected !== null && this.selected.begin !== null) {
      this.activeReference = this.selected.begin;
    } else {
      this.activeReference = this.startAt || this.minReference;
      // this.selectedChange.emit(this.activeReference);
    }

    this._currentView = this.startView;
  }

  ngAfterViewChecked() {
    if (this._moveFocusOnNextTick) {
      this._moveFocusOnNextTick = false;
      this.focusActiveCell();
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
  }

  ngOnChanges(changes: SimpleChanges) {}

  focusActiveCell() {
    this._getCurrentViewComponent()._focusActiveCell();
  }

  _verseSelected(reference: Reference): void {
    this.activeReference = reference;
    this.selectedChange.emit(reference);
  }

  _bookSelected(reference: Reference) {
    this.setBibleMinMax();
    this.bookSelected.emit(reference);
    this.selectedChange.emit(reference);
  }

  _chapterSelected(reference: Reference) {
    this._setBookMinMax(reference);
    this.chapterSelected.emit(reference);
    this.selectedChange.emit(reference);
  }

  _goToReferenceInView(
    reference: Reference,
    view: 'chapter' | 'book' | 'bible'
  ) {
    this.activeReference = reference;
    this.currentView = view;
  }

  private _getCurrentViewComponent() {
    return this.chapterView || this.bookView || this.bibleView;
  }

  close() {
    this.closeDialog.emit();
  }

  setBibleMinMax() {
    this.minReference = this._referenceAdapter.first();
    this.maxReference = this._referenceAdapter.last();
  }

  private _setBookMinMax(reference: Reference) {
    if (this.selected !== null && this.selected.begin !== null) {
      this.minReference = this._referenceAdapter.bookFirst(reference.book);
      this.maxReference = this._referenceAdapter.bookLast(reference.book);
    }
  }
}
