import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import {
  ReferenceBodyComponent,
  ReferenceCell,
} from '../reference-body/reference-body.component';
import { ReferenceAdapter } from '@zwiloo/verse/domain/reference-adapter';
import { Reference, ReferenceRange } from '@zwiloo/verse/domain/reference';

const CHAPTERS_PER_ROW = 10;

@Component({
  selector: 'zwi-book-view',
  templateUrl: './book-view.component.html',
  styleUrls: ['./book-view.component.scss'],
})
export class BookViewComponent implements AfterContentInit {
  @Input()
  get activeReference(): Reference {
    return this._activeReference;
  }
  set activeReference(value: Reference) {
    const oldActiveReference = this._activeReference;
    this._activeReference = value;
    if (
      this._referenceAdapter.getBook(oldActiveReference) !==
      this._referenceAdapter.getBook(this._activeReference)
    ) {
      this._init();
    }
  }
  private _activeReference: Reference;

  @Input()
  get selected(): ReferenceRange | null {
    return this._selected;
  }
  set selected(value: ReferenceRange | null) {
    this._selected = value;
    if (this._selected !== null) {
      this._selectedChapter = this._getChapterInCurrentBook(
        this._selected.begin
      );
    }
  }
  private _selected: ReferenceRange | null = null;

  @Input()
  get minReference(): Reference | null {
    return this._minReference;
  }
  set minReference(value: Reference | null) {
    this._minReference = value;
  }
  private _minReference: Reference | null = null;

  @Input()
  get maxReference(): Reference | null {
    return this._maxReference;
  }
  set maxReference(value: Reference | null) {
    this._maxReference = value;
  }
  private _maxReference: Reference | null = null;

  @Output() readonly selectedChange: EventEmitter<Reference> =
    new EventEmitter<Reference>();
  @Output() readonly chapterSelected: EventEmitter<Reference> =
    new EventEmitter<Reference>();

  @ViewChild(ReferenceBodyComponent) _referenceBody!: ReferenceBodyComponent;

  _chapters: ReferenceCell[][] = [[]];

  _selectedChapter = 0;

  constructor(@Optional() public _referenceAdapter: ReferenceAdapter) {
    this._activeReference = this._referenceAdapter.first();
  }

  ngAfterContentInit() {
    this._init();
  }

  _chapterSelected(chapter: number) {
    const reference = this._referenceAdapter.createReference(
      this._referenceAdapter.getBook(this.activeReference),
      chapter
    );
    this.chapterSelected.emit(reference);
    this.selectedChange.emit(reference);
  }

  _init() {
    this._createChapterCells();
  }

  private _getChapterInCurrentBook(reference: Reference) {
    return this._referenceAdapter.getBook(reference) ===
      this._referenceAdapter.getBook(this.activeReference)
      ? this._referenceAdapter.getChapter(reference)
      : 0;
  }

  private _createChapterCells() {
    const chaptersInBook = this._referenceAdapter.getNumChaptersInBook(
      this.activeReference
    );
    this._chapters = [[]];
    for (let i = 0, cell = 0; i < chaptersInBook; i++, cell++) {
      if (cell === CHAPTERS_PER_ROW) {
        this._chapters.push([]);
        cell = 0;
      }
      const chapter = i + 1;
      this._chapters[this._chapters.length - 1].push(
        new ReferenceCell(chapter, String(chapter), String(chapter), true)
      );
    }
  }

  _focusActiveCell() {
    this._referenceBody._focusActiveCell();
  }

  getActiveCell() {
    if (this.activeReference) {
      const chapter = this._referenceAdapter.getChapter(this.activeReference);
      return chapter > 0 ? chapter : 1;
    } else {
      return 1;
    }
  }
}
