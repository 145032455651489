
<zwi-main-wrapper>
  <zwi-main-content>
    <div class="zwi-section">
      <zwi-back-headline (navigateBack)="navigateHome()">Settings</zwi-back-headline>
      <mat-list role="list">
        <mat-list-item role="listitem" routerLink="/mobile-email">
          <div matListItemTitle>Email</div>
          <div matListItemMeta>
            <mat-icon fontIcon="chevron_right"></mat-icon>
          </div>
        </mat-list-item>
        <mat-list-item role="listitem" routerLink="/mobile-password">
          <div matListItemTitle>Password</div>
          <div matListItemMeta>
            <mat-icon fontIcon="chevron_right"></mat-icon>
          </div>
        </mat-list-item>
        <mat-list-item role="listitem" routerLink="/mobile-account">
          <div matListItemTitle>Account</div>
          <div matListItemMeta>
            <mat-icon fontIcon="chevron_right"></mat-icon>
          </div>
        </mat-list-item>
      </mat-list>
      <div class="zwi-justify-center">
        <button mat-flat-button color="primary" (click)="signOut()">Sign out</button>
      </div>
    </div>
  </zwi-main-content>
</zwi-main-wrapper>

