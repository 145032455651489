import { UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export class SubmitErrorStateMatcher implements ErrorStateMatcher {
  public force = false;

  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return Boolean((isSubmitted || this.force) && control?.invalid);
  }
}
