<table class="zwi-reference-table">
  <thead class="zwi-reference-table-header">
    <tr><th class="zwi-reference-table-header-divider" colspan="10"></th></tr>
  </thead>
  <tbody zwi-reference-body
         [activeCell]="getActiveCell()"
         [rows]="_chapters"
         [beginValue]="_selectedChapter"
         [endValue]="_selectedChapter"
         (selectedValueChange)="_chapterSelected($event)">
<!--         (keydown)="_handleReferenceBodyKeydown($event)">-->
<!--[selectedValue]="[_selectedChapter]"-->
  </tbody>
</table>
