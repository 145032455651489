import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AnonymousGuardService } from './services/anonymous-guard.service';
import { AuthGuard } from './services/auth-guard.service';
import { MinistryGuard } from './services/ministry-guard.service';
import { OnboardingGuard } from '@zwiloo/frontend/core/services/onboarding-guard.service';

@NgModule({
  imports: [CommonModule],
  providers: [AnonymousGuardService, AuthGuard, MinistryGuard, OnboardingGuard],
})
export class CoreModule {}
