import { Subscription } from 'rxjs';

import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';

import { MinistryService } from '@zwiloo/ministry/ministry.service';
import { Ministry } from '@zwiloo/ministry/domain';
import { SubscribeChange } from '../subscribe-button/subscribe-button.component';
import { Sermon } from '@zwiloo/sermon/domain';

@Component({
  selector: 'zwi-ministry-info-header',
  templateUrl: './ministry-info-header.component.html',
  styleUrls: ['./ministry-info-header.component.scss'],
})
export class MinistryInfoHeaderComponent implements OnDestroy {
  @Input() ministry = new Ministry();
  @Input() sermons: Sermon[] = [];
  @Output() subscribeChange: EventEmitter<SubscribeChange> = new EventEmitter();
  subscription: Subscription;

  constructor(private ministryService: MinistryService) {
    this.subscription = ministryService.ministry.subscribe((m) => {
      this.ministry = m;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public changeSubscription(change: SubscribeChange) {
    this.subscribeChange.emit(change);
    if (change.value === 'subscribe') {
      this.ministryService.subscribe(change.source.ministry);
    } else if (change.value === 'unsubscribe') {
      this.ministryService.unsubscribe(change.source.ministry);
    }
  }
}
