<audio #audioRef type="audio/mpeg" (ended)="pause()" (timeupdate)="updateTime($event)" (durationchange)="updateDuration($event)"></audio>
<div class="zwi-player-container" style="display: flex; align-items: center; flex: 1 1;">
  <div class="zwi-main-player-container">
    <div class="zwi-progress-container">
      <mat-icon (click)="subtractTenSeconds()" class="zwi-seek-back-button">replay_10</mat-icon>
      <mat-slider color="accent" disableRipple="true" class="zwi-progress-bar" style="margin-left: 24px; margin-right: 24px;">
        <input [value]="percent()" (dragEnd)="changeTimeByPercent($event.value)" matSliderThumb />
      </mat-slider>
      <mat-icon (click)="addTenSeconds()" class="zwi-seek-forward-button">forward_10</mat-icon>
      <!--    <button mat-icon-button type="button" class="zwi-reference-previous-button"-->
    </div>
    <zwi-time-display [currentTime]="remainingTime()"></zwi-time-display>
  </div>
  @if (sermonService.status() === 'PLAYING') {
    <mat-icon class="zwi-play-button" (click)="pause()">pause_circle</mat-icon>
  } @else {
    <mat-icon class="zwi-play-button" (click)="play()">play_circle</mat-icon>
  }
</div>
<!--<div class="zwi-player-wrapper">-->
<!--  -->
<!--</div>-->
